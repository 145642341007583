import ComposeInternalLayout from "../components/HOC/ComposeInternalLayout";
import { foundersUrl } from "../url/urls";
import Dashboard from "../pages/founder/Dashboard/Dashboard";
import AccountSettings from "../pages/founder/Settings/AccountSettings";
// import Connections from "../pages/founder/Connections/Connections";
import Connections from "../pages/experts/Connections/Connections";
import Investors from "../pages/founder/Investors/Investors";
import StartupOffers from "../pages/founder/Startup/StartupOffers";
import Experts from "../pages/founder/Experts/Experts";
import Advert from "../pages/founder/Advert/Advert";
import ViewAdvertPage from "../pages/founder/Advert/ViewAdvertPage";
import PDFViewer from "../pages/founder/Settings/PDFViewer";
import VideoViewer from "../pages/founder/Settings/VideoViewer";
import MyBookings from "../pages/founder/Bookings";
import Feed from "../pages/founder/Feed";
import PostPage from "../pages/founder/Post";


export const foundersRoutes = [
  {
    title: "Dashboard",
    path: foundersUrl.dashboard(),
    element: ComposeInternalLayout(Dashboard),
  },
  {
    title: "Feed",
    path: foundersUrl.feed(),
    element: ComposeInternalLayout(Feed),
  },
  {
    title: "Post",
    path: foundersUrl.post(),
    element: ComposeInternalLayout(PostPage),
  },
  {
    title: "Connections",
    path: foundersUrl.connections(),
    element: ComposeInternalLayout(Connections),
  },
  {
    title: "Investors",
    path: foundersUrl.investors(),
    element: ComposeInternalLayout(Investors),
  },
  {
    title: "Startup Offers",
    path: foundersUrl.startupOffers(),
    element: ComposeInternalLayout(StartupOffers),
  },
  {
    title: 'Bookings',
    path: foundersUrl.bookings(),
    element: ComposeInternalLayout(MyBookings),
  },
  {
    title: "Experts",
    path: foundersUrl.experts(),
    element: ComposeInternalLayout(Experts),
  },
  {
    title: "Account Settings",
    path: foundersUrl.accountSettings(),
    element: ComposeInternalLayout(AccountSettings),
  },
  {
    title: "Advert",
    path: foundersUrl.advert(),
    element: ComposeInternalLayout(Advert),
  },
  {
    title: "View Advert",
    path: foundersUrl.viewAdvert(),
    element: ComposeInternalLayout(ViewAdvertPage),
  },
  {
    title: "View Pitch Deck",
    path: foundersUrl.pdfViewer(),
    element: ComposeInternalLayout(PDFViewer),
  },
  {
    title: "Video Viewer",
    path: foundersUrl.videoViewer(),
    element: ComposeInternalLayout(VideoViewer),
  },
 
];
