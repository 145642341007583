import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import Header from "../../../components/header/Header";
import SubscribersCountCard from "./SubscribersCountCard";
import SubscriptionsTable from "./SubscriptionsTable";
import useData from "../../../data/useData";

const Subscriptions = () => {
    const tableHeaders = ["user", "plan", "price", "payment due", "actions"]
    const [subscriptions, setSubscriptions] = useState()
    const [count, setCount] = useState()


    // const subsdata = {
    //     totalCount: 323,
    //     basicCount: 123,
    //     premiumCount: 200,
    //     subscribers: [
    //         {
    //             plan: "premium",
    //             price: "$20",
    //             paymentDue: "01/01/2023",
    //             user: {
    //                 profile_image: "https://media.vanityfair.com/photos/620150a759963d2b4b169b8e/master/pass/Lashana%20Lynch%20Headshot%20-%20credit%20Greg%20Williams.png", 
    //                 last_name: "Lynch",
    //                 first_name: "Lashana"
    //             }
    //         },
    //         {
    //             plan: "basic",
    //             price: "",
    //             paymentDue: "01/01/2023",
    //             user: {
    //                 profile_image: "https://www.hollywoodreporter.com/wp-content/uploads/2022/04/GettyImages-1184046935.jpg?w=1296", 
    //                 last_name: "Davis",
    //                 first_name: "Viola"
    //             }
    //         },
    //         {
    //             plan: "premium",
    //             price: "$20",
    //             paymentDue: "01/01/2023",
    //             user: {
    //                 profile_image: "https://media.vanityfair.com/photos/620150a759963d2b4b169b8e/master/pass/Lashana%20Lynch%20Headshot%20-%20credit%20Greg%20Williams.png", 
    //                 last_name: "Lynch",
    //                 first_name: "Lashana"
    //             }
    //         },
    //         {
    //             plan: "premium",
    //             price: "$20",
    //             paymentDue: "01/01/2023",
    //             user: {
    //                 profile_image: "https://www.southernliving.com/thmb/wainSFqEpPUw_YvUoJN6SHuhCDU=/1200x0/filters:no_upscale():max_bytes(150000):strip_icc():gifv()/GettyImages-1390953042-2000-a50b7a3f93d64972a9499150a06a44a3.jpg", 
    //                 last_name: "Mackie",
    //                 first_name: "Anthony"
    //             }
    //         },
    //         {
    //             plan: "premium",
    //             price: "$20",
    //             paymentDue: "01/01/2023",
    //             user: {
    //                 profile_image: "https://media.vanityfair.com/photos/620150a759963d2b4b169b8e/master/pass/Lashana%20Lynch%20Headshot%20-%20credit%20Greg%20Williams.png", 
    //                 last_name: "Lynch",
    //                 first_name: "Lashana"
    //             }
    //         },
    //         {
    //             plan: "basic",
    //             price: "",
    //             paymentDue: "01/01/2023",
    //             user: {
    //                 profile_image: "https://www.goldderby.com/wp-content/uploads/2017/03/tom-holland-spider-man-homecoming.jpg?w=620&h=360&crop=1", 
    //                 last_name: "Parker",
    //                 first_name: "Peter"
    //             }
    //         }
            
    //     ]
    // }
    const {data: subsdata, isLoading, isError} = useData("subscriptions")

    useEffect(()=>{
        if (subsdata?.data){
            setSubscriptions(subsdata?.data?.subscriptions)
            setCount(subsdata?.data?.subscriptionCount?.length)
        }
    },[subsdata])


    return (
        <>
            <Box>
                <Header title='Subscriptions'  />
                <Box px={15} py={5}>

                    <SubscribersCountCard data={count}/>
                    <SubscriptionsTable isLoading={isLoading} header={tableHeaders} data={subscriptions}/>
                </Box>
            </Box>
        </>
    )
}
export default Subscriptions;