import React from "react";
import { Box, Image, Text, Button, Flex, Center, HStack, useDisclosure, MenuButton, Menu, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import IsDesktop from "../../utils/IsDesktop";
import { GiHamburgerMenu } from "react-icons/gi";
import Pitch from "../../components/pitch/Pitch";
import Portfolio from "../../components/portfolio/Portfolio";
import Offers from "../../components/offers/Offers";
import BookCall from "../../components/bookCall/BookCall";
import { IoIosArrowForward } from "react-icons/io";
import { FiLinkedin } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { CgInstagram } from "react-icons/cg";
import { FiYoutube } from "react-icons/fi";
import { FiTwitter } from "react-icons/fi";
import BrandButton from "../../components/brandButton/BrandButton";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { Link as RouterLink } from 'react-router-dom';
import BackToTop from "../../components/backToTop";



function Homepage() {
  const isDesktop = IsDesktop();
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box w="100%" boxSizing="border-box">
      {/* ......navbar */}
      <Box bg="white" px={isDesktop ? 12 : 5} py={8} position="fixed" w="100vw" zIndex="banner" boxShadow="0px .1px 5px black">
        <Flex alignItems="center" justify="space-between">
          <Image h={isDesktop ? "50px" : "30px"} src="assets/images/image 1.png" alt="logo" />

          {isDesktop ? (
            <Flex alignItems="center" justify="space-between" w={1000}>
              <Flex alignItems="center" justify="space-between" w={600}>
                <Link to="how-it-works" spy={true} smooth={true} offset={-150} duration={500}>
                  <Text cursor="pointer" color="brand.primary">
                    How it works
                  </Text>
                </Link>
                <Link to="featured-projects" spy={true} smooth={true} offset={-150} duration={500}>
                  <Text cursor="pointer" color="brand.primary">
                    Featured projects
                  </Text>
                </Link>
                <Link to="expert-booking" spy={true} smooth={true} offset={-100} duration={500}>
                  <Text cursor="pointer" color="brand.primary">
                    Expert Booking
                  </Text>
                </Link>
                <Link to="startup-offers" spy={true} smooth={true} offset={-150} duration={500}>
                  <Text cursor="pointer" color="brand.primary">
                    Startup Offers
                  </Text>
                </Link>
              </Flex>

              <Flex justify="space-between" w={350}>
                <BrandButton text="Sign in" colorScheme="blue" onClick={() => navigate("/login")} />
                <BrandButton w="190px" text="Create An Account" colorScheme="blue" onClick={() => navigate("/signUp")} />
              </Flex>
            </Flex>
          ) : (
            <>
              {/* <GiHamburgerMenu size={20} onClick={toggleNav} onBlur={toggleNav}/> */}
              <Menu>
                <MenuButton as={IconButton} aria-label="Options" icon={<GiHamburgerMenu />} variant="outline" />
                <MenuList h="250px" w="100%">
                  <Link to="how-it-works" spy={true} smooth={true} offset={-150} duration={500}>
                    <MenuItem my={2}>
                      <Text color="brand.primary">How it works</Text>
                    </MenuItem>
                  </Link>
                  <Link to="featured-projects" spy={true} smooth={true} offset={-150} duration={500}>
                    <MenuItem my={2}>
                      <Text color="brand.primary">Featured projects</Text>
                    </MenuItem>
                  </Link>
                  <Link to="expert-booking-mobile" spy={true} smooth={true} offset={-50} duration={500}>
                    <MenuItem my={2}>
                      <Text color="brand.primary">Expert Booking</Text>
                    </MenuItem>
                  </Link>
                  <Link to="startup-offers" spy={true} smooth={true} offset={-150} duration={500}>
                    <MenuItem my={2}>
                      <Text color="brand.primary">Startup Offers</Text>
                    </MenuItem>
                  </Link>
                  <MenuItem my={2}>
                    <Box onClick={() => navigate("/login")} w="100%">
                      <Text color="brand.primary" _hover={{ textDecoration: "underline" }}>
                        Sign In
                      </Text>
                    </Box>
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          )}
        </Flex>
      </Box>
      {/* {(!isDesktop && isOpen) && (
      <Flex flexDir="column" alignItems="center" justify="space-between" w="100%" bg="red">
                  <Box><Text>How it works</Text></Box>
                  <Box><Text>Featured projects</Text></Box>
                  <Box><Text>Expert Booking</Text></Box>
                  <Box><Text>Startup Offers</Text></Box>
      </Flex>)} */}

      {/* . end.....navbar */}

      {/* .........banner */}

      <Box
        // height={600}
        backgroundImage="url('assets/images/image 6.png')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        h="80vh"
        w="100%"
        // pl={{ md: 139, sm: 10 }}
        // pr={{ md: 154, sm: 40 }}
      >
        {/* ..1 */}
        <HStack px={isDesktop ? 20 : 10} justify="flex-start" alignItems="center" h="100%" w="100%" bg="rgba(0,0,0,0.4)">
          <Box w={isDesktop ? "40%" : "100%"}>
            <Text
              // fontFamily="Poppins"
              // fontStyle="normal"
              fontWeight={700}
              fontSize={{ base: "24px", md: "30px", xl: "40px" }}
              // lineHeight="72px"
              color="#FFFFFF"
            >
              Connect With Investors All Over The World
            </Text>
            <Text fontFamily="Poppins" fontStyle="normal" fontWeight={400} fontSize="20px" lineHeight="30px" color="#FFFFFF">
            Tap into a vast network of enthusiastic investors or engage directly with professionals specifically seeking start-ups like yours.
            </Text>
            <Box mt={8}>
              <Flex flexDir={isDesktop ? "row" : "column"} justify="space-between" w={isDesktop ? 350 : "100%"}>
                <BrandButton m={2} fontSize={isDesktop ? "15px" : "12px"} h="60px" text="Join As Founder" w={isDesktop ? "170px" : "130px"} colorScheme="blue" onClick={() => navigate("/signup")} />
                <BrandButton m={2} fontSize={isDesktop ? "15px" : "12px"} h="60px" text="Join As Investor" w={isDesktop ? "170px" : "130px"} bg="" borderColor="white" color="white" onClick={() => navigate("/signup")} _hover={{ color: "brand.primary", bg: "" }} />
                {/* {!isDesktop && <BrandButton m={2} fontSize={isDesktop ? "15px" : "12px"} h="60px" text="Sign In" w="130px" colorScheme="blue" onClick={() => navigate("/login")} />} */}
              </Flex>
            </Box>
          </Box>
          {/* ....2 */}
          {isDesktop ? (
            <>
              <HStack justifyContent="center" alignItems="center" w="60%" h="100%" pt={20}>
                <Image src="assets/images/laptop.png" />
              </HStack>
            </>
          ) : (
            <></>
          )}
        </HStack>
      </Box>

      {/* ..end.......banner */}

      {/* ....start pitchingHeading */}

      <Box mt={isDesktop ? 24 : 18} id="how-it-works">
        <Center>
          {" "}
          <Text textAlign="center" color="#000000" fontFamily="Poppins" fontStyle="normal" fontWeight={600} fontSize="32px" lineHeight="48px">
            We create the platform, you do the convincing.
          </Text>
        </Center>
        <Center>
          {" "}
          <Text textAlign="center" mt={4} fontFamily="Poppins" fontStyle="normal" fontWeight={400} fontSize="16px" lineHeight="24px" color="#9FA7AD">
          Seed A Founder Marketplace: Empowering African founders with worldwide exposure.
          </Text>
        </Center>
      </Box>
      {/* ...end... pitchingHeading */}

      {/* ...start...pitchingBox */}
      {isDesktop ? (
        <Box mt={24} pl={120}>
          <Flex justify="space-evenly">
            <Box w={389} mt={36}>
              <Text color="brand.primary" fontFamily="Poppins" fontStyle="normal" fontWeight="600" fontSize="32px" lineHeight="48px">
                Pitching doesn’t have to be tensioning.
              </Text>
              <Text fontFamily="Poppins" fontStyle="normal" fontWeight="400" fontSize="16px" lineHeight="24px" color="#9FA7AD">
              Seed A Founder Marketplace: Where funding becomes accessible, and your exposure reaches new horizons.
              </Text>
              <RouterLink to="/login">
      <Button mt={6}>Get Started Already</Button>
    </RouterLink>
            </Box>
            <Box>
              <Flex gap={6}>
                <Pitch image={"assets/images/icons8-accuracy 1.png"} title={"Upload a killer pitch deck"} describe={"Easily upload an attention-grabbing pitch deck that showcases your ideas with impact."} />
                <Pitch image={"assets/images/icons4-connect.png"} title={"Connect with Investor"} describe={"Access a diverse network of investors who are eager to discover your potential."} />
              </Flex>
              <Flex gap={6} mt={6}>
                <Pitch image={"assets/images/icons8-handshake.png"} title={"Have mutual agreement"} describe={"Streamline negotiations and secure deals that benefit both you and your investors."} />
                <Pitch image={"assets/images/icons8-document.png"} title={"Deal! We take it from there"} describe={"From pitch to funding, we'll guide you every step of the way, ensuring your ideas soar to new heights."} />
              </Flex>
            </Box>
          </Flex>
        </Box>
      ) : (
        <>
          <Flex flexDir="column">
            <Box mt={36}>
              <Center>
                {" "}
                <Text textAlign="center" color="brand.primary" fontFamily="Poppins" fontStyle="normal" fontWeight="600" fontSize="32px" lineHeight="48px">
                  Pitching doesn’t have to be tensioning.
                </Text>
              </Center>
              <Center>
                {" "}
                <Text textAlign="center" fontFamily="Poppins" fontStyle="normal" fontWeight="400" fontSize="16px" lineHeight="24px" color="#9FA7AD">
                Seed A Founder Marketplace: Where funding becomes accessible, and your exposure reaches new horizons.                </Text>
              </Center>
              <Center>
                {" "}
                <RouterLink to="/login">
      <Button mt={6}>Get Started Already</Button>
    </RouterLink>
              </Center>
            </Box>
            <Box margin="auto">
              <Flex flexDir="column" gap={6} mt={10}>
                <Pitch image={"assets/images/icons8-accuracy 1.png"} title={"Upload a killer pitch deck"} describe={"Easily upload an attention-grabbing pitch deck that showcases your ideas with impact."} mt={10} />

                <Pitch image={"assets/images/icons4-connect.png"} title={"Connect with Investor"} describe={"Access a diverse network of investors who are eager to discover your potential."} />
                <Pitch image={"assets/images/icons8-handshake.png"} title={"Have mutual agreement"} describe={"Streamline negotiations and secure deals that benefit both you and your investors."} />
                <Pitch image={"assets/images/icons8-document.png"} title={"Deal! We take it from there"} describe={"From pitch to funding, we'll guide you every step of the way, ensuring your ideas soar to new heights."} />
              </Flex>
            </Box>
          </Flex>
        </>
      )}

      {/* ....end....pitchingBox */}

      {/* start...memeber project invested */}
      {isDesktop ? (
        <>
          <Box mt="24">
            <Center>
              <Flex>
                <Image src="assets/images/item (3).png" />
                <Image src="assets/images/item (2).png" />
                <Image src="assets/images/item (1).png" />
              </Flex>
            </Center>
          </Box>
          {/* end....memeber project invested */}
        </>
      ) : (
        <>
          <Box mt="24">
            <Center>
              <Flex flexDir="column" gap={6}>
                <Image src="assets/images/item (3).png" />
                <Image src="assets/images/item (2).png" />
                <Image src="assets/images/item (1).png" />
              </Flex>
            </Center>
          </Box>
        </>
      )}

      {/* ...start opportunities */}
      <Box mt={24} id="featured-projects">
        <Center>
          {" "}
          <Text textAlign="center" color="#000000" fontFamily="Poppins" fontStyle="normal" fontWeight={600} fontSize="32px" lineHeight="48px">
            Featured Investment Opportunities
          </Text>
        </Center>
        <Center>
          {" "}
          <Text textAlign="center" mt={4} fontFamily="Poppins" fontStyle="normal" fontWeight={400} fontSize="16px" lineHeight="24px" color="#9FA7AD">
            Explore exciting businesses and build your portfolio of startup, growth and venture-backed businesses with the potential to deliver significant returns.
          </Text>
        </Center>
      </Box>
      {/* ...end..opportunities */}

      {/* ...start..portfolio */}
      {isDesktop ? (
        <>
          <Box mt={24}>
            <Center>
              <Flex overflowX="auto" overflowY="hidden" w={1200}>
                <Portfolio image={"assets/images/image 2.png"} title={"CRAFT"} heading={"Gallery MX App"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} price={"$35,567"} target={"target"} rate={"4.85% "} equity={"equity"} button={"View project"} />
                <Portfolio image={"assets/images/image 2 (1).png"} title={"TECHNOLOGY"} heading={"Project Name"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} price={"$35,567"} target={"target"} rate={"4.85% "} equity={"equity"} button={"View project"} />
                <Portfolio image={"assets/images/image 2 (2).png"} title={"TECHNOLOGY"} heading={"Project Name"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} price={"$35,567"} target={"target"} rate={"4.85% "} equity={"equity"} button={"View project"} />
                <Portfolio image={"assets/images/image 2 (7).png"} title={"CRAFT"} heading={"Gallery MX App"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} price={"$35,567"} target={"target"} rate={"4.85% "} equity={"equity"} button={"View project"} />
                <Portfolio image={"assets/images/image 2 (8).png"} title={"TECHNOLOGY"} heading={"Project Name"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} price={"$35,567"} target={"target"} rate={"4.85% "} equity={"equity"} button={"View project"} />
                <Portfolio image={"assets/images/image 2 (9).png"} title={"TECHNOLOGY"} heading={"Project Name"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} price={"$35,567"} target={"target"} rate={"4.85% "} equity={"equity"} button={"View project"} />
              </Flex>
            </Center>
          </Box>
        </>
      ) : (
        <>
          <Box mt={24}>
            <Center>
              <Portfolio image={"assets/images/image 2.png"} title={"CRAFT"} heading={"Gallery MX App"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} price={"$35,567"} target={"target"} rate={"4.85% "} equity={"equity"} button={"View project"} />
            </Center>
          </Box>
        </>
      )}

      {/* ...end..portfolio */}

      {/* ...start offerHeading */}
      <Box mt={24} id="startup-offers">
        <Center>
          {" "}
          <Text textAlign="center" color="#000000" fontFamily="Poppins" fontStyle="normal" fontWeight={600} fontSize="24px" lineHeight="36px">
            Featured Startup Offers
          </Text>
        </Center>
        <Center>
          {" "}
          <Text textAlign="center" mt={4} fontFamily="Poppins" fontStyle="normal" fontWeight={400} fontSize="16px" lineHeight="24px" color="#9FA7AD">
            Explore exciting businesses and build your portfolio of startup, growth and venture-backed businesses with the potential to deliver significant returns.{" "}
          </Text>
        </Center>
      </Box>
      {/* ...end..offerHeading */}

      {isDesktop ? (
        <>
          <Box mt={24}>
            <Center>
              <Flex gap="15px" overflowX="auto" width={1200}>
                <Offers image={"assets/images/AmazonMT.png"} title={"Amazon Web Services"} price={"$50,000"} description={"Reliable, scalable and inexpensive cloud computing services"} button={"View offer"} />
                <Offers image={"assets/images/AmazonMT (1).png"} title={"Amazon Web Services"} price={"$50,000"} description={"Reliable, scalable and inexpensive cloud computing services"} button={"View offer"} />
                <Offers image={"assets/images/AmazonMT2.png"} title={"Amazon Web Services"} price={"$50,000"} description={"Reliable, scalable and inexpensive cloud computing services"} button={"View offer"} />
                <Offers image={"assets/images/AmazonMT.png"} title={"Amazon Web Services"} price={"$50,000"} description={"Reliable, scalable and inexpensive cloud computing services"} button={"View offer"} />
                <Offers image={"assets/images/AmazonMT2.png"} title={"Amazon Web Services"} price={"$50,000"} description={"Reliable, scalable and inexpensive cloud computing services"} button={"View offer"} />
                <Offers image={"assets/images/AmazonMT.png"} title={"Amazon Web Services"} price={"$50,000"} description={"Reliable, scalable and inexpensive cloud computing services"} button={"View offer"} />
              </Flex>
            </Center>
          </Box>
        </>
      ) : (
        <>
          <Box mt={24}>
            <Center>
              <Flex>
                <Offers image={"assets/images/AmazonMT.png"} title={"Amazon Web Services"} price={"$50,000"} description={"Reliable, scalable and inexpensive cloud computing services"} button={"View offer"} />
              </Flex>
            </Center>
          </Box>
        </>
      )}

      {isDesktop ? (
        <>
          <Box id="expert-booking">
            <Center>
              <Box mt={20} width={1200} height="344px" background="brand.primary" borderRadius="4px" position="relative">
                <Box mt="80px" pl="100px">
                  <Flex>
                    <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600" fontSize="32px" lineHeight="48px" width="389px" height="96px" color="#FFFFFF">
                      Book/Schedule Calls with Experts
                    </Text>
                    <Text fontFamily="Poppins" fontStyle="normal" fontWeight="400" fontSize="16px" lineHeight="24px" width="390px" height="72px" color="#D1DCE2">
                    Connect with experienced professionals and experts to assist you on your startup journey.
                    </Text>
                    <Button width="140px" height="56px" borderRadius="4px" bg="#FFFFFF" color="brand.primary">
                      Book A Call
                    </Button>
                  </Flex>
                </Box>
                <Box></Box>

                <Box position="absolute" top={210} left={9}>
                  <Flex>
                    <BookCall image={"assets/images/image 2 (3).png"} title={"Expert in Project planning"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} />
                    <BookCall image={"assets/images/image 2 (4).png"} title={"Expert in Business Analysis"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} />
                    <BookCall image={"assets/images/image 2 (5).png"} title={"Expert in Startup funding"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} />
                  </Flex>
                </Box>
              </Box>
            </Center>
          </Box>
        </>
      ) : (
        <>
          <Flex flexDir="column" id="expert-booking-mobile" h="auto" position="relative">
            <Box mt={70} bg="brand.primary" h="540px" w="100%" borderRadius="10px 10px 0px 0px" />
            <Box width="100%" mt="-530px" height="auto" borderRadius="4px">
              <Box mt="30px" textAlign="left" p={6}>
                <Flex flexDir="column">
                  <Text fontFamily="Poppins" fontStyle="normal" fontWeight="800" fontSize={28} lineHeight="48px" color="#FFFFFF">
                    Book/Schedule Calls with Experts
                  </Text>
                  <Text fontFamily="Poppins" fontStyle="normal" fontWeight="400" fontSize="16px" lineHeight="24px" width="100%" height="72px" color="#D1DCE2">
                  Connect with experienced professionals and experts to assist you on your startup journey.
                  </Text>
                  <Button mt={8} mb={10} width="140px" height="56px" borderRadius="4px" bg="#FFFFFF" color="brand.primary">
                    Book A Call
                  </Button>
                  <Center>
                    <BookCall image={"assets/images/image 2 (3).png"} title={"Expert in Project planning"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} position="absolute" />
                  </Center>

                  <Flex flexDir="column" gap={6} mt={6}>
                    <Center>
                      <BookCall image={"assets/images/image 2 (4).png"} title={"Expert in Business Analysis"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} />
                    </Center>
                    <Center>
                      <BookCall image={"assets/images/image 2 (5).png"} title={"Expert in Startup funding"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."} />
                    </Center>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Flex>
        </>
      )}

      {/* ..start..invest */}

      {isDesktop ? (
        <>
          <Center>
            <Box mt={400} borderWidth="0px 0px 1px 1px" borderStyle="solid" borderColor="rgba(19, 74, 112, 0.05)" bg="#FFFFFF" width="1440px" height="378px" textAlign="center">
              <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600px" fontSize="36px" lineHeight="54px" width="915px" height="108px" color="brand.primary" ml="230px" mt="20px" p="50px 40px">
                Invest confidently with Seed A Founder crowdfunding and investment marketplace{" "}
              </Text>
              <Flex gap="10px" ml="530px">
                <Button bg="brand.primary" color="#FFFFFF" mt="70px" bordeRadius="4px" width="178px" height="56px">
                  Join As Founder
                </Button>
                <Button bg="#FFFFFF" color="brand.primary" mt="70px" bordeRadius="4px" width="178px" height="56px" border="1px solid black">
                  Join As Investor
                </Button>
              </Flex>
              <Box>
                <Flex justify="center">
                  <Text mt="20px" color="#0776C3" fontStyle="italic">
                    Apply as an Expert on Seed A Founder
                  </Text>
                  <Box mt="23px" ml="4px">
                    <IoIosArrowForward color="brand.primary" />
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Center>
        </>
      ) : (
        <>
          {/* <Box mt={1350}> */}
          <Center>
            {" "}
            <Text textAlign="center" color="#000000" fontFamily="Poppins" fontStyle="normal" fontWeight={600} fontSize="32px" lineHeight="48px">
              Invest confidently with Seed A Founder crowdfunding and investment marketplace
            </Text>
          </Center>
          <Center flexDir="column">
            {" "}
            <Button bg="brand.primary" color="#FFFFFF" bordeRadius="4px" width="178px" height="56px">
              Join As Founder
            </Button>
            <Button bg="#FFFFFF" mt={3} color="brand.primary" bordeRadius="4px" width="178px" height="56px" border="1px solid black">
              Join As Investor
            </Button>
          </Center>
          <Center>
            {" "}
            <Text textAlign="center" mt={4} color="#0776C3" fontStyle="italic">
              Apply as an Expert on Seed A Founder
            </Text>
            <Box mt="18px" ml="4px">
              <IoIosArrowForward color="brand.primary" />
            </Box>
          </Center>
          {/* </Box> */}
        </>
      )}
      {/* ..end..invest */}
      {isDesktop ? (
        <>
          <Box bg="#F5F5F5" width="100%" height="316px" p="30px 90px">
            <Flex justify="space-between">
              <Box>
                <Image src="assets/images/image 1.png" alt="logo" height="60px" mb="20px" />
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="400px" fontSize="14px" lineHeight="150%px" width="508px" height="63px" color="brand.primary">
                  We believe that by supporting and giving startups access to world-class investors and a functional team with expertise in relevant fields, we can solve our world's biggest challenges through technology companies.
                </Text>
                <Flex mt="30px" gap="7px">
                  <Button bg="#FFFFFF" color="brand.primary">
                    email address
                  </Button>
                  <Button bg="brand.primary" color="#FFFFFF">
                    Subscribe
                  </Button>
                </Flex>
              </Box>
              <Box>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600px" fontSize="20px" lineHeight="30px" width="55px" height="30px" color="brand.primary">
                  Legal
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500px" fontSize="14px" lineHeight="21px" width="91px" height="21px" color="brand.primary" mt="15px">
                  Terms of use
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500px" fontSize="14px" lineHeight="21px" width="97px" height="21px" color="brand.primary" mt="15px">
                  Privacy Policy
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500px" fontSize="14px" lineHeight="21px" width="57px" height="21px" color="brand.primary" mt="15px">
                  Security
                </Text>
              </Box>
              <Box>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600px" fontSize="20px" lineHeight="30px" width="83px" height="30px" color="brand.primary">
                  Contact
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="157px" height="21px" color="brand.primary" mt="15px">
                  (UK) +44 7825 774556
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="147px" height="21px" color="brand.primary" mt="15px">
                  hello@seedafounder.com
                </Text>
                <Box>
                  <Flex color="brand.primary" mt="15px" mr="15px">
                    <FiLinkedin />
                    <FiFacebook />
                    <CgInstagram />
                    <FiTwitter />
                    <FiYoutube />
                  </Flex>
                </Box>
              </Box>
              <Box>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600px" fontSize="20px" lineHeight="30px" width="62px" height="30px" color="brand.primary">
                  About
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="100px" height="21px" color="brand.primary" mt="15px">
                  Our Company
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="70px" height="21px" color="brand.primary" mt="15px">
                  Our Team
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="78px" height="21px" color="brand.primary" mt="15px">
                  Our Pricing
                </Text>
              </Box>
              <Box>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600px" fontSize="20px" lineHeight="30px" width="100px" height="21px" color="brand.primary">
                  Company
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="83px" height="21px" color="brand.primary" mt="15px">
                  About
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="83px" height="21px" color="brand.primary" mt="15px">
                  FAQs
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="83px" height="21px" color="brand.primary" mt="15px">
                  Partner with us
                </Text>
              </Box>
            </Flex>
          </Box>
        </>
      ) : (
        <>
          <Box bg="#F5F5F5" height="1000px" p="30px 40px" mt={10}>
            <Flex justify="space-between" flexDir="column">
              <Box>
                <Image src="assets/images/image 1.png" alt="logo" height="60px" mb="20px" />
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="400px" fontSize="14px" lineHeight="150%px" height="63px" color="brand.primary">
                  We believe that by supporting and giving startups access to world-class investors and a functional team with expertise in relevant fields, we can solve our world's biggest challenges through technology companies.
                </Text>
                <Flex mt="90px" gap="7px">
                  <Button bg="#FFFFFF" color="brand.primary" width="178px" height="56px">
                    email address
                  </Button>
                  <Button bg="brand.primary" color="#FFFFFF" width="178px" height="56px">
                    Subscribe
                  </Button>
                </Flex>
              </Box>
              <Box mt={8}>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600px" fontSize="20px" lineHeight="30px" width="55px" height="30px" color="brand.primary">
                  Legal
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500px" fontSize="14px" lineHeight="21px" width="91px" height="21px" color="brand.primary" mt="15px">
                  Terms of use
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500px" fontSize="14px" lineHeight="21px" width="97px" height="21px" color="brand.primary" mt="15px">
                  Privacy Policy
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500px" fontSize="14px" lineHeight="21px" width="57px" height="21px" color="brand.primary" mt="15px">
                  Security
                </Text>
              </Box>
              <Box mt={8}>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600px" fontSize="20px" lineHeight="30px" width="83px" height="30px" color="brand.primary">
                  Contact
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="157px" height="21px" color="brand.primary" mt="15px">
                  (UK) +44 3301 3344436
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="147px" height="21px" color="brand.primary" mt="15px">
                  hello@seedafounder.com
                </Text>
                <Box>
                  <Flex color="brand.primary" mt="15px" mr="15px" gap={5}>
                    <FiLinkedin />
                    <FiFacebook />
                    <CgInstagram />
                    <FiTwitter />
                    <FiYoutube />
                  </Flex>
                </Box>
              </Box>
              <Box mt={8}>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600px" fontSize="20px" lineHeight="30px" width="62px" height="30px" color="brand.primary">
                  About
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="100px" height="21px" color="brand.primary" mt="15px">
                  Our Company
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="70px" height="21px" color="brand.primary" mt="15px">
                  Our Team
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="78px" height="21px" color="brand.primary" mt="15px">
                  Our Pricing
                </Text>
              </Box>
              <Box mt={8}>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="600px" fontSize="20px" lineHeight="30px" width="100px" height="21px" color="brand.primary">
                  Company
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="83px" height="21px" color="brand.primary" mt="15px">
                  About
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="83px" height="21px" color="brand.primary" mt="15px">
                  FAQs
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontWeight="500" fontSize="14px" lineHeight="21px" width="83px" height="21px" color="brand.primary" mt="15px">
                  Partner with us
                </Text>
              </Box>
            </Flex>
          </Box>
        </>
      )}
      {isDesktop ? (
        <>
          <Box bg="brand.primary" width="100%" height="72px" p="4px">
            <Text color="#FFFFFF" textAlign="center" mt="20px">
              © Copyright 2022 Seed A Founder Incorporated. All rights reserved
            </Text>
          </Box>
        </>
      ) : (
        <>
          <Center>
            <Box bg="brand.primary" width="100%" height="72px" p="4px" mt={2}>
              <Text color="#FFFFFF" textAlign="center" mt="20px">
                © Copyright 2022 Seed A Founder Incorporated. All rights reserved
              </Text>
            </Box>
          </Center>
        </>
      )}
      {!isDesktop ? <BackToTop /> : null}
    </Box>
  );
}

export default Homepage;
