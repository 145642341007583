import React from "react";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import ViewSingleUser from "../../components/viewSingleUser/ViewSingleUser";
import IsDesktop from "../../utils/IsDesktop";
import { Flex } from "@chakra-ui/react";

const ViewUser = () => {
    const isDesktop = IsDesktop()
    return (
        <>
            {/* <Sidebar /> */}
            <Header title='View User'  />
            <Flex className={`flex-col ${isDesktop ? 'max-h-[95vh] pb-10 overflow-y-scroll' : ''}`}>
                <ViewSingleUser/>
            </Flex>
        </>
    )

}
export default ViewUser;