import { Box, Flex, Text } from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/header/Header";

const PDFViewer = () => {
  const pitchDeck = useSelector((state) => state.company.companyInfo?.pitch_deck);
  const navigate = useNavigate();
  return (
    <Box h="90vh" display="flex" flexDirection="column">
      <Header title="My Pitch Deck" />
      <Flex cursor="pointer" onClick={() => navigate(-1)} justifyContent="flex-start" alignItems="center">
        <Box mr={2}>
          <HiOutlineArrowNarrowLeft size="35px" />
        </Box>
        <Box>
          <Text fontWeight={600}>Back</Text>
        </Box>
      </Flex>
      {pitchDeck ? (
        <Box flex="1">
          <iframe title="document viewer" src={`https://docs.google.com/viewer?url=${pitchDeck}&embedded=true`} width="100%" height="100%" />
        </Box>
      ) : (
        <Text>You haven't uploaded a Pitch Deck</Text>
      )}
    </Box>
  );
};

export default PDFViewer;
