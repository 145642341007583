import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatInfo: null,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatInfo(state, action) {
      state.chatInfo = action.payload;
    }
  }
});
export const { setChatInfo } = chatSlice.actions;
export default chatSlice.reducer;
