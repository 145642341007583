import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/authSlice";
import userReducer from "../reducers/userSlice"
import companyReducer from "../reducers/companySlice";
import surveyReducer from "../reducers/SurveySlice";
import upgradeUserReducer from "../reducers/upgradeUserSlice";
import chatReducer from "../reducers/chatSlice";
import messageReducer from "../reducers/messagesSlice"
import allCompaniesReducer from "../reducers/allCompaniesSlice";
import expertReducer from "../reducers/expertSlice";


export const store = configureStore({
    reducer:{
        auth: authReducer,
        user: userReducer,
        company: companyReducer,
        survey: surveyReducer,
        upgradeUser: upgradeUserReducer,
        chat: chatReducer,
        usermessages: messageReducer,
        expert: expertReducer,
        allcompanies: allCompaniesReducer
    }
})