import ComposeInternalLayout from "../components/HOC/ComposeInternalLayout";
import { adminUrl } from "../url/urls";
import Subscriptions from "../pages/admin/Subscriptions/Subscriptions";
import AccountSettings from "../pages/experts/Settings/AccountSettings";
import Dashboard from "../pages/admin/Dashboard/Dashboard";
import AllUsers from "../pages/founder/Allusers/AllUsers";
import UserExperts from "../pages/founder/Allusers/Experts"
import UserInvestors from "../pages/founder/Allusers/Investors";
import UserAddexpert from "../pages/founder/Allusers/Addexpert"
import ViewUser from "../pages/founder/Allusers/ViewUser";
import StartupOffers from "../pages/admin/startup-offers/StartupOffers";
import Adverts from "../pages/admin/adverts/Adverts";
import Brands from "../pages/admin/brands/Brands";
import DeletedUsers from "../pages/admin/deleted-users/DeletedUsers";

export const adminRoutes = [
    
    {
      title: 'Subscriptions',
      path: adminUrl.subscriptions(),
      element: ComposeInternalLayout(Subscriptions),
    },
    {
      title: 'Dashboard',
      path: adminUrl.dashboard(),
      element: ComposeInternalLayout(Dashboard),
    },
    {
      title: 'Account Settings',
      path: adminUrl.accountSettings(),
      element: ComposeInternalLayout(AccountSettings),
    },
    {
      title: 'Users',
      path: adminUrl.allUsers(),
      element: ComposeInternalLayout(AllUsers),
    },
    {
      title: 'Startup Offers',
      path: adminUrl.startupOffers(),
      element: ComposeInternalLayout(StartupOffers),
    },
    {
      title: 'Brands',
      path: adminUrl.brands(),
      element: ComposeInternalLayout(Brands),
    },
    {
      title: 'User experts',
      path: adminUrl.userExperts(),
      element: ComposeInternalLayout(UserExperts),
    },
    {
      title: 'User investors',
      path: adminUrl.userInvestors(),
      element: ComposeInternalLayout(UserInvestors),
    },
    {
      title: 'User addexpert',
      path: adminUrl.userAddExpert(),
      element: ComposeInternalLayout(UserAddexpert),
    },
    {
      title: 'View User',
      path: adminUrl.viewUser(),
      element: ComposeInternalLayout(ViewUser),
    },
    {
      title: 'Adverts',
      path: adminUrl.adverts(),
      element: ComposeInternalLayout(Adverts),
    },
    {
      title: 'Deleted Users',
      path: adminUrl.deletedUsers(),
      element: ComposeInternalLayout(DeletedUsers),
    }
]
