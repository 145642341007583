import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import BrandButton from "../../../components/brandButton/BrandButton";
import IsDesktop from "../../../utils/IsDesktop";
import CustomRadioGroup from "./CustomRadioGroup";
import CustomCheckboxGroup from "./CustomCheckBoxGroup";

const Page = ({
  question, 
  options, 
  isCheckBox, 
  setFilledData, 
  data, 
  filledData, 
  nextPage = () => {}, 
  prevPage = () => {}, 
  isLoading, 
  responseId,
  pageContainerClasses='min-w-[400px] max-w-[600px]',
  optionsContainerClasses='',
  elemContainerClasses='',
  elemClasses='',
  isOnboardingView=true
}) => {
    const isDesktop = IsDesktop();

    return (
        <Box display="flex" justifyContent="center">
      <VStack
        className={`mb-5 gap-8 ${isOnboardingView ? isDesktop ? "items-start" : "items-center" : ''} ${pageContainerClasses}`}
      >
        {isCheckBox ? (
          <CustomCheckboxGroup
            question={question}
            options={options}
            setFilledData={setFilledData}
            data={data}
            filledData={filledData}
            responseId={responseId}
            isOnboardingView={isOnboardingView}
            containerClasses={optionsContainerClasses}
            radioElemContainerClasses={elemContainerClasses}
          />
        ) : (
          <CustomRadioGroup
            question={question}
            options={options}
            setFilledData={setFilledData}
            data={data}
            filledData={filledData}
            responseId={responseId}
            isOnboardingView={isOnboardingView}
            containerClasses={optionsContainerClasses}
            radioElemContainerClasses={elemContainerClasses}
          />
        )}
        {
          isOnboardingView ? (
            <HStack spacing={10} alignItems="center" justifyContent="center">
              <BrandButton
                as="button"
                w="100px"
                h="50px"
                onClick={() => prevPage()}
                text="Previous"
              />
              <BrandButton
                w="100px"
                h="50px"
                colorScheme="blue"
                onClick={() => nextPage()}
                isLoading={isLoading}
                text="Next"
              />
            </HStack>
          ) : null
        }
      </VStack>
    </Box>
    )
}
export default Page;