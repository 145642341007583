
import React, { useState, useEffect } from 'react';
import { Box, Flex, IconButton, Text, Input } from "@chakra-ui/react";


const GreyTile = ({ startTime, endTime, title, style = {}}) => (
  <Box style={style} className="flex flex-col gap-1 py-2 rounded text-xs bg-stone-300">
    <Text className="text-black px-2">
      {startTime} - {endTime}
    </Text>
    <Box className="text-black flex w-full items-start gap-2">
      <Box className="bg-blue-500 h-3 w-[2px]" />
      <Text
        style={{ whiteSpace: 'nowrap' }}
        className="text-ellipsis overflow-hidden pt-[2px] mt-0"
      >
        {title}
      </Text>
    </Box>
  </Box>
);


const OrangeTile = ({startTime, endTime, title}) => (
    <Box className={`flex flex-col gap-1 py-2 rounded text-xs bg-orange-100`}>
        <Text className={`text-orange-600 px-2`}>
            {startTime} - {endTime}
        </Text>
        <Box className={`text-orange-600 flex w-full items-start gap-2 `}>
            <Box className="bg-orange-600 h-3 w-[2px]" />
            <Text style={{textWrap: 'nowrap'}} className="text-ellipsis overflow-hidden pt-[2px] mt-0">{ title }</Text>
        </Box>
    </Box>
)



const BlueTile = ({ startTime, endTime, title, style = {}}) => (
  <Box style={style} className="flex flex-col gap-1 py-2 rounded text-xs bg-blue-50">
    <Text className="text-blue-600 px-2">
      {startTime} - {endTime}
    </Text>
    <Box className="text-blue-600 flex w-full items-start gap-2">
      <Box className="bg-gray-800 h-3 w-[2px]" />
      <Text
        style={{ whiteSpace: 'nowrap' }}
        className="text-ellipsis overflow-hidden pt-[2px] mt-0"
      >
        {title}
      </Text>
    </Box>
  </Box>
);


const PinkTile = ({ startTime, endTime, title, style = {}}) => (
  <Box style={style} className="flex flex-col gap-1 py-2 rounded text-xs bg-pink-100">
    <Text className="text-[#FE4D97] px-2">
      {startTime} - {endTime}
    </Text>
    <Box className="text-[#FE4D97] flex w-full items-start gap-2">
      <Box className="bg-[#FE4D97] h-3 w-[2px]" />
      <Text
        style={{ whiteSpace: 'nowrap' }}
        className="text-ellipsis overflow-hidden pt-[2px] mt-0"
      >
        {title}
      </Text>
    </Box>
  </Box>
);


const GreenTile = ({ startTime, endTime, title, style = {}}) => (
    <Box style={style} className="flex flex-col gap-1 py-2 rounded text-xs bg-green-50">
      <Text className="text-green-700 px-2">
        {startTime} - {endTime}
      </Text>
      <Box className="text-green-700 flex w-full items-start gap-2">
        <Box className="bg-green-700 h-3 w-[2px]" />
        <Text
          style={{ whiteSpace: 'nowrap' }}
          className="text-ellipsis overflow-hidden pt-[2px] mt-0"
        >
          {title}
        </Text>
      </Box>
    </Box>
  );




const RandomTile = (props) => {
  const [TileComponent, setTileComponent] = useState(null);

  useEffect(() => {
    const tiles = [GreenTile, PinkTile, BlueTile, GreyTile, OrangeTile];
    const randomIndex = Math.floor(Math.random() * tiles.length);
    setTileComponent(() => tiles[randomIndex]);
  }, []);

  if (!TileComponent) return null; 

  return <TileComponent {...props} />;
};

export default RandomTile;
