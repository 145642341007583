import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Avatar,
  Spinner,
  Button,
  Checkbox
} from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useData } from "../../../data";
import { CustomToast } from "../../../components/toast/CustomToast";
import { mutateFunction, useMutation } from "../../../libs/apis";

const RestrictedUsers = ({ viewUser, isRestrictedUsers, type = "blocked" }) => {
  const { data, isLoading, refetch: refetchInvestors } = useData(`admins/investors?status=${type}`);
  const { data: expertsData, isLoading: isLoadingExperts, refetch: refetchExperts } = useData(`experts?status=${type}`);
  const { data: foundersData, isLoading: isLoadingFounders, refetch: refetchFounders } = useData(`admins/founders?status=${type}`);
  const [index, setIndex] = useState(-1);
  const { mutate, isLoading: Load } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const [selectAll, setSelectAll] = useState(false)
  const [listOfSelectedUsers, setListOfSelectedUsers] = useState([])
  const [isSorted, setIsSorted] = useState(false)
  const [sortType, setSortType] = useState('date') 

  const triggerResetPassword = (e, user) => {
    e.stopPropagation();
    e.preventDefault();

    let data = {
      email: user?.email,
    };

    setIndex(user.user_id);
    mutate(
      { key: "forgot-password", method: "post", data },
      {
        onSuccess(res) {
          setIndex(-1);

          // showCheckEmail();
          // onToggleCheckEmail();
          addToast({
            message: "Sucesss, email reset process sent to user.",
            type: "info",
          });
        },
      }
    );
  };

  const compareStr = (a, b) => {
    if (a < b) {
      return -1;
    }else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }

  const compareDates = (a, b) => {
    if (moment(b).isBefore(a, "day")) {
      return 1;
    } else if (moment(a).isBefore(b, "day")) {
      return -1;
    } else {
      return 0;
    }
  }

  const accountTypes = {
    'active': 0,
    'disabled': 1,
    'blocked': 2
  }

  const sortFunctions = {
    'profile': (a, b) => { return isSorted ? compareStr(a?.first_name, b?.first_name) : compareStr(b?.first_name, a?.first_name)},
    'email': (a, b) => { return isSorted ? compareStr(a?.email, b?.email) : compareStr(b?.email, a?.email)},
    'date' : (a, b) => { return isSorted ? compareDates(a?.date_added, b?.date_added) : compareDates(b?.date_added, a?.date_added)},  
  }

  const sortFunction = (a, b) => {
    return sortType && sortFunctions[sortType] && sortFunctions[sortType](a, b) 
  }

  const toggleSort = (type) => {
    type === sortType ? setIsSorted(prev => !prev) : setIsSorted(true)
    setSortType(type)
  }

  const allIds = useMemo(() => {
    return [
      ...(data?.data?.founders || []),
      ...(expertsData?.data?.experts || []),
      ...(foundersData?.data?.founders || [])
    ].map(items => items.user_id)
  }, [data?.data?.founders?.length, expertsData?.data?.experts?.length, foundersData?.data?.founders?.length  ])

  const onSelectAll = (e) => {
    if(selectAll) {
      setSelectAll(prev => !prev)
      setListOfSelectedUsers([])
    }else {
      setSelectAll(prev => !prev)
      setListOfSelectedUsers(allIds)
    }
    
  }

  const toggleListStatus = (e, id) => {
    let index = listOfSelectedUsers.indexOf(id)
    if(index >= 0){
      let _list  = [...listOfSelectedUsers]
      _list.splice(index, 1)
      setListOfSelectedUsers(_list)
      setSelectAll(false)
    }else {
      setListOfSelectedUsers(prev => [...prev, id])
      
    }

  }

  const blockUsers = (e) => {
      e.stopPropagation();
      e.preventDefault();

      try {
        if(!listOfSelectedUsers?.length || listOfSelectedUsers?.length <= 0) return
        for(let id of listOfSelectedUsers){
          mutate(
            { key: `admins/user/deactivate/${id}`, method: "put" },
            {
              onSuccess(res) {
                
              },
            }
          );
        }
        addToast({
          message: `Users successfully removed`,
          type: "info",
        });
        setListOfSelectedUsers([])
        setSelectAll(false)
        refetchExperts()
        refetchFounders()
        refetchInvestors()
      } catch (e) {
        //console.log(e)
      }
  };

  const disableUsers = (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      if(!listOfSelectedUsers?.length || listOfSelectedUsers?.length <= 0) return
      for (let id  of listOfSelectedUsers){
        mutate(
          { key: `admins/user/disable/${id}`, method: "put" },
          {
            onSuccess(res) {
              
            },
          }
        );
      }
      addToast({
        message: `Users successfully disabled`,
        type: "info",
      });
      setListOfSelectedUsers([])
      setSelectAll(false)
      refetchExperts()
      refetchFounders()
      refetchInvestors()
    } catch (e) {
      //console.log(e)
    }
  };

  const restoreUsers = async() => {
    try {
      if(!listOfSelectedUsers?.length || listOfSelectedUsers?.length <= 0) return
      for(let id of listOfSelectedUsers){
        mutate(
          { key: `admins/user/activate/${id}`, method: "put" },
          {
            onSuccess(res) {
              
            },
          }
        );
      }
      addToast({
        message: `Users successfully reinstated`,
        type: "info",
      });
      setListOfSelectedUsers([])
      setSelectAll(false)
      refetchExperts()
      refetchFounders()
      refetchInvestors()
    } catch (e) {
      //console.log(e)
    }
  }

  useEffect(() => {
    if(listOfSelectedUsers?.length > 0 && listOfSelectedUsers?.length === allIds?.length){
      !selectAll && setSelectAll(true)
    }
  }, [listOfSelectedUsers.length])

  useEffect(() => {
    refetchExperts()
    refetchFounders()
    refetchInvestors()
  }, [])


  return (
    <>
      <Flex className="flex-row mr-5 lg:mr-10 justify-center md:justify-end items-center gap-5 mt-5">
          <Button onClick={restoreUsers}>
              Restore
          </Button>
          {
            type === "blocked" ? (
              <Button onClick={disableUsers}>
                  Disable
              </Button>
            ) : (
              <Button onClick={blockUsers}>
                  Block
              </Button>
            )
          }
      </Flex>
      <Box maxHeight={"100vh"} className={`hide-scroll`} overflow={"auto"}>
        {/* --------------------table */}
        <TableContainer mb="50px" mt="50px" borderRadius="10px 10px 0px 0px">
          <Table variant="simple" borderWidth="2px" size="sm" fontSize="12px">
            <Thead bgColor="rgba(19, 74, 112, 0.05)">
              <Tr fontSize="2px">
                <Th>
                  <input 
                    type={"checkbox"}
                    checked={selectAll}
                    onChange={onSelectAll}
                  />
                </Th>
                <Th className="cursor-pointer" onClick={() => toggleSort('profile')}>Profile</Th>
                <Th className="cursor-pointer" onClick={() => toggleSort('email')}>Email</Th>
                <Th>Phone no</Th>
                <Th>Country</Th>
                <Th>Position</Th>
                <Th className="cursor-pointer" onClick={() => toggleSort('date')}>{type === "blocked" ? "Date Blocked" : "Date Disabled"}</Th>
                <Th>Trigger Password Reset</Th>
                <Th>Last Contact</Th>
                <Th></Th>
              </Tr>
            </Thead>
            {!isLoading ? (
              <Tbody>
                {
                [
                  ...(data?.data?.founders || []),
                  ...(expertsData?.data?.experts || []),
                  ...(foundersData?.data?.founders || [])
                ]?.sort(sortFunction).map((items) => {
                  return (
                    <Tr
                      style={{ padding: "5rem" }}
                      boxShadow="md"
                      borderRadius="10px"
                      _hover={{
                        borderWidth: "2px",
                        borderColor: "brand.primary",
                        borderRadius: "10px",
                      }}
                      onClick={(e) => {
                        const elem = e.target.closest(".checkbox")
                        if(!elem) viewUser(items.user_id)
                      }}
                    >
                      <Td>
                      <input 
                        type={"checkbox"}
                        checked={listOfSelectedUsers.includes(items.user_id)}
                        onChange={(e) => {toggleListStatus(e, items.user_id)}}
                        className="checkbox"
                      />
                      </Td>
                      <Td
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          height: "100%",
                        }}
                        px={2}
                      >
                        <Avatar
                          name={`${items?.first_name} ${items?.last_name}`}
                          src={items.profile_image}
                          alt=""
                          size="sm"
                          mr={2}
                        />
                        <Text>{`${items?.first_name} ${items?.last_name}`}</Text>
                      </Td>
                      <Td className="td">{items.email}</Td>
                      <Td className="td">{items.phone_number || "N/A"}</Td>
                      <Td className="td">{items.country || "N/A"}</Td>
                      <Td className="td">{items.position || "N/A"}</Td>
                      <Td className="td">
                        {moment(items?.updated_at).format(`MMM D, YYYY`)}
                      </Td>
                      <Th
                        onClick={(e) => triggerResetPassword(e, items)}
                        className="td"
                      >
                        {items.user_id == index ? (
                          <Spinner />
                        ) : (
                          <>
                            <Button
                              _hover={{
                                borderWidth: "2px",
                                borderColor: "brand.primary",
                                borderRadius: "10px",
                              }}
                            >
                              Trigger
                            </Button>
                          </>
                        )}
                      </Th>
                      <Flex justify="space-evenly" alignItems="center" gap="1">
                        <Text>
                          <img src={items.lastContact} alt="" />
                          N/A
                        </Text>
                        <Text>{items.name}</Text>
                      </Flex>
                      {/* <Td className="td">
                      <BiDotsVerticalRounded />
                    </Td> */}
                    </Tr>
                  );
                })}
              </Tbody>
            ) : (
              <Flex w="100%">
                <Spinner />
              </Flex>
            )}
          </Table>
        </TableContainer>
        {/* --------------------table */}
      </Box>
    </>
  );
};

export default RestrictedUsers;
