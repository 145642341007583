import { Avatar, Box, Spinner, Text, useDisclosure } from "@chakra-ui/react"
import moment from "moment";
import { useEffect, useState } from "react";
import { FaBookmark, FaComment, FaHeart, FaRegBookmark, FaRegComment, FaRegHeart } from "react-icons/fa"
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../helpers/constant";
import { mutateFunction } from "../../libs/apis";
import client from "../../libs/client";
import CreateCommentModal from "../modal/CreateCommentModal";
import { CustomToast } from "../toast/CustomToast";
import Comment from "./Comment";
import CreateComment from "./CreateComment";

const PostDetails = ({
    
}) => {
    const { mutate, isLoading } = useMutation(mutateFunction);
    const { addToast } = CustomToast();
    const navigate = useNavigate()
    const location  = useLocation()
    const {
        isOpen : createCommentModalIsOpen,
        onClose : onCloseCreateCommentModal,
        onOpen : onOpenCreateCommentModal
    } = useDisclosure()

    const [isLoadingPost, setIsLoadingPost] = useState(false)
    const [isLoadingComments, setIsLoadingComments] = useState(false)
    const [ post, setPost] = useState(null)
    const [comments, setComments] = useState([])

    const getPost = ({postId}) => {
        setIsLoadingPost(true)
        return client().get(`${BASE_URL}post/${postId}`)
            .then((res) => {
                setIsLoadingPost(false)
                const data = res.data.data.post;
                setPost(data)
        })
    }

    const getComments = ({postId}) => {
        setIsLoadingPost(true)
        return client().get(`${BASE_URL}comment/post/${postId}`)
            .then((res) => {
                setIsLoadingPost(false)
                const data = res.data.data.comments;
                setComments(data)
        })
    }


    const likePost = () => {
        try {
            mutate(
                { key: "react/post", method: "post", data : {
                    post_id: post.id,
                    comment_id: null,
                }},
                {
                  onSuccess(res) {
                    addToast(res);
                    getPost()
                  },
                  onError(e){
                    addToast({message: 'Post was not liked'}, "error")
                    console.error(e)
                  }
                }
              );
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const id = location.search.split("&")[0].split("=")[1] || null
        if(id){
            getPost({
                postId: id
            })
            getComments({
                postId: id
            })
        }
    }, [location])

    return (
        <Box className="flex cursor-pointer items-start gap-2 pt-5">
            <CreateCommentModal
                isOpen={createCommentModalIsOpen}
                onClose={onCloseCreateCommentModal}
                post={post}
                refetchPost={() => {
                    getPost()
                    getComments()
                }}
            />
            <Box className="flex flex-col gap-2 w-full">
            {
                isLoadingPost ? (
                        <Box>
                            <Spinner />
                        </Box>
                ) : (
                    !post ? (
                        <Box>
                            <Text className="text-center text-gray-700 font-bold text-sm">Could not load post</Text>
                        </Box>
                    ) : (
                        <>
                            <Box className="p-2 flex w-full">
                                <Box>
                                    <Avatar 
                                        src={post.user.profile_image ?? null}
                                        name={`${post.user.first_name} ${post.user.last_name}`}
                                        size={"sm"}
                                    />
                                </Box>
                                <Box className="w-full cursor-pointer flex px-2 pl-4 flex-col">
                                    <Box className="flex items-center text-sm mb-3 pt-2 gap-2">
                                        <Text className="font-bold">{`${post.user.first_name} ${post.user.last_name}`}</Text>
                                        <Text className="text-xs text-gray-600">{moment(post.createdAt).fromNow()}</Text>
                                    </Box>
                                    <Box className="w-full pb-10 min-h-30">
                                    <Text className="w-full" color="#000">
                                        {post?.text.split('\n\n').map(text => <span>{text}<br/><br/></span>)}
                                    </Text>
                                    </Box>
                                    <Box className="flex items-center justify-between">
                                        <Box className="flex gap-1 cursor-pointer items-center">
                                            <FaRegComment onClick={onOpenCreateCommentModal} className="text-gray-600" />
                                            <Text>{post.comment_count}</Text>
                                        </Box>
                                        <Box className="flex gap-1 cursor-pointer items-center">
                                            <FaRegHeart onClick={likePost} className="text-gray-600" />
                                            <Text>{post.likes_count}</Text>
                                        </Box>
                                        <Box className="flex gap-1 cursor-pointer items-center">
                                            <FaRegBookmark />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="p-2">
                                <CreateComment 
                                    post={post}
                                    showPost={false}
                                    showCloseButton={false}
                                />
                            </Box>
                            
                        </>
                    )
                )
            }
            {
                isLoadingPost || isLoadingComments ? (
                    <Box>
                        <Spinner />
                    </Box>
                ) : (
                    <Box>
                        {
                            comments.length > 0 ? comments.map((comment) => {
                               return (
                                <Comment post={comment} />
                               ) 
                            }) : (
                                <Box>
                                    <Text className="font-medium text-gray-500 text-center">No comments yet</Text>
                                </Box>
                            )
                        }
                    </Box>
                )
            }
            </Box>
        </Box>
        
    )
}


export default PostDetails