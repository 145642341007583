import React, { useEffect } from "react";
import { Box, Flex, Heading, Image, Spinner, Text } from "@chakra-ui/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useData } from "../../../data";
import { CustomToast } from "../../../components/toast/CustomToast";
import logo_2 from '../../../public/icons/logo_.svg';

const VerifyEmail = () => {
    const [searchParams] = useSearchParams({});
    const ref = searchParams.get("reference");
    const navigate = useNavigate();
    const { addToast } = CustomToast();
    const {data, isLoading} = useData(`verify-email?reference=${ref}`);

    useEffect(() => {
        if(!isLoading){
            data?.data
            ? window.setTimeout(() => {
                addToast({message: "Email Verified", type: "success"})
                navigate('/login', { replace: true })}, 3000) 
                : window.setTimeout(() => {
                    navigate('/signUp', { replace: true })}, 3000);
        }
    });

    

    return (
        <>
            <Box width="full" h="100vh" display="flex" flexDir="column" alignItems="center" justifyContent="center">
                <Image src={logo_2} boxSize='130px' className="mt-20" objectFit='cover' mb={5}/>

            {isLoading && 
                <Flex className="flex-wrap justify-center gap-3 items-center" p={5}>
                    <Text className="text-3xl md:text-4xl lg:text-5xl text-center font-bold" color="brand.primary">
                        Verifying Email
                    </Text>
                    <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl'/>
                </Flex>

            }
            {!isLoading && 
                (data?.data ?
                    (<Flex p={5} className="gap-5 justify-center border" flexDir="column">
                        <Text className="text-3xl md:text-4xl lg:text-5xl text-center font-bold" color="brand.primary">
                            Verification Successful
                        </Text>
                        {/* <Text>redirecting to Login page...</Text> */}
                        <Text className="text-center">Thank you for verifying your email. Please login using your credentials and complete the onboarding process.</Text>
                    </Flex> 
                    ):(
                    <Flex p={5} flexDir="column">
                        <Text className="text-3xl md:text-4xl lg:text-5xl text-center font-bold" color="brand.primary">
                            Verification Failed
                        </Text>
                        <Text className="text-center">redirecting to sign up...</Text>
                    </Flex> 
                    ))}
                </Box>
        </>
    )
}
export default VerifyEmail;
