import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Input } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeSlots from "../TimeSlots";
import client from "../../../../libs/client";
import { BASE_URL } from "../../../../helpers/constant";
import { BsThreeDots } from "react-icons/bs";
import { useData } from "../../../../data";
import { useSelector } from "react-redux";
import SingularDateBooking from "./DateBooking";
import RandomTile from "./RandomTile";

const WeeklyBookings = ({
  bookingDates = [],
  bookingSchedule = {},
  setCalendarType = () => {}
}) => {
  const DAYS_OF_WEEK = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));

  useEffect(()=>{
    if (selectedDate) {
      // setFetching(true);
      // client().get(`${BASE_URL}bookings/todays-booking?date=${selectedDate.format("YYYY-MM-DD")}`)
      //       .then((res) => {
      //         setSelectedDateBookings(res?.data?.data?.bookings)
      //         setFetching(false);
      //   })
    }
  },[selectedDate])

  
  const handlePrevWeekClick = () => setCurrentDate((prev) => prev.clone().subtract(1, "week"));
  const handleNextWeekClick = () => setCurrentDate((prev) => prev.clone().add(1, "week"));

  const handleDateChange = (e) => {
    const value = e.target.value;
    const date = moment(value, "YYYY-MM-DD");
    if (date.isValid()) {
      setCurrentDate(date);
      setSelectedDate(date.clone().startOf("day"));
    }
  };

  const convertToTime = (timeString) => {
    var parts = timeString.split(":");
    var date = new Date();
    date.setHours(parts[0]);
    date.setMinutes(parts[1]);
    date.setSeconds(parts[2]);

    return date;
  }


  const renderDaysOfWeek = () => {
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const firstWeek = [];

    let day = startOfWeek;
    while (day <= endOfWeek) {
      firstWeek.push(day);
      day = day.clone().add(1, "day");
    }
    return (
      <Box className="flex py-4 bg-[#F8FAFB]">
        <style>{` ::-webkit-scrollbar {display: none; }`}</style>
        <Box className="flex flex-col">
            <Box className="flex w-[30px] h-[1px]"></Box>
        </Box>
        {
          firstWeek.map((day, index) => (
            <Box key={`${index}`} flex={1} className="text-center flex-col gap-1 px-2">
              <Text className="text-[#778CA2] text-sm font-medium">
                {
                  moment(day).format("ddd")
                }
              </Text>
              <Text className="text-[#778CA2] text-sm font-medium">
                {
                  moment(day).format("DD")
                }
              </Text>
            </Box>
          ))
        }
      </Box>
    );
  };

  const renderCalendarDays = () => {
    const startOfWeek = currentDate.clone().startOf("week");
    const endOfWeek = currentDate.clone().endOf("week");
    const firstWeek = [];

    let day = startOfWeek;
    while (day <= endOfWeek) {
      firstWeek.push(day);
      day = day.clone().add(1, "day");
    }
    return (
      <Box className="flex flex-col gap-2">
      <style>{` ::-webkit-scrollbar {display: none; }`}</style>
      <Box className="flex w-full items-start">
        <Box className="flex flex-col">
            {
                Array.from({ length: 24 }).map((_, index) => {
                    return (
                      <Box className="flex h-[60px]">

                        <Box className="w-[30px] text-[10px] font-medium text-gray-500">
                            <Text className="relative bottom-2 pl-1">
                            {
                              index > 0 ? index > 12 ? `${index - 12}PM` : `${index}AM` : ''
                            }
                            </Text>
                        </Box>
                        <Box className="w-3 border-b-2 relative top-[1px]"></Box>
                      </Box>
                    )
                })
            }
        </Box>
        <Box flex={1} className="flex w-full">
          {
            firstWeek.map((day) => (
                <SingularDateBooking 
                  bookingDates={
                    bookingDates.includes(day.format("MM-DD-YYYY")) ? bookingSchedule[day.format("MM-DD-YYYY")] : []
                  }
                />
            ))
          }
        </Box>

        </Box>
      </Box>
    );
  };

const generateWeeks = ( month = currentDate.format('M'), year = currentDate.format('YYYY'), weekStart = 0) => { 

    let startOfMonth = moment([year, month - 1]); // month is 1-indexed
    let endOfMonth = moment(startOfMonth).endOf('month');

    // Determine the start of the first week
    let startOfWeek = moment(startOfMonth).startOf('week');
    if (weekStart === 1) { // If week starts on Monday
        startOfWeek = moment(startOfMonth).startOf('isoWeek');
    }

    // Determine the end of the last week
    let endOfWeek = moment(endOfMonth).endOf('week');
    if (weekStart === 1) { // If week starts on Monday
        endOfWeek = moment(endOfMonth).endOf('isoWeek');
    }

    let current = moment(startOfWeek);
    let weeks = [];
    let week = [];

    while (current.isSameOrBefore(endOfWeek, 'day')) {
        // Push a clone of the current day to avoid mutation
        week.push(current.clone());

        // If the current day is the end of the week, push the week to weeks and reset
        if (current.day() === (weekStart === 1 ? 6 : 6)) { // Saturday if week starts on Sunday, Sunday if week starts on Monday
            weeks.push(week);
            week = [];
        }

        // Move to the next day
        current.add(1, 'day');
    }

    // Push any remaining days as the last week
    if (week.length > 0) {
        weeks.push(week);
    }

    return weeks;
}

const renderNewCalendarDays = () => {
    const weeks = generateWeeks()
    return (
      <Box className="flex flex-col">
      <style>{` ::-webkit-scrollbar {display: none; }`}</style>
        {
            weeks.map((week) => {
                return (
                    <Box className="flex">
                    {
                        week.map((day, index) => (
                            <Box display="flex" key={day.format("MM-DD-YYYY")} flex={1} className="border flex-col justify-between px-2 py-3 h-[130px] overflow-hidden"  >
                                <Box 
                                    cursor="pointer" 
                                    onClick={() => setSelectedDate(day.clone().startOf("day"))} 
                                    color={
                                        moment().isAfter(day, 'day') ? "grey" : "#000"
                                    }
                                    className="justify-self-end self-end text-right w-full"
                                    >
                                    <Text className="text-sm font-medium">
                                        {day.format("D")}
                                    </Text>
                                    {/* <Text className={`text-lg font-bold p-0 m-0 ${bookingDates.includes(day.format("MM-DD-YYYY")) ? 'text-[#4D7CFE]' : selectedDate.isSame(day, "day") ? "text-[#134a70]" : 'text-white'} leading-[0.2]`}>.</Text> */}
                                </Box>
                                {
                                    bookingDates.includes(day.format("MM-DD-YYYY")) ? (
                                        <RandomTile 
                                            title={bookingSchedule[day.format("MM-DD-YYYY")][0]?.slot_title}
                                            startTime={
                                                moment(convertToTime(bookingSchedule[day.format("MM-DD-YYYY")][0]?.slot_start_time)).format("hh:mm")
                                            }
                                            endTime={
                                                moment(convertToTime(bookingSchedule[day.format("MM-DD-YYYY")][0]?.slot_end_time)).format("hh:mm")
                                            }
                                        />
                                    ) : null
                                }
                            </Box>
                        ))
                    }
                    </Box>
                )
            })
        }
      </Box>
    );
  };

  return (
    <Box className="overflow-x-auto max-w-max lg:max-w-full" display="flex" flexDir="column" h="100%" justifyContent="flex-start">
      <Box className="w-full min-w-[1024px] lg:min-w-full border bg-white rounded-lg pt-4">
        <Flex className="border-b px-4 pb-5" alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Text className="text-xs">
              {currentDate.format("MMM, YYYY")}
            </Text>
          </Flex>
          <Box className="flex hidden flex-col items-center">
            <Input type="date" value={selectedDate.format("YYYY-MM-DD")} onChange={handleDateChange} />
          </Box>
          <Box className="flex gap-2">
            <Box className="flex items-center rounded-lg border">
                <Box onClick={() => {setCalendarType("month")}} className={`p-2 border-r text-xs cursor-pointer text-[#778CA2]`}>
                    Month
                </Box>
                <Box onClick={() => {setCalendarType("week")}} className={`p-2 border-r text-xs cursor-pointer text-[#4D7CFE] bg-[#F8FAFB]`}>
                    Week
                </Box>
                <Box onClick={() => {setCalendarType("day")}} className={`p-2 text-xs cursor-pointer text-[#778CA2]`}>
                    Day
                </Box>
            </Box>
            <Box className="flex items-center gap-1.5">
                <IconButton rounded={"full"} p={0} bg={"transparent"} className="border" aria-label="Previous Week" icon={<ChevronLeftIcon fontSize={"1.5rem"} color="#778CA2"/>} onClick={handlePrevWeekClick} size="xs" />
                <IconButton rounded={"full"} p={0} bg={"transparent"} className="border" aria-label="Next Week" icon={<ChevronRightIcon fontSize={"1.5rem"} color="#778CA2" />} onClick={handleNextWeekClick} size="xs" />
            </Box>
          </Box>
        </Flex>
        {renderDaysOfWeek()}
        {renderCalendarDays()}
      </Box>
    </Box>
  );
};
export default WeeklyBookings;
