import { createSlice } from "@reduxjs/toolkit";
import UserService from "../../utils/UserService";

const initialState = {
  completeSurvey: UserService.getCompleteSurveyAction()
};

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    setCompleteSurvey(state, action) {
      state.completeSurvey = action.payload;
    }
  }
});
export const { setCompleteSurvey } = surveySlice.actions;
export default surveySlice.reducer;
