import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import IsDesktop from "../../../utils/IsDesktop";

const ViewUserDetails = ({ user, onToggle, }) => {
    const isDesktop = IsDesktop();
    const clarityOptions = [
      'Yes, very clear',
      'Yes, somewhat clear',
      'Neutral',
      'No, somewhat confusing',
      'No, very confusing'
    ]
    const easeOptions = [
      'Very easy',
      'Somewhat easy',
      'Neutral',
      'Somewhat difficult',
      'Very difficult'
    ]
    return (
      <Flex className="w-[100%] flex-col overflow-hidden">
        <Flex className="w-[100%] overflow-x-hidden gap-5 flex-col overflow-y-auto pb-8 md:pb-24" h={isDesktop ? "85vh" : "auto"} w="100%" p={4}  >
          <Flex justifyContent="space-between" alignItems="center" my={4} mx={3}>
            <Flex cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
              <Box mr={2}>
                <HiOutlineArrowNarrowLeft size="35px" />
              </Box>
              <Box>
                <Text fontWeight={600}>Back</Text>
              </Box>
            </Flex>
          </Flex>
          <Flex mx={3} className="flex-col gap-3">
            <Flex className="flex-wrap items-end gap-2">
            <Avatar name={`${user.firstName} ${user.lastName}`} size="xl" />
            <Text className="text-sm">
              {
                user?.confirmedAt ? 
                `Deleted account on ${moment(user.confirmedAt).format("DD/MM/YYYY [at] HH:mm")}` 
                :
                `Triggered a request to delete account on ${moment(user.createdAt).format("DD/MM/YYYY [at] HH:mm")}`
              }
            </Text>
            </Flex>
            <Flex className="flex-wrap flex-col gap-2" >
              <Text className="font-semibold">Name:</Text>
              <Text className="capitalize">{`${user.firstName} ${user.lastName}`}</Text>
            </Flex>
            <Flex className="flex-wrap flex-col gap-2" >
              <Text className="font-semibold">Email:</Text>
              <Text>{`${user.email}`}</Text>
            </Flex>
            <Flex className="flex-wrap flex-col gap-2" >
              <Text className="font-semibold">Primary reason for deleting account?:</Text>
              <Text>{`${user.reason || "No answer"}`}</Text>
            </Flex>
            <Flex className="flex-wrap flex-col gap-2" >
              <Text className="font-semibold">Changes that could have been done to prevent the user from deleting account:</Text>
              <Text>{`${user.changes || "No answer"}`}</Text>
            </Flex>
            <Flex className="flex-wrap flex-col gap-2" >
              <Text className="font-semibold">Suggestions for improving the account deletion process:</Text>
              <Text>{`${user.deletionSuggestions || "No answer"}`}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  export default ViewUserDetails;
  