import React, { useEffect, useState, useRef } from "react";
import { 
    Box, 
    Flex, 
    Grid, 
    GridItem, 
    Text, 
    VStack, 
    Avatar, 
    HStack, 
    Divider, 
    Heading, 
    Spinner, 
    useDisclosure,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import BrandButton from "../../../components/brandButton/BrandButton";
import IsDesktop from "../../../utils/IsDesktop";
import { useData } from "../../../data";
import RecentActivitiesTable from "./RecentActivitiesTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../components/header/Header";
import moment from "moment";
import { mutateFunction, useMutation } from "../../../libs/apis";
import { CustomToast } from "../../../components/toast/CustomToast";


const ViewUser = () =>{
    const [searchParams] = useSearchParams({});
    const userid = searchParams.get("userid");
    const isDesktop = IsDesktop();
    const {data, isLoading} = useData(`admins/users/${userid}`)
    const [userData, setUserData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen:isDisabledToggleOpen, onOpen: onOpenDisabledToggle, onClose: onCloseDisabledToggle } = useDisclosure()
    const cancelRef = useRef(null)
    const disableRef = useRef(null)
    const { mutate, isLoading : mutationIsLoading } = useMutation(mutateFunction)
    const { addToast } = CustomToast()

    useEffect(()=>{
        if(data?.data){
            setUserData(data?.data?.user)
            setCompanyData(data?.data?.company)
        }
    }, [data])

    const activities = [
        {
            id: 1,
            activity_title: "booked session with",
            interactingObject: {
                id: 1,
                title: "Abisayo Peter Abiodun",
                image: "https://link.to.png"
            },
            date: "01/02/2022"
        },
        {
            id: 5,
            activity_title: "got",
            interactingObject: {
                id: 6,
                title: "AWS",
                image: "https://link.to.png"
            },
            date: "01/02/2022"
        },
        {
            id: 4,
            activity_title: "Connected with",
            interactingObject: {
                id: 6,
                title: "Lashana Lynch",
                image: "https://link.to.png"
            },
            date: "01/02/2022"
        }
    ]

    const toggleUserBlockStatus = (e) => {
        e.stopPropagation();
        e.preventDefault();
    
        mutate(
          { key: `admins/user/${userData?.status === "active" ? 'deactivate' : 'activate'}/${userData.id}`, method: "put" },
          {
            onSuccess(res) {
              addToast({
                message: `User successfully ${userData?.status === "active" ? 'removed' : 'reinstated'}`,
                type: "info",
              });
              onClose()
            },
          }
        );
    };

    const toggleDeletePopup = () => {
        onOpen()
    }

    const toggleUserDisabledStatus = (e) => {
        e.stopPropagation();
        e.preventDefault();
    
        mutate(
          { key: `admins/user/${userData?.status === "active" ? 'disable' : 'activate'}/${userData.id}`, method: "put" },
          {
            onSuccess(res) {
              addToast({
                message: `User successfully ${userData?.status === "active" ? 'disabled' : 'reinstated'}`,
                type: "info",
              });
              onCloseDisabledToggle()
            },
          }
        );
    };

    const toggleDisablePopup = () => {
        onOpenDisabledToggle()
    }


    return (
        <Box px={5} zIndex='99'>
            <Header title={"Users"} />
            <Flex cursor="pointer" my={2} onClick={()=> navigate(-1)} justifyContent="flex-start" alignItems="center">
                <Box mr={2}><HiOutlineArrowNarrowLeft size="35px"/></Box>
                <Box><Text fontWeight={600}>Back</Text></Box>
            </Flex>
            
            {!isLoading ? (
            <>
            <Flex>
            <VStack mx={4} mb={2} overflowY="hidden" w="60%" h="250px" bg="white" borderRadius="10px" boxShadow="0.5px 2px 10px lightgray" justifyContent="space-between" alignItems="flex-start">
                <Flex h="100%" w="100%" alignItems="center" p={4} justifyContent="space-between">
                        <HStack h="100%" w="100%" spacing={5} justifyContent="flex-start">
                            <VStack w="50%" ml={2} alignItems="center" justifyContent="center">
                                <Box p={1} borderRadius="50%" borderColor="lightgray" borderWidth={2}>
                                    <Avatar name={`${userData?.first_name} ${userData?.last_name}`} src={userData?.profile_image} size={isDesktop ? "xl" : "lg"}/>
                                </Box>
                                <Box d="flex" flexDir="column">
                                    <Text fontWeight={500} fontSize={isDesktop ? "16px": "12px"}>
                                        {`${userData?.first_name} ${userData?.last_name}`}
                                    </Text>
                                    <Text textAlign="center" fontWeight={300} fontSize="8px">
                                        Joined {moment(userData?.createdAt).format("MMM D, YYYY")}
                                    </Text>
                                </Box>
                                <Flex className="gap-[3px] flex-wrap items-center justify-center">
                                <>
                                    <BrandButton colorScheme={userData?.status === "disabled" ? 'white' : 'blue'}  text={userData?.status !== "disabled" ? 'Disable' : 'Enable'} width="100px" onClick={toggleDisablePopup}/>
                                    <AlertDialog
                                        isOpen={isDisabledToggleOpen}
                                        leastDestructiveRef={disableRef}
                                        onClose={onCloseDisabledToggle}
                                    >
                                        <AlertDialogOverlay>
                                        <AlertDialogContent>
                                            <AlertDialogHeader className="text-center" fontSize='lg' fontWeight='bold'>
                                                {userData?.status === "disabled" ? 'Re-enable user' : 'Disable user'}
                                            </AlertDialogHeader>

                                            <AlertDialogBody>
                                                Are you sure? This action cannot be undone afterwards.
                                            </AlertDialogBody>

                                            <AlertDialogFooter>
                                            <Button ref={disableRef} onClick={onCloseDisabledToggle}>
                                                Cancel
                                            </Button>
                                            <Button colorScheme='red' onClick={toggleUserDisabledStatus} ml={3}>
                                                {userData?.status === "disabled" ? 'Re-enable' : 'Disable'}
                                            </Button>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                        </AlertDialogOverlay>
                                    </AlertDialog>
                                </>
                                <>
                                    <BrandButton colorScheme={userData?.status === "active" ? 'white' : 'blue'}  text={userData?.status !== "blocked" ? 'Delete' : 'Unblock'} width="100px" onClick={toggleDeletePopup}/>
                                    <AlertDialog
                                        isOpen={isOpen}
                                        leastDestructiveRef={cancelRef}
                                        onClose={onClose}
                                    >
                                        <AlertDialogOverlay>
                                        <AlertDialogContent>
                                            <AlertDialogHeader className="text-center" fontSize='lg' fontWeight='bold'>
                                                {userData?.status === "blocked" ? 'Unblock user' : 'Delete user'}
                                            </AlertDialogHeader>

                                            <AlertDialogBody>
                                                Are you sure? This action cannot be undone afterwards.
                                            </AlertDialogBody>

                                            <AlertDialogFooter>
                                            <Button ref={cancelRef} onClick={onClose}>
                                                Cancel
                                            </Button>
                                            <Button colorScheme='red' onClick={toggleUserBlockStatus} ml={3}>
                                                {userData?.status === "blocked" ? 'Unblock' : 'Delete'}
                                            </Button>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                        </AlertDialogOverlay>
                                    </AlertDialog>
                                </>
                                </Flex>

                            </VStack>
                            <Divider orientation="vertical"/>
                            <Box d="flex" flexDir="column" justifyContent="center" alignItems="center" h="100%" w="full">
                                <HStack spacing={16} h={7}>
                                    <Text w={isDesktop ? "100px" : "70px"} fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Email</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}} align='right'>{userData?.email || "N/A"}</Text>
                                </HStack>
                                <HStack spacing={16} h={7}>
                                    <Text w={isDesktop ? "100px" : "70px"} fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Phone no.</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{userData?.phone_number || "N/A"}</Text>
                                </HStack>
                                <HStack spacing={16} h={7}>
                                    <Text w={isDesktop ? "100px" : "70px"} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Country</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{userData?.user_type_id === 3 ? userData?.expert_price?.location?.title : userData?.country || "N/A"}</Text>
                                </HStack>
                                <HStack spacing={16} h={7}>
                                    <Text w={isDesktop ? "100px" : "70px"} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Position</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{userData?.headline || "N/A"}</Text>
                                </HStack>
                                <HStack spacing={16} h={7}>
                                    <Text w={isDesktop ? "100px" : "70px"} fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Plan</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{userData?.plan || "N/A"}</Text>
                                </HStack>
                                <HStack spacing={16} h={7}>
                                    <Text w={isDesktop ? "100px" : "70px"} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Date Added</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{new Date(userData?.createdAt).toLocaleString("en-US")}</Text>
                                </HStack>
                                <HStack spacing={16} h={7}>
                                    <Text w={isDesktop ? "100px" : "70px"} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Current Deal</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{userData?.current_deal}</Text>
                                </HStack>
                                <HStack spacing={16} h={7}>
                                    <Text w={isDesktop ? "100px" : "70px"} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Status</Text>
                                    <Text className="capitalize" fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{userData?.status}</Text>
                                </HStack>
                                        
                            </Box>
                        </HStack>
                    {/* </Box> */}
                </Flex>
                
            </VStack>
            {companyData && (<VStack mx={4} overflowY="hidden" flex={1} h="250px" bg="white" borderRadius="10px" boxShadow="0.5px 2px 10px lightgray" justifyContent="space-between" alignItems="flex-start">
                <Flex h="100%" w="100%" alignItems="center" p={2} justifyContent="space-between">
                    <HStack h="100%" w="100%" justifyContent="flex-start">
                        <Box w="100%" d="flex" flexDir="column" justifyContent="center" alignItems="center" h="100%" px={2}>
                            <Heading fontSize="16px" color='brand.primary' fontWeight={700} mb={2}>Company</Heading>
                            <Grid
                              h='90%'
                              templateColumns='repeat(6, 1fr)'
                              gap={2}
                            >
                                        
                              <GridItem colSpan={2}>
                                <VStack justifyContent="space-between" alignItems="flex-start">
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Stage</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Fund</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Locations</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Model</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Category</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Pitch Deck</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Video</Text>
                                </VStack>
                              </GridItem>
                              <GridItem colSpan={4}>
                                <VStack justifyContent="space-between" alignItems="flex-start" w="100%">
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" w="100%" fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.company_stage?.title}</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" w="100%" fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.fund_category?.title}</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" w="100%" fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.incorporation_location?.name}</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" w="100%" fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.business_model?.title}</Text>
                                <Text whiteSpace="nowrap" textOverflow="ellipsis" w="100%" fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.business_category?.title}</Text>
                                {companyData?.pitch_deck ? (<a href={companyData?.pitch_deck} target="_blank" rel="noreferrer">View</a>) : (<Text>N/A</Text>)}
                                {companyData?.video_url ? (<a href={companyData?.video_url} target="_blank" rel="noreferrer">View</a>) : (<Text>N/A</Text>)}
                                </VStack>
                              </GridItem>
                            </Grid>
                            {/* <Box>
                                <HStack spacing={8} h={7}>
                                    <Text w={{base: 59, lg: 160}} fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Stage</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.company_stage?.title}</Text>
                                </HStack>
                                <HStack spacing={8} h={7}>
                                    <Text w={{base: 59, lg: 160}} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Fund:</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.fund_category?.title}</Text>
                                </HStack>
                                <HStack spacing={8} h={7}>
                                    <Text w={{base: 59, lg: 160}} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Locations</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.incorporation_location?.name}</Text>
                                </HStack>
                                <HStack spacing={8} h={7}>
                                    <Text w={{base: 59, lg: 160}} fontSize={{base: 12, lg: 14}} fontWeight={700} color='#9FA7AD'>Model</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.business_model?.title}</Text>
                                </HStack>
                                <HStack spacing={8} h={7}>
                                    <Text w={{base: 59, lg: 160}} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Category</Text>
                                    <Text fontWeight={{base:400, '2xl':700}} color="#000" fontSize={{base: 12, lg: 14}}>{companyData?.business_category?.title}</Text>
                                </HStack>
                                <HStack spacing={8} h={7}>
                                    <Text w={{base: 59, lg: 160}} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Pitch Deck</Text>
                                    {companyData?.pitch_deck ? (<a href={companyData?.pitch_deck} target="_blank" rel="noreferrer">View</a>) : (<Text>N/A</Text>)}
                                </HStack>
                                <HStack spacing={8} h={7}>
                                    <Text w={{base: 59, lg: 160}} fontSize={{base: 11, lg: 14}} fontWeight={700} color='#9FA7AD'>Video</Text>
                                    {companyData?.video_url ? (<a href={companyData?.video_url} target="_blank" rel="noreferrer">View</a>) : (<Text>N/A</Text>)}
                                </HStack>
                            </Box> */}
                        </Box>
                    </HStack>
                    {/* </Box> */}
                </Flex>
                
            </VStack>)}
            </Flex>
            <Heading mt={3} mx={4} fontSize="16px" color='brand.primary' fontWeight={700}>Recent Activities</Heading>
            <RecentActivitiesTable user={userData} data={activities}/>
            </>
            ) : (
                <><Spinner/></>
            )}
        </Box>
    )
}
export default ViewUser;