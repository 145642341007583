import React, { useState } from "react";
import {
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { AiFillCustomerService, AiFillWarning } from "react-icons/ai";
import { mutateFunction, useMutation } from "../../libs/apis";
import { CustomToast } from "../toast/CustomToast";

export default function CustomerSupportModal({ isOpen, onClose }) {
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const [complaint, setComplaint] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault()
    if(!complaint.trim()) {
      addToast({message: 'Please type in your complaint'}, "warning")
      return 
    }

    
    mutate(
      { key: "user-contact-support", method: "post", data : {
        complaint: `${complaint.trim()}`
      } },
      {
        onSuccess(res) {
          addToast(res);
          onClose()
        },
        onError(e){
          addToast({message: 'Complaint was not shared'}, "error")
          console.error(e)
        }
      }
    );
  };

  

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Flex flexDirection="column">
              <Stack spacing={6}>
                <Flex className="items-center gap-2" justify={"center"}>
                  <Text
                    fontSize={20}
                    fontWeight={700}
                    color="brand.primary"
                    align="center"
                  >
                    Contact Support
                  </Text>
                  <AiFillCustomerService style={{ color: "rgba(0,0,0,0.7)", fontSize: "28px" }} />
                </Flex>

                <Text fontSize={14} color="#000000">
                  Have an issue or suggestion? Please use the text box, and we will respond via email. 
                </Text>
              </Stack>
              <Flex
                borderWidth="1px"
                borderColor="brand.primary"
                borderRadius="5px"
                alignItems="center"
                mt={"20px"}
              >
                <form onSubmit={formSubmit} >
                  <Textarea
                    focusBorderColor="#"
                    borderWidth="0px"
                    variant="outline"
                    isRequired={true}
                    name="complaint"
                    value={complaint}
                    onChange={(e) => setComplaint(e.target.value)}
                    placeholder="Type here...."
                    type="complaint"
                    w={"350px"}
                    maxLength={250}
                    rows={6}
                  />
                </form>
              </Flex>
              <Flex gap={10} className="mt-6 items-center">
                <BrandButton
                  isLoading={isLoading}
                  text={`Submit`}
                  onClick={formSubmit}
                  type="submit"
                  variant="md"
                  bgColor="brand.primary"
                  color="#fff"
                  fontSize="14px"
                  borderRadius={4}
                  px={4}
                  _hover={{ background: "brand.primary" }}
                />
                <BrandButton
                  text="Close"
                  onClick={onClose}
                />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
