import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Input } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeSlots from "../TimeSlots";
import client from "../../../../libs/client";
import { BASE_URL } from "../../../../helpers/constant";
import { BsThreeDots } from "react-icons/bs";
import { useData } from "../../../../data";
import { useSelector } from "react-redux";
import MonthlyBookings from "./MonthlyBookings";
import SingularDateBooking from "./DateBooking";
import WeeklyBookings from "./WeeklyBookings";
import DailyBookings from "./DailyBookings";

const FounderBookingsCalendarWide = ({
  showBookingsList = false,
}) => {
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const DAYS_OF_WEEK = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [currentDate, setCurrentDate] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [selectedDateBookings, setSelectedDateBookings] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [bookingDates, setBookingDates] = useState([]);
  const [bookingSchedule, setBookingSchedule] = useState([])
  const [calendarType, setCalendarType] = useState('month')
  const accountType = useSelector(state => state?.user?.userInfo?.userTypeTitle);

  const { data, isLoading, refetch, } = useData(`users/bookings/all`, 5000);

  useEffect(()=>{
    if (selectedDate) {
    }
  },[selectedDate])

  
  useEffect(()=>{
    const _schedule = {}
    const dates = [
      ...(data?.data?.bookings || []),
      ...(data?.data?.receivedBookings || [])
    ].map((booking) => {
      const _date = moment(
        booking?.expert_slot?.slot_start_date || booking?.founder_slot?.slot_start_date
      ).format("MM-DD-YYYY")
      if(_schedule[_date]){
        _schedule[_date] = [
          ..._schedule[_date],
          booking?.expert_slot ?
          {
            ...booking?.expert_slot
          } 
          :
          {
            ...booking?.founder_slot
          }
        ]
      }else {
        _schedule[_date] = [
          booking?.expert_slot ?
          {
            ...booking.expert_slot
          } 
          :
          {
            ...booking.founder_slot
          }
        ]
      }
      return _date
    })
    setBookingDates(dates)
    setBookingSchedule(_schedule)
  },[])

  return (
    <Box className="overflow-hidden">
      {
        calendarType === "day" ? (
          <DailyBookings setCalendarType={setCalendarType} bookingSchedule={bookingSchedule} bookingDates={bookingDates} />
        ) : calendarType === "week" ? (
          <WeeklyBookings setCalendarType={setCalendarType} bookingSchedule={bookingSchedule} bookingDates={bookingDates} />
        ) : (
          <MonthlyBookings setCalendarType={setCalendarType} bookingSchedule={bookingSchedule} bookingDates={bookingDates} /> 
        )
      }
      <Box className={`${showBookingsList ? 'flex' : 'hidden'} flex-col`} mt={2} h="100%">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight={600} fontSize="18px" my={4}>
            Today's Bookings
          </Text>
          <BsThreeDots size="25px" />
        </Flex>
        <Box overflowY="auto" flex={1}>
        <TimeSlots bookings={selectedDateBookings} loading={fetching}/>
        </Box>
      </Box>
    </Box>
  );
};
export default FounderBookingsCalendarWide;
