import { Flex, Box, Spacer, Center, Text, VStack, Image, Avatar, useDisclosure, Spinner, Wrap } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import Header from "../../../components/header/Header";
import { useData } from "../../../data";
import ViewAdvertDetails from "./ViewAdvertDetails";
import { useState } from "react";
import DashboardAdvertCard from "../../../pages/founder/Dashboard/DashboardAdvertCard";
import IsDesktop from "../../../utils/IsDesktop";
import AdvertCard from "./AdvertCard";
import SearchBar from "../../../components/searchbar/SearchBar";

const Advert = () => {
  const { data, isLoading } = useData("adverts");
  const { isOpen, onToggle } = useDisclosure();
  const [selectedAdvert, setSelectedAvdert] = useState();
  const isDesktop = IsDesktop();

  const viewAdvert = (advert) => {
    setSelectedAvdert(advert);
    onToggle();
  };

  const navigate = useNavigate();

  return (
    <Box>
      <Header title="Advert List" />

      {/* /...........content */}

      <Box className="p-4">
      {isOpen ? (
        <ViewAdvertDetails advert={selectedAdvert} onToggle={onToggle} />
      ) : (
        <>
          <Flex mt="15px" >
            <Box>
              <Flex>
                <Center mt={2}>
                  <BsArrowLeft />
                  <Text ml="10px" fontSize="1xl" fontWeight="700" color="#134A70" cursor="pointer" onClick={() => navigate(-1)}>
                    Go Back
                  </Text>
                </Center>
              </Flex>
            </Box>

            <Spacer />
            <SearchBar 
                searchType="offer"
                searchRoute="search-adverts"
                onSelectOption={(advert) => {
                    viewAdvert(advert)
                }}
            />
          </Flex>
          {!isLoading ? (
          <Wrap wrap="wrap" spacing={5} mt={5} py={0} px={2} justify={isDesktop ? "flex-start" : "center"}>
            {
              data?.data?.adverts?.length > 0 ? data?.data?.adverts?.map((advert) => {
                return (
                  <AdvertCard advert={advert} viewAdvert={viewAdvert} />
                );
              }) : (
                <Flex direction={"column"} w="100%" h="100%">
                  <Text className="text-center w-full pt-7 text-gray-500 pb-14">
                    No adverts at this moment
                  </Text>
                </Flex>
              )
            }
          </Wrap>):(<Spinner />)}
        </>
      )}
      </Box>
    </Box>
  );
};

export default Advert;
