import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    upgradeUser: false
};

const upgradeUserSlice = createSlice({
  name: "upgradeUser",
  initialState,
  reducers: {
    setUpgradeUser(state, action) {
      state.upgradeUser = action.payload;
    }
  }
});
export const { setUpgradeUser } = upgradeUserSlice.actions;
export default upgradeUserSlice.reducer;
