import React, { useEffect, useState } from "react";
import { Text, Box, Flex, Heading, Input, VStack, InputGroup, InputLeftAddon, Link, Image, useDisclosure, InputRightAddon } from "@chakra-ui/react";
import SideComponent from "../SideComponent";
import { Link as Route, useNavigate } from "react-router-dom";
import envelope from '../../../public/icons/envelope.svg';
import lock from '../../../public/icons/lock.svg';
import google from '../../../public/icons/google-logo.svg';
import person from '../../../public/icons/person.svg';
import logo_2 from '../../../public/icons/logo_.svg';
import IsDesktop from "../../../utils/IsDesktop";
import { CustomToast } from "../../../components/toast/CustomToast";
import { mutateFunction, useMutation } from "../../../libs/apis";
import BrandButton from "../../../components/brandButton/BrandButton";
import { signUpSchema, VALIDATION_CONSTANTS } from "../../../validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ValidationError from "../../../validators/ValidationError";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import LoginWithGoogle from "../GoogleSignIn";


const SignUpForm = () => {
    const isDesktop = IsDesktop();
    const { addToast } = CustomToast();
    const navigate = useNavigate();
    const {mutate, isLoading} = useMutation(mutateFunction);

    const {register, handleSubmit, watch, formState: { errors },} = useForm({
        resolver : yupResolver (signUpSchema)
    });
    const {isOpen, onToggle} = useDisclosure();
    const watchPassword = watch("password", "")
    const [isVisible, setIsVisible] = useState(false)

    const formSubmit = (data) => {
        mutate(
          { key: "sign-up", method: "post", data },
          {
            onSuccess(res) {
                // navigate("/login");
                addToast({message: res.message, type: "info"});
                onToggle();
            },
            onError(res) {
                addToast(res?.response?.data || {message: "Failed to create account"}, "error")
            }
          }
        );
      };

      const CheckEmail = () => {
        useEffect(() => {
            if(!isLoading){
                window.setTimeout(() => {
                        navigate('/login', { replace: true })}, 5000);
            }
        });
        return (
            <>
                <Flex w="100%" justifyContent="center" alignItems="center" flexDir="column">
                    <Text className="text-5xl mb-5">
                        🎉 
                    </Text>
                    <Box display="flex" flexDir="column" justifyContent="center" alignItems="center">
                        <Heading fontSize="32px" color="brand.primary">Verification Link Sent</Heading>
                        <Text textAlign="center" color="gray">Check your inbox or spam folder to verify your email</Text>
                    </Box>
                </Flex>
            </>
        )
      }


    return (
        <Box className={`flex items-center justify-center`} overflowX="hidden">
            {isDesktop &&(
                <Flex w="50%">
                    <SideComponent />
                </Flex>
            )}
            <Flex className={`${isDesktop ? "max-h-[100vh] overflow-y-auto hide-scroll" : "justify-center"} flex-col items-center`}  minW="50%" >
                {isOpen && <CheckEmail/>}
                {!isOpen && <Box>
                {!isDesktop && (
                <Flex justifyContent="center" mb={5}>
                    <Image src={logo_2} boxSize='100px' objectFit='cover'/>
                </Flex>
                )}
                <Flex flexDir="column" h="100%" alignItems="flex-start">
                    <Flex flexDir="column" mb={4}>
                        <Heading color="brand.primary">Create an Account</Heading>
                        <Text color="gray">Already have an Account? <Link color="brand.primary" as={Route} to='/login' fontWeight="bold">Sign In</Link></Text>
                    </Flex>
                <Flex alignItems="center">
                <form onSubmit={handleSubmit(formSubmit)}>
                    <VStack spacing={!isDesktop ? "10px" : "25px"} alignItems="flex-start" w={!isDesktop ? "260px" : "400px"}>
                        <Flex flexDir="column">
                            <Text fontSize="14px" mb={2}>First name</Text>
                            <InputGroup>
                            <Flex borderWidth='1px' borderColor="brand.primary" borderRadius="5px" alignItems="center">
                                <InputLeftAddon borderWidth='0px' bg="" children={<Image src={person} size={30}/>} />
                                <Input {...register('first_name')} focusBorderColor="#" borderWidth='0px' variant='outline' name="first_name" placeholder='First name' type="text" w={!isDesktop ? "260px" : "350px"} h="50px" />
                            </Flex>
                            </InputGroup>
                            <ValidationError message={errors.first_name?.message} />
                        </Flex>
                        <Flex flexDir="column">
                            <Text fontSize="14px" mb={2}>Last name</Text>
                            <InputGroup>
                            <Flex borderWidth='1px' borderColor="brand.primary" borderRadius="5px" alignItems="center">
                                <InputLeftAddon borderWidth='0px' bg="" children={<Image src={person} size={30}/>} />
                                <Input {...register('last_name')} focusBorderColor="#" borderWidth='0px' variant='outline' placeholder='Last name' name="last_name" type="text" w={!isDesktop ? "260px" : "350px"} h="50px" />
                            </Flex>
                            </InputGroup>
                            <ValidationError message={errors.last_name?.message} />
                        </Flex>
                        <Flex flexDir="column">
                            <Text fontSize="14px" mb={2}>Your Email</Text>
                            <InputGroup>
                            <Flex borderWidth='1px' borderColor="brand.primary" borderRadius="5px" alignItems="center">
                                <InputLeftAddon borderWidth='0px' bg="" children={<Image src={envelope} size={30}/>} />
                                <Input {...register('email')} focusBorderColor="#" type="email" borderWidth='0px' variant='outline' name="email" placeholder='yourname@example.com' w={!isDesktop ? "260px" : "350px"} h="50px" />
                            </Flex>
                            </InputGroup>
                            <ValidationError message={errors.email?.message} />
                        </Flex>
                        <Flex flexDir="column">
                            <Text mb={2}>Your Password</Text>
                            <InputGroup>
                            <Flex borderWidth='1px' borderColor="brand.primary" borderRadius="5px" alignItems="center">
                                <InputLeftAddon borderWidth='0px' bg="" children={<Image src={lock} size={30}/>} />
                                <Input {...register('password')} focusBorderColor="#" borderWidth='0px' variant='outline' name="password" placeholder='At least 6 characters' type={isVisible ? "text" : "password"} w={!isDesktop ? "230px" : "320px"} h="50px" />
                                <InputRightAddon borderWidth='0px' bg="" children={
                                    isVisible ? (
                                                    
                                        <FaEye onClick={() => {setIsVisible(prev => !prev)}} className="cursor-pointer" size={20}/>
                                    ) : (
                                        <FaEyeSlash src={lock} onClick={() => {setIsVisible(prev => !prev)}} className="cursor-pointer" size={20}/>
                                    )
                                } />
                            </Flex>
                            </InputGroup>
                            <ValidationError message={errors.password?.message} />
                            <Box className={`bg-gray-50 my-4 p-3 ${!errors.password?.message ? 'hidden' : 'block'}`}>
                                <Flex className="items-center gap-2 mb-2">
                                    <BsCheckCircleFill fill={watchPassword.match(VALIDATION_CONSTANTS.PASSWORD_PARTS.UPPERCASE) ? "green" : "gray"} />
                                    <Text className="text-sm">Contains uppercase</Text>
                                </Flex>
                                <Flex className="items-center gap-2 my-2">
                                    <BsCheckCircleFill fill={watchPassword.match(VALIDATION_CONSTANTS.PASSWORD_PARTS.LOWERCASE) ? "green" : "gray"}  />
                                    <Text className="text-sm">Contains lowercase</Text>
                                </Flex>

                                <Flex className="items-center gap-2 my-2">
                                    <BsCheckCircleFill fill={watchPassword.match(VALIDATION_CONSTANTS.PASSWORD_PARTS.NUMBER) ? "green" : "gray"}  />
                                    <Text className="text-sm">Contains number</Text>
                                </Flex>
                                <Flex className="items-center gap-2 my-2">
                                    <BsCheckCircleFill fill={watchPassword.match(VALIDATION_CONSTANTS.PASSWORD_PARTS.SPECIAL_CHAR) ? "green" : "gray"}  />
                                    <Text className="text-sm">Contains special character</Text>
                                </Flex>
                                <Flex className="items-center gap-2 mt-2">
                                    <BsCheckCircleFill fill={watchPassword?.length > 5 ? "green" : "gray"}  />
                                    <Text className="text-sm">Minimum of 6 characters</Text>
                                </Flex>
                            </Box>
                        </Flex>
                        <Text color="brand.primary">
                            By joining, you agree to Seed A Founder's&nbsp;
                            <Link href='https://seedafounder.com/terms-of-service/' fontWeight="700" target="_blank">terms of use</Link>
                            &nbsp;and &nbsp;
                            <Link href='https://seedafounder.com/privacy-policy' fontWeight="700" target="_blank">Privacy policy.</Link>
                        </Text>

                        <VStack w="100%" alignItems="flex-start">
                            <BrandButton isLoading={isLoading} width={isDesktop ? "400px" : "290px"} text={"Sign Up"} type="submit" colorScheme="blue" />
                            <Flex p={0} w="100%" alignItems="center" justifyContent="center">
                                <Box minW="45%" maxW="70%" h="3px" bg="grey"/>
                                <Text ml={1} mr={1}>OR</Text>
                                <Box minW="45%" maxW="70%" h="3px" bg="grey"/>
                            </Flex>
                            <LoginWithGoogle context="Sign up" />
                        </VStack>
                    </VStack>
                </form>
                </Flex>
                </Flex>
                </Box>}
            </Flex>
        </Box>
    )
}
export default SignUpForm;