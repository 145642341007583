import moment from "moment"

const recurringSlotPredictor = ({
    slot_start_date,
    end_date,
    recurring = 'daily',
    onlyWeekday = false,
}) => {
    try {
        let generated_slots = []
        let count = 1
        let timeline = recurring == 'yearly' ? 'year' : recurring == 'monthly' ? 'month' : recurring == 'weekly' ? 'week' : 'day'
        //@ts-ignore
        if(!moment(end_date, "YYYY-MM-DD").isSameOrAfter(moment(slot_start_date, "YYYY-MM-DD").add(count, timeline), 'day'))return []
        //@ts-ignore
        while(moment(end_date, "YYYY-MM-DD").isSameOrAfter(moment(slot_start_date, "YYYY-MM-DD").add(count, timeline), 'day')){
            count++;
            //@ts-ignore
            let _dateObject = moment(slot_start_date, "YYYY-MM-DD").add(count, timeline)
            let _date = _dateObject.format("YYYY-MM-DD")
            let _day = _dateObject.format('d')
            if(onlyWeekday && (_day == '0' || _day == '6')) continue
            generated_slots.push(_date)
        }
        return generated_slots
    } catch (error) {
        //console.error("Error generating daily slots=====", error)
    }
}

export default recurringSlotPredictor