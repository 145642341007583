import { Flex } from "@chakra-ui/react";
import React from "react";
import IsDesktop from "../../utils/IsDesktop";
import SurveyForm from "./SurveyForm";

const Survey = () => {

    const isDesktop = IsDesktop()
    return (
        <Flex className={`flex-col ${isDesktop ? 'max-h-[95vh] pb-10 overflow-y-auto' : ''}`}>
            <SurveyForm />
        </Flex>
    )
}
export default Survey;