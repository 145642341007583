import { Box, Heading, Text, VStack } from "@chakra-ui/react";

const CountCard = ({ count, text, width }) => {
  return (
    <Box ml={2} w={width || "180px"} minW="150px" maxW="300px" h="100px" p={5} boxShadow="0px 1px 8px lightgray" borderRadius="10px">
    <VStack justifyContent="center" alignItems="flex-start">
      <Heading fontSize="24px" color="brand.primary">
        {count || 0}
      </Heading>
      <Text fontSize="14px" color="gray">
        {text}
      </Text>
    </VStack>
    </Box>
  );
};
export default CountCard;
