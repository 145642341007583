import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated } from "../redux-toolkit/reducers/authSlice";
import { setUserInfo, setUserSubscriptionInfo } from "../redux-toolkit/reducers/userSlice";
import { setCompanyInfo } from "../redux-toolkit/reducers/companySlice";
import { useNavigate } from "react-router-dom";
import UserService from "./UserService";
import { CustomToast } from "../components/toast/CustomToast";
import UseLogoutTimer from "./UseLogoutTimer";

const Logout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { addToast } = CustomToast();
    const { resetLogoutTimer } = UseLogoutTimer()

    const doLogout = () => {
        addToast({ message: "Logged out successfully", type: "success" });
        UserService.removeToken();
        UserService.removeUserInfo();
        UserService.removeCompanyInfo();
        UserService.removeExpertInfo()
        UserService.removeUserSubscriptionInfo();
        dispatch(setIsAuthenticated(false));
        dispatch(setUserInfo(null));
        dispatch(setCompanyInfo(null));
        dispatch(setUserSubscriptionInfo(null));
        navigate("../login");
        window.location.reload(false)
        resetLogoutTimer();

    }
    return { doLogout }
}
export default Logout;