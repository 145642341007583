import { Box, Spinner } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../components/header/Header";
import { useData } from "../../../data";
import ViewAdvertDetails from "./ViewAdvertDetails";


const ViewAdvertPage = () => {
    const [searchParams] = useSearchParams({});
    const advertId = searchParams.get("advertId");
    const {data, isLoading} = useData(`adverts/${advertId}`)
    const navigate = useNavigate();

    return (
        <>
            <Box>
                <Header title="View Advert" />
                {(!isLoading && data?.advert) ? (<ViewAdvertDetails advert={data?.advert} onToggle={()=> navigate(-1)}/>):(
                    <Spinner />
                )}
            </Box>
        </>
    )

}
export default ViewAdvertPage;