import { Avatar, Box, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import ViewBookingModal from "./ViewBookingModal";


function Booking({ booking, selectBooking}) {

  const convertToTime = (timeString) => {
    var parts = timeString.split(":");
    var date = new Date();
    date.setHours(parts[0]);
    date.setMinutes(parts[1]);
    date.setSeconds(parts[2]);

    return date;
  }
    return (
      <Box cursor="pointer" className="py-5 border-b-2" onClick={()=> selectBooking(booking)}>
         <Text className="font-medium pb-1 w-[20ch] overflow-hidden text-ellipsis">
          {booking?.slot_title}
        </Text>
        <Box className="flex space-between items-center w-full justify-between">
          <Box className="flex items-center gap-1">
            <Avatar size={"xs"} name={`${booking?.first_name} ${booking?.last_name}`} src={booking?.profile_image} />
              <Text className="text-gray-600">
              {`${booking?.first_name} ${booking?.last_name}`}
              </Text>
          </Box>
          <Text className="text-gray-600 text-sm">
            {moment(convertToTime(booking?.slot_start_time)).format("hh:mm a")}
          </Text>
        </Box>
      </Box>
    );
  }


const TimeSlots = ({bookings, loading}) => {
  const [selectedBooking, setSelectedBooking] = useState();
  const {isOpen: isViewBookingModalOpen, onOpen: onOpenViewBookingModal, onClose: onCloseViewBookingModal} = useDisclosure();
  const selectBooking = (booking) => {
    setSelectedBooking(booking);
    onOpenViewBookingModal();
  }
  const convertToTime = (timeString) => {
    var parts = timeString.split(":");
    var date = new Date();
    date.setHours(parts[0]);
    date.setMinutes(parts[1]);
    date.setSeconds(parts[2]);

    return date;
  }

    return (
        <>
        {isViewBookingModalOpen && <ViewBookingModal booking={selectedBooking} isOpen={isViewBookingModalOpen} onClose={onCloseViewBookingModal} />}
        {(!loading && bookings.length > 0) && <Box position="relative" width="100%" height="auto">
          {[...Array(24)].map((_, eachHour) => {
            const startTime = new Date();
            startTime.setHours(eachHour);
            startTime.setMinutes(0);
            startTime.setSeconds(0);
    
            const endTime = new Date(startTime.getTime());
            endTime.setHours(eachHour + 1);
    
            const slotBookings = bookings?.filter((booking) => {
              return (
                moment(convertToTime(booking?.slot_start_time)).isBetween(moment(startTime), moment(endTime), null, "[)") 
              );
            });

            return (
              <>
              {slotBookings.length > 0 && <Box
                key={eachHour}
                m
                minH="60px"
                width="100%"
              >
                <Box px={4} h="auto">
                  {slotBookings
                    ?.sort((a, b) => a.startTime - b.startTime)
                    ?.map((booking) => (
                      <Booking
                        key={booking?.booking_id}
                        booking={booking}
                        selectBooking={selectBooking}
                      />
                    ))}
                </Box>
              </Box>}
              </>
            );
          })}
        </Box>}
        {loading && <Box width="100%" display="flex" justifyContent="center"><Spinner /></Box>}
        {(!loading && bookings.length === 0) && <Box width="100%" display="flex" justifyContent="center" className="p-3 text-gray-600"><Text>No bookings on this date...</Text></Box>}
        </>
      );
  }
export default TimeSlots;