import React, { useState } from "react";
import {
  Center,
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { AiFillWarning } from "react-icons/ai";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useNavigate } from "react-router-dom";
import { CustomToast } from "../toast/CustomToast";

export default function OpenExternalOfferLinkModal({ isOpen, onClose, link, id }) {
  const { mutate, isLoading } = useMutation(mutateFunction);
  const {addToast} = CustomToast()

  const handleConfirm = () => {
    mutate(
      { key: `offers/${id}/click`, method: "get", data: {} },
      {
        onSuccess(res) {
          //addToast(res)
        },
      }
    );
    window.open(link, '_blank');
    onClose()
  };

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody maxWidth={"450px"}  p={"32px"}>
            <Center maxWidth={"450px"} d="flex" flexDirection="column">
              <Stack spacing={4}>
                <Flex className="items-center gap-3" justify={"center"}>
                  <Text
                    fontSize={13}
                    color="#8A8A8A"
                    align="center"
                  >
                    Warning ⚠️
                  </Text>
                </Flex>
                <Text textAlign={"center"} fontWeight={500} fontSize={23} color="#000">
                  You're leaving Seed a Founder. <br/> Would you like to continue?
                </Text>
                <Text textAlign={"center"}  fontSize={13} color="#8A8A8A">
                Disclaimer: Seed A Founder is not responsible for the content, accuracy, or availability of the external site.
                </Text>
              </Stack>
              <Flex className="gap-2 mt-6 w-full">
                          <BrandButton
                            isLoading={isLoading}
                            fontSize="13px" py={"8px"} px={"10px"}  
                            color="#F2112D" 
                            borderColor="#F2112D"
                            bg="#FFF"
                            _hover={{ backgroundColor: "red.50" }}
                            height="36px"
                            
                            text={`Yes`}
                            width="full"
                  onClick={handleConfirm}

                            
                          />
                        <BrandButton
                          fontSize="13px" bg="#20B962" py={2}
                          height="36px"
                          colorScheme="blue"
                          onClick={onClose}
                          
                          text="No"
                          width="full"
                        />
              </Flex>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
