import {
  Box,
  Button,
  Center,
  Checkbox,
  Stack,
  HStack,
  VStack,
  Text,
  Avatar,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Icon,
  Wrap,
  WrapItem,
  useDisclosure,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { ChevronRightIcon, DeleteIcon } from "@chakra-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AiOutlineUser,
  AiOutlineMail,
  AiOutlineLock,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { FiEdit2, FiUpload } from "react-icons/fi";
import { useSelector } from "react-redux";
import Header from "../../../components/header/Header";
import { CustomToast } from "../../../components/toast/CustomToast";
import useData from "../../../data/useData";
import { mutateFunction, useMutation } from "../../../libs/apis";
import IsDesktop from "../../../utils/IsDesktop";
import { loginSchema } from "../../../validators";
import DeleteAccountModal from "../../../components/modal/DeleteAccountModal";

const AccountSettings = () => {
  const {
    isOpen: addSlotModalIsOpen,
    onOpen: onOpenAddSlotModal,
    onClose: closeAddSlotModal,
  } = useDisclosure();
  const isDesktop = IsDesktop();
  const { addToast } = CustomToast();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { data, refetch, isLoading : isLoadingDetails } = useData(`users/${loggedInUser?.id}`);
  const [loading, setLoading] = useState(false)

  const [pushNotf, setPushNotf] = useState(data?.data?.user?.push_notification);
  const [emailNotf, setEmailNotf] = useState(
    data?.data?.user?.email_notification
  );
  const [promoNotf, setPromoNotf] = useState(
    data?.data?.user?.promotional_newsletter
  );

  const [profilePicture, setProfilePicture] = useState(
    data?.data?.user?.profile_image
  );
  const [userDetails, setUserDetails] = useState(data?.data?.user);
  // const { mutate: mutateUpload, isLoading: isLoadingUpload } =
  //   useMutation(mutateFunction);
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: `${userDetails?.first_name || ''} ${userDetails?.last_name || ''}`,
      email: userDetails?.email,
      image: userDetails?.profile_image,
    },
    resolver: yupResolver(loginSchema),
  });

  useEffect(() => {
    setUserDetails(data?.data?.user)
  }, [data?.data?.user])

  useEffect(() => {
    setValue("name", `${userDetails?.first_name || ''} ${userDetails?.last_name || ''}`)
  }, [userDetails?.first_name, userDetails?.last_name])
  const handleChangeForFile = (e, type) => {
    const fileData = e.target.files[0];
    setProfilePicture(fileData);
    const formData = new FormData();
    formData.append("attachment", fileData);
    uploadPhoto(formData, type);
    // uploadPhoto({attachment: fileData})
    // uploadPhoto(e.target.files[0]);
  };

  const uploadPhoto = (data, type) => {
    mutate(
      { key: `users/${loggedInUser.id}/${type}-image`, method: "put", data },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
        },
      }
    );
  };

  const handleNotification = (e, type) => {
    e.preventDefault();

    const value = e.target.checked;
    if (type === "email_notification") {
      setEmailNotf(value);
    } else if (type === "push_notification") {
      setPushNotf(value);
    } else if (type === "promotional_newsletter") {
      setPromoNotf(value);
    }

    const data = {
      type,
      value: value,
    };
    mutate(
      {
        key: `users/user-update-notf-settings`,
        method: "post",
        data,
      },
      {
        onSuccess(res) {
          addToast(res);
        },
      }
    );
  };

  const formSubmit = (data) => {
    mutate(
      { key: `${loggedInUser.id}/profile-image`, method: "post", data },
      {
        onSuccess(res) {
          addToast(res);
        },
      }
    );
  };
  useEffect(() => {
    switch (typeof profilePicture) {
      case "string":
        // setProfilePicture(profilePicture);
        break;
      case "object":
        setProfilePicture(
          window.URL.createObjectURL(
            new Blob([profilePicture], { type: "image" })
          )
        );
        break;
      default:
        setProfilePicture(null);
    }
  }, [profilePicture, data]);

  const deletePhoto = () => {
    let data = {};
    mutate(
      { key: `users/user-delete-photo`, method: "put", data },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
        },
      }
    );
  };

  const formSubmitResetPassword = () => {

    let data = {
      email: userDetails?.email
    }

    setLoading(true)
    mutate(
      { key: "forgot-password", method: "post", data },
      {
        onSuccess(res) {
          setLoading(false)

          // showCheckEmail();
          // onToggleCheckEmail();
          addToast({ message: res.message, type: "info" });
        },
      }
    );
  };

  return (
    <Box>
      <DeleteAccountModal
        isOpen={addSlotModalIsOpen}
        onClose={closeAddSlotModal}
      />
      <Header title="Account Settings" />
      <Flex
        className={`flex-col w-full ${
          isDesktop ? "max-h-[95vh] fixed pb-10 overflow-y-scroll" : ""
        }`}
      >
      <Stack direction={["column", "row"]} spacing="24px" p={8}>
        <Box
          w="full"
          boxShadow="0px 0px 8px 4px rgba(19, 74, 112, 0.1)"
          borderRadius="10px"
          p={4}
        >
          <Text color="brand.primary" fontWeight={700}>
            My Profile
          </Text>
          <VStack spacing={5} align="left" mt={4}>
            {/* <HStack>
              <Avatar
                size="xl"
                name={`${loggedInUser?.firstName} ${loggedInUser?.lastName}`}
                src={profilePicture}
                mr={2}
              />
              <Input
                accept="image/*"
                hidden
                id="image-input"
                type="file"
                onChange={(e) => handleChangeForFile(e)}
                name="avatar"
                key="avatar"
              />
              <label htmlFor="image-input">
                <Box
                  cursor="pointer"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgColor="transparent"
                  h="24px"
                  w="96px"
                  border="1px solid #000"
                  borderRadius="15px"
                  fontSize={10}
                  mt={10}
                >
                  Choose image
                </Box>
              </label>
            </HStack> */}
            <div
              style={{
                marginBottom: "60px",
                position: "relative",
                background: `${!data?.data?.user?.cover_image && "#466fc2"}`,
                borderRadius: "8px",
                backgroundImage: `url("${data?.data?.user?.cover_image}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%", // Fill the width of the parent div
                paddingTop: "56.25%",
              }}
              className="min-h-[15rem]"
            >
              <Input
                accept="image/*"
                hidden
                id="image-input-2"
                type="file"
                onChange={(e) => handleChangeForFile(e, "cover")}
                name="avatar"
                key="avatar"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  top: "10px",
                  position: "absolute",
                  right: "10px",
                }}
                className="p-[20px] "
              >
                <label htmlFor="image-input-2">
                  <div className="bg-[#FFFFFF]">
                    <FiUpload className="text-[#134A70] text-[30px] font-bold cursor-pointer" />
                  </div>
                </label>
              </div>
              <HStack position={"absolute"} bottom={"-60px"}>
                <div style={{ position: "relative" }}>
                  <Avatar
                    style={{ border: "1px solid #ccc", borderRadius: "100px",                         background: "#466fc2"
                  }}
                    size="xl"
                    name={`${userDetails?.firstName} ${userDetails?.lastName}`}
                    src={data?.data?.user?.profile_image}
                    mr={2}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "#ffffff",
                      cursor: "pointer",
                      borderRadius: "100px",
                    }}
                    onClick={deletePhoto}
                  >
                    <DeleteIcon />
                  </div>
                </div>

                <Input
                  accept="image/*"
                  hidden
                  id="image-input"
                  type="file"
                  onChange={(e) => handleChangeForFile(e, "profile")}
                  name="avatar"
                  key="avatar"
                />
                <label htmlFor="image-input">
                  <VStack>
                    <Box
                      cursor="pointer"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      bgColor="transparent"
                      h="24px"
                      w="96px"
                      border="1px solid #000"
                      borderRadius="15px"
                      fontSize={10}
                      mt={5}
                    >
                      Choose image
                    </Box>
                    {isLoading ? (
                      <Flex>
                        <Text fontSize="12px">Uploading...</Text>
                        <Spinner />
                      </Flex>
                    ) : (
                      <></>
                    )}
                  </VStack>
                </label>
              </HStack>
            </div>

            <form onSubmit={handleSubmit(formSubmit)}>
              <Box>
                <Text color="#000" fontWeight={400}>
                  Your Name
                </Text>
                <InputGroup mt={4}>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={AiOutlineUser} color="brand.primary" />}
                  />
                  <Input
                    {...register("name")}
                    placeholder="Hafis Raji"
                    w={{ base: "100%", lg: "70%" }}
                    borderColor="brand.primary"
                    _placeholder={{ color: "brand.primary" }}
                  />
                </InputGroup>
                <Text color="#9FA7AD" fontWeight={400} fontSize={12} mt={2}>
                  You can't change your name now if you changed it within the
                  last 14 days.
                </Text>
              </Box>
              <Box>
                <Text color="#000" fontWeight={400}>
                  Your Email
                </Text>
                <InputGroup mt={4}>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Icon as={AiOutlineMail} color="brand.primary" />}
                  />
                  <Input
                    {...register("email")}
                    disabled
                    placeholder="yourname@example.com"
                    w={{ base: "100%", lg: "70%" }}
                    borderColor="brand.primary"
                    _placeholder={{ color: "brand.primary" }}
                  />
                </InputGroup>
                <Text color="#9FA7AD" fontWeight={400} fontSize={12} mt={2}>
                  Enter an email you check regularly. Any alerts you activate
                  will be sent there.
                </Text>
              </Box>
              <Box>
                <Text color="#000" fontWeight={400}>
                  Your password
                </Text>
                <Stack direction={["column", "row"]} spacing="24px" mt={4}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <Icon as={AiOutlineLock} color="brand.primary" />
                      }
                    />
                    <Input
                      placeholder="Your current password"
                      w="full"
                      borderColor="brand.primary"
                      _placeholder={{ color: "#9FA7AD" }}
                    />
                    <InputRightElement
                      pointerEvents="none"
                      children={
                        <Icon as={AiOutlineEyeInvisible} color="#9FA7AD" />
                      }
                    />
                  </InputGroup>
                  <Button
                    bgColor="brand.primary"
                    color="#fff"
                    fontSize={["12px", "14px"]}
                    borderRadius={4}
                    px={6}
                    _hover={{ background: "brand.primary" }}
                  >
                    Save changes
                  </Button>
                </Stack>
                <Text color="#9FA7AD" fontWeight={400} fontSize={12} mt={2}>
                  To make any changes, you must enter your current password.
                </Text>
              </Box>

              <Box>
                <Button
                  w={{ base: "100%", lg: "70%" }}
                  border="1px solid #134A70"
                  bg="#fff"
                  color="brand.primary"
                  rightIcon={<ChevronRightIcon />}
                  align="bottom"
                  fontSize="14px"
                  borderRadius={4}
                  px={4}
                  mt={{ base: "auto", lg: 8 }}
                  _hover={{ background: "brand.primary", color: "#fff" }}
                  setUserDetails={setUserDetails}
                  onClick={formSubmitResetPassword}
                  >
                  Reset my password
                </Button>
                {loading && <Spinner/>}
              </Box>
            </form>
          </VStack>
          <Box mt={6}>
            <Text color="brand.primary" fontSize={"1.2rem"} fontWeight={700}>
              Notifications
            </Text>
            <Text mt={3} color="#000" fontWeight={500} fontSize={"1rem"}>
              Never miss any activity on Seed A Founder
            </Text>
            <Wrap mt={4}>
              <WrapItem>
                <Center w="180px" h="25px">
                  <Checkbox
                    value={pushNotf}
                    isChecked={pushNotf}
                    fontSize={14}
                    color="brand.primary"
                    borderColor="brand.primary"
                    onChange={(e) => handleNotification(e, "push_notification")}
                  >
                    Push notifications
                  </Checkbox>
                </Center>
              </WrapItem>
              <WrapItem>
                <Center w="180px" h="25px">
                  <Checkbox
                    value={emailNotf}
                    isChecked={emailNotf}
                    fontSize={14}
                    color="brand.primary"
                    borderColor="brand.primary"
                    onChange={(e) =>
                      handleNotification(e, "email_notification")
                    }
                  >
                    Email notifications
                  </Checkbox>
                </Center>
              </WrapItem>
              <WrapItem>
                <Center h={{ base: "auto", lg: 25 }} ml={1}>
                  <Checkbox
                    value={promoNotf}
                    isChecked={promoNotf}
                    fontSize={14}
                    color="brand.primary"
                    borderColor="brand.primary"
                    onChange={(e) =>
                      handleNotification(e, "promotional_newsletter")
                    }
                  >
                    Receive Promotional emails and newsletter
                  </Checkbox>
                </Center>
              </WrapItem>
            </Wrap>
          </Box>
          <Box mt={6}>
            <Text color="brand.primary" fontSize={"1.2rem"} fontWeight={700}>
              Delete Account
            </Text>
            <Text color="#000" fontWeight={400} fontSize={14}>
              This will completely remove your account from Seed A Founder
            </Text>
            <Button
              mt={4}
              color="brand.primary"
              bgColor="#fff"
              borderRadius={4}
              fontSize={14}
              border="1px solid #134A70"
              _hover={{ background: "inherit" }}
              onClick={() => {
                onOpenAddSlotModal();
              }}
            >
              Delete account
            </Button>
          </Box>
        </Box>

        {/* Right column */}
        <Box w="full" borderRadius="10px" p={4}></Box>
      </Stack>
      </Flex>
    </Box>
  );
};

export default AccountSettings;
