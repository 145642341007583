import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  Avatar,
  HStack,
  Divider,
  Heading,
  Spinner,
  Stack,
  Input,
  background,
  useDisclosure,
} from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import IsDesktop from "../../utils/IsDesktop";
import BrandButton from "../brandButton/BrandButton";
import { useData } from "../../data";
import ConnectionButton from "../connectionButton/ConnectionButton";
import investor_cover_photo from "../../public/images/Investors.png";
import InvestorsPage from "./InvestorsPage";
import { useSelector } from "react-redux";
import { mutateFunction, useMutation } from "../../libs/apis";
import { CustomToast } from "../toast/CustomToast";
import { FiUpload } from "react-icons/fi";
import { useLocation, useSearchParams } from "react-router-dom";
import NewSingleInvestor from "./NewSingleInvestor";
import { BASE_URL } from "../../helpers/constant";
import client from "../../libs/client";

const ViewInvestorDetails = ({ investor, onToggle }) => {
  const isDesktop = IsDesktop();
  const lastResultsCount = useRef(0);
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const { data, refetch } = useData(`users/${investor}`);
  const ref = useRef(null)
  const [userData, setUserData] = useState(null);
  const { isOpen: viewDetailsIsOpen, onToggle: onToggleViewDetails } = useDisclosure();

  const [selectedFounder, setSelectedFounder] = useState(null);
  const [companyData, setCompanyData] = useState({});
  const [profilePicture, setProfilePicture] = useState(
    data?.data?.user?.cover_image
  );
  const [investors, setInvestors] = useState([])
  const { addToast } = CustomToast();
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const [filter, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  const uploadPhoto = (data) => {
    mutate(
      { key: `users/${loggedInUser.id}/cover-image`, method: "put", data },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
        },
      }
    );
  };

  const handleChangeForFile = (e) => {
    const fileData = e.target.files[0];
    setProfilePicture(fileData);
    const formData = new FormData();
    formData.append("attachment", fileData);
    uploadPhoto(formData);
    // uploadPhoto({attachment: fileData})
    // uploadPhoto(e.target.files[0]);
  };

  const acceptRequest =  () => {
    mutate(
      { key: `users/${loggedInUserId}/connections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
      {
        onSuccess(res) {
          addToast(res);
          setSearchParams({
            userid: investor,
            usertype: 2
          })
          refetch()
        },
      }
    );
  }

  const declineRequest =  () => {
    mutate(
      { key: `users/${loggedInUserId}/declineConnections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
      {
        onSuccess(res) {
          addToast(res);
          setSearchParams({
            userid: investor,
            usertype: 2
          })
          refetch()
        },
      }
    );
  }

  useEffect(() => {
    if (data?.data) {
      setUserData(data.data.user);
      setCompanyData(data.data.company);
    }
    if(ref?.current)ref.current.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
  }, [data]);

  useEffect(() => {

    if(userData){
      if(location?.search?.includes("status=accept")){
        acceptRequest()
      }
      if(location?.search?.includes("status=decline")){
        declineRequest()
      }
    }
    
  }, [userData, location?.search])

  const parseFilters = (obj) => {
    const params = {
      'companyStages': 'company_stage', 
      'fundCategories': 'fund_category', 
      'locations': 'incorporation_location',
      'businessModels': 'business_model', 
      'categories': 'business_category',  
    }
    return Object.entries(obj).map(([key, val]) => `${params[key]}=${encodeURIComponent(val)}`).join('&')
  }

  const getInvestors = ({filters = filter, append = true, pageNumber = 1 }) => {
    const _filters = parseFilters(filters)
    return client().get(`${BASE_URL}user-types/investor?page_no=${pageNumber}&page_size=10${_filters ? `&${_filters}` : ''}`).then((res) => {
      setIsLoading(true)
      let data = res.data.data.users;
      updateState(data, append);
    });
  }

  useEffect(() => {
    const companyStages = (data?.data?.company?.company_stage || []).map(({title}) => title)
    const categories = (data?.data?.company?.business_category || []).map(({title}) => title)
    const businessModels = (data?.data?.company?.business_model || []).map(({title}) => title)
    const fundCategories = data?.data?.company?.fund_category?.title || ''
    const locations =  (data?.data?.company?.incorporation_location || []).map(({name}) => name) 
    const filters = {}
    if(companyStages.length > 0) filters['companyStages'] = companyStages
    if(fundCategories) filters['fundCategories'] = [fundCategories]
    if(businessModels.length > 0) filters['businessModels'] = businessModels
    if(categories.length > 0) filters['categories'] = categories
    if(locations.length > 0) filters['locations'] = locations
    getInvestors({
      filters
    })
  }, [data?.data?.company])

  const updateState = (data, append = true, isFetchedFromServer = true) => {
    if(isFetchedFromServer)lastResultsCount.current = data.length;
    let _investors = append ? [...investors, ...data] : [...data];
    setIsLoading(false);
    setInvestors([..._investors]);
  };

  return (
    <>
      {userData !== null ? (
        <>
          <Flex
            cursor="pointer"
            onClick={onToggle}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box mr={2}>
              <HiOutlineArrowNarrowLeft size="20px" />
            </Box>
            <Box>
              <Text >Go Back</Text>
            </Box>
          </Flex>
          <VStack
            overflowY="scroll"
            minH="80vh"
            bg="white"
            borderRadius="10px"
            justifyContent="start"
            alignItems="flex-start"
            className="relative hide-scroll"
          >
            <div className="bg-gray-300 w-full ">
              <div
                ref={ref}
                style={{
                  backgroundImage: `url(${data?.data?.user?.cover_image || investor_cover_photo})`,
                }}
                className="w-[100%] bg-no-repeat rounded-lg bg-cover bg-center h-60"
              >
                <Input
                  accept="image/*"
                  hidden
                  id="image-input"
                  type="file"
                  onChange={(e) => handleChangeForFile(e)}
                  name="avatar"
                  key="avatar"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="p-[20px]"
                >
                  <label htmlFor="image-input">
                    {/* <FiUpload className="text-[#134A70] text-[30px] font-bold cursor-pointer" /> */}
                  </label>
                </div>
              </div>
            </div>
            <Box className={`w-full`}>
              <div className={`flex w-full lg:w-[80%] max-w-[1000px]  mx-auto flex-wrap justify-between items-center px-5 py-5 lg:px-4`}>
                <Box className="flex flex-col gap-3">
                  <Box
                    borderRadius="50%"
                  >
                    <Avatar
                      name={`${userData?.first_name} ${userData?.last_name}`}
                      src={userData?.profile_image}
                      size={isDesktop ? "2xl" : "xl"}
                    />
                  </Box>
                  <Box
                      className="items-center justify-center"
                      display="flex"
                      flexDir="column"
                      alignItems={!isDesktop ? "flex-start" : "center"}
                    >
                      <Text className="text-2xl font-semibold">
                        {`${userData?.first_name} ${userData?.last_name}`}
                      </Text>
                    </Box>
                </Box>
                <div className={`mb-5 xs:mb-2 w-full max-w-[296px]`}>
                  <ConnectionButton
                    style={{ backgroundColor: "white" }}
                    user={userData}
                  />
                </div>
              </div>
            </Box>
            <Box className={`w-full p-5 xl:px-10 pb-20 bg-[#FAFAFA]`}>
                <Text className="text-lg font-medium text-[#2F4B6E] pb-10">
                  Overview
                </Text>
              <Box className={`w-full lg:w-[80%] max-w-[1000px] mx-auto px-5 py-5 border rounded-lg lg:px-4`}> 
                   <Box className="flex flex-col xl:flex-row gap-8">
                      <Box className="flex flex-col gap-4 xl:w-[50%]">
                          <Text className="text-[#2F4B6E] text-lg font-medium">
                            Business Overview
                          </Text>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Company Name:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.title}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Location:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                              {companyData?.incorporation_location?.map((item) => item?.name).join(', ')}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Social media:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Website
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Business model:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.business_model?.map((item) => item?.title).join(', ')}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Category:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.business_category?.map((item) => item?.title).join(', ')}
                                
                              </Text>
                            </Box>
                          </Flex>
                      </Box>
                      <Box className="flex flex-col gap-4 xl:w-[50%]">
                          <Text className="text-[#2F4B6E] text-lg font-medium">
                            Investment Overview
                          </Text>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Kind of investment:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.fund_category?.title}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Valuation:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.company_stage?.map((item) => item?.title).join(', ')}
                             
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Target Locations:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.investment_location?.map((item) => item?.name).join(', ')}

                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Equity offered:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Share price:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Investors:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                      </Box>
                    </Box>
              </Box>
            </Box>

            <Box className={`w-full p-5 xl:px-10 pb-20`}>
                <Text className="text-lg font-medium text-[#2F4B6E] pb-10">
                  About Company
                </Text>
              <Box className={``}> 
                   <Box className="flex flex-col xl:flex-row gap-8">
                      <Box className="flex flex-col gap-4 xl:w-[50%]">
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Description:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.description}
                              </Text>
                            </Box>
                          </Flex>
                      </Box>
                      <Box className="flex flex-col gap-4 xl:w-[50%]"></Box>
                    </Box>
              </Box>
            </Box>
            <Box className={`w-full p-5 xl:px-10 pb-20 bg-[#FAFAFA]`}>
                <Text className="text-lg font-medium text-[#2F4B6E] pb-10">
                  Similar Investors
                </Text>
            <Box 
              // onScroll={() => {handleScroll(investorsContainerRef.current.scrollTop + investorsContainerRef.current.clientHeight, investorsContainerRef.current.scrollHeight)}} 
              className={`flex flex-wrap items-center justify-center`} 
            >
              {!isLoading ? (
              
                <Box
                  className="flex flex-wrap gap-3 items-center px-4 justify-center mx-auto"
                >
                  {investors && investors.length > 0  ? investors.map((investor, index) => (
                    <Fragment key={index}>
                      <NewSingleInvestor
                        setSelectedFounder={setSelectedFounder}
                        investor={investor}
                        isOpen={viewDetailsIsOpen}
                        onToggle={onToggleViewDetails}
                      />
                    </Fragment>
                  )) : <Flex className="text-center justify-center my-2 w-full">No similar investors!</Flex>}
                </Box>
            ) : (
                <Spinner />
            )}
            </Box>
            </Box>
          </VStack>
          
        </>
      ) : (
        <div className="">
          
          <Spinner />
        </div>
      )}
    </>
  );
};
export default ViewInvestorDetails;
