import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Text,
  Textarea,
  VStack,
  Button,
  Spinner
} from "@chakra-ui/react";
import ProgressSlider from "./assets/ProgressSlider";
import IsDesktop from "../../utils/IsDesktop.js";
import Page from "./assets/Page";
import file from "../../public/icons/file.svg";
import { mutateFunction, useMutation } from "../../libs/apis";
import { CustomToast } from "../../components/toast/CustomToast";
import { BASE_URL } from "../../helpers/constant";
import client from "../../libs/client";
import UserService from "../../utils/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux-toolkit/reducers/userSlice";
import { setCompanyInfo } from "../../redux-toolkit/reducers/companySlice";
import { setCompleteSurvey } from "../../redux-toolkit/reducers/SurveySlice";
import BrandButton from "../../components/brandButton/BrandButton";
import { useNavigate } from "react-router-dom";
import { BsFileImage } from "react-icons/bs";
import evaluateCompanyProfile from "../../utils/evaluateCompanyProfile";
import { useClickOutside } from "../../utils/useClickOutside";
import { useData } from "../../data";
import ValidationError from "../../validators/ValidationError";
import { setExpertInfo } from "../../redux-toolkit/reducers/expertSlice";

const ExpertSurveyForm = ({
  pageNumber,
  setPageNumber,
  setPage,
  surveyData,
  filledData,
  setFilledData,
  userCompany,
  refetch
}) => {

  const userInfo = useSelector((state) => state?.user?.userInfo);
  const expertDetails = useSelector((state) => state?.expert?.expertInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = CustomToast();
  const { mutate, isLoading } = useMutation(mutateFunction);
  const isDesktop = IsDesktop();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [image, setImage] = useState(null);
  const {data : expertCategoriesData} = useData("experts/categories")
  const {data : expertLocations} = useData("experts/locations")
  const list = expertCategoriesData?.data?.categories || []
  const countriesList  = expertLocations?.data?.locations || []
  const [selected, setSelected] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [searchLocationValue, setSearchLocationValue] = useState('')
  const [isLocationOpen, setIsLocationOpen] = useState(false)
  const [ref] = useClickOutside(() => {
      if (isOpen) setIsOpen(false);
  });
  const [countrySelectionError, setCountrySelectionError] = useState(false)

  const [locationRef] = useClickOutside(() => {
    if (isLocationOpen) setIsLocationOpen(false);
  });

  const searchCategoriesFilter = (value) => {
      return searchValue ? value?.title?.toLowerCase().includes(searchValue.toLowerCase()) || value?.title == "Other" : true
  }
  const searchLocationFilter = (value) => {
    return searchLocationValue ? value?.title?.toLowerCase().includes(searchLocationValue.toLowerCase()) || value?.title == "Other" : true
  }
  const onChange = (e) => {
    setIsOpen(true)
    setSearchValue(e.target.value)
    setSelected(null)
  }

  const onChangeLocation = (e) => {
    setIsLocationOpen(true)
    setSearchLocationValue(e.target.value)
    setSelectedLocation(null)
    setCountrySelectionError(false)
  }
  const selectItem = (item) => {
      setSearchValue(item?.title)
      setSelected(item)
      toggleDropDown()
  }
  const selectLocation = (item) => {
    setSearchLocationValue(item?.title)
    setSelectedLocation(item)
    toggleLocationDropDown()
}
  const toggleDropDown = () => setIsOpen(prev => !prev)
  const toggleLocationDropDown = () => setIsLocationOpen(prev => !prev)

  useEffect(() => {
    if(expertDetails?.company_name){
      setFilledData({
        ...expertDetails
      })
      if(expertDetails?.expert_category){
        setSearchValue(expertDetails?.expert_category?.title)
        setSelected(expertDetails?.expert_category)
      }
      if(expertDetails?.location){
        setSearchLocationValue(expertDetails?.location?.title)
        setSelectedLocation(expertDetails?.location)
      }
      
    }
  }, [])
  
  useEffect(() => {
  }, [selectedLocation, selected])

  const nextPage = () => {

    if(isLoading) return
    let _data = {
      ...filledData
    }

    if(!_data?.company_name){
      addToast({message: 'Please specify company name', type: "error"})
      return
    }

    _data["user_type"] = "expert";

    if(!selectedLocation){
      setCountrySelectionError(true)
      return
    }
    const data = new FormData();

    if (image !== null && image != undefined) {
      data.append("avatar", image);
    }

    const params = [
      'headline', 
      'bio', 
      'category_id',
      'country_id',
      'company_name', 
      'phone_number'
    ]

    let categoryId = selected?.id || list.filter(({title}) => title == "Other")[0]?.id || ""

    if(categoryId && !data.has("category_id")) data.append("category_id", categoryId)
    if(!data.has("country_id")) data.append("country_id", selectedLocation?.id || '')

    for (let key in _data) {
      if(params.includes(`${key}`) && !data.has(key))  data.append(key, _data[key]);
    }
    
    if (pageNumber <= 6) {
      mutate(
        {
          key: `experts/submit-survey`,
          method: "post",
          data,
        },
        {
         async onSuccess(res) {
            await refetch()
            if (res.data) {
              addToast({
                message: "Saved profile data Successfully",
                type: "success",
              });
              const user = res?.data?.user;
              const expert = res?.data?.expert
              UserService.setUserInfo(user);
              UserService.setExpertInfo(expert)
              dispatch(setUserInfo(user));
              dispatch(setExpertInfo(expert))
              setPage("thank-you");
            }
          },
          onError(e) {
            addToast({message: "Please fill in all details", type: "error"});
          },
        }
      );
    } 
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(0);
      setPage("welcome");
    }
  };

  const Page1 = () => {
    const handleChange = (e) => {
      setFilledData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    };
    const handleChangeForFile = (e) => {
      setFilledData((prev) => ({
        ...prev,
        [e.target.name]: e.target.files[0],
      }));
      setImage(e.target.files[0]);
    };
    
    return (
      <Box display="flex" className="flex-col gap-2" justifyContent="center">
        <Box mt="1.25rem">
          <Flex>
              {image || userInfo?.profile_image ? (
                  <Image
                    src={image ? window.URL.createObjectURL(
                      new Blob([image], { type: "image" })
                    ): userInfo?.profile_image }
                    w="100%"
                    h="100%"
                    alt="Preview" style={{ maxWidth: "100px" }} 
                    className="max-h-[100px]" 
                    fit="cover"
                    borderRadius="100%"
                  />
              ): (<Image src="/assets/images/Ellipse 3 (1).png" alt="image" />)}

            <Button
              mt="1.25rem"
              ml="0.625rem"
              // border="1px solid #000000"
              // borderRadius="20px"
            >
              <Input
                accept="image/*"
                hidden
                id="image-input"
                type="file"
                onChange={(e) => handleChangeForFile(e)}
                name="avatar"
                key="avatar"
              />
              <label htmlFor="image-input">
                <VStack>
                  <Box
                    cursor="pointer"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgColor="transparent"
                    h="24px"
                    w="96px"
                    border="1px solid #000"
                    borderRadius="15px"
                    fontSize={10}
                    mt={5}
                  >
                    Choose image
                  </Box>
                </VStack>
              </label>
            </Button>
          </Flex>
        </Box>
        <VStack
          flexDir="column"
          w={isDesktop ? "600px" : "90vw"}
          justifyContent="flex-start"
          alignItems={isDesktop ? "flex-start:" : "center"}
          spacing={5}
          maxWidth={isDesktop ? "600px" : "500px"}
        >
          <Flex className="flex-col w-full mt-3 gap-2">
              <Text fontWeight={700} fontSize="16px" color="brand.primary">
                Enter your Company name?
              </Text>
              <Input
                key="company_name"
                type="text"
                placeholder="Type here...."
                defaultValue={expertDetails?.company_name || filledData.company_name}
                name="company_name"
                borderRadius="10px"
                borderWidth={2}
                borderColor="brand.primary"
                h="50px"
                onChange={(e) => handleChange(e)}
              />
            </Flex>
          <Flex className="flex-col w-full gap-2">
              <Text fontWeight={700} fontSize="16px" color="brand.primary">
                Phone number
              </Text>
              <Input
                key="phone_number"
                name="phone_number"
                placeholder="Phone number"
                type="number"
                defaultValue={userInfo?.phone_number || filledData?.phone_number}
                borderRadius="10px"
                borderWidth={2}
                borderColor="brand.primary"
                h="50px"
                onChange={(e) => handleChange(e)}
              />
            </Flex>
          <Flex className="flex-wrap gap-4 items-start justify-between w-full ">
            <Flex className="flex-col w-full md:w-[35%] gap-2">
              <Text fontWeight={700} fontSize="16px" color="brand.primary">
                Position
              </Text>
              <Input
                key="headline"
                type="text"
                placeholder="Position"
                defaultValue={userInfo?.headline !== "Hub8020 User" ? userInfo?.headline : filledData?.headline}
                name="headline"
                borderRadius="10px"
                borderWidth={2}
                borderColor="brand.primary"
                h="50px"
                onChange={(e) => handleChange(e)}
              />
            </Flex>
            <div className={`relative w-full flex flex-col gap-2 md:max-w-[300px]`} ref={ref}>
                  <Text fontWeight={700} fontSize="16px" color="brand.primary" >
                    Categories
                  </Text>
                  <div ref={ref} className={`bg-white cursor-pointer flex justify-between items-center mb-2 max-w-2xl `}>
                    <input 
                        id="search"
                        value={searchValue}
                        onChange={onChange}
                        placeholder={"Category"}
                        className="border-2 border-[#134A70] w-full py-2.5 pr-2 pl-4 rounded-lg outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none"
                    />
                  </div>
                  {
                      isOpen && list.length ? (
                          <div className="absolute top-[80px] bg-white w-full max-w-2xl rounded-2xl py-2 flex flex-col gap-4 left-0 ad-card-shadow z-10">
                              <div className={`flex flex-col gap-4 max-h-[30vh] overflow-y-auto pb-5`}>
                              {
                                  list.filter(searchCategoriesFilter).map((item, index) => {
                                  return (
                                      <div className="cursor-pointer px-5 py-2 hover:bg-gray-100" onClick={() => {selectItem(item)}} key={item?.id}>{item?.title}</div>
                                  )
                              })}
                              </div>
                          </div>
                      ) : null
                  }
                  
              </div>
          </Flex>
          <Flex className="flex-wrap gap-4 items-start justify-between w-full ">
            <div className={`relative w-full flex flex-col gap-2 md:max-w-[300px]`} ref={ref}>
                  <Text fontWeight={700} fontSize="16px" color="brand.primary">
                    Location
                  </Text>
                  <div ref={locationRef} className={`bg-white cursor-pointer flex justify-between items-center mb-2 max-w-2xl `}>
                    <Flex className="flex-col gap-1 w-full">
                      <input 
                          id="search-location"
                          value={searchLocationValue}
                          onChange={onChangeLocation}
                          placeholder={"Location"}
                          className="border-2 border-[#134A70] w-full py-2.5 pr-2 pl-4 rounded-lg outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none"
                      />
                      <ValidationError message={countrySelectionError ? 'Please select a country' : ''} />
                    </Flex>

                  </div>
                  {
                      isLocationOpen && countriesList.length ? (
                          <div className="absolute top-[80px] bg-white w-full max-w-2xl rounded-2xl py-2 flex flex-col gap-4 left-0 ad-card-shadow z-10">
                              <div className={`flex flex-col gap-4 max-h-[30vh] overflow-y-auto pb-5`}>
                              {
                                  countriesList.filter(searchLocationFilter).map((item, index) => {
                                  return (
                                      <div className="cursor-pointer px-5 py-2 hover:bg-gray-100" onClick={() => {selectLocation(item)}} key={item?.id}>{item?.title}</div>
                                  )
                              })}
                              </div>
                          </div>
                      ) : null
                  }
                  
              </div>
          </Flex>
          <Flex className="flex-col w-full gap-2">
              <Text
                key="bio"
                fontWeight={700}
                fontSize="16px"
                color="brand.primary"
              >
                Describe yourself in a few words
              </Text>
              <Textarea
                placeholder="Type here...."
                defaultValue={userInfo?.bio || filledData?.bio}
                type="text"
                name="bio"
                borderRadius="10px"
                borderWidth={2}
                borderColor="brand.primary"
                minH="150px"
                onChange={(e) => handleChange(e)}
              />
            </Flex>
          <div className="flex space-x-4">
            <Box
              as="button"
              w="100px"
              h="50px"
              mb={7}
              bg="brand.primary"
              borderRadius="5px"
              onClick={() => prevPage()}
            >
              <Text fontWeight={700} fontSize="16px" color="white">
                Previous
              </Text>
            </Box>
            <Box
              as="button"
              w="100px"
              h="50px"
              mb={7}
              bg="brand.primary"
              borderRadius="5px"
              onClick={() => {
                nextPage()
              }}
            >
              {isLoading ? <Spinner color="#fff" /> : (
                <Text fontWeight={700} fontSize="16px" color="white">
                  Next
                </Text>
              )}
              
            </Box>
          </div>
        </VStack>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDir="column" alignItems="center">
      <Flex
        mt={10}
        flexDir="column"
        justifyContent="center"
        w={IsDesktop() ? "600px" : "400px"}
        alignItems={isDesktop ? "flex-start" : "center"}
      >
        {pageNumber === 1 && Page1()}
      </Flex>
    </Box>
  );
};

export default ExpertSurveyForm;
