import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import IsDesktop from "../../../utils/IsDesktop.js";
import { FiCheck } from "react-icons/fi"

const ProgressSlider = ({pageNumber, noOfPages=6}) => {
    const isDesktop = IsDesktop();
    const responsiveSliderBarLength = isDesktop ? "120px" : "80px";
    const responsiveFontsize = isDesktop ? "14px" : "10px";

    return (
        <Box display="flex" justifyContent="center">
            <Flex justifyContent="center" alignItems="center" position="relative" w={isDesktop ? "600px" : "400px"}>
                <Box h="4px" w={responsiveSliderBarLength} bg={pageNumber > 0 ? "brand.primary" : "lightgray"} borderRadius="10px" position="relative" left={2}/>    
                <Flex mt={5} flexDir="column" justifyContent="center">
                    <Box h="20px" w="20px" bg={pageNumber > 1 ? "brand.primary" : "white"} borderRadius="50%" mr={-3} boxShadow="1px 2px 5px grey" borderWidth="1px" borderColor={pageNumber > 0 ? "brand.primary" : "lightgray"} zIndex="overlay" display="flex" justifyContent="center" alignItems="center">{pageNumber > 0 &&<FiCheck fontSize="15px" color="white"/>}
                    </Box>
                    <Box maxW="10px"><Text fontSize={responsiveFontsize} textOverflow="hidden" whiteSpace="nowrap" fontWeight={600} color={pageNumber > 0 ? "brand.primary" : "lightgray"}>One</Text></Box>
                </Flex>
                <Box h="4px" w={responsiveSliderBarLength} bg={pageNumber > 1 ? "brand.primary" : "lightgray"} borderRadius="10px" position="relative" left={2}/>    
                <Flex mt={5} flexDir="column">
                    <Box h="20px" w="20px" bg={pageNumber > 2 ? "brand.primary" : "white"} borderRadius="50%" mr={-3} boxShadow="1px 2px 5px grey" borderWidth="1px" borderColor={pageNumber > 1 ? "brand.primary" : "lightgray"} zIndex="overlay" display="flex" justifyContent="center" alignItems="center">{pageNumber > 1 &&<FiCheck fontSize="15px" color="white"/>}
                    </Box>
                    <Box maxW="10px"><Text fontSize={responsiveFontsize} textOverflow="hidden" whiteSpace="nowrap" mr={-3} fontWeight={600} color={pageNumber > 1 ? "brand.primary" : "lightgray"}>Two</Text></Box>
                </Flex>
                <Box h="4px" w={responsiveSliderBarLength} bg={pageNumber > 2 ? "brand.primary" : "lightgray"} borderRadius="10px" position="relative" left={2}/>    
                <Flex mt={5} flexDir="column">
                    <Box h="20px" w="20px" bg={pageNumber > 3 ? "brand.primary" : "white"} borderRadius="50%" mr={-3} boxShadow="1px 2px 5px grey" borderWidth="1px" borderColor={pageNumber > 2 ? "brand.primary" : "lightgray"} zIndex="overlay" display="flex" justifyContent="center" alignItems="center">{pageNumber > 2 &&<FiCheck fontSize="15px" color="white"/>}
                    </Box>
                    <Box maxW="10px"><Text fontSize={responsiveFontsize} ml={-1} textOverflow="hidden" whiteSpace="nowrap" mr={-3} fontWeight={600} color={pageNumber > 2 ? "brand.primary" : "lightgray"}>Three</Text></Box>
                </Flex>
                <Box h="4px" w={responsiveSliderBarLength} bg={pageNumber > 3 ? "brand.primary" : "lightgray"} borderRadius="10px" position="relative" left={2}/>    
                <Flex mt={5} flexDir="column">
                    <Box h="20px" w="20px" bg={pageNumber > 4 ? "brand.primary" : "white"} borderRadius="50%" mr={-3} boxShadow="1px 2px 5px grey" borderWidth="1px" borderColor={pageNumber > 3 ? "brand.primary" : "lightgray"} zIndex="overlay" display="flex" justifyContent="center" alignItems="center">{pageNumber > 3 &&<FiCheck fontSize="15px" color="white"/>}
                    </Box>
                    <Box maxW="10px"><Text fontSize={responsiveFontsize} ml={-1} textOverflow="hidden" whiteSpace="nowrap" mr={-3} fontWeight={600} color={pageNumber > 3 ? "brand.primary" : "lightgray"}>Four</Text></Box>
                </Flex>
                <Box h="4px" w={responsiveSliderBarLength} bg={pageNumber > 4 ? "brand.primary" : "lightgray"} borderRadius="10px" position="relative" left={2}/>    
                <Flex mt={5} flexDir="column">
                    <Box h="20px" w="20px" bg={pageNumber > 5 ? "brand.primary" : "white"} borderRadius="50%" mr={-3} boxShadow="1px 2px 5px grey" borderWidth="1px" borderColor={pageNumber > 4 ? "brand.primary" : "lightgray"} zIndex="overlay" display="flex" justifyContent="center" alignItems="center">{pageNumber > 4 &&<FiCheck fontSize="15px" color="white"/>}
                    </Box>
                    <Box maxW="20px" display="flex" justifyContent="center"><Text fontSize={responsiveFontsize} textOverflow="hidden" whiteSpace="nowrap" ml={0} fontWeight={600} color={pageNumber > 4 ? "brand.primary" : "lightgray"}>{pageNumber > 5 ? <span>Five</span> : <span>Five</span> }</Text></Box>
                </Flex>
                <Box h="4px" w={responsiveSliderBarLength} bg={pageNumber > 5 ? "brand.primary" : "lightgray"} borderRadius="10px" position="relative" right={1}/>  
                <Flex mt={5} flexDir="column">
                    <Box h="20px" w="20px" bg={pageNumber > 6 ? "brand.primary" : "white"} borderRadius="50%" mr={-3} boxShadow="1px 2px 5px grey" borderWidth="1px" borderColor={pageNumber > 5 ? "brand.primary" : "lightgray"} zIndex="overlay" display="flex" justifyContent="center" alignItems="center">{pageNumber > 5 &&<FiCheck fontSize="15px" color="white"/>}
                    </Box>
                    <Box maxW="20px" display="flex" justifyContent="center"><Text fontSize={responsiveFontsize} textOverflow="hidden" whiteSpace="nowrap" ml={0} fontWeight={600} color={pageNumber > 5 ? "brand.primary" : "lightgray"}>{noOfPages <= 6  && pageNumber > 6 ? <span>Completed</span> : <span>Six</span> }</Text></Box>
                </Flex>  
                <Box h="4px" w={responsiveSliderBarLength} bg={pageNumber > 6 ? "brand.primary" : "lightgray"} borderRadius="10px" position="relative" right={1}/> 
                {
                    noOfPages > 6 ? (
                        <>
                        <Flex mt={5} flexDir="column">
                            <Box h="20px" w="20px" bg={pageNumber > 7 ? "brand.primary" : "white"} borderRadius="50%" mr={-3} boxShadow="1px 2px 5px grey" borderWidth="1px" borderColor={pageNumber > 6 ? "brand.primary" : "lightgray"} zIndex="overlay" display="flex" justifyContent="center" alignItems="center">{pageNumber > 6 &&<FiCheck fontSize="15px" color="white"/>}
                            </Box>
                            <Box maxW="20px" display="flex" justifyContent="center"><Text fontSize={responsiveFontsize} textOverflow="hidden" whiteSpace="nowrap" ml={0} fontWeight={600} color={pageNumber > 6 ? "brand.primary" : "lightgray"}>{pageNumber > 7 ? <span>Completed</span> : <span>Seven</span> }</Text></Box>
                        </Flex>  
                        <Box h="4px" w={responsiveSliderBarLength} bg={pageNumber > 7 ? "brand.primary" : "lightgray"} borderRadius="10px" position="relative" right={1}/>
                        </> 
                    ) : null
                } 
                
            </Flex>
        </Box>
    )
  


}
export default ProgressSlider;