import { HStack } from "@chakra-ui/react"
import CountCard from "../../../components/countCard/CountCard";


const AdvertsCountSection = ({totalAdverts, totalImpressions, totalViews, totalClicks}) => {
    return (
        <HStack justifyContent="flex-start" alignItems="center" mb={4} spacing={4}>
            <CountCard text="Total Adverts" count={totalAdverts} />
            <CountCard text="Total Impressions" count={totalImpressions} />
            <CountCard text="Total Views" count={totalViews} />
            <CountCard text="Total Clicks" count={totalClicks} />
        </HStack>
    )
}
export default AdvertsCountSection;