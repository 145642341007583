import { Avatar, Box, Flex, Image, Text, VStack } from "@chakra-ui/react"

const AdvertCard = ({ advert, viewAdvert }) => {
  return (
    <Box w="350px">
        <VStack cursor="pointer" h="250px" mb={1} onClick={() => viewAdvert(advert)}>
        <Box w="100%" h="200px" maxH="200px">
            <Image src={advert?.media} alt={advert?.title} rounded="lg" objectFit="cover" boxSize="100%" />
        </Box>
        <Box display="flex" alignItems="flex-start" w="100%" h="50px" overflow="hidden">
            <Text textOverflow="ellipsis">{advert?.title}</Text>
        </Box>
        </VStack>
        <Flex alignItems="center">
           <Avatar name={`${advert?.brand?.name || 'Seed A Founder'}`} src={advert?.brand?.logo} alt={advert?.title} size="sm" mr={2} />
            <Text>{`${advert?.brand?.name || 'Seed A Founder'}`}</Text>
        </Flex>
    </Box>
  )
} 
export default AdvertCard;

