import React from 'react'
import { Box, Image, Text, Button, Flex} from "@chakra-ui/react"


export default function Portfolio({image, title , heading, description, price, target, rate, equity, button}) {
  return (
    <div>
      <Box width="371px" height="550px" background="#FFFFFF" borderRadius="10px" border='1px' borderColor='#ededed' mr={2} >
        <Image src={image} color="#134A70" width="410.26px" height="258px" borderTopRadius="10px"/>
        <Box p="24px">
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="700px" fontSize="12px" lineHeight="150%"  mt="5px" width="83px" height="18px" color="#134A70">{title}</Text>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="700px" fontSize="20px" lineHeight="150%" width="323px" height="30px" color="#000000" mt="5px">{heading}</Text>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="400px" fontSize="14px" lineHeight="150%" width="323px" height="63px" mt="5px"color="#9FA7AD">{description}</Text>
        <Flex justify="space-between">
        <Box>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="700px" fontSize="22px" lineHeight="150%"  width="89px" height="33px" color="#134A70" mt="5px">{price}</Text>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="400px" fontSize="11px" lineHeight="120%"  width="36px" height="13px" color="#134A70">{target}</Text>
        </Box>
        <Box>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="700px" fontSize="14px" lineHeight="150%" width="44px" height="21px" color="#134A70" mt="10px">{rate}</Text>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="400px" fontSize="11px" lineHeight="120%" width="40px" height="13px" color="#134A70" >{equity}</Text>
        </Box>
        </Flex>
        <Button fontFamily='Poppins' fontStyle="normal" fontWeight="600px"  width="150px" height="50px" border="1px solid #134A70" fontSize="16px" lineHeight="24px"  color="#134A70" mt="20px">{button}</Button>
        </Box>
      </Box>
    </div>
  )
}