import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ExpertBookingChart = ({
  labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  bookingValues = [0, 0, 0, 0, 0, 0],
  max = 100,
}) => {

  const [data, setData] = useState({
    labels,
    datasets: [
      {
        label: 'Bookings',
        data: bookingValues,
        fill: true,
        backgroundColor: 'rgba(144, 238, 144, 0.2)', // a
        borderColor: 'rgba(144, 238, 144, 1)', // Green line
        tension: 0.4, // Smoother lines
        pointRadius: 0, // Hide points
      },
    ],
  })
  const options = {
    plugins: {
      legend: {
        display: false, // Hides the legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid
        },
        ticks: {
          display: false, // Hide x-axis labels
        },
      },
      y: {
        min: 0,
        max,
        ticks: {
          stepSize: 5,
        },
        grid: {
          color: '#e0e0e0', // Light grid color
          borderDash: [5, 5], // Dotted line for the Y-axis
        },
        border: {
          display: false, // Hide Y-axis border line
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default ExpertBookingChart;
