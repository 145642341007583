import { createSlice } from "@reduxjs/toolkit";
import UserService from "../../utils/UserService";

const initialState = {
    expertInfo: UserService.getExpertInfo()
};

const expertSlice = createSlice({
  name: "expert",
  initialState,
  reducers: {
    setExpertInfo(state, action) {
      state.expertInfo = action.payload;
    }
  }
});
export const { setExpertInfo } = expertSlice.actions;
export default expertSlice.reducer;
