import { useToast } from "@chakra-ui/react";

export const CustomToast = () => {
    const toast = useToast();
    // types are: "success", "info", "warning", "error"

    const addToast = (newRes, type) => {
        toast({
            description: newRes.message ?? newRes, 
            status: newRes.type || type || "success", 
            position:"top-right", 
            isClosable: true, 
            duration: newRes.duration || 5000,
            variant: 'left-accent' 
        })
    }
    
    return { addToast };
}