import { createSlice } from "@reduxjs/toolkit";
import UserService from "../../utils/UserService";

const initialState = {
    allCompaniesInfo: UserService.getAllCompaniesInfo()
};

const allCompaniesSlice = createSlice({
  name: "allcompanies",
  initialState,
  reducers: {
    setAllCompaniesInfo(state, action) {
      state.allCompaniesInfo = action.payload;
    }
  }
});
export const { setAllCompaniesInfo } = allCompaniesSlice.actions;
export default allCompaniesSlice.reducer;
