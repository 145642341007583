import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { WiTime4 } from 'react-icons/wi';
import IsDesktop from "../../../../utils/IsDesktop";

const Slot = ({slot, openSlot}) => {
    const isDesktop = IsDesktop();
  return (
    <>
        <VStack onClick={()=> openSlot(slot)} cursor="pointer" justifyContent="space-between" alignItems="flex-start" w="100%" h="155px" px={2} py={4} borderRadius="10px" boxShadow="0px 1px 8px lightgray" mb={2}>
            <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Text color="brand.primary" fontSize={isDesktop ? "18px": "14px"} fontWeight={600} >{slot?.slot_title}</Text>
                <BsThreeDotsVertical size="25px"/>
            </Flex>
            <Box maxW="95%" maxH="40px" overflow="hidden" mb={2}>
                <Text color="gray" fontSize="12px" textOverflow="ellipsis">
                    {slot?.slot_description}
                </Text>
            </Box>
            <VStack spacing={1} alignItems="flex-start">
                <Flex alignItems="center">
                    <AiOutlineCalendar size="20px" color="gray" />
                    <Text fontSize={12} ml={4} >{moment(slot?.slot_start_date).format("DD/MM/YYYY")}</Text>
                </Flex>
                <Flex alignItems="center">
                    <WiTime4 size="20px" color="gray" />
                    <Text fontSize={12} ml={4} >{`${slot?.slot_start_time} - ${slot?.slot_end_time}`}</Text>
                </Flex>

            </VStack>

        </VStack>
    </>
  )
}
export default Slot;