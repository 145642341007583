import React from "react";
import { Box, Flex, Image, Link } from "@chakra-ui/react";
import logo from "../../public/icons/logo_alt_.svg";

const SideComponent = () => {

    return (
        <Box w="100%" bg="#134A70" h="100vh" display="flex" justifyContent="center" alignItems="center">
            <Flex justifyContent="space-between" alignItems="center">
                <Link href='https://seedafounder.com'><Image src={logo} boxSize='400px' objectFit={"cover"} /></Link>
            </Flex>

        </Box>

    )

}
export default SideComponent;