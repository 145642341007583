import React from "react";
import { Box, Flex, Input, Text, Textarea, VStack } from "@chakra-ui/react";
import ProgressSlider from "./assets/ProgressSlider";
import IsDesktop from "../../utils/IsDesktop.js";
import Page from "./assets/Page";
import { CustomToast } from "../../components/toast/CustomToast";
import { BASE_URL } from "../../helpers/constant";
import client from "../../libs/client";
import UserService from "../../utils/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux-toolkit/reducers/userSlice";
import { setCompanyInfo } from "../../redux-toolkit/reducers/companySlice";
import { mutateFunction, useMutation } from "../../libs/apis/index.js";

const InvestorSurveyForm = ({
  pageNumber,
  setPageNumber,
  setPage,
  surveyData,
  filledData,
  setFilledData,
  userCompany,
  refetch
}) => {

  const userInfo = useSelector((state) => state?.user?.userInfo);
  const dispatch = useDispatch();
  const isDesktop = IsDesktop();
  const { addToast } = CustomToast();

  const { mutate, isLoading } = useMutation(mutateFunction);

  const nextPage = () => {

    let _data = {
      ...filledData
    }

    
    _data.title =  _data?.title || userCompany?.title 
    _data.description =  _data?.description || userCompany?.description
    _data.job_title = _data.job_title || userCompany?.job_title
    _data.companyStageId = _data.companyStageId ||  []
    _data.businessModelId = _data.businessModelId || []
    _data.businessCategoryId = _data.businessCategoryId || []
    _data.incorporationLocationId = _data.incorporationLocationId || []
    _data.investmentlocationId = _data.investmentlocationId || []


    if (pageNumber > 6) {
      setPageNumber(7);
      mutate(
        {
          key: `companies/${pageNumber}`,
          method: "post",
          data: _data,
        },
        {
          async onSuccess (res) {
            await refetch()
            if (res.data) {
              addToast({
                message: "Saved Company data Successfully",
                type: "success",
              });
              const company = res?.data?.company;

              UserService.setCompanyInfo(company);
              dispatch(setCompanyInfo(company));
              const newUserData = {
                ...userInfo,
                userTypeTitle: "investor",
                userTypeId: 2,
              };
              dispatch(setUserInfo(newUserData));
              UserService.setUserInfo(newUserData);
              setPage("thank-you");
              // setButtonLoading(false);
              //   setPageNumber(pageNumber + 1);
              addToast(res);
            }
          },
          onError(e) {
            addToast(e.message);
          },
        }
      );
    } else {
      // setButtonLoading(true);


      mutate(
        {
          key: `companies/${pageNumber >= 5 ? pageNumber : pageNumber + 1}?${pageNumber === 5 ? "location=incorporation" : pageNumber === 4 ? "location=investment" : ""}`,
          method: "post",
          data: _data,
        },
        {
         async onSuccess(res) {
            await refetch()
            if (res.data) {
              addToast({
                message: "Saved Company data Successfully",
                type: "success",
              });
              const company = res?.data?.company;

              UserService.setCompanyInfo(company);
              dispatch(setCompanyInfo(company));
            
              
              const newUserData = {
                ...userInfo,
                userTypeTitle: "investor",
                userTypeId: 2,
              };

              dispatch(setUserInfo(newUserData));
              UserService.setUserInfo(newUserData);
              // setButtonLoading(false);
              //   setPageNumber(pageNumber + 1);
              addToast(res);
            }
          },
          onError(e) {
            addToast(e.message);
          },
        }
      );
      // setButtonLoading(false);
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(0);
      setPage("welcome");
    }
  };

  const Page1 = () => {
    const handleChange = (e) => {
      setFilledData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    };
    return (
      <Box display="flex" justifyContent="center">
        <VStack
          flexDir="column"
          w={isDesktop ? "600px" : "300px"}
          justifyContent="flex-start"
          alignItems={isDesktop ? "flex-start:" : "center"}
          spacing={5}
        >
          <Text fontWeight={700} fontSize="16px" color="brand.primary">
            Enter your name or company's name?
          </Text>
          <Input
            key="title"
            type="text"
            placeholder="Type here...."
            defaultValue={userCompany?.title ?? filledData?.title}
            name="title"
            borderRadius="10px"
            borderWidth={2}
            borderColor="brand.primary"
            h="50px"
            onChange={(e) => handleChange(e)}
          />
          <Text fontWeight={700} fontSize="16px" color="brand.primary">
            Enter your job title
          </Text>
          <Input
            key="title"
            type="text"
            placeholder="Type here...."
            defaultValue={userCompany?.job_title ?? filledData?.job_title}
            name="job_title"
            borderRadius="10px"
            borderWidth={2}
            borderColor="brand.primary"
            h="50px"
            onChange={(e) => handleChange(e)}
          />
          <Text
            key="description"
            fontWeight={700}
            fontSize="16px"
            color="brand.primary"
          >
            Describe your company or service in a few words
          </Text>
          <Textarea
            placeholder="Type here...."
            defaultValue={userCompany?.description ?? filledData.description}
            type="text"
            name="description"
            borderRadius="10px"
            borderWidth={2}
            borderColor="brand.primary"
            minH="150px"
            onChange={(e) => handleChange(e)}
          />
          <div className="flex space-x-4">
          <Box
              as="button"
              w="100px"
              h="50px"
              mb={7}
              bg="brand.primary"
              borderRadius="5px"
              onClick={() => prevPage()}
            >
              <Text fontWeight={700} fontSize="16px" color="white">
                Previous
              </Text>
            </Box>
            <Box
              as="button"
              w="100px"
              h="50px"
              mb={7}
              bg="brand.primary"
              borderRadius="5px"
              onClick={() => nextPage()}
            >
              <Text fontWeight={700} fontSize="16px" color="white">
                Next
              </Text>
            </Box>
            </div>
          {/* <Box cursor="pointer" onClick={() => prevPage()}>
            <Text textDecoration="underline">Back to previous page</Text>
          </Box> */}
        </VStack>
      </Box>
    );
  };
  const Page2 = () => {
    return (
      <Page
        question="What company stage are you interested in?"
        options={surveyData?.companyStages}
        data={{ companyStageId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        isCheckBox={true}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.company_stage_ids?.map((item) => `${item}`)}
      />
    );
  };
  const Page3 = () => {
    return (
      <Page
        question="How much money are you interested in investing?"
        options={surveyData.fundCategories?.sort(
          (a, b) => a.min_amount - b.min_amount
        )}
        data={{ fundCategoryId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.fundCategoryId}
      />
    );
  };
  const Page4 = () => {
    return (
      <Page
        question="Where would you like to invest in"
        options={surveyData.locations}
        isCheckBox={true}
        data={{ investmentlocationId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.investment_location_ids?.map((item) => `${item}`)}
      />
    );
  };

  const Page5 = () => {
    return (
      <Page
        question="Where is your company incorporated?"
        options={surveyData.locations}
        isCheckBox={true}
        data={{ incorporationLocationId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.incorporation_location_ids?.map((item) => `${item}`)}

      />
    );
  };
  const Page6 = () => {
    return (
      <Page
        question="Which business models match your investment interest?"
        isCheckBox={true}
        options={surveyData.businessModels}
        data={{ businessModelId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.business_model_ids?.map((item) => `${item}`)}
      />
    );
  };
  const Page7 = () => {
    return (
      <Page
        question="Which business category would you like to invest in?"
        options={surveyData.categories}
        isCheckBox={true}
        data={{ businessCategoryId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.business_category_ids?.map((item) => `${item}`)}

      />
    );
  };

  return (
    <Box display="flex" flexDir="column" alignItems="center">
      <ProgressSlider noOfPages={7}  pageNumber={pageNumber} />
      <Flex
        mt={10}
        flexDir="column"
        justifyContent="center"
        w={IsDesktop() ? "600px" : "400px"}
        alignItems={isDesktop ? "flex-start" : "center"}
      >
        {pageNumber === 1 && Page1()}
        {pageNumber === 2 && Page2()}
        {pageNumber === 3 && Page3()}
        {pageNumber === 4 && Page4()}
        {pageNumber === 5 && Page5()}
        {pageNumber === 6 && Page6()}
        {pageNumber === 7 && Page7()}
      </Flex>
    </Box>
  );
};
export default InvestorSurveyForm;
