import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// Load pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.min.js';


const PdfViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setErrorMessage(''); // Clear any existing error messages
  }

  function onDocumentLoadError(error) {
    console.error('Error loading document:', error.message);
    setErrorMessage(`Error loading document: ${error.message}`);
  }

  return (
    <div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          )
        )}
      </Document>
    </div>
  );
};

export default PdfViewer;
