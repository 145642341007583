import React, { useEffect, useState } from "react";
import { Table, Avatar, Box, Flex, Tbody, Td, Text, Th, Thead, Tr, Icon, Spinner, Button, HStack, Heading } from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import IsDesktop from "../../../utils/IsDesktop";
import moment from "moment";

const SubscriptionsTable = ({ header=[], data=[], isLoading = false }) => {
    const [presentedData, setPresentedData] = useState(data);
    const [currentSelection, setCurrentSelection] = useState("all")
    const isDesktop = IsDesktop();

    const types = data.map((each)=> each.subscription_plan)

    useEffect(() => {
        if (currentSelection === "all"){
            setPresentedData(data);
        }else{
            const dataToShow = data.filter((eachSub) => eachSub.subscription_plan === currentSelection);
            setPresentedData(dataToShow);
        }
    }, [currentSelection, data])

    return (
        <Box mt={2} overflowY='auto' borderRadius='10px 10px 10px 10px' h="100%" p={2}>
            <HStack justifyContent="space-between" alignItems="center" mb={4}>
                <Heading fontSize="18px" color="brand.primary">Subscription</Heading>
            <Box borderRadius="5px 5px 5px 5px" bg="blue">
                <Flex alignItems="flex-start">
                    <Button color={currentSelection === "all" ? "white" : "brand.primary"} bg={currentSelection === "all" ? "brand.primary" : "white"} _hover={{bg:"#134A70", color:"#FFFFFF"}} borderRadius="0px" boxShadow='base' onClick={() => setCurrentSelection("all")} fontSize={isDesktop? "14px": "12px"}>All</Button>
                    {types.length > 0 ? types.map((eachType) => (<Button color={currentSelection === eachType ? "white" : "brand.primary"} bg={currentSelection === eachType ? "brand.primary" : "white"} _hover={{bg:"#134A70", color:"#FFFFFF"}} boxShadow='base' borderRadius="0px" onClick={() => setCurrentSelection(eachType)} fontSize={isDesktop? "14px": "12px"}><Text textTransform='capitalize'>{eachType}</Text></Button>)):(<></>)}
                </Flex>
            </Box>
            </HStack>
            <Table whiteSpace='nowrap'>
                {isDesktop && (<Thead>
                    <Tr bg='rgba(19, 74, 112, 0.05)' borderRadius='10px 10px 0px 0px' boxShadow='0px 0px 8px 4px rgba(19, 74, 112, 0.1)' borderBottom='1px solid #9FA7AD'>
                        {header?.map(header => (
                            <Th key={header} color='brand.primary' fontSize={isDesktop ? "14px":"12px"}>{header}</Th>
                        ))}
                        <Th>{" "}</Th>
                    </Tr>
                </Thead>)}

                <Tbody borderRadius='10px'  overflow={isDesktop ? 'auto' : 'hidden'} p={2} fontSize={isDesktop ? "14px": "12px"}>
                        {(!presentedData || presentedData.length === 0) && isLoading && <Spinner mt={2}/>}

                        {presentedData?.map((subscription) => (
                            <Tr key={subscription?.subscription_id} boxShadow='10px 10px 20px rgba(54, 171, 190, 0.05)' borderRadius='10px' bg='white.300' m={2}>
                                <Td>
                                    <Flex alignItems='center'>
                                        <Avatar size='xs' src={subscription?.profile_image} name={subscription?.user?.first_name} mr={2} />
                                        <Text textTransform='capitalize'>{`${subscription?.first_name} ${subscription?.last_name}`}</Text>
                                    </Flex>
                                </Td>
                                {isDesktop && (<Td>
                                    <Text textTransform='capitalize'>{subscription?.subscription_plan}</Text>
                                </Td>)}
                                {isDesktop && (<Td>
                                    <Text textTransform='capitalize'>{subscription?.amount || "N/A"}</Text>
                                </Td>)}
                                {isDesktop && (<Td>
                                    <Text textTransform='capitalize'>{moment(new Date(subscription?.end_date)).format("DD-mm-yyyy")}</Text>
                                </Td>)}
                                <Td>{subscription.plan === "premium" ? (
                                    (Date.parse(subscription?.end_date) > new Date()) ? (
                                        <Text cursor="pointer" textDecoration="underline" color="brand.primary" textTransform='capitalize'>renew</Text>
                                        ):(
                                            <Text cursor="pointer" textDecoration="underline" color="red" textTransform='capitalize'>cancel</Text>
                                        )
                                    ):(
                                        <Text cursor="pointer" textDecoration="underline" color="brand.primary" textTransform='capitalize'>upgrade</Text>
                                    )}
                                </Td>
                                <Td cursor='pointer'>
                                    <Icon as={BsThreeDotsVertical} />
                                </Td>
                            </Tr>
                        ))}
                </Tbody>
            </Table>
        </Box>
    );
}
 
export default SubscriptionsTable;