import { Box, Flex, Image, Spinner, Text, useDisclosure, VStack, IconButton, Input, Tooltip } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Calendar, Button, Spin } from "antd";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useData } from "../../data";
import BrandButton from "../brandButton/BrandButton";
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import { CustomToast } from "../toast/CustomToast";
import { useQueryClient } from "react-query";


const SmallCalendar = ({
  bookingDates = [],
  handleSelect = () => {},
  selectedDate = moment()
}) => {
  const DAYS_OF_WEEK = ["S", "M", "T", "W", "T", "F", "S"];


  const getBackgoundColor = (date) => {
    if(moment(selectedDate).isSame(date, "day")) {
      return "brand.primary";
    } else if (bookingDates.includes(date.format("YYYY-MM-DD"))) {
      return "lightblue"
    } else {
      return "white"
    }
    // day.format("MM-DD-YYYY")
  }


  const handlePrevWeekClick = () => {
    handleSelect(moment(selectedDate)?.subtract(1, "week"))
  };
  const handleNextWeekClick = () => {
    handleSelect(moment(selectedDate)?.add(1, "week"))
  };

  const renderDaysOfWeek = () => {
    return DAYS_OF_WEEK.map((day, index) => (
      <Box key={`${index}`} flex={1} textAlign="center">
        <Text fontSize="md" fontWeight="bold">
          {day}
        </Text>
      </Box>
    ));
  };

  const renderCalendarDays = () => {
    const startOfWeek = moment(selectedDate).clone().startOf("week");
    const endOfWeek = moment(selectedDate).clone().endOf("week");
    const calendarDays = [];
    let day = startOfWeek;
    while (day <= endOfWeek) {
      calendarDays.push(day);
      day = day.clone().add(1, "day");
    }
    return calendarDays.map((day) => (
      <Tooltip label={bookingDates.includes(day.format("YYYY-MM-DD")) ? "Available slot" : ""}>
      <Box display="flex" justifyContent="center" alignItems="center" key={day.format("MM-DD-YYYY")} flex={1} textAlign="center" py={2}  >
        <Box display="flex" justifyContent="center" alignItems="center" border={moment(selectedDate).isSame(day, "day") ? "2px solid" : "none"} borderRadius="full" borderColor="brand.primary" w="35px" h="35px" cursor="pointer" onClick={() => {
          handleSelect(day)
        }} bg={getBackgoundColor(day)} color={moment(selectedDate).isSame(day, "day") ? "white" : "brand.primary"}>
          <Text>
            {day.format("D")}
          </Text>
        </Box>
      </Box>
      </Tooltip>
      ));
  };

  return (
    <Box display="flex" flexDir="column" h="100%" justifyContent="flex-start">
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text fontSize="xl" className="mb-1" fontWeight="bold">
            {moment(selectedDate)?.format("MMMM, YYYY")}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <IconButton aria-label="Previous Week" icon={<ChevronLeftIcon />} onClick={handlePrevWeekClick} size="sm" />
        <IconButton aria-label="Next Week" icon={<ChevronRightIcon />} onClick={handleNextWeekClick} size="sm" />
      </Flex>
      <Flex mt={2}>
        <style>{` ::-webkit-scrollbar {display: none; }`}</style>
        {renderDaysOfWeek()}
      </Flex>
      <Flex mt={2}>
        <style>{` ::-webkit-scrollbar {display: none; }`}</style>
        {renderCalendarDays()}
      </Flex>
    </Box>
  );
}

const FoundersBookingCalendar = ({ founder, refetch = () => {} }) => {
  const queryClient = useQueryClient();
  const today = moment();
  const nextMonth = moment(today).add(1, "month");
  const validRange = [today, nextMonth];
  const { data, refetch: refetchSlotsData, isLoading: isLoadingSlotsData } = useData(`founders/${founder}/booking-slots?from=${today.format("YYYY-MM-DD")}&to=${nextMonth.format("YYYY-MM-DD")}`);
  const [bookingDates, setBookingDates] = useState([]);
  const { addToast } = CustomToast();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [slots, setSlots] = useState([]);
  const [pickedSlot, setPickedSlot] = useState();
  const { isOpen: isOpenSelectedDate, onToggle: onToggleSelectedDate, onClose: onCloseSelectedDate } = useDisclosure();
  const { isOpen: isFullCalendarOpen, onToggle: onToggleFullCalendar, onClose: onCloseFullCalendar } = useDisclosure();
  const { isOpen: showComfirmIsOpen, onToggle: onToggleShowConfirm, onClose: onCloseShowConfirm } = useDisclosure();
  const { isOpen: successPageIsOpen, onToggle: onToggleShowSuccessPage, onClose: onCloseShowSuccessPage } = useDisclosure();

  const { mutate, isLoading } = useMutation(mutateFunction);

  const submitBooking = (id, callback = () => {}) => {
    mutate(
      { key: `founders/${founder}/book`, method: "post", data: { slot_id: id } },
      {
        onSuccess() {
          addToast({ message: "Booked", type: "success" });
          callback()
          refetchSlotsData()
          refetch()
        },
        onError(){
          addToast({ message: "Failed to book session", type: "error" })
        }
      }
    );
  };

  const closeBooking = () => {
    onCloseSelectedDate();
    onCloseShowConfirm();
    onCloseShowSuccessPage();
    setSlots([]);
    setPickedSlot();
    queryClient.invalidateQueries(`founders/${founder}/booking-slots?from=${selectedDate}&to=${selectedDate}`);
  };

  const disabledDate = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    return !bookingDates.includes(formattedDate);
  };
  const handleSelect = (date) => {
    setSelectedDate(date.format("YYYY-MM-DD"));
    onToggleSelectedDate();
  };
  useEffect(() => {
    if (data?.data) {
      setBookingDates(data?.data?.slots?.map((slot) => moment(slot.slot_start_date).format("YYYY-MM-DD")));
    }
  }, [data, isLoading]);

  useEffect(() => {
    handleSelect(moment())
  }, [])

  const SlotsPerDay = () => {
    const { data: slotsData, isLoading: slotsLoading } = useData(`founders/${founder}/booking-slots?from=${selectedDate}&to=${selectedDate}`);
    useEffect(() => {
      if (slotsData?.data) {
        setSlots(slotsData?.data?.slots);
      }
    }, [slotsData]);

    const showConfirm = () => {
      if (pickedSlot) {
        onToggleShowConfirm();
      } else {
        addToast({ message: "Please select a time slot", type: "warning", duration: 3000 });
      }
    };

    const pickSlot = (slotId) => {
      setPickedSlot(slotId);
    };

    return (
      <>
        <Box w="100%" h="100%" display="flex" justifyContent="space-between" flexDir="column" overflow="hidden">
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <Text color="gray">{moment(selectedDate).format("dddd, D MMMM, YYYY")}</Text>
            <Text color="brand.primary" fontWeight={500}>
              Duration: 30mins
            </Text>
          </Flex>
          <Box flexDir="column" justifyContent="space-between" h="70%" alignItems="center" w="100%" overflowY="auto">
            {!slotsLoading ? (
              <Box w="100%" display="flex" alignItems="center" justifyContent="center" flexDir="column">
                {slots?.length > 0 ? (
                  slots.map((slot) => (
                    <Box as="button" display="flex" w="80%" alignItems="center" justifyContent="center" h="35px" border="1px" borderRadius="5px" m={2} bg={pickedSlot === slot.id ? "brand.primary" : "white"} key={slot.id} onClick={() => pickSlot(slot.id)} color={pickedSlot === slot.id ? "white" : "brand.primary"}>
                      {moment(slot?.slot_start_time, "HH:mm:ss").format("HH:mm")}
                    </Box>
                  ))
                ) : (
                  <Box>No slots available</Box>
                )}
              </Box>
            ) : (
              <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
                <Spinner />
              </Flex>
            )}
          </Box>
          <Flex flexDir="row" justifyContent="center" alignItems="center">
            <Box as="button" mr={2} display="flex" flexDir="row" alignItems="center" onClick={() => onToggleSelectedDate()}>
              <HiOutlineArrowNarrowLeft />
              <Text ml={2}>Previous</Text>
            </Box>
            <Box as="button" ml={2} display="flex" flexDir="row" alignItems="center" onClick={() => showConfirm()}>
              <Text mr={2}>Next</Text>
              <HiOutlineArrowNarrowRight />
            </Box>
          </Flex>
        </Box>
      </>
    );
  };

  const NewSlotsPerDay = () => {
    const { data: slotsData, isLoading: slotsLoading, refetch: refetchSessions } = useData(`founders/${founder}/booking-slots?from=${selectedDate || moment().add(1, 'day').format('YYYY-MM-DD')}&to=${selectedDate || moment().add(1, 'day').format('YYYY-MM-DD')}`);
    useEffect(() => {
      if (slotsData?.data) {
        setSlots(slotsData?.data?.slots);
      }
    }, [slotsData]);

    const showConfirm = () => {
      if (pickedSlot) {
        onToggleShowConfirm();
      } else {
        addToast({ message: "Please select a time slot", type: "warning", duration: 3000 });
      }
    };

    const pickSlot = (slotId) => {
      setPickedSlot(slotId);
    };

    return (
      <>
        <Box w="100%" h="100%" display="flex" justifyContent="space-between" flexDir="column" overflow="hidden">
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <Text mt={6} color="gray">{moment(selectedDate).format("dddd, D MMMM, YYYY")}</Text>
            <Text color="brand.primary" fontWeight={500}>
              Available sessions
            </Text>
          </Flex>
          <Box flexDir="column" justifyContent="space-between" h="70%" alignItems="center" w="100%" overflowY="auto">
            {!slotsLoading ? (
              <Box w="100%" display="flex" alignItems="center" justifyContent="center" flexDir="column">
                
                {

                  isLoading ? (
                    <Box mt={10}>
                      <Spinner size={"lg"}  />
                    </Box>
                  ) :
                   slots?.length > 0 ? (
                    slots.map((slot) => (
                        <Box  w="80%" alignItems="center" justifyContent="center" className="my-3 flex flex-col gap-1.5">
                          <Box border="1px" borderRadius="5px" className="flex w-full items-center justify-center gap-1">
                            <Text>
                              {moment(slot?.slot_start_time, "HH:mm:ss").format("HH:mm")}
                            </Text>
                            <Text>
                                -
                            </Text>
                            <Text>
                              {moment(slot?.slot_end_time, "HH:mm:ss").format("HH:mm")}
                            </Text>
                          </Box>
                          <BrandButton text="Book session" isLoading={isLoading} onClick={() => {submitBooking(slot.id, refetchSessions)}}  padding={0} h={"28px"} colorScheme="blue" w={"100%"} className="w-full" />
                        </Box>
                      ))
                    ) : (
                  <Box className="w-full">
                    <Text className="text-gray-700 p-3 font-medium text-center">
                      No slots available today. Please select a different date on the calendar to find an available slot
                    </Text>
                  </Box>
                )}
              </Box>
            ) : (
              <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
                <Spinner />
              </Flex>
            )}
          </Box>
        </Box>
      </>
    );
  };

  const ConfirmBooking = () => {
    const findSlot = slots.find((slot) => slot.id === pickedSlot);
    return (
      <>
        {!successPageIsOpen ? (
          <VStack h="100%" pt={5} justifyContent="space-between" alignItems="center">
            <Box  w="320px" px="20px" h="150px" display="flex" flexDir="column" justifyContent="center">
              <Flex>
                <Text mr={4} color="brand.primary">
                  Duration:
                </Text>
                <Text color="brand.primary" fontWeight={600}>
                  30mins
                </Text>
              </Flex>
              <Flex>
                <Text mr={4} color="brand.primary">
                  Time:
                </Text>
                <Text color="brand.primary" fontWeight={600}>
                  {moment(findSlot?.slot_start_time, "HH:mm:ss").format("HH:mm")} - {moment(findSlot?.slot_end_time, "HH:mm:ss").format("HH:mm")}
                </Text>
              </Flex>
              <Flex>
                <Text mr={4} color="brand.primary">
                  Day{" "}
                </Text>
                <Text color="brand.primary" fontWeight={600}>
                  {moment(selectedDate).format("dddd, D MMMM, YYYY")}
                </Text>
              </Flex>
            </Box>
            <Flex flexDir="column" justifyContent="center" alignItems="center">
              <BrandButton mb={2} isLoading={isLoading} text="Confirm Booking" w="170px" colorScheme="blue" onClick={submitBooking} />
              <Box as="button" mr={2} display="flex" flexDir="row" alignItems="center" onClick={() => onToggleShowConfirm()}>
                <HiOutlineArrowNarrowLeft />
                <Text ml={2}>Previous</Text>
              </Box>
            </Flex>
          </VStack>
        ) : (
          <SuccessPage />
        )}
      </>
    );
  };

  const onPanelChange = (value, mode) => {};

  const SuccessPage = () => {
    return (
      <>
        <VStack spacing={2} w="100%" h="100%" justifyContent="center" alignItems="center">
          <Image src="https://jekawin.s3.amazonaws.com/email+sent.gif" alt="successful" h="150px" w="150px" />
          <Text color="brand.primary" fontWeight={600} fontSize="18px">
            Call booked successfully!
          </Text>
          <Text fontSize="14px"> Your Booking details will now be sent to your email address.</Text>
          <Text color="brand.primary" _hover={{ textDecoration: "underline", cursor: "pointer" }} onClick={closeBooking}>
            Close
          </Text>
        </VStack>
      </>
    );
  };

  return (
    <Box className="flex flex-col">
        <Box display={"none"} p={2} boxShadow="0.5px 2px 10px lightgray" >
        {!isOpenSelectedDate ? (
          <Box w="100%" h="100%">
            
            <Calendar
              // onSelect={handleSelect}
              onChange={() => {}}
              mode="month"
              // validRange={validRange}
              fullscreen={false}
              disabledDate={disabledDate}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                const currentMonth = value.format("MMMM-YY");
                return (
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button onClick={() => {
                      if(value > today)
                      onChange(value.clone().add(-1, "month"))}}>{"<"}</Button>
                    <span>{currentMonth}</span>
                    <Button onClick={() => {
                      if(value < nextMonth)
                      onChange(value.clone().add(1, "month"))}}>{">"}</Button>
                  </div>
                );
              }}
              monthFullCellRender={() => {}}
              dateFullCellRender={(date) => {
                const formattedDate = date.format("YYYY-MM-DD");
                if (bookingDates?.includes(formattedDate)) {
                  return (
                    <Tooltip label="Available slots">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "white",
                        width: "100%",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelect(date)}
                    >
                      <div style={{ backgroundColor: "#134A70", display: "flex", justifyContent: "center", alignItems: "center", color: "white", width: "30px", height: "30px", borderRadius: "50%" }}>
                          {date.format("D")}
                      </div>
                    </div>
                    </Tooltip>
                  );
                } else {
                  return (
                    <div style={{ backgroundColor: "white", height: "100%", width: "100%", borderRadius: "50%", display: "flex", justifyContent: "center" }}>
                      <div style={{ backgroundColor: "white", color: "grey" }}>{date.format("D")}</div>
                    </div>
                  );
                }
                // return <div>{}</div>;
              }}
            />
          </Box>
        ) : (
          <>{!showComfirmIsOpen ? <SlotsPerDay /> : <ConfirmBooking />}</>
        )}
        </Box>
        <Box className="flex flex-col w-full">
          {
            !isFullCalendarOpen ? (
              <Box className="w-[90vw] max-w-[520px]">
                <SmallCalendar selectedDate={selectedDate} handleSelect={handleSelect} bookingDates={bookingDates} />
              </Box>
            ) : (
              <Box p={2} boxShadow="0.5px 2px 10px lightgray" >
              <Calendar
                  onChange={() => {}}
                  mode="month"
                  fullscreen={false}
                  headerRender={({ value, type, onChange, onTypeChange }) => {
                    const currentMonth = value.format("MMMM-YY");
                    return (
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button onClick={() => {
                          if(value > today)
                          onChange(value.clone().add(-1, "month"))}}>{"<"}</Button>
                        <span>{currentMonth}</span>
                        <Button onClick={() => {
                          if(value < nextMonth)
                          onChange(value.clone().add(1, "month"))}}>{">"}</Button>
                      </div>
                    );
                  }}
                  monthFullCellRender={() => {}}
                  dateFullCellRender={(date) => {
                    const formattedDate = date.format("YYYY-MM-DD");
                    if (bookingDates?.includes(formattedDate)) {
                      return (
                        <Tooltip label="Available slots">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            color: "white",
                            width: "100%",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                          onClick={() => handleSelect(date)}
                        >
                          <div style={{ backgroundColor: "lightblue", display: "flex", justifyContent: "center", alignItems: "center", color: "#134A70", width: "30px", height: "30px", borderRadius: "50%" }}>{date.format("D")}</div>
                        </div>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <div onClick={() => handleSelect(date)} style={{ backgroundColor: "white", height: "100%", width: "100%", borderRadius: "50%", display: "flex", justifyContent: "center" }}>
                          <div style={{ backgroundColor: "white", color: "grey", padding: "3px 0" }}>{date.format("D")}</div>
                        </div>
                      );
                    }
                  }}
                />
            </Box>
            )
          }
        <Box onClick={onToggleFullCalendar} className="cursor-pointer font-semibold hover:font-bold hover:underline text-blue-950 text-right my-2 text-sm">
          {!isFullCalendarOpen ? 'Show full calendar' : 'Hide full calendar'}
        </Box>
        </Box>
        <Box className="my-2">
          <NewSlotsPerDay />
        </Box>
    </Box>
  );
};
export default FoundersBookingCalendar;
