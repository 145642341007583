import { Avatar, Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import moment from "moment";
import BrandButton from "../../../components/brandButton/BrandButton";

const ViewBookingModal = ({ booking, isOpen, onClose }) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="sm">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color="brand.primary">Booking Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <Text fontSize="lg" fontWeight="bold">
                            {booking?.slot_title}
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                            {booking?.slot_description}
                        </Text>
                    </Box>
                    <Box>
                        <Text fontWeight={500} color="brand.primary" mb={2}>Booked By</Text>
                        <Avatar name={`${booking?.first_name} ${booking?.last_name}`} src={booking?.profile_image} />
                        <Text fontSize="16px" color="brand.primary" mb={1} fontWeight={500}>
                        {`${booking?.first_name} ${booking?.last_name}`}
                        </Text>

                    </Box>
                   
                    <Box mt={4}>
                        <Text fontWeight={500} color="brand.primary">Date</Text>
                        <Text fontSize="sm" color="brand.primary">
                            {moment(new Date(booking?.slot_start_date)).format("DD/MM/YYYY")}
                        </Text>
                        <Text fontWeight={500} color="brand.primary" mt={2}>Time</Text>
                        <Text fontSize="sm" color="brand.primary">
                            {moment(booking?.slot_start_time, 'HH:mm:ss').format('h:mm A')} - {moment(booking?.slot_end_time, 'HH:mm:ss').format('h:mm A')}
                        </Text>
                    </Box>
                    <Box mt={4}>
                    <Text fontWeight={500} color="brand.primary">Status</Text>
                        <Text textTransform="capitalize" fontSize="sm" color="brand.primary">
                            {"Booked"}
                        </Text>
                    </Box>
                    <Box display={"none"} mt={4}>
                        <Text fontSize="sm" color="gray.500">
                            {booking?.slot_status}
                        </Text>
                    </Box>
                    <BrandButton style={{display: "none"}} w="150px" text="Edit slot" colorScheme="blue" />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
export default ViewBookingModal;