import {
  Box,
  Text,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import IsDesktop from "../../utils/IsDesktop";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import ConnectionRequestTabs from "../connectionRequests/ConnectionRequestTabs";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../data";
import { useNavigate } from "react-router-dom";
import BrandButton from "../brandButton/BrandButton";
import { mutateFunction, useMutation } from "../../libs/apis";
import { setChatInfo } from "../../redux-toolkit/reducers/chatSlice";
import moment from "moment";
import RequestListsTabs from "../connectionRequests/RequestListsTabs";
import { data } from "autoprefixer";

const RequestList = ({ isOpen, onClose = () => {}, onReactToRequest = () => {} }) => {
  const isDesktop = IsDesktop();
  const navigate = useNavigate();
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { mutate, isLoading } = useMutation(mutateFunction);
  const dispatch = useDispatch();
  const [otherAllConnections, setOtherAllConnections] = useState();
  const [connectionNotification, setConnectionNotification] = useState([])
  const [messageTracker, setMessageTracker] = useState({})
  const [connections, setConnections] = useState([]);
  const [notifications, setNotifications] = useState([])
  const {
    data: connectionsData,
    isLoading: connectionsLoading,
    refetch: refetchConnections,
  } = useData(`users/${loggedInUserId}/connections?page_no=1&page_size=10`, 10000);

  const readNotification = (notificationId) => {
    mutate(
      {
        key: `users/${notificationId}/notification`,
        method: "post",
      },
      {
        onSuccess(res) {},
      }
    );
  };


  const fetchOtherNotificationsForUser = () => {
    mutate(
      {
        key: `users/${loggedInUserId}/notification?page_no=1&page_size=10`,
        method: "get",
      },
      {
        onSuccess(res) {
          setConnectionNotification(res?.data?.notifications.filter(({type}) => type === "connection_request"));
        },
      }
    );
  };


  useEffect(() => {
    const fetchNotifications = () => {
      fetchOtherNotificationsForUser();
    };

    fetchNotifications();
    return () => {
      //clearInterval(intervalId);
    };
  }, []);


  

  useEffect(() => {

    const updateList = () => {

      const senderIds = [...connectionNotification].map((item) => item.senderId)
      const arr2 = (connectionsData?.data?.connections || []).filter((item) => !senderIds.includes(item.id)).map((item) => ({
        ...item,
        sender: {
          ...item
        },
        reciever: item.connection,
        type: "connection_request",
        title: "Connection Request",
        senderId: item.id
      }))
      setNotifications(
        [
          ...connectionNotification,
          ...arr2
        ]
        )
    }

    updateList()
  },[connectionNotification?.length, otherAllConnections?.length, connectionsLoading])

  return (
   <Box>
      {
        notifications?.length > 0 ? (
          <RequestListsTabs onReactToRequest={onReactToRequest} notifications={notifications.map(({type, sender, id}) => ({id, type, senderId: sender.id }))} onReadNotification={readNotification} onClose={onClose} />
        ) : (
          <Box>
            <Box className="text-center">
              <Text className="text-gray-500 text-sm font-semibold">No requests</Text>
            </Box>
          </Box>
        )
      }
   </Box>
  );
};
export default RequestList;
