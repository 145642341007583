import React, { useEffect } from "react";
import { Box, Flex, Heading, Image, Input, InputGroup, InputLeftAddon, Text, useDisclosure } from "@chakra-ui/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { CustomToast } from "../../../components/toast/CustomToast";
import logo_2 from '../../../public/icons/logo_.svg';
import { mutateFunction, useMutation } from '../../../libs/apis'
import { useForm } from "react-hook-form";
import { resetPasswordSchema, VALIDATION_CONSTANTS } from "../../../validators";
import { yupResolver } from "@hookform/resolvers/yup";
import lock from '../../../public/icons/lock.svg';
import BrandButton from "../../../components/brandButton/BrandButton";
import ValidationError from "../../../validators/ValidationError";
import { BsCheck, BsCheckCircleFill } from "react-icons/bs";

const ResetPassword = () => {
    const [searchParams] = useSearchParams({});
    const ref = searchParams.get("reference");
    const navigate = useNavigate();
    const { addToast } = CustomToast();
    const {mutate, isLoading} = useMutation(mutateFunction);
    const {register, handleSubmit, watch, formState: { errors },} = useForm({
        resolver : yupResolver(resetPasswordSchema)
    });
    const {isOpen, onToggle} = useDisclosure();
    const watchPassword = watch("password", "")


    const formSubmit = (data) => {
        mutate(
          { key: "reset-password?reference=" + ref, method: "post", data },
          {
            onSuccess(res) {
                onToggle();
                addToast(res);
            }
          }
        );
      };

      const PasswordResetSuccessful = () => {
        useEffect(() => {
            if(!isLoading){
                window.setTimeout(() => {
                        navigate('/login', { replace: true })}, 3000);
            }
        });
        return (
            <>
                <Flex w="100%" justifyContent="center" alignItems="center" flexDir="column">
                    <Image src="https://jekawin.s3.amazonaws.com/email+sent.gif" alt="image" h="250px" w="300px"/>
                    <Box display="flex" flexDir="column" justifyContent="center" alignItems="center">
                        <Heading fontSize="32px" color="brand.primary">Password Reset Successful</Heading>
                        <Text textAlign="center" color="gray">Redirecting to login page</Text>
                    </Box>
                </Flex>
            </>
        )
      }

    

    return (
        <>
            <Box width="full" h="100vh" display="flex" flexDir="column" alignItems="center" justifyContent="center">
                <Image src={logo_2} boxSize='100px' className="mt-20" objectFit='cover' mb={2}/>
                <Flex p={5}>
                    <Heading color="brand.primary">Reset Password</Heading>
                </Flex>
                {isOpen && <PasswordResetSuccessful/>}
                {!isOpen && <form className="flex flex-col" onSubmit={handleSubmit(formSubmit)}>
                    <InputGroup mb={3}>
                        <Flex borderWidth='1px' borderColor="brand.primary" borderRadius="5px" alignItems="center">
                            <InputLeftAddon borderWidth='0px' bg="" children={<Image src={lock} size={30}/>} />
                          <Input {...register("password")} focusBorderColor="#" borderWidth='0px' variant='outline' name="password" placeholder='New password' type="password" w="250px" h="50px" />
                        </Flex>
                    </InputGroup>
                    <ValidationError message={errors.password?.message} />
                    
                    <InputGroup mb={3}>
                    <Flex borderWidth='1px' borderColor="brand.primary" borderRadius="5px" alignItems="center">
                        <InputLeftAddon borderWidth='0px' bg="" children={<Image src={lock} size={30}/>} />
                        {/* <Input {...register('password')} focusBorderColor="#" borderWidth='0px' variant='outline' name="password" placeholder='At least 6 characters' type="password" w={!isDesktop ? "250px" : "350px"} h="50px" /> */}
                        <Input {...register("confirmPassword")} focusBorderColor="#" borderWidth='0px' variant='outline' name="confirmPassword" placeholder='Confirm new password' type="password" w="250px" h="50px" />
                    </Flex>
                    </InputGroup>
                    <ValidationError message={errors.confirmPassword?.message} />
                    <BrandButton className="mx-auto" isDisabled={isLoading} isLoading={isLoading} colorScheme="blue" w="180px" text="Reset Password" type="submit"/>
                    <Box className={`bg-gray-50 my-4 p-3 ${!errors.password?.message ? 'hidden' : 'block'}`}>
                        <Flex className="items-center gap-2 mb-2">
                            <BsCheckCircleFill fill={watchPassword.match(VALIDATION_CONSTANTS.PASSWORD_PARTS.UPPERCASE) ? "green" : "gray"} />
                            <Text className="text-sm">Contains uppercase</Text>
                        </Flex>
                        <Flex className="items-center gap-2 my-2">
                            <BsCheckCircleFill fill={watchPassword.match(VALIDATION_CONSTANTS.PASSWORD_PARTS.LOWERCASE) ? "green" : "gray"}  />
                            <Text className="text-sm">Contains lowercase</Text>
                        </Flex>

                        <Flex className="items-center gap-2 my-2">
                            <BsCheckCircleFill fill={watchPassword.match(VALIDATION_CONSTANTS.PASSWORD_PARTS.NUMBER) ? "green" : "gray"}  />
                            <Text className="text-sm">Contains number</Text>
                        </Flex>
                        <Flex className="items-center gap-2 my-2">
                            <BsCheckCircleFill fill={watchPassword.match(VALIDATION_CONSTANTS.PASSWORD_PARTS.SPECIAL_CHAR) ? "green" : "gray"}  />
                            <Text className="text-sm">Contains special character</Text>
                        </Flex>
                        <Flex className="items-center gap-2 mt-2">
                            <BsCheckCircleFill fill={watchPassword?.length > 5 ? "green" : "gray"}  />
                            <Text className="text-sm">Minimum of 6 characters</Text>
                        </Flex>
                    </Box>
                </form>}
                    
            </Box>
        </>
    )
}
export default ResetPassword;
