import { Avatar, Box, Flex, Heading, HStack, Image, Input, InputGroup, InputLeftAddon, Text, Textarea, VStack, Select, Radio, RadioGroup } from "@chakra-ui/react";
import { Divider } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FiPause, FiPlay } from "react-icons/fi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useQueryClient } from "react-query";
import BrandButton from "../../../components/brandButton/BrandButton";
import { useData } from "../../../data";
import IsDesktop from "../../../utils/IsDesktop";
import share_icon from "../../../public/icons/share_icon.svg";
import copy_icon from "../../../public/icons/copy_icon.svg";
import { BiImage } from "react-icons/bi";
import { AiFillPlayCircle } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editAdvertSchema } from "../../../validators";
import { CustomToast } from "../../../components/toast/CustomToast";
import { copyToClipboard } from "../../../utils/copyToClipboard";

const ViewAdvertDetails = ({ editAdvert, advertId, onToggle, changeStatus, deleteAdvert }) => {
    const isDesktop = IsDesktop();
    const { data, refetch: refetchThisadvert } = useData(`adverts/${advertId}`);
    const [advertDetails, setAdvertDetails] = useState({});
    const queryClient = useQueryClient();
    const [image, setImage] = useState();
    const { addToast } = CustomToast()
    const [editAdvertDetails, setEditAdvertDetails] = useState({});


    useEffect(() => {
      if (data?.advert) {
        setAdvertDetails({...data?.advert, user_type: data?.advert?.user_type?.title || null});
        setEditAdvertDetails({
          attachment: data?.advert?.media,
          title: data?.advert?.title,
          description: data?.advert?.description,
          agency_id: data?.advert?.brand?.id,
          start_date: data?.advert?.start_date,
          end_date: data?.advert?.end_date,
          advert_type: data?.advert?.advert_type || "default",
          user_type: data?.advert?.user_type?.title || null,
          external_link: data?.advert?.external_link || ''
    })
      }
    }, [data]);
    const changeThisAdvertStatus = (advertId) => {
      changeStatus(advertId);
      queryClient.invalidateQueries(`adverts/${advertId}`);
    };
    const deleteThisAdvert = (advertId) => {
      deleteAdvert(advertId);
      onToggle();
    };

    const editThisAdvert = () => {
      var formData = new FormData();
      formData.append("attachment", editAdvertDetails?.attachment);
      formData.append("title", editAdvertDetails?.title);
      formData.append("description", editAdvertDetails?.description);
      formData.append("agency_id", editAdvertDetails?.agency_id);
      formData.append("start_date", editAdvertDetails?.start_date);
      formData.append("end_date", editAdvertDetails?.end_date);
      formData.append("advert_type", editAdvertDetails?.advert_type || "default")
      formData.append("user_type", editAdvertDetails?.user_type || "all")
      formData.append("external_link", editAdvertDetails?.external_link)
    
      editAdvert(advertId, formData, refetchThisadvert);

      onToggle();
    }

    const handleChange = (e) => {
      if (e.target.name === "external_link") {
        setEditAdvertDetails((prev) => ({
          ...prev,
          [e.target.name]: /^http/ig.test(e.target?.value) ? e.target.value : `https://${e.target.value}`,
      }))}else {
        setEditAdvertDetails({ ...editAdvertDetails, [e.target.name]: e.target.value });
      }
    };
    const handleChangeForFile = (e) => {
      const fileData = e.target.files[0];
      setImage(fileData);
      setEditAdvertDetails((prev) => ({
          ...prev,
          attachment: fileData,
        }));
    };

    const handleRadioChange = (value) => {
      setEditAdvertDetails(prev => ({
        ...prev,
        advert_type: value
      }))
    }

    const copyLink = () => {
      if(advertDetails?.external_link){
        copyToClipboard(advertDetails?.external_link)
        addToast({message: 'Copied'}, 'info')
      }else {
        addToast({message: 'Advert has no link'}, 'info')
      }
    }

    return (
      <Flex className="w-[100%] flex-col overflow-hidden">
        <Flex className="w-[100%] overflow-x-hidden gap-5 flex-col overflow-y-auto pb-8 md:pb-24" h={isDesktop ? "85vh" : "auto"} w="100%" p={4}  >
        <Flex justifyContent="space-between" alignItems="center" my={4} mx={4}>
          <Flex cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
            <Box mr={2}>
              <HiOutlineArrowNarrowLeft size="35px" />
            </Box>
            <Box>
              <Text fontWeight={600}>Back</Text>
            </Box>
          </Flex>
          <BrandButton type="submit" text="Save Changes" w="130px" onClick={()=> editThisAdvert()} />
        </Flex>
        <HStack h="100%" w="100%" alignItems="flex-start" m={4}>
          <VStack w="60%">
          <Flex flexDir="column" w="100%" key="image" justifyContent="flex-start" >
          <Input accept="image/*" hidden id="image-input" type="file" onChange={(e) => handleChangeForFile(e)} name="attachement" key="image-input" />
          <label htmlFor="image-input">
              <VStack alignItems="flex-start">
                <Flex w="50%" h="150px" justifyContent="center" alignItems="center" bg="lightgray" borderRadius="10px">
                  {image && <Image src={window.URL.createObjectURL(new Blob([image], { type: "image" }))} w="100%" h="100%" fit="cover" borderRadius="10px" />}
                  {(!image && !advertDetails?.media) && <BiImage size={50} color="gray" />}
                  {(!image && advertDetails?.media) && <Image alt={advertDetails?.title} src={advertDetails?.media} w="100%" h="100%" fit="cover" borderRadius="10px" />}
                </Flex>
              </VStack>
            </label>
          </Flex>
            <Flex flexDir="column" w="100%" key="title">
              <Text fontSize="14px" mb={2}>
              Advert Title <span style={{color:"red"}}>*</span>
              </Text>
              <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Advert Title" defaultValue={editAdvertDetails?.title} name="title" type="text" w="100%" h="50px" onChange={(e)=> handleChange(e)} />
            </Flex>
            <Flex flexDir="column" w="100%" key="brand">
            <Text fontSize="14px" mb={2}>
              Advert Type <span style={{color:"red"}}>*</span>
            </Text>
            <RadioGroup onChange={handleRadioChange} value={editAdvertDetails?.advert_type}>
              <Flex className="flex-col gap-1 max-w-max">
                <Radio value='default'>Default</Radio>
                <Radio value='inbox'>Inbox</Radio>
              </Flex>
            </RadioGroup>
          </Flex>
          <Flex flexDir="column" w="100%" key="brand">
            <Text fontSize="14px" mb={2}>
              Select Target Audience 
            </Text>
            <Select borderWidth="1px" borderColor="brand.primary" borderRadius="5px" placeholder="Choose your target Audience" focusBorderColor="#"  name="user_type" value={editAdvertDetails?.user_type} defaultValue={advertDetails?.user_type} disabled={editAdvertDetails?.advert_type === 'default'} onChange={handleChange}>
                <option value={"all"}>
                    All
                </option>
                <option value={"founder"}>
                    Founder
                </option>
                <option value={"investor"}>
                    Investors
                </option>
                <option value={"expert"}>
                    Experts
                </option>
            </Select>
          </Flex>
          <Flex flexDir="column" w="100%" key="title">
            <Text fontSize="14px" mb={2}>
              URL <span>(Optional)</span> <span className="text-xs"> ex: https://www.link.com</span>
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="URL" name="external_link" defaultValue={editAdvertDetails?.external_link} type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex>
            <Flex flexDir="column" w="100%" key="brand">
              <Text fontSize="14px" mb={2}>
                Brand Name
              </Text>
              <InputGroup w="100%">
                <Flex borderWidth="1px" borderColor="brand.primary" borderRadius="5px" alignItems="center" w="100%">
                  <InputLeftAddon borderWidth="0px" bg="" children={<Avatar size="sm" name={advertDetails?.brand?.name} src={advertDetails?.brand?.logo} />} />
                  <Input disabled focusBorderColor="#" borderWidth="0px" variant="outline" placeholder="Brand" defaultValue={advertDetails?.brand?.name} name="brand" type="text" w="100%" h="50px" />
                </Flex>
              </InputGroup>
            </Flex>
            <Flex flexDir="column" w="100%" key="description">
              <Text fontSize="14px" mb={2}>
                Description
              </Text>
              <Textarea h="150px" borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Description" defaultValue={advertDetails?.description} name="description" type="text" w="100%" onChange={(e)=> handleChange(e)} />
            </Flex>
            <Flex flexDir="column" w="100%">
              <HStack spacing={isDesktop ? 2 : 0} flexDir={isDesktop ? "row" : "column"} justifyContent="space-between" alignItems="center" w="100%">
                <Flex flexDir="column" w="100%" key="start_date">
                  <Text fontSize="14px" mb={2}>
                    Start Date
                  </Text>
                  <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="start_date" value={moment(editAdvertDetails?.start_date).format("YYYY-MM-DD")} type="date" size="md" onChange={(e)=> handleChange(e)} />
                </Flex>
                <Flex flexDir="column" w="100%" key="end_date">
                  <Text fontSize="14px" mb={2}>
                    End Date
                  </Text>
                  <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="end_date" value={moment(editAdvertDetails?.end_date).format("YYYY-MM-DD")} type="date" size="md" onChange={(e)=> handleChange(e)} />
                </Flex>
              </HStack>
            </Flex>
          </VStack>
          <VStack h="100%" w="38%" boxShadow="0px 1px 8px lightgray" p={4} borderRadius="10px">
            <VStack w="100%" h="120px" justifyContent="space-between">
                <Flex w="100%" >
                <Heading fontSize="18px" color="brand.primary">Performance</Heading>
                </Flex>
              <VStack>
                <Heading color="brand.primary" fontSize="28px">
                  {advertDetails?.impression_count || 0}
                </Heading>
                <Text fontSize="16px">Impressions</Text>
              </VStack>
              <Divider orientation="horizontal" colorScheme="gray" />
            </VStack>
            <VStack w="100%" alignItems="flex-start" spacing={2} my={4}>
              <Heading color="brand.primary" fontSize="18px">
                Other Info
              </Heading>
              <Flex justifyContent="space-between" alignItems="center" w="100%">
                <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                  <Text fontSize="14px" mb={2}>
                    Date Added
                  </Text>
                </Flex>
                <Box w="full" ml={2}>
                  <Text color="brand.primary">{moment(advertDetails?.createdAt).format("MMM D, YYYY")}</Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center" w="100%">
                <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                  <Text fontSize="14px" mb={2}>
                    Start Date
                  </Text>
                </Flex>
                <Box w="full" ml={2}>
                  <Text color="brand.primary">{moment(advertDetails?.start_date).format("MMM D, YYYY")}</Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center" w="100%">
                <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                  <Text fontSize="14px" mb={2}>
                    End Date
                  </Text>
                </Flex>
                <Box w="full" ml={2}>
                  <Text color="brand.primary">{moment(advertDetails?.end_date).format("MMM D, YYYY")}</Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center" w="100%">
                <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                  <Text fontSize="14px" mb={2}>
                    Advert Clicks
                  </Text>
                </Flex>
                <Box w="full" ml={2}>
                  <Text color="brand.primary">{advertDetails?.click_count || 0}</Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center" w="100%">
                <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                  <Text fontSize="14px" mb={2}>
                    Advert Views
                  </Text>
                </Flex>
                <Box w="full" ml={2}>
                  <Text color="brand.primary">{advertDetails?.impression_count || 0}</Text>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center" w="100%" pb={12}>
                <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                  <Text fontSize="14px" mb={2}>
                    Status
                  </Text>
                </Flex>
                <Box w="full" ml={2}>
                  {advertDetails?.is_active === true && (
                    <Box borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="120px" h="40px" bg="#E6F3E6">
                      <Text color="green" fontWeight={500}>
                        Ongoing
                      </Text>
                    </Box>
                  )}
                  {(advertDetails?.is_active === false || !advertDetails?.is_active) && (
                    <Box borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="120px" h="40px" bg="#FFF6E6">
                      <Text color="orange" fontWeight={500}>
                        Paused
                      </Text>
                    </Box>
                  )}
                </Box>
              </Flex>
              <Divider orientation="horizontal" />
            </VStack>
            <HStack justifyContent="flex-start">
              {advertDetails?.is_active === true && (
                <Box cursor="pointer" onClick={() => changeThisAdvertStatus(advertDetails?.id)} border="1px" borderColor="orange" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px">
                  <FiPause color="orange" />
                </Box>
              )}
              {(advertDetails?.is_active === false || !advertDetails?.is_active) && (
                <Box cursor="pointer" onClick={() => changeThisAdvertStatus(advertDetails?.id)} border="1px" borderColor="green" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px">
                  <FiPlay color="green" />
                </Box>
              )}
              <Box cursor="pointer" onClick={() => deleteThisAdvert(advertDetails.id)} border="1px" borderColor="red" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px">
                <RiDeleteBin6Line color="red" />
              </Box>
              <BrandButton style={{display : "none"}} text="Share" icon={share_icon} w="100px" h="40px" />
              <BrandButton text="Copy Link" onClick={() => {copyLink()}} icon={copy_icon} w="120px" h="40px" />
            </HStack>
          </VStack>
        </HStack>
        </Flex>
        </Flex>
    );
  };
  export default ViewAdvertDetails;
  