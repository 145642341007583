import ReactGA from 'react-ga';

export function loadGoogleAnalytics(GA_MEASUREMENT_ID) {
  const scriptUrl = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
  const scriptTag = document.createElement('script');
  scriptTag.src = scriptUrl;
  scriptTag.async = true;
  document.head.appendChild(scriptTag);

  scriptTag.onload = () => {
    window.dataLayer = window?.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', GA_MEASUREMENT_ID);
  };
}


const TRACKING_ID = process.env.REACT_APP_GA || ''; 
if(TRACKING_ID)ReactGA.initialize(TRACKING_ID);

export const trackPage = (path) => {
  if(TRACKING_ID) ReactGA.pageview(path);
};

export const trackEvent = (category, action, label) => {

  if(TRACKING_ID){
    ReactGA.event({
        category,
        action,
        label
      });
  }
};
