import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import ProgressSlider from "./assets/ProgressSlider";
import IsDesktop from "../../utils/IsDesktop.js";
import Page from "./assets/Page";
import file from "../../public/icons/file.svg";
import { mutateFunction, useMutation } from "../../libs/apis";
import { CustomToast } from "../../components/toast/CustomToast";
import { BASE_URL } from "../../helpers/constant";
import client from "../../libs/client";
import UserService from "../../utils/UserService";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux-toolkit/reducers/userSlice";
import { setCompanyInfo } from "../../redux-toolkit/reducers/companySlice";
import { setCompleteSurvey } from "../../redux-toolkit/reducers/SurveySlice";
import BrandButton from "../../components/brandButton/BrandButton";
import { useNavigate } from "react-router-dom";
import { BsFileImage } from "react-icons/bs";
import evaluateCompanyProfile from "../../utils/evaluateCompanyProfile";
import { setAllCompaniesInfo } from "../../redux-toolkit/reducers/allCompaniesSlice";

const FounderSurveyForm = ({
  pageNumber,
  setPageNumber,
  setPage,
  surveyData,
  filledData,
  setFilledData,
  userCompany,
  refetch
}) => {

  const userInfo = useSelector((state) => state?.user?.userInfo);
  const company = useSelector((state) => state?.company?.companyInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = CustomToast();
  const { mutate, isLoading } = useMutation(mutateFunction);
  const isDesktop = IsDesktop();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [image, setImage] = useState(null);

  const nextPage = () => {

    let _data = {
      ...filledData
    }

    _data.title =  _data?.title || userCompany?.title 
    _data.description =  _data?.description || userCompany?.description
    _data.companyStageId = _data.companyStageId ? [_data.companyStageId] : []
    _data.businessModelId = _data.businessModelId ? [_data.businessModelId] : []
    _data.businessCategoryId = _data.businessCategoryId ? [_data.businessCategoryId] : []
    _data.incorporationLocationId = _data.incorporationLocationId || []

    
    
    if (pageNumber <= 6) {
      setButtonLoading(true);
      mutate(
        {
          key: `companies/${pageNumber + 1}`,
          method: "post",
          data: _data,
        },
        {
         async onSuccess(res) {
            await refetch()
            if (res.data) {
              addToast({
                message: "Saved Company data Successfully",
                type: "success",
              });
              const company = res?.data?.company;

              UserService.setCompanyInfo(company);
              dispatch(setCompanyInfo(company));
              UserService.setAllCompaniesInfo([company])
              dispatch(setAllCompaniesInfo([company]))
              const newUserData = { 
                ...userInfo,
                userTypeTitle: "founder",
                userTypeId: 1,
              };
              dispatch(setUserInfo(newUserData));
              UserService.setUserInfo(newUserData);
              setButtonLoading(false);
              setPageNumber(pageNumber + 1);
              addToast(res);
            }
          },
          onError(e) {
            addToast(e.message);
          },
        }
      );
      setButtonLoading(false);
      setPageNumber(pageNumber + 1);
    } else if (pageNumber > 6) {
      setPageNumber(7);
    } else {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(0);
      setPage("welcome");
    }
  };

  const Page1 = () => {
    const handleChange = (e) => {
      setFilledData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    };
    const handleChangeForFile = (e) => {
      setFilledData((prev) => ({
        ...prev,
        [e.target.name]: e.target.files[0],
      }));
      setImage(e.target.files[0]);
    };
    return (
      <Box display="flex" justifyContent="center">
        <VStack
          flexDir="column"
          w={isDesktop ? "600px" : "300px"}
          justifyContent="flex-start"
          alignItems={isDesktop ? "flex-start:" : "center"}
          spacing={5}
        >
          <Text fontWeight={700} fontSize="16px" color="brand.primary">
            Enter your start-up name?
          </Text>
          <Input
            key="title"
            type="text"
            placeholder="Type here...."
            defaultValue={userCompany?.title ?? filledData.title}
            name="title"
            borderRadius="10px"
            borderWidth={2}
            borderColor="brand.primary"
            h="50px"
            onChange={(e) => handleChange(e)}
          />
          <Text
            key="description"
            fontWeight={700}
            fontSize="16px"
            color="brand.primary"
          >
            Describe your start-up in few words
          </Text>
          <Textarea
            placeholder="Type here...."
            defaultValue={userCompany?.description ?? filledData?.description}
            type="text"
            name="description"
            borderRadius="10px"
            borderWidth={2}
            borderColor="brand.primary"
            minH="150px"
            onChange={(e) => handleChange(e)}
          />
          <Input
            accept="image/*"
            hidden
            id="image-input"
            type="file"
            onChange={(e) => handleChangeForFile(e)}
            name="cover_photo"
            key="cover_photo"
          />
          <label htmlFor="image-input">
            <VStack alignItems="flex-start" cursor="pointer">
              <Text
                key="cove_photo"
                fontWeight={700}
                fontSize="16px"
                color="brand.primary"
              >
                Upload Start-up Cover Photo
              </Text>
              <Flex
                w={isDesktop ? "50%" : "250px"}
                h="150px"
                justifyContent="center"
                alignItems="center"
                bg="lightgray"
                borderRadius="10px"
              >
                {image && (
                  <Image
                    src={window.URL.createObjectURL(
                      new Blob([image], { type: "image" })
                    )}
                    w="100%"
                    h="100%"
                    fit="cover"
                    borderRadius="10px"
                  />
                )}
                {!image && <BsFileImage size={50} color="gray" />}
              </Flex>
            </VStack>
          </label>
          <div className="flex space-x-4">
            <Box
              as="button"
              w="100px"
              h="50px"
              mb={7}
              bg="brand.primary"
              borderRadius="5px"
              onClick={() => prevPage()}
            >
              <Text fontWeight={700} fontSize="16px" color="white">
                Previous
              </Text>
            </Box>
            <Box
              as="button"
              w="100px"
              h="50px"
              mb={7}
              bg="brand.primary"
              borderRadius="5px"
              onClick={() => nextPage()}
            >
              <Text fontWeight={700} fontSize="16px" color="white">
                Next
              </Text>
            </Box>
          </div>
        </VStack>
      </Box>
    );
  };
  const Page2 = () => {
    return (
      <Page
        question="What is your start-up stage?"
        options={surveyData?.companyStages}
        data={{ companyStageId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.company_stage_ids[0] || ''}
      />
    );
  };
  const Page3 = () => {
    return (
      <Page
        question="What type of funding does your start-up require?"
        // queryKey={`company-stage`}
        options={surveyData.fundCategories}
        data={{ fundCategoryId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.fundCategoryId}

      />
    );
  };
  const Page4 = () => {
    return (
      <Page
        question="Where is your start-up incorporated?"
        // queryKey={`company-stage`}
        isCheckBox={true}
        options={surveyData.locations}
        data={{ incorporationLocationId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.incorporation_location_ids?.map((item) => `${item}`)}

      />
    );
  };
  const Page5 = () => {
    return (
      <Page
        question="Which business model best describes your start-up?"
        options={surveyData.businessModels}
        data={{ businessModelId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        responseId={userCompany?.business_model_ids[0] || ''}
      />
    );
  };
  const Page6 = () => {
    return (
      <Page
        question="Which category best describes your start-up?"
        options={surveyData.categories}
        data={{ businessCategoryId: undefined }}
        setFilledData={setFilledData}
        filledData={filledData}
        nextPage={nextPage}
        prevPage={prevPage}
        isLoading={buttonLoading}
        responseId={userCompany?.business_category_ids[0] || ''}

      />
    );
  };

  const UploadDocs = () => {
    const [docs, setDocs] = useState();
    const [videoUrl, setVideoUrl] = useState("");
    const companyInfo = company;
    const upload = () => {
      const formData = new FormData();
      {
        docs && formData.append("pitch_deck", docs);
      }
      {
        videoUrl && formData.append("video_url", videoUrl);
      }
      mutate(
        { key: `companies/${companyInfo.id}`, method: "put", data: formData },
        {
          onSuccess(res) {
            const result = res?.data?.updatedData;
            addToast(res);
            if(result?.status !== "live"){
              addToast("Your account is in draft mode. Please update all company details in account settings", "error")
            }
            UserService.setCompanyInfo({ ...company, ...result });
            dispatch(setCompanyInfo({ ...company, ...result }));
            UserService.setAllCompaniesInfo([{ ...company, ...result }])
            dispatch(setAllCompaniesInfo([{ ...company, ...result }]))
            dispatch(setCompleteSurvey(false));
            UserService.setCompleteSurveyAction(false);
            // // dispatch(setCompanyInfo())
            setPage("thank-you");
          },
        }
      );
    };

    const handleFileInput = (e) => {
      setDocs(e.target.files[0]);
    };
    const handleChange = (e) => {
      setVideoUrl(e.target.value);
    };
    return (
      <>
        <Flex
          mb={7}
          color="brand.primary"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <Box
            display="flex"
            justifyContent="center"
            w={isDesktop ? "600px" : "300px"}
          >
            <Heading
              textAlign="center"
              fontSize={isDesktop ? "25px" : "20px"}
              mb={isDesktop ? 7 : 3}
            >
              Upload your company&apos;s pitch deck
            </Heading>
          </Box>
          <Box w={isDesktop ? "600px" : "300px"}>
            <Text textAlign="center" fontSize={14}>
              Upload documents you want to share with potential investors. (PDF
              files only, not more than 10MB)
            </Text>
          </Box>
        </Flex>
        <HStack
          mb={7}
          spacing="20px"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            border="dashed 3px"
            borderRadius="5px"
            borderColor="lightgray"
            h="200px"
            w={IsDesktop() ? "350px" : "200px"}
            bg="#134A700D"
          >
            <Image src={file} size={30} mb={3} />
            {!docs ? (
              <Flex px={3}>
                <Text textAlign="center" color="lightgray">
                  Drag and Drop your files here
                </Text>
              </Flex>
            ) : (
              <Flex px={3}>
                <Text textAlign="center" color="green">
                  {docs.name}
                </Text>
              </Flex>
            )}
            <Text color="brand.primary" fontWeight={700}>
              OR
            </Text>
            <Input
              hidden
              id="file-input-btn"
              type="file"
              name="file"
              key="file"
              accept="application/pdf"
              onChange={handleFileInput}
            />
            <label bg="red" h="100%" htmlFor="file-input-btn">
              <Flex
                w="100px"
                cursor="pointer"
                borderRadius="5px"
                _active={{ backgroundColor: "lightgray" }}
                justifyContent="center"
                alignItems="center"
                h="50px"
                bg="brand.primary"
                fontWeight="600"
                fontSize="16px"
              >
                <Text color="white" fontSize="14px">
                  Browse File
                </Text>
              </Flex>
            </label>
          </Box>
        </HStack>
        <Text fontWeight={700} fontSize="16px" color="brand.primary">
          Add Video url
        </Text>
        <Box w={isDesktop ? "600px" : "300px"}>
          <Text textAlign="center" fontSize={14} color="brand.primary">
            Upload a video to youtube, and paste the video link here.
          </Text>
        </Box>
        <Input
          maxW="500px"
          key="video-url"
          type="text"
          placeholder="Type here...."
          defaultValue={company?.video_url}
          name="video_url"
          borderRadius="10px"
          borderWidth={1}
          borderColor="brand.primary"
          h="50px"
          onChange={(e) => handleChange(e)}
          w="90vw"
          className="mx-auto"
        />
        <Flex justifyContent="center" alignItems="center" flexDir="column">
          <HStack
            spacing={10}
            mt={10}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              as="button"
              w="100px"
              h="50px"
              bg="white"
              borderRadius="5px"
              borderWidth="2px"
              borderColor="brand.primary"
              onClick={() => prevPage()}
              _active={{ backgroundColor: "lightgray" }}
            >
              <Text fontWeight={700} fontSize="16px" color="brand.primary">
                Previous
              </Text>
            </Box>
            <BrandButton
              w="100px"
              bg="brand.primary"
              borderRadius="5px"
              onClick={() => upload()}
              _active={{ backgroundColor: "brand.secondary" }}
              text="Upload"
              colorScheme="blue"
              isLoading={isLoading}
            />
          </HStack>
          <Box cursor="pointer" mt={10} onClick={() => {
            if(company?.status !== "live"){
              addToast("Your account is in draft mode. Please update all company details in account settings", "error")
              evaluateCompanyProfile(company, navigate)
              return
            }
          }}>
            <Text textDecoration="underline" fontWeight={600}>
              I'll do this later
            </Text>
          </Box>
        </Flex>
      </>
    );
  };

  return (
    <Box display="flex" flexDir="column" alignItems="center">
      <ProgressSlider pageNumber={pageNumber} />
      <Flex
        mt={10}
        flexDir="column"
        justifyContent="center"
        w={IsDesktop() ? "600px" : "400px"}
        alignItems={isDesktop ? "flex-start" : "center"}
      >
        {pageNumber === 1 && Page1()}
        {pageNumber === 2 && Page2()}
        {pageNumber === 3 && Page3()}
        {pageNumber === 4 && Page4()}
        {pageNumber === 5 && Page5()}
        {pageNumber === 6 && Page6()}
      </Flex>
      <Flex
        flexDir="column"
        justifyContent="center"
        w={IsDesktop() ? "600px" : "400px"}
        alignItems="center"
      >
        {pageNumber === 7 && <UploadDocs />}
      </Flex>
    </Box>
  );
};
export default FounderSurveyForm;
