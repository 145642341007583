import React from 'react'
import { Box, Image, Text, Button, Flex} from "@chakra-ui/react"
import IsDesktop from '../../utils/IsDesktop'


export default function Pitch({image, title , describe}) {
  const isDesktop = IsDesktop();
  return (
    <div>
      <Box width={isDesktop ? "373px" : "90%"} height="221px" background="#FFFAFB" borderRadius="4px" gap="15px" p="24px">
        <Image src={image} color="#134A70" width="48px" height="48px"/>
        <Text color="#134A70" width={isDesktop ? "300px" : ""} height="30px" fontFamily='Poppins'fontStyle="normal" fontWeight="600" fontSize="20px" lineHeight="30px"mt="20px">{title}</Text>
        <Text color="#9FA7AD" width={isDesktop ? "325px" : ""} height="63px" fontFamily='Poppins'fontStyle="normal" fontWeight="400" fontSize="14px" lineHeight="21px" mt="10px">{describe}</Text>
      </Box>
    </div>
  )
}

