import { Flex, Box, Text, Spacer, Image } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useData } from "../../../data";
import IsDesktop from "../../../utils/IsDesktop";
import DashboardAdvertCard from "./DashboardAdvertCard";
import getRandomInt from "../../../utils/getRandomInt";

const dataImg = [
  { id: 1, img: "/assets/images/1.png" },
  { id: 3, img: "/assets/images/3.png" },
  { id: 4, img: "/assets/images/4.png" },
  { id: 5, img: "/assets/images/5.png" },
];

const AdvertFeature = () => {
  const {data} = useData("adverts")
  const navigate = useNavigate();
  const isDesktop = IsDesktop();
  const [firstRandomNumber, setFirstRandomNumber] = useState(getRandomInt(0, (data?.data?.adverts || []).length))
  const [count, setCount] = useState(0)
  const [secondRandomNumber, setSecondRandomNumber] = useState(0)
  const intervalId = useRef(null)

  function resetTimeout() {
    if (intervalId.current) {
      clearTimeout(intervalId.current);
    }
  }

  const handleAdvertClick = (link) => {
    link && window.open(link, '_blank')
  };


  useEffect(() => {
      resetTimeout()
      intervalId.current = setTimeout(() => {
          setCount(prev => prev === data?.data?.adverts?.length - 1 ? 0 : prev + 1)
      }, 3000)

      return () => { resetTimeout() }
  }, [count, data?.data?.adverts?.length])

  useEffect(() => {
    let _secondRandomNumber = (() => {
      let _num = getRandomInt(0, (data?.data?.adverts || []).length)
      return _num !== firstRandomNumber ? _num : firstRandomNumber > 0 ? _num - 1 : _num + 1
    })()

    setSecondRandomNumber(_secondRandomNumber)
  }, [firstRandomNumber])



  return (
    <Box w="full">
      {
                  data?.data?.adverts?.length > 0 ? (
                    <Box className="items-start gap-x-10 gap-y-3 justify-between my-5 w-full flex-wrap">
                      <Box className="relative w-full max-w-[695px] overflow-hidden">
                          <div className='whitespace-nowrap'
                          style={{ transform: `translate3d(${-count * 100}%, 0, 0)`, transition: 'ease 1000ms', }}
                          >
                            {
                              data?.data?.adverts?.map((advert, index) => {
                                return (
                                  <div onClick={() => {handleAdvertClick(advert?.external_link)}} key={index} className="w-full inline-block">
                                    <img className="w-full max-w-[695px] rounded-lg object-cover aspect-[2.9]" src={advert?.media} alt={advert?.title} srcset="" />
                                  </div>
                                )
                              })
                            }   
                          </div>
                        <div className='absolute bottom-5 flex w-full items-center gap-1 justify-center'
                          >
                            {
                              data?.data?.adverts?.map((img, index) => {
                                return (
                                  <div onClick={() => {setCount(index)}} key={index} className={`h-1 w-6 cursor-pointer ${index == count ? 'bg-blue-300' : 'bg-gray-100'}`}>
                                  </div>
                                )
                              })
                            }   
                          </div>
                      </Box>
                    </Box>
                  ) : null 
                }
    </Box>
  );
};

export default AdvertFeature;
