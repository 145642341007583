import React from 'react'
import { Box, Image, Text, Button, Flex} from "@chakra-ui/react"


export default function Offers({image, title , price, description, button}) {
  return (
    <div>
      <Box width="371px" height="360.48px" boxShadow="0px 0px 13.76px 6.88px rgba(19, 74, 112, 0.1)" borderRadius="16px" p="24px">
        <Image src={image} color="#134A70"/>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="700px" fontSize="20px" lineHeight="150%" color="#062F36" mt="15px">{title}</Text>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="700px" fontSize="18px" lineHeight="150%" color="#134A70" mt="15px">{price}</Text>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="400px" fontSize="14px" lineHeight="150%" width="323px" height="42px" color="#000000" mt="10px">{description}</Text>
        <Button fontFamily='Poppins' fontStyle="normal" fontWeight="600px" fontSize="16px" lineHeight="24px" width="150px" height="50px" bg="#FFFFFF" border="1px solid #134A70" color="#134A70" mt="30px">{button}</Button>
      </Box>
    </div>
  )
}