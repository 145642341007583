import { Avatar } from "@chakra-ui/avatar";
import { Box, Heading, HStack, Text, VStack, Divider, Flex } from "@chakra-ui/layout";
import React from "react";
import ConnectionButton from "../connectionButton/ConnectionButton";
import { Image, Tooltip } from "@chakra-ui/react";
import default_cover_photo from "../../public/icons/default_cover_photo.png";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsFillHandThumbsUpFill, BsFillHandThumbsDownFill, BsXCircle } from "react-icons/bs";
import { setUserInfo } from "../../redux-toolkit/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import client from "../../libs/client";
import { BASE_URL } from "../../helpers/constant";
import InvestorBanner from "../../public/images/investor_dashboard.jpg"

const NewSingleInvestor = ({ investor, onToggle, setSelectedInvestor }) => {
  const navigate = useNavigate()
  const viewDetails = () => {
    navigate(`/view-user?userid=${investor?.id}&usertype=${investor?.userTypeId}`) 
    window.scrollTo(0, 0); 
  };
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state?.user?.userInfo);
  const loggedInUserType = userDetails?.userTypeTitle || "";

  

  return (
    <Box style={{flex: '1 1 300px'}} className="w-[100%] min-w-[290px]  max-w-[300px] sm:w-[45%] sm:max-w-[350px] xl:w-[43%] xl:max-w-[390px] 2xl:max-w-[300px] px-3 pt-3 pb-1 relative border border-[#DBE2ED] rounded-lg">
      <Flex className="flex-col">
        <Box className="rounded-md" w="100%" h="120px"  bg="lightgray" position="relative">
          {investor?.cover_image && (
            <Box className="rounded-md" position="absolute" top="0" w="100%" h="120px" overflow="hidden">
              <Image
                src={investor?.cover_image}
                objectFit="cover"
                position="absolute"
                top="0"
              />
            </Box>
          )}
          {!investor?.cover_image && (
            <Box className="rounded-md" h="120px" w="100%" overflow="hidden">
            <Image
              src={InvestorBanner}
              objectFit="cover"
              h="120px"
              className="object-left"
            />
          </Box>
          )}
        </Box>
        <Box cursor="pointer" className="flex flex-col px-3" onClick={viewDetails}>
          <Box className="w-full flex justify-end" mt="-25px"><Avatar w={"58px"} h={"58px"} className="border borde-white border-[2px]"  name={investor?.company?.title} src={investor.profile_image} /></Box>
          <Box w="80%" >
            <Flex w="90%" className="relative mt-[-25px] space-between" justifyContent={"space-between"} overflow="hidden" >
              <Text className="font-semibold"  textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" fontSize="1rem">
                {`${investor.company.title}`}
              </Text>
            </Flex>
          </Box>
        </Box>
        <Flex className="flex-col p-3 gap-2" w="100%" >
          <Box maxW="100%" py={1}>
            <Text className="preview_message text-xs" color="#000" textOverflow="ellipsis">
              {investor.company.description}
            </Text>
          </Box>

          <Box>
            <Box className="flex gap-2 flex-wrap mb-1 pb-4 border-b border-[#E9E9E9] min-h-[75px] items-start">
              {/* {investor?.company?.company_stage?.map(({title}) => <Box className="bg-[#FFE3AC] border border-1.5 text-[9px] font-medium border-[#EC981C] p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">{title}</Box> )} */}
              {investor?.company?.company_stage?.length > 0 ? <Box className="bg-[#FFE3AC] border border-1.5 text-[9px] font-medium border-[#EC981C] p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">{investor?.company?.company_stage[0].title}</Box> : null}
              {investor.company.fund_category?.title ? <Box className="bg-[#DDFBE7] border border-1.5 text-[9px] font-medium border-[#8BDCAD] p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">
                {investor.company.fund_category?.title}
              </Box> : null}
              {/* {investor?.company?.incorporation_location?.map(({name}) => <Box className="bg-[#DBE2ED] border border-1.5 text-[9px] font-medium border-gray-200 p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">{name}</Box> )} */}
              {investor?.company?.incorporation_location?.length > 0 ? <Box className="bg-[#DBE2ED] border border-1.5 text-[9px] font-medium border-gray-200 p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">{investor?.company?.incorporation_location[0]?.name}</Box> : null }
            </Box>
          </Box>
          <Box display={"none"} className="flex hidden mb-2 gap-2.5">
            <Box className="flex flex-col gap-1 min-w-[65px] border-r pr-3 border-[#E9E9E9] ">
              <Text className="text-[10px] text-[#C0BCBC]">Valuation</Text>
              <Text className="text-lg font-medium">-</Text>
            </Box>
            <Box className="flex flex-col gap-1 min-w-[65px]  border-r pr-3 border-[#E9E9E9] ">
              <Text className="text-[10px] text-[#C0BCBC]">Target</Text>
              <Text className="text-lg font-medium">{
                investor.company.fund_category?.title ? investor.company.fund_category?.title?.includes('-') ? investor.company.fund_category?.title?.split('-')[1] : investor.company.fund_category?.title : '-'
              }</Text>
            </Box>
            <Box className="flex flex-col  min-w-[65px] gap-1 ">
              <Text className="text-[10px] text-[#C0BCBC]">Investors</Text>
              <Text className="text-lg font-medium">-</Text>
            </Box>
          </Box>
          <Flex className="justify-center">
            <ConnectionButton user={investor}/>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
export default NewSingleInvestor;
