import { HStack } from "@chakra-ui/react"
import CountCard from "../../../components/countCard/CountCard";


const UserCountSection = ({totalUsers = 0, totalDeleted = 0, totalRequested = 0}) => {
    return (
        <HStack justifyContent="flex-start" alignItems="center" mb={4} spacing={4}>
            <CountCard text="Total Users" count={totalUsers} />
            <CountCard text="Total Deleted" count={totalDeleted} />
            <CountCard text="Total Requested" count={totalRequested} />
        </HStack>
    )
}
export default UserCountSection;