import { Box, Flex } from "@chakra-ui/react";
import IsDesktop from "../../../utils/IsDesktop";
import BookingsDashboard from "./BookingsList";

const MyBookings = () => {
    const isDesktop = IsDesktop()
    return (
        <Box className="w-full">
            <BookingsDashboard />
        </Box>
    );
}
 
export default MyBookings;