// import { axiosClient } from "libs";
// import { client } from "../client";
import axiosClient from '../client';
import UseLogoutTimer from "../../utils/UseLogoutTimer"

const fetchData = ({ queryKey, headers }) => {
	const {resetLogoutTimer} = UseLogoutTimer()
	return axiosClient()
	  .get(`/${queryKey}`, {headers})
	  .then((res) => {
		resetLogoutTimer()
		return res.data;
	  }).catch(err => {
		console.error(err)
		if(err?.response?.status == 401){
			window.location.href = "/login"
		}else{
			throw new Error(err)
		}
	  });
  };
export default fetchData;