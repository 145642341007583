import { Avatar } from "@chakra-ui/avatar";
import { Box, Heading, HStack, Text, VStack, Divider, Flex } from "@chakra-ui/layout";
import React from "react";
import ConnectionButton from "../connectionButton/ConnectionButton";
import { Image, Tooltip, useDisclosure } from "@chakra-ui/react";
import default_cover_photo from "../../public/icons/default_cover_photo.png";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsFillHandThumbsUpFill, BsFillHandThumbsDownFill, BsXCircle } from "react-icons/bs";
import { setUserInfo } from "../../redux-toolkit/reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import client from "../../libs/client";
import { BASE_URL } from "../../helpers/constant";
import InvestorBanner from "../../public/images/investor_dashboard.jpg"
import FounderBanner from "../../public/images/founder_dashboard.png"
import { shortenNumber } from "../../utils/shortenNumber";
import { FaShare, FaShareAlt } from "react-icons/fa";
import ShareAccountModal from "../modal/ShareAccountModal";

const NewSingleFounder = ({ founder, onToggle, setSelectedFounder, onDislikeCompany = () => {}, onCompleteAction = () => {} }) => {
  const navigate = useNavigate()
  const viewDetails = () => {
    navigate(`/view-user?userid=${founder?.id}&usertype=${founder?.userTypeId}&cid=${founder?.company?.id}`) 
    onToggle();
  };
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state?.user?.userInfo);
  const loggedInUserType = userDetails?.userTypeTitle || "";
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure()

    
  const likeCompany = async(e, companyId) => {
      try {
          e.stopPropagation()
          //let _founders = founders.filter(user => user.company.id !== companyId) 
          //updateState(_founders, false, false)
    
          if(userDetails?.likedCompanies?.includes(companyId)){
            return client()
            .delete(
              `${BASE_URL}remove-preference/${companyId}`
            )
            .then((res) => {
              
              dispatch(setUserInfo({
                ...userDetails,
                likedCompanies: userDetails?.likedCompanies?.filter((id) => id !== companyId)
              }))
            });
          }else{
            return client()
            .put(
              `${BASE_URL}like-company/${companyId}`
            )
            .then((res) => {
              
              dispatch(setUserInfo({
                ...userDetails,
                likedCompanies: [...userDetails.likedCompanies, companyId],
                disLikedCompanies: userDetails?.disLikedCompanies?.filter((id) => id !== companyId)
              }))
            });
          }
          
        } catch (e) {
          //console.log("the error====", e)
        }
    }
    
    const disLikeCompany = async(e, companyId) => {
        try {
          e.stopPropagation()
     
          if(userDetails?.disLikedCompanies?.includes(companyId)){
            return client()
            .delete(
              `${BASE_URL}remove-preference/${companyId}`
            )
            .then((res) => {
              
              dispatch(setUserInfo({
                ...userDetails,
                disLikedCompanies: userDetails?.disLikedCompanies?.filter((id) => id !== companyId)
              }))
            });
          }else{
            return client()
            .put(
              `${BASE_URL}dislike-company/${companyId}`
            )
            .then((res) => {
              
              dispatch(setUserInfo({
                ...userDetails,
                likedCompanies: userDetails?.likedCompanies?.filter((id) => id !== companyId),
                disLikedCompanies: [...userDetails.disLikedCompanies, companyId],
              }))

              onDislikeCompany(companyId)

            });
          }
          
        } catch (e) {
          //console.log("the error====", e)
        }
    }

  return (
    <Box style={{flex: '1 1 300px'}} className="w-[100%] max-w-[300px] sm:w-[45%] sm:max-w-[350px] xl:w-[43%] xl:max-w-[390px] 2xl:max-w-[300px] px-3 pt-3 pb-1 relative border border-[#DBE2ED] rounded-lg">
      {
        loggedInUserType === "investor" && !founder?.company?.isDisliked ? (
            <Flex className="flex-row justify-end absolute right-3 z-[1] my-2 w-full">
              <Tooltip label="remove from list">
                <span>
                  <BsXCircle className="cursor-pointer bg-white p-1 rounded-full" onClick={(e) => {disLikeCompany(e, founder?.company?.id)}} fill={userDetails?.disLikedCompanies?.includes(founder?.company?.id) ? 'red' : 'grey'} size={"26px"} />
                </span>
              </Tooltip>
            </Flex>
        ) : null
      }
      <ShareAccountModal 
        isOpen={isOpen}
        onClose={onClose}
        userId={founder.id}
        companyId={founder.company.id}
      />
      <Flex className="flex-col">
        <Box className="rounded-md" w="100%" h="120px"  bg="lightgray" position="relative">
          {founder?.cover_image && (
            <Box className="rounded-md" position="absolute" top="0" w="100%" h="120px" overflow="hidden">
              <Image
                src={founder?.cover_image}
                //   src="https://images.unsplash.com/photo-1579353977828-2a4eab540b9a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2FtcGxlfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
                objectFit="cover"
                position="absolute"
                top="0"
              />
            </Box>
          )}
          {!founder?.cover_image && (
            <Box className="rounded-md" h="120px" w="100%" overflow="hidden">
            <Image
              src={FounderBanner}
              objectFit="cover"
              h="120px"
              className="object-left"
            />
          </Box>
          )}
        </Box>
        <Box cursor="pointer" className="flex flex-col px-3" onClick={viewDetails}>
          <Box className="w-full flex justify-end" mt="-25px"><Avatar w={"58px"} h={"58px"} className="border borde-white border-[2px]"  name={founder?.company?.title} src={founder.profile_image} /></Box>
          <Box w="80%" >
            <Flex w="90%" className="relative mt-[-25px] space-between" justifyContent={"space-between"} overflow="hidden" >
              <Text className="font-semibold"  textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" fontSize="1rem">
                {`${founder.company.title}`}
              </Text>
            </Flex>
          </Box>
        </Box>
        <Flex className="flex-col p-3 gap-2" w="100%" >
          <Box maxW="100%" py={1}>
            <Text className="preview_message text-xs" color="#000" textOverflow="ellipsis">
              {founder.company.description}
            </Text>
          </Box>

          <Box>
            <Box className="flex gap-2 flex-wrap mb-1 pb-4 border-b border-[#E9E9E9] min-h-[75px] items-start">
              {/* {founder?.company?.company_stage?.map(({title}) => <Box className="bg-[#FFE3AC] border border-1.5 text-[9px] font-medium border-[#EC981C] p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">{title}</Box> )} */}
              {founder?.company?.company_stage?.length > 0 ? <Box className="bg-[#FFE3AC] border border-1.5 text-[9px] font-medium border-[#EC981C] p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">{founder?.company?.company_stage[0].title}</Box> : null}
              {founder.company.fund_category?.title ? <Box className="bg-[#DDFBE7] border border-1.5 text-[9px] font-medium border-[#8BDCAD] p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">
                {founder.company.fund_category?.title}
              </Box> : null}
              {/* {founder?.company?.incorporation_location?.map(({name}) => <Box className="bg-[#DBE2ED] border border-1.5 text-[9px] font-medium border-gray-200 p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">{name}</Box> )} */}
              {founder?.company?.incorporation_location?.length > 0 ? <Box className="bg-[#DBE2ED] border border-1.5 text-[9px] font-medium border-gray-200 p-1 whitespace-nowrap px-2 min-w-[60px] rounded-full text-center">{founder?.company?.incorporation_location[0]?.name}</Box> : null }
            </Box>
          </Box>
          <Box className="flex mb-2 gap-2.5">
            <Box className="flex flex-col gap-1 min-w-[65px] border-r pr-3 border-[#E9E9E9] ">
              <Text className="text-[10px] text-[#C0BCBC]">Valuation</Text>
              <Text className="text-lg font-medium">{founder.company?.valuation ? `$${shortenNumber(founder.company?.valuation)}` : '-'}</Text>
            </Box>
            <Box className="flex flex-col gap-1 min-w-[65px]  border-r pr-3 border-[#E9E9E9] ">
              <Text className="text-[10px] text-[#C0BCBC]">Target</Text>
              <Text className="text-lg font-medium">{ founder.company?.funding_target ? `$${shortenNumber(founder.company?.funding_target)}` : '-'}</Text>
            </Box>
            <Box className="flex flex-col  min-w-[65px] gap-1 ">
              <Text className="text-[10px] text-[#C0BCBC]">Investors</Text>
              <Text className="text-lg font-medium">{founder.company?.number_of_investors || '-'}</Text>
            </Box>
          </Box>
          <Flex className="justify-between w-full gap-2 items-center">
            <ConnectionButton onCompleteAction={onCompleteAction} user={founder}/>
            {
              loggedInUserType === "investor" ? (
                <Box onClick={onOpen} className="w-min text-[#134A70] h-full cursor-pointer border px-2 py-3 rounded-lg">
                  <FaShareAlt />
                </Box>
              ) : null 
            }
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
export default NewSingleFounder;
