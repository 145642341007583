import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import IsDesktop from "../../../utils/IsDesktop";

const SubscribersCountCard = ({data}) => {
    const isDesktop = IsDesktop();
    // data = [
    //     {title: 'premium pro', count: '18879273'},
    //     {title: 'basic', count: '3455'},
    //     {title: 'platinum', count: '15622'}]

    return (
        <>
            <Box maxW={isDesktop ? "50%" : "100%"} h="150px" p={5} boxShadow="0px 1px 8px lightgray" borderRadius="10px" overflow="hidden">
                <Text fontWeight={700} fontSize="18px" color="brand.primary">
                    No. of Subscribers
                </Text>
                <Flex h="100%" justifyContent="space-between" alignItems="center" overflowX="auto" >
                    {data?.length > 0 ? (
                        data?.map((eachType)=> (
                        <>
                        <Flex key={eachType.title} flexDir="column" mx={2}>
                            <Heading textTransform='capitalize' fontWeight={700} color="brand.primary">
                                {eachType?.count}
                            </Heading>
                            <Text textTransform='capitalize' fontWeight={500} fontSize="14px" color="lightgray">
                                {eachType?.title}
                            </Text>

                        </Flex>
                        {data[data.length-1].title !== eachType.title ? (<Divider orientation="vertical" h="70px" w="2px" bg="lightgray"/>):(<></>)}
                        </>

                            
                            ))    
                            ) : <Text className="text-lg font-semibold">0</Text>}
                </Flex>
            </Box>
        </>
    )
}
export default SubscribersCountCard;