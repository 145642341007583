const ConvertVideoLinkToEmbedLink = (link) => {
  if (!link){
    return
  }else if (link.includes("youtube.com/watch")){
    return link.replace("watch?v=", "embed/");
  } else if (link.includes("youtu.be")){
    return link.replace("youtu.be", "www.youtube.com/embed");
  } else {
    return link;
  }
}

export default ConvertVideoLinkToEmbedLink;