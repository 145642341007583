import { 
    Table, 
    Avatar, 
    Box, 
    Flex, 
    Tbody, 
    Td, 
    Text,
    Th, 
    Thead, 
    Tr, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Textarea,
    Stack,
    useDisclosure,
    TableContainer
} from '@chakra-ui/react';
import moment from 'moment';
import BrandButton from '../brandButton/BrandButton';
import { useNavigate } from 'react-router-dom';
import IsDesktop from '../../utils/IsDesktop';
import Spinner from '../spinner/Spinner';
import { FaRegCheckCircle,  FaRegTimesCircle, FaTrash, FaTrashAlt } from "react-icons/fa";
import { mutateFunction, useMutation } from '../../libs/apis';
import { CustomToast } from '../toast/CustomToast';
import { useState } from 'react';
import { useSelector } from 'react-redux';


const FoundersBookingListAlt = ({ listType = "upcoming", header = [], data = [], isLoading, refetch }) => {
    const {mutate, isLoading : mutateIsLoading} = useMutation(mutateFunction)
    const navigate = useNavigate()
    const { addToast }= CustomToast()
    
    const deleteBooking  = (id) => {
        mutate(
            { key: `users/bookings/remove-booking/${id}`, method: "delete", data: {} },
            {
              onSuccess(res) {
                addToast({message: 'Booking Removed'})
                refetch()
              },
            }
        );
    }

    return (
        <Box overflowY='auto' className='rounded-lg my-4 w-full'>
            <TableContainer>
                <Table w={"full"} variant={'unstyled'} whiteSpace='nowrap'>
                    <Thead  bg={"#F1F9FF"}  >
                        <Tr
                        borderBottom="1px solid #197FC6"
                        >
                            {header?.map((column, index) => (
                                <Th style={{padding: "20px 30px", textTransform: "none"}} fontSize="16px" key={column.key} color='#2F4B6E' fontWeight={500}>{column.title}</Th>
                            ))}
                        </Tr>
                    </Thead>

                    <Tbody bg={"#fff"} borderRadius='10px' h="100%" overflowY='auto' mx={"20px"} p={"20px"}>
                            {(data.length === 0 && isLoading) && <Spinner mt={2}/>}

                            {data.sort((a, b)=>
                                moment(new Date(a.slot_start_date)).isBefore(moment(new Date(b.slot_start_date))) ? 1 : -1).map((booking) => {
                                    return(
                                <Tr
                                    key={booking.id}
                                    maxH="60px"
                                    p={2}
                                    w="100%"
                                    mx={"10px"}
                                    className="border cursor-pointer"
                                    onClick={() => {
                                        navigate(`/view-user?userid=${booking?.expert_id}&usertype=3`)
                                    }}
                                >
                                    <Td style={{padding: "10px 30px"}} className='lg:w-[40%] lg:max-w-[600px]'>
                                        <Flex alignItems='center' className='gap-1'>
                                            <Avatar className='cursor-pointer' size='xs' src={booking?.expert_slot?.expert?.profile_image || ''} name={booking?.expert_slot?.expert?.first_name} mr={2} />
                                            <Text className='text-[#2F4B6E] font-medium text-sm text-ellipsis overflow-hidden max-w-[20ch]'>{`${booking?.expert_slot?.expert?.first_name} ${booking?.expert_slot?.expert?.last_name}`}</Text>
                                            <Text className='text-[#000] py-2 font-medium text-sm text-ellipsis overflow-hidden max-w-[20ch]'>{booking?.expert_slot?.slot_title}</Text>
                                        </Flex>
                                    </Td>
                                    <Td className='text-[#2F4B6E] font-medium text-sm' style={{padding: "10px 30px"}}>
                                        <Text>{moment(booking?.expert_slot?.slot_start_date).format('DD-MM-YYYY')}</Text>
                                    </Td>
                                    <Td className='text-[#2F4B6E] font-medium text-sm' style={{padding: "10px 30px"}}>
                                        <Text>{moment(booking?.expert_slot?.slot_start_time, "hh:mm:ss").format("hh:mm")}</Text>
                                    </Td>
                                    <Td style={{padding: "10px 30px"}} cursor='pointer'>
                                        <Flex className='gap-3 pl-3 items-center'>
                                            {
                                                booking?.status === 'accepted' ? (
                                                    <Box className={`${
                                                        booking?.status == 'accepted' ? 'text-[#0C4220] bg-[#8BDCAD]' : booking?.status == 'rejected' ? 'text-[#CF1137] bg-[#FF97AC]' : 'text-[#EC981C] bg-[#FFE3AC]'
                                                    } p-2 rounded-lg capitalize`}>
                                                        <Text className={`capitalize text-xs font-medium`}>{booking?.status || 'pending'}</Text>
                                                    </Box>
                                                ) : <FaTrashAlt onClick={(e) => {
                                                    e.stopPropagation()
                                                    deleteBooking(booking.id)
                                                }} color='red' />
                                            }
                                            
                                        </Flex>
                                    </Td>
                                </Tr>
                        )})}
                    </Tbody>
                </Table>
            </TableContainer>
            {(data.length === 0 && !isLoading)? (
                <Text onClick={() => {navigate('?slot=open')}} className='text-center w-full px-2 pt-10 text-gray-700 font-medium hover:font-semibold hover:underline cursor-pointer'>
                    You currently do not have any bookings! Click to add slot
                </Text>
            ) : null}
        </Box>
    );
}
 
export default FoundersBookingListAlt;