import React from 'react'
import { Box, Image, Text, Button, Flex} from "@chakra-ui/react"
import IsDesktop from '../../utils/IsDesktop'


export default function BookCall({image, title , description,}) {
  const isDesktop = IsDesktop();
  return (
    //<Box>
      <Box pb={10} width={isDesktop ? 371 : "100%"} height={isDesktop ? "485px" : "auto"} boxShadow="0px .5px 5px lightgray" borderRadius="16px" mr={2}>
        <Image src={image} color="#134A70" width={isDesktop ? "410.26px" : "100%"} height="auto"borderTopRadius="10px"/>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="700px" fontSize="20px" lineHeight="150%"  color="#000000" textAlign="center" mt="30px" pl="10px">{title}</Text>
        <Text fontFamily='Poppins' fontStyle="normal" fontWeight="400px" fontSize="14px" lineHeight="150%"  color="#9FA7AD" mt="20px" pl="10px">{description}</Text>
      </Box>
    //</Box>
  )
}