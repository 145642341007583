import { 
    Table, 
    Avatar, 
    Box, 
    Flex, 
    Tbody, 
    Td, 
    Text,
    Th, 
    Thead, 
    Tr, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Textarea,
    Stack,
    useDisclosure,
    Select
} from '@chakra-ui/react';
import { IoIosArrowDown } from "react-icons/io";
import moment from 'moment';
import BrandButton from '../brandButton/BrandButton';
import { useNavigate } from 'react-router-dom';
import IsDesktop from '../../utils/IsDesktop';
import Spinner from '../spinner/Spinner';
import { FaArrowDown, FaRegCheckCircle,  FaRegTimesCircle } from "react-icons/fa";
import { mutateFunction, useMutation } from '../../libs/apis';
import { CustomToast } from '../toast/CustomToast';
import { useState } from 'react';


const ExpertTable = ({ listType = "upcoming", header = [], data = [], isLoading, refetch }) => {
    const {mutate, isLoading : mutateIsLoading} = useMutation(mutateFunction)
    const {mutate: rejectMutate, isLoading : rejectMutateIsLoading} = useMutation(mutateFunction)
    const isDesktop = IsDesktop();
    const navigate = useNavigate()
    const { addToast }= CustomToast()
    const [reason, setReason] = useState(null)
    const [value, setValue] = useState({})
    const [currentBookingId, setCurrentBookingId] = useState('')
    const {isOpen, onClose, onOpen} = useDisclosure()
    const acceptBooking  = (id) => {
        mutate(
            { key: `experts/accept-booking/${id}`, method: "post", data: {} },
            {
              onSuccess(res) {
                setCurrentBookingId('')
                addToast({message: 'Booking accepted'})
                refetch()
              },
            }
          );
    }


    const formSubmit = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        rejectMutate(
            { key: `experts/reject-booking/${id}`, method: "post", data: {reason} },
            {
              onSuccess(res) {
                addToast({message: 'Success'})
                refetch()
                setReason(null)
                onClose()
              },
            }
        );
    }

    const onChangeStatus = (e, id) => {
        setCurrentBookingId(id)
        setValue(prev => ({
            ...prev,
            [id]: e.target.value
        }))
        if(e.target.value === "accepted"){
            acceptBooking(id)
        } else if(e.target.value == "rejected"){
            onOpen()
        }
    }

    const onCloseModal = () => {
        setCurrentBookingId('')
        setReason('')
        onClose()
    }
    

    return (
        <Box mt={6} overflowY='auto' borderRadius='10px 10px 10px 10px'>
            <>
            <Modal isOpen={isOpen} onClose={onCloseModal} >
                                                            <ModalOverlay />
                                                            <ModalContent>
                                                            <ModalBody p={4}>
                                                                <Flex flexDirection="column">
                                                                <Stack spacing={6}>
                                                                    <Flex className="items-center gap-2" justify={"center"}>
                                                                    <Text
                                                                        fontSize={20}
                                                                        fontWeight={700}
                                                                        color="brand.primary"
                                                                        align="center"
                                                                    >
                                                                        Reason
                                                                    </Text>
                                                                    </Flex>

                                                                    <Text fontSize={14} color="#000000">
                                                                        Add the reason for a rejection (Optional)
                                                                    </Text>
                                                                </Stack>
                                                                <Box
                                                                    borderWidth="1px"
                                                                    borderColor="brand.primary"
                                                                    borderRadius="5px"
                                                                    alignItems="center"
                                                                    mt={"20px"}
                                                                >
                                                                    <form className='w-full' onSubmit={(e) => {formSubmit(e, currentBookingId)}} >
                                                                        <Textarea
                                                                            focusBorderColor="#"
                                                                            borderWidth="0px"
                                                                            variant="outline"
                                                                            name="reason"
                                                                            value={reason}
                                                                            onChange={(e) => setReason(e.target.value)}
                                                                            placeholder="Type in a brief reason...."
                                                                            w={"80vw"}
                                                                            maxWidth={"350px"}
                                                                            maxLength={150}
                                                                            rows={6}
                                                                        />
                                                                    </form>
                                                                </Box>
                                                                <Box className="mt-6 flex gap-8 w-full items-center justify-center">
                                                                    <BrandButton
                                                                        isLoading={rejectMutateIsLoading}
                                                                        text={`Submit`}
                                                                        onClick={(e) => {formSubmit(e, currentBookingId)}} 
                                                                        variant="md"
                                                                        bgColor="brand.primary"
                                                                        color="#fff"
                                                                        fontSize="14px"
                                                                        borderRadius={4}
                                                                        px={4}
                                                                        _hover={{ background: "brand.primary" }}
                                                                    />
                                                                    <BrandButton
                                                                        text="Close"
                                                                        onClick={(e) => {formSubmit(e, currentBookingId)}} 
                                                                    />
                                                                </Box>
                                                                </Flex>
                                                            </ModalBody>
                                                            </ModalContent>
                                                        </Modal>
            </>
            <Table w={"full"} variant={'unstyled'} whiteSpace='nowrap'>
                    <Thead  bg={"#F1F9FF"}  >
                        <Tr
                        borderBottom="1px solid #197FC6"
                        >
                            {header?.map((column, index) => (
                                <Th style={{padding: "20px 30px", textTransform: "none"}} fontSize="16px" key={column.key} color='#2F4B6E' fontWeight={500}>{column.title}</Th>
                            ))}
                        </Tr>
                    </Thead>

                    <Tbody bg={"#fff"} borderRadius='10px' h="100%" overflowY='auto' mx={"20px"} p={"20px"}>

                        {(data.length === 0 && isLoading) && <Spinner mt={2}/>}

                            {data.sort((a, b)=>
                                moment(new Date(a.slot_start_date)).isBefore(moment(new Date(b.slot_start_date))) ? 1 : -1).map((booking) => {
                                    return(
                                <Tr
                                    key={booking.id}
                                    maxH="60px"
                                    p={2}
                                    w="100%"
                                    mx={"10px"}
                                    className="border rounded-lg"
                                >
                                    <Td style={{padding: "10px 30px"}} className='lg:w-[40%] lg:max-w-[600px]'>
                                        <Flex alignItems='center' className='gap-1'>
                                            <Avatar className='cursor-pointer' onClick={() => {
                                                navigate(`/view-user?userid=${booking?.founder_id}&usertype=3`)
                                            }} size='xs' src={booking?.profile_image || ''} name={booking?.first_name} mr={2} />
                                            <Text className='text-[#2F4B6E] font-medium text-sm text-ellipsis overflow-hidden max-w-[20ch]'>{`${booking?.first_name} ${booking?.last_name}`}</Text>
                                            <Text className='text-[#000] font-medium text-sm text-ellipsis overflow-hidden max-w-[20ch]'>{booking?.slot_title}</Text>
                                        </Flex>
                                    </Td>
                                    <Td className='text-[#2F4B6E] font-medium text-sm' style={{padding: "10px 30px"}}>
                                        <Text>{moment(booking?.slot_start_date).format('DD-MM-YYYY')}</Text>
                                    </Td>
                                    <Td className='text-[#2F4B6E] font-medium text-sm' style={{padding: "10px 30px"}}>
                                        <Text>{moment(booking?.slot_start_time, "hh:mm:ss").format("hh:mm a")}</Text>
                                    </Td>
                                    <Td cursor='pointer'>
                                    <Flex className='gap-3 items-center justify-center'>
                                        {
                                            mutateIsLoading && currentBookingId == booking?.booking_id ? (
                                                <Spinner size="sm" />
                                            ) : booking?.status == 'pending' && listType == "upcoming" ? (
                                                <Select borderColor={"#134A70"} icon={<IoIosArrowDown fill='#134A70' stroke='#134A70' />} value={value[booking?.booking_id]} onChange={(e) => {onChangeStatus(e, booking?.booking_id)}} defaultValue="pending" placeholder='Status'>
                                                    <option value='accepted'>Accepted</option>
                                                    <option value='rejected'>Rejected</option>
                                                </Select>
                                            ) : <Box className={`${
                                                booking?.status == 'accepted' ? 'text-[#0C4220] bg-[#8BDCAD]' : booking?.status == 'rejected' ? 'text-[#CF1137] bg-[#FF97AC]' : 'text-[#EC981C] bg-[#FFE3AC]'
                                            } p-2 rounded-lg capitalize`}>
                                                <Text className={`capitalize text-xs font-medium`}>{booking?.status || 'pending'}</Text>
                                            </Box>
                                        }
                                    </Flex>
                                </Td>
                                </Tr>
                        )})}
                </Tbody>
            </Table>
            {(data.length === 0 && !isLoading)? (
                <Text onClick={() => {navigate('/dashboard?slot=open')}} className='text-center w-full px-2 pt-10 text-gray-700 font-medium hover:font-semibold hover:underline cursor-pointer'>
                    You currently do not have any bookings! Click to add slot
                </Text>
            ) : null}
        </Box>
    );
}
 
export default ExpertTable;