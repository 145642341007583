import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Avatar,
  Text,
  Flex,
  Button,
  Spinner,
  VStack,
  HStack,
  useDisclosure,
  Thead,
  Th,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useData } from "../../../data";
import moment from "moment";
import SearchBar from "../../../components/searchbar/SearchBar";
import Header from "../../../components/header/Header";
import OffersCountSection from "./OffersCountSection";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiPause, FiPlay } from "react-icons/fi";
import { mutateFunction, useMutation } from "../../../libs/apis";
import { CustomToast } from "../../../components/toast/CustomToast";
import ViewOfferDetails from "./ViewBrandDetails";
import BrandButton from "../../../components/brandButton/BrandButton";
import AddBrand from "./AddBrand";

const Brands = () => {
  const { data, isLoading, refetch } = useData(
    "brands?status=&page_no=1&page_size=20"
  );
  const [displayedListStatus, setDisplayedListStatus] = useState("all");
  const [displayedList, setDisplayedList] = useState([]);
  const { isOpen: offerDetailsIsOpen, onToggle: onToggleOfferDetails } =
    useDisclosure();
  const { isOpen: addOfferIsOpen, onToggle: onToggleAddNewBrand } =
    useDisclosure();
  const [selectedOffer, setSelectedOffer] = useState(0);
  const changeUserView = (offerStatus) => {
    setDisplayedListStatus(offerStatus);
  };
  const { mutate } = useMutation(mutateFunction);
  const { addToast } = CustomToast();

  const addNewBrand = () => {
    onToggleAddNewBrand();
  };

  const sortList = (offerActiveStatus) => {
    if (offerActiveStatus === "all") {
      return data?.data?.offers;
    }
    return data?.data?.offers?.filter(
      (offer) => offer.is_active === offerActiveStatus
    );
  };

  const changeStatus = (offerId) => {
    mutate(
      { key: `offers/${offerId}`, method: "patch" },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
        },
      }
    );
  };
  const deleteOffer = (offerId) => {
    mutate(
      { key: `brandDelete/${offerId}`, method: "delete" },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
        },
      }
    );
  };
  const viewOffer = (offer) => {
    setSelectedOffer(offer);
    onToggleOfferDetails();
  };

  useEffect(() => {
    if (data?.data) {
      setDisplayedList(sortList(displayedListStatus));
    }
  }, [data?.data, displayedListStatus]);

  return (
    <Box>
      <Header title="Brands" />

      {offerDetailsIsOpen && (
        <ViewOfferDetails
          refetch={refetch}
          brand={selectedOffer?.brand}
          offerId={selectedOffer?.id}
          onToggle={onToggleOfferDetails}
          changeStatus={changeStatus}
          deleteOffer={deleteOffer}
        />
      )}
     
      <Box display={"flex"} justifyContent={"flex-end"} paddingTop={10} paddingRight={10}>
        <BrandButton text="Add New" w="120px" onClick={addNewBrand} />
      </Box>

      {!isLoading ? (
        <>
          {offerDetailsIsOpen && (
            <ViewOfferDetails
              refetch={refetch}
              brand={selectedOffer?.brand}
              offerId={selectedOffer?.id}
              onToggle={onToggleOfferDetails}
              changeStatus={changeStatus}
              deleteOffer={deleteOffer}
            />
          )}
          {addOfferIsOpen && (
            <AddBrand onToggle={onToggleAddNewBrand} refetch={refetch} />
          )}
          {!offerDetailsIsOpen && !addOfferIsOpen && (
            <>
              {/* --------------------table */}
              <TableContainer mt="50px" borderRadius="10px">
                <Table variant="simple" size="sm" fontSize="12px">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Logo</Th>
                      <Th>Description</Th>
                      <Th>Created At</Th>
                      <Th>Action</Th>
                      {/* <Th>Ellipses</Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.data?.brands?.map((items) => {
                      return (
                        <Tr
                          key={items.id}
                          style={{ padding: "2rem 2rem", height: "80px" }}
                          boxShadow="md"
                          borderRadius="10px"
                          _hover={{
                            borderWidth: "2px",
                            borderColor: "brand.primary",
                            borderRadius: "10px",
                          }}
                        >
                          <Td>
                            <Flex alignItems="center">
                              {/* <Avatar
                                name={`${items?.name}`}
                                src={items?.logo}
                                alt=""
                                size="sm"
                                mr={2}
                              /> */}
                              <Text>{`${items?.name}`}</Text>
                            </Flex>
                          </Td>

                          <Td>
                            <Flex alignItems="center">
                              <Avatar
                                name={`${items?.name}`}
                                src={items?.logo}
                                alt=""
                                size="sm"
                                mr={2}
                              />
                              {/* <Text>{`${items?.name}`}</Text> */}
                            </Flex>
                          </Td>

                          <Td>
                            <VStack alignItems={"start"}>
                              <Text
                                fontSize="16px"
                                fontWeight={600}
                                color="brand.primary"
                                className="text-ellipsis max-w-[20ch] overflow-hidden"
                              >
                                {items?.description}
                              </Text>
                              {/* <Text fontSize="12px" color="gray">
                            Description
                          </Text> */}
                            </VStack>
                          </Td>

                          <Td cursor="pointer" onClick={() => viewOffer(items)}>
                            <VStack
                              justifyContent="center"
                              alignItems="flex-start"
                            >
                             
                              <Text fontSize="12px" color="gray">
                                Added on{" "}
                                {moment(items?.createdAt).format(`MMM D, YYYY`)}
                              </Text>
                            </VStack>
                          </Td>
                          <Td>
                            <HStack>
                              <Box
                                cursor="pointer"
                                onClick={() => deleteOffer(items.id)}
                                border="1px"
                                borderColor="red"
                                borderRadius="5px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                w="40px"
                                h="40px"
                              >
                                <RiDeleteBin6Line color="red" />
                              </Box>
                            </HStack>
                          </Td>
                          {/* <Td>
                            <BiDotsVerticalRounded />
                          </Td> */}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      ) : (
        <>
          <Spinner m={5} />
        </>
      )}
      {/* --------------------table */}
    </Box>
  );
};

export default Brands;
