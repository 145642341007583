import React, { useEffect, useState } from "react";
import { Table, Avatar, Box, Flex, Tbody, Td, Text, Th, Thead, Tr, Icon, Spinner, Button, HStack, Heading } from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import IsDesktop from "../../../utils/IsDesktop";

const RecentActivitiesTable = ({ data = [], user={} }) => {
    const isDesktop = IsDesktop();


    return (
        <Box mt={2} overflowY='auto' borderRadius='10px 10px 10px 10px' h="100%">
            
            <Table whiteSpace='nowrap'>
                <Tbody borderRadius='10px'  overflow={isDesktop ? 'auto' : 'hidden'} p={2} fontSize={isDesktop ? "14px": "12px"}>
                        {data.length === 0 && <Spinner mt={2}/>}

                        {data.map((activity) => (
                            <Tr key={activity.id} boxShadow='10px 10px 20px rgba(54, 171, 190, 0.05)' borderRadius='10px' bg='white.300' m={2}>
                                <Td>
                                    <Flex alignItems='center'>
                                        <Avatar size='xs' src={user?.profile_image} name={user?.first_name} mr={2} />
                                        <Text textTransform='capitalize'>{`${user?.first_name} ${user?.last_name}`}</Text>
                                    </Flex>
                                </Td>
                                <Td>
                                    <Text textTransform='capitalize'>{activity?.activity_title}</Text>
                                </Td>
                                <Td>
                                    <Flex alignItems='center'>
                                        <Avatar size='xs' src={activity?.inteactingObject?.image} name={activity?.inteactingObject?.title} mr={2} />
                                        <Text textTransform='capitalize'>{activity?.interactingObject?.title}</Text>
                                    </Flex>
                                </Td>
                                {isDesktop && (<Td>
                                    <Text textTransform='capitalize'>{activity?.date || "N/A"}</Text>
                                </Td>)}
                                {/* <Td cursor='pointer'>
                                    <Icon as={BsThreeDotsVertical} />
                                </Td> */}
                            </Tr>
                        ))}
                </Tbody>
            </Table>
        </Box>
    );
}
 
export default RecentActivitiesTable;