import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { CustomToast } from "../toast/CustomToast";
import {
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  Button,
  Textarea,
  Box,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { Avatar } from "antd";
import { mutateFunction } from "../../libs/apis";
import { useSelector } from "react-redux";
import { UserContext } from "../../context/UserContext";

const CreatePost = ({
    onClose = () => {},
    showCloseButton = true,
    refetchPost = () => {}
}) => {
    const { mutate, isLoading } = useMutation(mutateFunction);
    const { addToast } = CustomToast();
    const [postMessage, setPostMessage] = useState("");
    const {userInformation} = useContext(UserContext);
    const formSubmit = async (e) => {
        e.preventDefault()
        if(!postMessage.trim()) {
            addToast({message: 'Please add a comment'}, "warning")
            return 
        }

        mutate(
          { key: "post", method: "post", data : {
            text: `${postMessage.trim()}`
          }},
          {
            onSuccess(res) {
              addToast(res);
              onClose()
              refetchPost()
            },
            onError(e){
              addToast({message: 'Post was not created'}, "error")
            }
          }
        );
    };

    return (
        <Flex flexDirection="column">
              <Box mt={"20px"} className="flex items-start gap-2">
              <Box>
                        <Avatar 
                            src={userInformation.profile_image}
                            name={`${userInformation.firstName} ${userInformation.lastName}`}
                            size={"sm"}
                        />
                    </Box>
              <Flex
                borderBottomWidth="1px"
                borderColor="brand.primary"
                alignItems="center"
                className="w-full"
              >
                <form className="w-full" onSubmit={formSubmit} >
                  <Textarea
                    focusBorderColor="#"
                    borderWidth="0px"
                    variant="outline"
                    isRequired={true}
                    name="postMessage"
                    value={postMessage}
                    onChange={(e) => setPostMessage(e.target.value)}
                    placeholder="Make a new post...."
                    type="postMessage"
                    w={"full"}
                    maxLength={150}
                    rows={5}
                  />
                </form>
              </Flex>
              </Box>
              <Flex gap={10} className={`mt-6 ml-10 items-center ${showCloseButton ? 'justify-between' : 'justify-end'}`}>
              {
                    showCloseButton ? (
                        <BrandButton
                            text="Close"
                            onClick={onClose}
                            />
                    ) : null
                }
                <BrandButton
                  isLoading={isLoading}
                  text={`Post`}
                  onClick={formSubmit}
                  type="submit"
                  variant="md"
                  bgColor="brand.primary"
                  color="#fff"
                  fontSize="14px"
                  borderRadius={4}
                  px={4}
                  _hover={{ background: "brand.primary" }}
                />
              </Flex>
            </Flex>
    )
}


export default CreatePost