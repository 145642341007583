import React, { useState } from "react";
import {
  Center,
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { AiFillWarning } from "react-icons/ai";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useNavigate } from "react-router-dom";
import { CustomToast } from "../toast/CustomToast";
import Logout from "../../utils/Logout";

export default function LogoutModal({ isOpen, onClose, }) {
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { doLogout } = Logout()


  const handleLogout = () => {
    mutate(
      { key: "logout", method: "get" },
      {
        onSuccess(res) {
          onClose()
          doLogout();
        },
      }
    );
  };

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={"16px"} maxW={350}>
          <ModalBody p={0}>
            <Center d="flex" flexDirection="column" className="gap-2" >
            <Text textAlign={"center"} fontWeight={500} fontSize={19} color="#000">
                  Are you sure you want to logout?
                </Text>
              <Flex className="gap-2 w-full">

                          <BrandButton
                            isLoading={isLoading}
                            fontSize="13px" py={2}  
                            color="#F2112D" 
                            borderColor="#F2112D"
                            bg="#FFF"
                            _hover={{ backgroundColor: "red.50" }}
                            height="36px"
                            mt={6}
                            text={`Yes`}
                            width="full"
                            onClick={handleLogout}
                            
                          />
                        <BrandButton
                          fontSize="13px" bg="#20B962" py={2}
                          height="36px"
                          colorScheme="blue"
                          onClick={onClose}
                          mt={6}
                          text="No"
                          width="full"
                        />
              </Flex>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
