import { useEffect, useState } from "react";
import { Flex, Text, Menu, MenuButton, MenuList, MenuItem, Checkbox, Box, Wrap, WrapItem, VStack } from "@chakra-ui/react";
import { MdFilterList } from "react-icons/md";
import { FaChevronDown, FaChevronUp, FaCheck } from "react-icons/fa";
import { useData } from "../../data";
import BrandButton from "../brandButton/BrandButton";
import IsDesktop from "../../utils/IsDesktop";

const NewFilter = ({
  onChange = () => {},
  onApply = () => {},
  filters = null,
  appendFilters = false,
  multichoice = false,
  reset = false,
  section = ''
}) => {
  const { data } = useData("survey");
  const [selectedValues, setSelectedValues] = useState({});
  const [fields, setFields] = useState({});
  const isDesktop = IsDesktop();

  // const handleSelect = (section, value) => {
  //   let _selectedValues = {
  //     ...selectedValues,
  //     [section]: value,
  //   }
  //   setSelectedValues(_selectedValues);
  //   onChange(_selectedValues)
  // };

  const handleSelect = (section, value) => {
    let _selectedValues = { ...selectedValues };

    // Toggle the selection state of the item
    if (_selectedValues[section]?.includes(value)) {
        // If the item is already selected, deselect it
        _selectedValues[section] = multichoice ? _selectedValues[section].filter((item) => item !== value) : []
    } else {
        // Otherwise, select the item
        _selectedValues[section] = multichoice ? [...(_selectedValues[section] || []),value] : [value];
    }

    setSelectedValues(_selectedValues);
    onChange(_selectedValues);
};


  useEffect(() => {
    let surveyData = data?.data || {}
    if(filters){
      appendFilters ? setFields({
        ...filters,
        surveyData
      }) : setFields(filters)
    } else {
      setFields(surveyData)
    }
  },[data, filters])


  function camelToTitleCase(str) {
    return str
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      });
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const clearSelections = () => {
    let _values = {}
    Object.entries(selectedValues)
    .map(([key, _]) => {
      _values[key] = []
    })
    onChange(_values)
    setSelectedValues(_values);
    setIsMenuOpen(false); // Close the menu
  };

  useEffect(() => {
    if(reset)clearSelections()
  }, [reset])

  useEffect(() => {
    let _values = {}
    Object.entries(selectedValues)
    .map(([key, value]) => {
      if(value && value?.length > 0){
        _values[key] = value.join(',')
      }
    })
    onApply(_values);
    // This will trigger every time selectedValues changes.
  }, [selectedValues]);



  return (
    <Menu isOpen={isMenuOpen} onClose={toggleMenu}  autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton onClick={toggleMenu} cursor="pointer" justifyContent="space-between" alignItems="center" _focus={{ boxShadow: "none" }} _active={{ bg: "none" }}>
            <Flex justifyContent="flex-start" alignItems="center">
                <Box className="flex min-w-[150px] max-w-min justify-between p-2 pr-3 bg-[#E9E9E9] rounded-md items-center gap-2">
                  <Text className="whitespace-nowrap text-black font-medium text-sm">{section}</Text>
                  {isOpen ? <FaChevronUp className="text-[8px] font-semibold" /> : <FaChevronDown className="text-[8px] font-semibold" />}
                </Box>
            </Flex>
          </MenuButton>
          {isOpen && (
            
            <MenuList boxShadow="0px 1px 8px lightgray" maxH="600px" w="300px" zIndex={10} px={2} overflow="hidden" overflowY="auto">
                <style>   {` ::-webkit-scrollbar {display: none;} [data-menu-list] { scrollbar-width: none;} [data-menu-list]::-ms-scrollbar { display: none;} [data-menu-list] {scrollbar-width: none;} [data-menu-list] {overflow: -moz-scrollbars-none;}`}
   </style>     
   {/* <div onClick={clearSelections} className="text-black cursor-pointer">Clear</div> */}
   
                <Box w="100%" className="max-h-[500px] pb-6" overflow="hidden" overflowY="auto">
                    {Object.entries(fields).map(([section, values]) => (
                        <VStack spacing={0} key={section} alignItems="flex-start">
                        <Text className="hidden" fontWeight="bold" mb={2} color="brand.primary">
                            {camelToTitleCase(section)}
                        </Text>
                        <Box>
                            <Box className="flex flex-col gap-4 my-3">
                            {values.map(({ title, name }) => (

                                  <Box 
                                    key={title ? title : name} 
                                    // borderWidth="1px" borderRadius="full" px={2} py={1} mr={2} mb={2} cursor="pointer" 
                                    // borderColor={((title && selectedValues[section]?.includes(title)) || (name && selectedValues[section]?.includes(name))) ? "brand.primary" : "gray"} 
                                    color="#000"//{((title && selectedValues[section]?.includes(title)) || (name && selectedValues[section]?.includes(name))) ? "white" : "gray" } 
                                    // bg={((title && selectedValues[section]?.includes(title)) || (name && selectedValues[section]?.includes(name))) ? "brand.primary" : "transparent"}
                                    //   _hover={{
                                    //   borderColor: "brand.primary",
                                    //   color: "white",
                                    //   background: "#134a7096"
                                    //   }}
                                    onClick={() => handleSelect(section, title ? title : name)}
                                    className="flex gap-2 items-center cursor-pointer"
                                  >
                                      <Box className={`border px-[2px] py-[2px] rounded-sm border-[#2F4B6E] ${((title && selectedValues[section]?.includes(title)) || (name && selectedValues[section]?.includes(name))) ? 'bg-[#2F4B6E]' : 'bg-white'}`}>
                                        <FaCheck className="text-[7px]" color="#fff" />
                                      </Box>
                                      <Text className="text-sm">{title || name}</Text>
                                  </Box>
                            ))}
                            </Box>
                        </Box>
                        </VStack>
                    ))}
                </Box>
            </MenuList>
          )}
        </>
      )}
    </Menu>
  );
};

export default NewFilter;
