import { Avatar, Box, Text, useDisclosure } from "@chakra-ui/react"
import { FaBookmark, FaComment, FaHeart, FaRegBookmark, FaRegComment, FaRegHeart } from "react-icons/fa"
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { mutateFunction } from "../../libs/apis";
import CreateCommentModal from "../modal/CreateCommentModal";
import { CustomToast } from "../toast/CustomToast";

const Comment = ({
    post
}) => {
    const { mutate, isLoading } = useMutation(mutateFunction);
    const { addToast } = CustomToast();
    const navigate = useNavigate()
    const {
        isOpen : createCommentModalIsOpen,
        onClose : onCloseCreateCommentModal,
        onOpen : onOpenCreateCommentModal
    } = useDisclosure()


    const goToPost = () => {
        navigate(`/post?id=${post.id}`)
    }

    const likePost = () => {
        try {
            mutate(
                { key: "react/post", method: "post", data : {
                    post_id: post.id,
                    comment_id: null,
                }},
                {
                  onSuccess(res) {
                    addToast(res);
                  },
                  onError(e){
                    addToast({message: 'Post was not liked'}, "error")
                    console.error(e)
                  }
                }
              );
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <Box className="flex border-b cursor-pointer items-start gap-2 p-2 pt-5">
            <CreateCommentModal
                isOpen={createCommentModalIsOpen}
                onClose={onCloseCreateCommentModal}
                post={post}
            />
            <Box>
                <Avatar 
                    src={post.user.profile_image ?? null}
                    name={`${post.user.first_name} ${post.user.last_name}`}
                    size={"sm"}
                />
            </Box>
            <Box onClick={goToPost} className="w-full cursor-pointer flex px-2 flex-col">
                <Box className="w-full pb-10 min-h-30">
                        <Text className="w-full" color="#000">
                            {post?.text.split('\n\n').map(text => <span>{text}<br/><br/></span>)}
                        </Text>
                </Box>
                <Box className="flex items-center justify-between">
                    <Box className="flex gap-1 cursor-pointer items-center">
                        <FaRegComment onClick={onOpenCreateCommentModal} className="text-gray-600" />
                        <Text>{post.comment_count}</Text>
                    </Box>
                    <Box className="flex gap-1 cursor-pointer items-center">
                        <FaRegHeart onClick={likePost} className="text-gray-600" />
                        <Text>{post.likes_count}</Text>
                    </Box>
                    <Box className="flex gap-1 cursor-pointer items-center">
                        <FaRegBookmark />
                    </Box>
                </Box>
            </Box>
        </Box>
        
    )
}


export default Comment