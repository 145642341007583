import React, { useState } from "react";
import {
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { AiFillCustomerService, AiFillWarning } from "react-icons/ai";
import { mutateFunction, useMutation } from "../../libs/apis";
import { CustomToast } from "../toast/CustomToast";
import { FaShareAlt } from "react-icons/fa";

function isValidEmail(email) {
  // Regular expression to check if the string is in email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}


export default function ShareAccountModal({ isOpen, onClose, userId, companyId = '' }) {
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const [emails, setEmails] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault()
    const _emails = emails.split(',').filter((text) => isValidEmail(text.trim())).map((text) => text.trim())
    if(_emails.length <= 0 || !userId) {
      addToast({message: 'Please type in valid email(s)'}, "warning")
      return 
    }

    
    mutate(
      { key: "users/share-account", method: "post", data : {
        emails: _emails,
        userId,
        companyId
      }},
      {
        onSuccess(res) {
          addToast(res);
          setEmails("")
          onClose()

        },
        onError(e){
          addToast({message: `${_emails.length == 1 ? 'email was' : 'emails were'} not shared`}, "error")
          console.error(e)
        }
      }
    );
  };

  

  return (
    <div>
      <Modal isOpen={isOpen} onClose={() => {
        setEmails("")
        onClose()
      }} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            <Flex flexDirection="column">
              <Stack spacing={6}>
                <Flex className="items-center gap-2" justify={"center"}>
                  <Text
                    fontSize={20}
                    fontWeight={700}
                    color="brand.primary"
                    align="center"
                  >
                    Invite 
                  </Text>
                  <FaShareAlt style={{ color: "rgba(0,0,0,0.7)", fontSize: "18px" }} />
                </Flex>

                <Text fontSize={14} color="#000000">
                  Share companies with promising portfolios via email. 
                </Text>
                <Text fontSize={14} color="#000000">
                  Enter emails seperated by a comma. 
                </Text>
              </Stack>
              <Flex
                alignItems="center"
                mt={"20px"}
                className="w-full"
                w={"full"}
              >
                <form onSubmit={formSubmit} className="w-full" >
                  <Input
                    focusBorderColor="#"
                    variant="outline"
                    isRequired={true}
                    name="emails"
                    value={emails}
                    onChange={(e) => setEmails(e.target.value)}
                    width="full"
                    placeholder="Type here...."
                    type="text"
                    borderWidth="1px"
                    borderColor="brand.primary"
                    borderRadius="5px"
                    />
                </form>
              </Flex>
              <Flex gap={10} className="mt-6 items-center">
                <BrandButton
                  isLoading={isLoading}
                  text={`Submit`}
                  onClick={formSubmit}
                  type="submit"
                  variant="md"
                  bgColor="brand.primary"
                  color="#fff"
                  fontSize="14px"
                  borderRadius={4}
                  px={4}
                  _hover={{ background: "brand.primary" }}
                />
                <BrandButton
                  text="Close"
                  onClick={() => {
                    setEmails("")
                    onClose()
                  }}
                />
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
