import client from "../libs/client";
import UserService from "./UserService";
import { useQueryClient } from "react-query";

const UseLogoutTimer =()=>{
    // const queryClient = useQueryClient();
    let timeoutId = null;
    const resetLogoutTimer = () => {
        if (timeoutId) {
            clearInterval(timeoutId);
        } 
        const isAuthenticated = UserService.getToken();
        if (isAuthenticated) {
            timeoutId = setInterval(() => {
                if(!UserService.getToken()){
                    clearInterval(timeoutId);
                }
            }, (18000000));
        }
    }
    return {resetLogoutTimer}
}

export default UseLogoutTimer;