import InternalLayout from "../../layout/InternalLayout";
import { Box } from "@chakra-ui/react";

const ComposeInternalLayout = (Component) => (passThroughProps) => {
    return (
        <Box w="full">
            <InternalLayout {...passThroughProps} Component={Component} />
        </Box>
    )
}
 
export default ComposeInternalLayout;