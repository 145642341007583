import Header from "../header/Header"
import { Box, useDisclosure } from "@chakra-ui/react"
import Trending from "./Trending"
import { FaPen } from "react-icons/fa"
import CreatePostModal from "../modal/CreatePostModal"
import { useData } from "../../data"
import { useEffect, useState } from "react"
import Post from "./Post"
import CreatePost from "./CreatePost"

const Feed = () => {
    const {
        isOpen : createPostModalIsOpen,
        onClose : onCloseCreatePostModal,
        onOpen : onOpenCreatePostModal
    } = useDisclosure()

    const [page, setPage] = useState(1)

    const [feed, setFeed] = useState([])

    const {
        data: feedData,
        isLoading: feedDataIsLoading,
        refetch : refetchFeed
    } = useData(
        "feed", 
        10000
    )

    useEffect(() => {
        setFeed(feedData?.data?.posts || [])
    }, [feedDataIsLoading])
    return (
        <Box>
            <Header title={"feed"} />
            <Box className="flex w-full">
                <Box className={`hide-scroll max-h-[90vh] overflow-x-auto pt-4 md:px-5 pb-5 pr-10 w-full pt-4`}>
                    <Box className="p-3">
                        <CreatePost 
                            showCloseButton={false}
                            refetchPost={refetchFeed}
                        />
                    </Box>
                    <Box>
                        {
                            feed?.map((post) => {
                                return (
                                    <Post 
                                        post={post}
                                        refetchPost={refetchFeed}
                                        key={post.id}
                                    />
                                )
                            })
                        }
                    </Box>
                </Box>
                <Box className="w-full px-5 max-w-sm border-l hidden lg:flex">
                    <Trending />
                </Box>
            </Box>
        </Box>
    )
}

export default Feed