import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import BrandButton from "../../../components/brandButton/BrandButton";
import CountCard from "../../../components/countCard/CountCard";

const OffersCountSection = ({totalCount, totalGotten}) => {

    return (
        <HStack justifyContent="flex-start" alignItems="center" mb={4} spacing={4}>
            <CountCard text="Total Offers" count={totalCount} />
            <CountCard text="Total Gotten" count={totalGotten} />
        </HStack>
    )
}
export default OffersCountSection;