import { publicRoutes } from "./PublicRoute";
import { foundersRoutes } from "./FoundersRoutes";
import { investorsRoutes } from "./InvestorsRoutes";
import { privateRoutes } from './PrivateRoutes';
import { expertsRoutes } from "./ExpertsRoutes";
import { adminRoutes } from "./AdminRoutes";

const routes = {
    publicRoutes,
    privateRoutes,
    foundersRoutes,
    investorsRoutes,
    expertsRoutes,
    adminRoutes
}

export default routes;