import * as yup from 'yup';


export const VALIDATION_CONSTANTS = {
    PASSWORD_PARTS: {
      NUMBER: /[0-9]/g,
      LOWERCASE: /[a-z]/g,
      UPPERCASE: /[A-Z]/g,
      SPECIAL_CHAR: /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
    }
}

export const loginSchema = yup.object().shape({
    email: yup.string().email().trim().required().label('Email'),
    password: yup.string().trim().min(6).required().label('Password'),
});

export const accountDeletionSchema = yup.object().shape({
    reason: yup.string().trim().optional(),
    changes: yup.string().trim().optional(),
    deletionSuggestions: yup.string().trim().optional()
});
  
export const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email().trim().required().label('Email'),
}) 

export const resetPasswordSchema = yup.object().shape({
    password: yup.string().trim().required().matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.LOWERCASE, 'Password should contain a lowercase character').matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.UPPERCASE, 'Password should contain an uppercase character').matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.SPECIAL_CHAR, 'Password should contain a special character').matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.NUMBER, 'Password should contain a number').min(6).label('Password'),
    confirmPassword: yup.string().trim().required().matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.LOWERCASE, 'Password should contain a lowercase character').matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.UPPERCASE, 'Password should contain an uppercase character').matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.SPECIAL_CHAR, 'Password should contain a special character').matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.NUMBER, 'Password should contain a number').min(6).label('Confirm Password'),
}) 
  
export const signUpSchema = yup.object().shape({
    first_name: yup.string().trim().required().label('First Name'),
    last_name: yup.string().trim().required().label('Last Name'),
    email: yup.string().email().trim().required().label('Email'),
    password: yup.string().trim().required().matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.LOWERCASE, 'Password should contain a lowercase character').matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.UPPERCASE, 'Password should contain an uppercase character').matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.SPECIAL_CHAR, 'Password should contain a special character').matches(VALIDATION_CONSTANTS.PASSWORD_PARTS.NUMBER, 'Password should contain a number').min(6).label('Password'),
}) 

export const addAdvertSchema = yup.object().shape({
    agency_id: yup.number().optional().label("Brand Id").nullable(true),
    description: yup.string().trim().required().label('Description'),
    title: yup.string().trim().required().label("Advert title"),
    advert_type: yup.string().trim().matches(/inbox|default/).required().label('Advert Type'),
    user_type: yup.string().trim().optional().matches(/all|founder|expert|investor/).label('User Type'),
    external_link: yup.string().trim().url().optional().label('External Link'),
    start_date: yup.date().required().label('Start date'),
    end_date:  yup.date().required().label('End date'), 
    attachment: yup.mixed().required().label("Advert image")
})

export const addExpertSchema = yup.object().shape({
    first_name: yup.string().trim().required().label('First Name'),
    last_name: yup.string().trim().required().label('Last Name'),
    email: yup.string().trim().required().label('Email'),
    password: yup.string().trim().min(8).required().label('Password'),
    phone_number: yup.string().trim().optional().label('Phone Number'),
    headline: yup.string().trim().required().label('Position'),
    bio: yup.string().trim().required().label('Bio'),
    company_name: yup.string().trim().required().label('Company Name'),
})