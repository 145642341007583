import { Avatar, Box, HStack, Text } from "@chakra-ui/react";
import moment from "moment";
import IsDesktop from "../../../utils/IsDesktop";
import { useSelector } from "react-redux";

const ConnectionMessage = ({message, openChatBox, recipient}) => {
  const isDesktop = IsDesktop();
  const recipientId = recipient?.id || 0
  const messages = useSelector((state) => state?.usermessages?.messages[`${recipientId}`] || [])
  const lastMessage = messages[messages?.length - 1] || recipient?.lastMessage || null

  const getLastTimeStamp = () => {
    let time = moment(recipient?.last_seen || lastMessage?.createdAt || recipient?.date_accepted || recipient?.created_at).fromNow();
    return time || "Just Now"
  };

  return (
    <>
      <Box
        onClick={() => openChatBox(recipientId, recipient)}
        borderRadius={4}
        //   bg={index === recipient ? "#F5F5F5" : "#fff"}
        bg="#white"
        _hover={{backgroundColor:"#F5F5F5"}}
        borderTop="1px solid rgba(19, 74, 112, 0.2)"
        cursor="pointer"
      >
        <HStack p={3} className="relative w-full">
          <Avatar name={`${recipient?.first_name} ${recipient?.last_name}`} src={message?.avatar} alt="Avatar" w="48px" h="48px" borderRadius="100%" />
          <Box className="max-w-[60%]" p={2} pl={4} flex={1}>
            <Text fontSize={15} fontWeight={500}>
              {recipient?.first_name} {recipient?.last_name}
            </Text>
            {!isDesktop && <Text className="max-w-[250px]" textOverflow="ellipsis" whiteSpace="nowrap" fontSize={12} fontWeight={500} color="#062F36" pr={2}>
              {lastMessage && lastMessage?.content?.length > 18 ? `${lastMessage?.content?.substring(0, 20)}...` : lastMessage?.content}
            </Text>}
            {isDesktop && <Text className="max-w-[250px]" textOverflow="ellipsis" whiteSpace="nowrap" fontSize={12} fontWeight={500} color="#062F36" pr={2}>
              {lastMessage && lastMessage?.content?.length > 18 ? `${lastMessage?.content?.substring(0, 20)}...` : lastMessage?.content}
            </Text>}
          </Box>
          <Box p={2} className="absolute right-0 top-1">
            <Text fontSize={10} color="#134A70" fontWeight={500}>
              {getLastTimeStamp()}
            </Text>
          </Box>
        </HStack>
      </Box>
      
    </>
  );
};
export default ConnectionMessage;
