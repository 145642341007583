import { Box, Flex, HStack, Input, Select, Text, Textarea, VStack, Image, Radio, RadioGroup } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiImage } from "react-icons/bi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import BrandButton from "../../../components/brandButton/BrandButton";
import { CustomToast } from "../../../components/toast/CustomToast";
import { useData } from "../../../data";
import { mutateFunction, useMutation } from "../../../libs/apis";
import IsDesktop from "../../../utils/IsDesktop";
import { useForm } from "react-hook-form";
import { addAdvertSchema } from "../../../validators";
import ValidationError from "../../../validators/ValidationError";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { FaRegTrashAlt } from "react-icons/fa";

const AddAdvert = ({ onToggle, refetch = () => {} }) => {
  const isDesktop = IsDesktop();
  const { data } = useData(`brands`);
  const [image, setImage] = useState();
  const [brands, setBrands] = useState([]);
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const {register, handleSubmit, watch, formState: { errors }, setError, setValue, clearErrors} = useForm({
    resolver : yupResolver (addAdvertSchema),
    defaultValues: {
      advert_type: 'default'
    }
  });

  const watch_advert_type = watch("advert_type", "default")
   
  const addAdvert = (data) => {
    const formData = new FormData();
    for (const key in data) {
      data[key] && formData.append(key, data[key]);
    }
    if(data?.advert_type !== 'default' && typeof data?.agency_id !== "number"){
      addToast({
        message: 'Please select a brand'
      }, 'error')
      return
    }
    if(data?.advert_type !== 'default' && typeof data?.user_type !== "string"){
      addToast({
        message: 'Please choose a target audience'
      }, 'error')
      return
    }

    if(moment(data?.end_date).isBefore(data?.start_date)){
      addToast({
        message: 'Invalid dates submitted'
      }, 'error')
      return
    }
    mutate(
      { key: `adverts`, method: "post", data: formData },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
          onToggle();
        },
        onError(res) {
          addToast(res, 'error');
        }
      }
    );
  };

  const handleChangeForFile = (e) => {
    const fileData = e.target.files[0];
    setImage(fileData);
    setValue("attachment", fileData)
    clearErrors("attachment")
  };

  const removeImage = () => {
    setImage(null);
    setValue("attachment", null)
    clearErrors("attachment")
  }

  const handleRadioChange = (value) => {
    setValue("advert_type", value)
  }

  useEffect(() => {
    if (data?.data) {
      setBrands(data?.data?.brands);
    }
  }, [data]);

  return (
    <Flex className="w-[100%] flex-col overflow-hidden">
      <Flex className="w-[100%] overflow-x-hidden gap-5 flex-col overflow-y-auto pb-8 md:pb-24" h={isDesktop ? "85vh" : "auto"} w="100%" p={4}  >
      <Flex justifyContent="flex-start" alignItems="center" my={4} mx={4}>
        <Flex cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
          <Box mr={2}>
            <HiOutlineArrowNarrowLeft size="35px" />
          </Box>
          <Box>
            <Text fontWeight={600}>Back</Text>
          </Box>
        </Flex>
      </Flex>
      <HStack w="100%" alignItems="flex-start" mx={4}>
        <form className="w-full" onSubmit={handleSubmit(addAdvert)}>
        <VStack className="gap-2 w-[90%] md:w-[60%]" alignItems="flex-start">
          <Flex flexDir="column" w="100%" key="title">
            <Text fontSize="14px" mb={2}>
              Advert Title  <span style={{color:"red"}}>*</span>
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Advert Title" name="title" {...register('title')} type="text" w="100%" h="50px" />
            <ValidationError message={errors.title?.message} />
          </Flex>
          <Flex flexDir="column" w="100%" key="advert_type">
            <Text fontSize="14px" mb={2}>
              Advert Type <span style={{color:"red"}}>*</span>
            </Text>
            <RadioGroup onChange={handleRadioChange} defaultValue={'default'}>
              <Flex className="flex-col gap-1 max-w-max">
                <Radio value='default'>Default</Radio>
                <Radio value='inbox'>Inbox</Radio>
              </Flex>
            </RadioGroup>
            <ValidationError message={errors.advert_type?.message} />
          </Flex>
          <Flex flexDir="column" w="100%" key="user_type">
            <Text fontSize="14px" mb={2}>
              Select Target Audience {watch_advert_type === 'default' ? null : <span style={{color:"red"}}>*</span>}
            </Text>
            <Select borderWidth="1px" borderColor="brand.primary" borderRadius="5px" placeholder="Choose your target Audience" focusBorderColor="#"  name="user_type" disabled={watch_advert_type === 'default'} onChange={(e) => setValue("user_type", e.target.value)}>
                <option value={"all"}>
                    All
                </option>
                <option value={"founder"}>
                    Founder
                </option>
                <option value={"investor"}>
                    Investors
                </option>
                <option value={"expert"}>
                    Experts
                </option>
            </Select>
            <ValidationError message={errors.user_type?.message} />
          </Flex>
          <Flex flexDir="column" w="100%" key="external_link">
            <Text fontSize="14px" mb={2}>
              URL <span>(Optional)</span> <span className="text-xs"> ex: https://www.link.com</span>
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="URL" name="external_link" {...register('external_link')} type="text" w="100%" h="50px"  />
            <ValidationError message={errors.external_link?.message} />
          </Flex>
          <Flex flexDir="column" w="100%" key="brand">
            <Text fontSize="14px" mb={2}>
              Select Brand {watch_advert_type === 'default' ? '(Optional)' : <span style={{color:"red"}}>*</span>}
            </Text>
            <Select borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" placeholder="Brand" name="agency_id" onChange={(e) => setValue("agency_id", e.target?.value ? Number(e.target.value) : null)}>
              {brands?.map((eachBrand, index) => (
                <option key={index} value={eachBrand.id}>
                  {eachBrand.name}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex flexDir="column" w="100%" key="description">
            <Text fontSize="14px" mb={2}>
              Description <span style={{color:"red"}}>*</span>
            </Text>
            <Textarea minH="150px" borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Description" name="description" {...register('description')} type="text" w="100%" h="50px" />
            <ValidationError message={errors.description?.message} />
          </Flex>
          <Flex flexDir="column" w="100%">
            <HStack spacing={isDesktop ? 2 : 0} flexDir={isDesktop ? "row" : "column"} justifyContent="space-between" alignItems="center" w="100%">
              <Flex flexDir="column" w="100%" key="start_date">
                <Text fontSize="14px" mb={2}>
                  Start Date  <span style={{color:"red"}}>*</span>
                </Text>
                <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="start_date" type="date" size="md" {...register('start_date')} />
                <ValidationError message={errors.start_date?.message} />
              </Flex>
              <Flex flexDir="column" w="100%" key="end_date">
                <Text fontSize="14px" mb={2}>
                  End Date  <span style={{color:"red"}}>*</span>
                </Text>
                <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="end_date" type="date" size="md" {...register('end_date')} />
                <ValidationError message={errors.end_date?.message} />
              </Flex>
            </HStack>
          </Flex>
          <Flex flexDir="column" w="100%" key="image" justifyContent="flex-start">
            <Text className="text-sm my-3">Recommended image size is 1280 x 720 pixels or 16 : 9 ratio </Text>
            <Input accept="image/*" hidden id="image-input" type="file" onChange={(e) => handleChangeForFile(e)} name="image" key="image" />
            <label htmlFor="image-input">
              <VStack alignItems="flex-start">
                <Flex w="50%" h="150px" justifyContent="center" alignItems="center" bg="lightgray" borderRadius="10px" position={"relative"}>
                  {image && <Box onClick={removeImage} className="absolute right-[-5px] top-[-10px] h-8 w-8 flex items-center justify-center p-1 rounded-full bg-gray-200 cursor-pointer"><FaRegTrashAlt color="red" size={"15px"} /></Box>} 
                  {image && <Image src={window.URL.createObjectURL(new Blob([image], { type: "image" }))} w="100%" h="100%" fit="cover" borderRadius="10px" />}
                  {!image && <BiImage size={50} color="gray" />}
                  <ValidationError message={errors.attachment?.message} />
                </Flex>
              </VStack>
            </label>
          </Flex>
          <BrandButton type="submit" isLoading={mutateIsLoading} text="Add Advert" w="120px" colorScheme="blue" />
        </VStack>
        </form>
      </HStack>
      </Flex>
    </Flex>
  );
};
export default AddAdvert;
