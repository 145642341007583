import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, Input } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import TimeSlots from "../TimeSlots";
import client from "../../../../libs/client";
import { BASE_URL } from "../../../../helpers/constant";
import { BsThreeDots } from "react-icons/bs";
import { useData } from "../../../../data";
import { useSelector } from "react-redux";
import RandomTile from "./RandomTile";

const SingularDateBooking = ({
  bookingDates = [],
}) => {
  const convertToTime = (timeString) => {
    var parts = timeString.split(":");
    var date = new Date();
    date.setHours(parts[0]);
    date.setMinutes(parts[1]);
    date.setSeconds(parts[2]);

    return date;
  }

  return (
    <Box className="flex w-full flex-col relative" >
        {
            bookingDates.map((booking, index) => {
                const diff = Number(moment(moment(convertToTime(booking?.slot_end_time))).diff(convertToTime(booking?.slot_start_time), "minute"))
                const pos = Number(moment(convertToTime(booking?.slot_start_time)).diff(moment().startOf('day'), "minute"))
                return (
                    <Box style={{top: `${pos}px`, left: "4px"}} className={`absolute w-full max-w-[130px] rounded-lg`}>
                        <RandomTile 
                            style={{height: `${diff}px`}}
                            title={booking?.slot_title}
                            startTime={
                                moment(convertToTime(booking?.slot_start_time)).format("hh:mm")
                            }
                            endTime={
                                moment(convertToTime(booking?.slot_end_time)).format("hh:mm")
                            }
                        />
                    </Box>
                )
            })
        }
        {
            Array.from({ length: 24 }).map((_, index) => {
                return (
                    <Box className="border w-full h-[60px]">
                    </Box>
                )
            })
        }
    </Box>
  );
};
export default SingularDateBooking;
