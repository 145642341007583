import { Avatar, Box, Flex, Heading, HStack, Input, InputGroup, InputLeftAddon, Spinner, Text, Textarea, VStack } from "@chakra-ui/react";
import { Divider } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FiPause, FiPlay } from "react-icons/fi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useQueryClient } from "react-query";
import BrandButton from "../../../components/brandButton/BrandButton";
import { useData } from "../../../data";
import IsDesktop from "../../../utils/IsDesktop";
import share_icon from "../../../public/icons/share_icon.svg";
import copy_icon from "../../../public/icons/copy_icon.svg";
import { useMutation, mutateFunction } from "../../../libs/apis";
import { CustomToast } from "../../../components/toast/CustomToast";
import { copyToClipboard } from "../../../utils/copyToClipboard";

const ViewOfferDetails = ({ brand, offerId, onToggle, changeStatus, deleteOffer, refetch }) => {
  const isDesktop = IsDesktop();
  const { data, isLoading, isError } = useData(`offers/${offerId}`);
  const { mutate, isLoading: mutateIsLoading} = useMutation(mutateFunction);
  const {addToast} = CustomToast();
  const [offerDetails, setOfferDetails] = useState({});
  const [editOfferDetails, setEditOfferDetails] = useState(offerDetails);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (data?.data) {
      setOfferDetails(data?.data?.offer);
    }
    if (isError) {
      onToggle();
      
    }
  }, [data, offerDetails, isError]);
  const changeThisOfferStatus = (offerId) => {
    changeStatus(offerId);
    queryClient.invalidateQueries(`offers/${offerId}`);
  };
  const deleteThisOffer = (offerId) => {
    deleteOffer(offerId);
    onToggle();
  };

  const handleChange = (e) => {
    setEditOfferDetails({...editOfferDetails, [e.target.name]: e.target.value})
  }

  const editOffer = () => {
    mutate(
      { key: `offers/${offerDetails?.id}`, method: "put", data: editOfferDetails },
      {
        onSuccess(res) {
          addToast(res)
          queryClient.invalidateQueries('offers');
          refetch();
          // queryClient.invalidateQueries('user-types');
        },
      }
    );
  }

  const copyLink = () => {
    if(offerDetails?.offer_url){
      copyToClipboard(offerDetails?.offer_url)
      addToast({message: 'Copied'}, 'info')
    }else {
      addToast({message: 'Offer has no link'}, 'info')
    }
  }

  return (
    <>
    {!isLoading ? (<>
    
      <Flex justifyContent="space-between" alignItems="center" my={4} mx={4}>
        <Flex cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
          <Box mr={2}>
            <HiOutlineArrowNarrowLeft size="35px" />
          </Box>
          <Box>
            <Text fontWeight={600}>Back</Text>
          </Box>
        </Flex>
        <BrandButton text="Edit Offer" isLoading={mutateIsLoading} w="120px" onClick={()=> editOffer()} />
      </Flex>
      <HStack h="600px" w="100%" alignItems="flex-start" m={4}>
        <VStack w="60%">
          <Flex flexDir="column" w="100%" key="brand">
            <Text fontSize="14px" mb={2}>
              Brand
            </Text>
            <InputGroup w="100%">
              <Flex pl={4} borderWidth="1px" h="50px" borderColor="brand.primary" borderRadius="5px" alignItems="center" w="100%">
                <Avatar size="sm" name={brand?.name} src={brand?.logo} />
                {/* <Input focusBorderColor="#" borderWidth="0px" variant="outline" placeholder="Brand" value={brand?.brand_name} type="text" w="100%" h="50px" onChange={(e)=>handleChange(e)} /> */}
                <Text pl={2} >{brand?.name}</Text>
              </Flex>
            </InputGroup>
          </Flex>
          <Flex flexDir="column" w="100%" key="title">
            <Text fontSize="14px" mb={2}>
              Offer Title
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer Title" defaultValue={offerDetails?.title} name="title" type="text" w="100%" h="50px" onChange={(e)=>handleChange(e)} />
          </Flex>
          <Flex flexDir="column" w="100%" key="value">
            <Text fontSize="14px" mb={2}>
              Offer Value
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer value" defaultValue={offerDetails?.price} name="price" type="text" w="100%" h="50px" onChange={(e)=>handleChange(e)}/>
          </Flex>
          <Flex flexDir="column" w="100%" key="reference">
            <Text fontSize="14px" mb={2}>
              Reference number
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" isDisabled placeholder="Reference number" defaultValue={offerDetails?.reference} name="reference_number" type="text" w="100%" h="50px" />
          </Flex>
          <Flex flexDir="column" w="100%" key="offer_url">
            <Text fontSize="14px" mb={2}>
              Offer URL
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer url" defaultValue={offerDetails?.offer_url} name="offer_url" type="text" w="100%" h="50px" onChange={(e)=>handleChange(e)} />
          </Flex>
          <Flex flexDir="column" w="100%" key="caption">
            <Text fontSize="14px" mb={2}>
              Offer Caption
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Brand" defaultValue={offerDetails?.caption} name="caption" type="text" w="100%" h="50px" onChange={(e)=>handleChange(e)}/>
          </Flex>
          <Flex flexDir="column" w="100%">
            <HStack spacing={isDesktop ? 2 : 0} flexDir={isDesktop ? "row" : "column"} justifyContent="space-between" alignItems="center" w="100%">
              <Flex flexDir="column" w="100%" key="start_date">
                <Text fontSize="14px" mb={2}>
                  Start Date
                </Text>
                <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="start_date" defaultValue={moment(editOfferDetails?.start_date).format("yyyy-MM-DD") || moment(offerDetails?.start_date).format("yyyy-MM-DD")} type="date" size="md" onChange={(e)=>handleChange(e)}/>
              </Flex>
              <Flex flexDir="column" w="100%" key="end_date">
                <Text fontSize="14px" mb={2}>
                  End Date
                </Text>
                <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="end_date" defaultValue={moment(editOfferDetails?.end_date).format("yyyy-MM-DD") || moment(offerDetails?.end_date).format("yyyy-MM-DD")} type="date" size="md" onChange={(e)=>handleChange(e)}/>
              </Flex>
            </HStack>
          </Flex>
        </VStack>
        <VStack h="100%" w="38%" boxShadow="0px 1px 8px lightgray" p={4} borderRadius="10px">
          <VStack w="100%" h="120px" justifyContent="space-between">
            <VStack>
              <Heading color="brand.primary" fontSize="28px">
                {offerDetails?.clicks || 0}
              </Heading>
              <Text fontSize="16px">Offer Clicks</Text>
              <BrandButton text="View all" h="40px" borderWidth="1px" />
            </VStack>
            <Divider orientation="horizontal" colorScheme="gray" />
          </VStack>
          <VStack w="100%" alignItems="flex-start" spacing={2} my={4}>
            <Heading color="brand.primary" fontSize="18px">
              Other Info
            </Heading>
            <Flex justifyContent="space-between" alignItems="center" w="100%">
              <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                <Text fontSize="14px" mb={2}>
                  Date Added
                </Text>
              </Flex>
              <Box w="full" ml={2}>
                <Text color="brand.primary">{moment(offerDetails?.createdAt).format("MMM D, YYYY")}</Text>
              </Box>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" w="100%">
              <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                <Text fontSize="14px" mb={2}>
                  Start Date
                </Text>
              </Flex>
              <Box w="full" ml={2}>
                <Text color="brand.primary">{moment(offerDetails?.start_date).format("MMM D, YYYY")}</Text>
              </Box>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" w="100%">
              <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                <Text fontSize="14px" mb={2}>
                  End Date
                </Text>
              </Flex>
              <Box w="full" ml={2}>
                <Text color="brand.primary">{moment(offerDetails?.end_date).format("MMM D, YYYY")}</Text>
              </Box>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" w="100%">
              <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                <Text fontSize="14px" mb={2}>
                  Offer Clicks
                </Text>
              </Flex>
              <Box w="full" ml={2}>
                <Text color="brand.primary">{offerDetails?.clicks || 0}</Text>
              </Box>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" w="100%" pb={12}>
              <Flex minW="40%" maxW="60%" w="full" display="flex" justifyContent="flex-start" alignItems="center">
                <Text fontSize="14px" mb={2}>
                  Status
                </Text>
              </Flex>
              <Box w="full" ml={2}>
                {offerDetails?.is_active === true && (
                  <Box borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="120px" h="40px" bg="#E6F3E6">
                    <Text color="green" fontWeight={500}>
                      Ongoing
                    </Text>
                  </Box>
                )}
                {offerDetails?.is_active === false && (
                  <Box borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="120px" h="40px" bg="#FFF6E6">
                    <Text color="orange" fontWeight={500}>
                      Paused
                    </Text>
                  </Box>
                )}
              </Box>
            </Flex>
            <Divider orientation="horizontal" />
          </VStack>
          <HStack justifyContent="flex-start">
            {offerDetails?.is_active === true && (
              <Box cursor="pointer" onClick={() => changeThisOfferStatus(offerDetails?.id)} border="1px" borderColor="orange" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px">
                <FiPause color="orange" />
              </Box>
            )}
            {offerDetails?.is_active === false && (
              <Box cursor="pointer" onClick={() => changeThisOfferStatus(offerDetails?.id)} border="1px" borderColor="green" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px">
                <FiPlay color="green" />
              </Box>
            )}
            <Box cursor="pointer" onClick={() => deleteThisOffer(offerDetails.id)} border="1px" borderColor="red" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px">
              <RiDeleteBin6Line color="red" />
            </Box>
            <BrandButton text="Share" icon={share_icon} w="100px" h="40px" />
            <BrandButton text="Copy Link" onClick={() => {copyLink()}} icon={copy_icon} w="120px" h="40px" />
          </HStack>
        </VStack>
      </HStack>
        <Flex flexDir="column" w="100%" key="details" mx={4}>
            <Text fontSize="14px" mb={2}>
              Offer Details
            </Text>
            <Textarea minH="150px" borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer Details" defaultValue={offerDetails?.description} name="description" type="text" w="100%" onChange={(e)=>handleChange(e)} />
        </Flex>
    </>):(<Spinner />)}
    </>
  );
};
export default ViewOfferDetails;
