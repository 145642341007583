import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Image, Spinner, Text, Input, VStack, InputGroup, InputLeftAddon, Link, Textarea, RadioGroup, Radio, UnorderedList, ListItem , OrderedList } from "@chakra-ui/react";
import { useSearchParams, useNavigate, Navigate , useRoutes} from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import logo_2 from '../../public/icons/logo_.svg';

const PrivacyPolicy = () => {
    const navigate = useNavigate()
    
    return (
        <>
            <Box width="full" h="100vh" display="flex" flexDir="column" alignItems="center" className="max-w-screen-lg mx-auto w-[90vw] mb-10" >
                <Image src={logo_2} boxSize='130px' className="mt-10" objectFit='cover' mb={5}/>
                <Flex className="gap-2 w-full items-center justify-start">
                   <Text onClick={() => {window.history.back()}} className="cursor-pointer">
                        <ArrowBackIcon fontSize={"18px"} /> Back
                    </Text>
                </Flex>

                <Text color="brand.primary" className="text-center font-bold text-2xl mb-5">
                    Privacy Policy
                </Text>

                <Text className="my-3">
                    Thank you for choosing SeedAFounder Software (“us”, “we”, or “our”). This Privacy Policy outlines how we collect, use, and protect your personal information when you use our software and services (“Service”).
                    By using the SeedAFounder Software Service, you agree to the terms outlined in this Privacy Policy. If you do not agree with any of these terms, please do not use our Service.
                    Information We Collect
                </Text>

                <Text className="my-3">
                    <OrderedList>
                        <ListItem>
                            Personal Information: We may collect personal information such as your name, contact information, email address, and other identifying details when you register for an account or use our Service.
                        </ListItem>
                        <ListItem>
                            Organization Information: In order to provide our Service effectively, we may collect information related to your organization, including but not limited to, its name, location, size, and various activities.
                        </ListItem>
                        <ListItem>
                            Usage Data: We may collect usage data related to how you interact with our Service. This includes information about the features you use, the frequency of use, and any settings you configure.
                        </ListItem>
                        <ListItem>
                            Payment Information: If you make payments through our Service, we may collect payment-related information, such as billing addresses and credit/debit card details. However, please note that we do not store your complete payment information. We utilize third-party payment processors to handle these transactions securely.
                        </ListItem>
                    </OrderedList>
                </Text>

                <Text className="my-3">
                    <Text className="font-semibold mb-1">
                        How We Use Your Information
                    </Text>

                    <OrderedList>
                        <ListItem>
                        Service Delivery: We use the collected information to provide, maintain, and improve our Service, ensuring that it meets your needs and preferences.
                        </ListItem>
                        <ListItem>
                        Communication: We may use your contact information to send you important updates, notifications, and newsletters related to the Service. You can opt-out of these communications at any time.
                        </ListItem>
                        <ListItem>
                        Analytics: We analyze usage patterns and data to understand how our Service is being used. This helps us improve the user experience and identify areas for enhancement.
                        </ListItem>
                        <ListItem>
                        Legal Requirements: We may use your information to comply with legal obligations, resolve disputes, and enforce our agreements.
                        </ListItem>
                    </OrderedList>
                </Text>
                

                <Text className="my-3" >
                    <Text className="font-semibold mb-1">
                        Data Protection and Security
                    </Text>
                    <Text>
                        We are committed to safeguarding your personal information. We employ industry-standard security measures to prevent unauthorized access, disclosure, alteration, or destruction of your data.
                    </Text>
                </Text>

                <Text className="my-3">
                    <Text className="font-semibold mb-1">
                        Your Rights
                    </Text>
                    <Text>
                    You have the right to access, update, or delete your personal information stored with us. If you wish to exercise any of these rights, please contact us using the information provided below.
                    </Text>
                </Text>

                <Text className="my-3">
                    <Text className="font-semibold mb-1">
                        Changes to this Privacy Policy
                    </Text>
                    <Text>
                    We reserve the right to update and modify this Privacy Policy from time to time. Any changes will be posted on our website, and the date of the latest revision will be indicated. Continued use of our Service after any modifications implies your acceptance of the updated Privacy Policy.
                    </Text>
                </Text>

                <Text className="my-3 mb-10">
                    <Text className="font-semibold mb-1">
                    Contact Us
                    </Text>
                    <Text className="mb-10">
                        If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at support@seedafounder.com
                        By using SeedAFounder Software, you agree to this Privacy Policy and our Terms of Service.
                    </Text>
                </Text>


            </Box>
        </>
    )
}
export default PrivacyPolicy;
