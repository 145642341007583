import { Avatar, Box, Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import BrandButton from "../../../components/brandButton/BrandButton";
import IsDesktop from "../../../utils/IsDesktop";
import share_icon from "../../../public/icons/share_icon.svg";
import copy_icon from "../../../public/icons/copy_icon.svg";
import Linkify from "linkify-react";


const ViewAdvertDetails = ({advert, onToggle}) => {
    const isDesktop = IsDesktop();
    return (
        <>
            <Flex cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
                <Box mr={2}><HiOutlineArrowNarrowLeft size="35px"/></Box>
                <Box><Text fontWeight={600}>Back</Text></Box>
            </Flex>
            <VStack mt={6} overflowY="hidden" h={{md:"80vh"}} bg="white" borderRadius="10px" boxShadow="0.5px 2px 5px lightgray" justifyContent="space-between" alignItems="flex-start">
                <VStack  h="100%" alignItems="flex-start" p={10} justifyContent="space-between">
                    <Box><Image borderRadius="10px" alt={advert?.title} src={advert?.media} w="330px" h="150px" bg="lightgray"/></Box>
                    <VStack  mt={3} alignItems="flex-start">
                        <Heading mb={4} fontSize={isDesktop ? "18px": "14px"}>{advert?.title}</Heading>
                        <Flex alignItems="center">
                            <Avatar  name={advert?.brand?.name || "Seed A Founder"} src={advert?.brand?.logo} size="sm"/>
                            <Text ml={2} fontSize={isDesktop ? "18px" : "14px"} color="brand.primary">
                                {advert?.brand?.name || "Seed A Founder"}
                            </Text>
                        </Flex>
                    </VStack>
                    <Box overflowY={{md:"auto" }} maxH={{md:"70%"}} pt={10} w="100%" className="my-4">
                        <Text fontSize={isDesktop ? "16px" : "13px"}>
                            {advert?.description}
                        </Text>
                    </Box>
                    {
                        advert?.external_link ? <a href={advert?.external_link} target="_blank">See more</a> : null
                    }
                    <Flex mt={3} className="flex-wrap items-center justify-center my-5 gap-4">
                        <BrandButton icon={share_icon} text="Share" width="150px" mx={2}/>
                        <BrandButton icon={copy_icon} text="Copy" width="150px" mx={2}/>
                    </Flex>
                </VStack>
            </VStack>
        </>
    )
}
export default ViewAdvertDetails;