import React, { useState, useEffect, useRef, useCallback, Fragment } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Box,
  Flex,
  Spinner,
  Text,
  Avatar,
  Switch,
  Tooltip
} from "@chakra-ui/react";
import IsDesktop from "../../utils/IsDesktop";
import client from "../../libs/client";
import { BASE_URL } from "../../helpers/constant";
import ViewFounderDetails from "./ViewFounderDetails";
import {useData} from "../../data"
import { useSelector } from "react-redux";
import moment from "moment";
import { useInterval } from "../../utils/useInterval";
import { Select } from "antd";
import NewSingleFounder from "./NewSingleFounder";
import NewFilter from "../filter/NewFilter";
import dashboard from "../../public/icons/dashboard.svg"
import { useNavigate } from "react-router-dom";
import BrandButton from "../brandButton/BrandButton";

const FoundersList = ({
  showViewAll = false,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const [selectedFounder, setSelectedFounder] = useState({});
  const isDesktop = IsDesktop();
  const [pageNo, setPageNo] = useState(1);
  const lastResultsCount = useRef(0);
  const [founders, setFounders] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [filter, setFilters] = useState({})
  const [selectedUserIndex, setSelectedUserIndex] = useState(0)
  const [selectedUser, setSelectedUser] = useState(null)
  const { isOpen: viewDetailsIsOpen, onToggle: onToggleViewDetails } = useDisclosure();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const chatWithUserState = useSelector((state) => state?.chat?.chatInfo);
  const [recipient, setRecipient] = useState(chatWithUserState);
  const [recipientId, setRecipientId] = useState(chatWithUserState?.id);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [showMessageTray, setShowMessageTray] = useState(false)
  const { data } = useData("survey");

  const [isChecked, setIsChecked] = useState(false)
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const { OptGroup } = Select;

  const [filterIsActive, setFilterIsActive] = useState(false)
  const [resetFilters, setResetFilters] = useState(false)

  // const { data, isLoading, refetch } = useData(`messages`);
  const [connections, setConnections] = useState([]);
  const { data: connectionsData, isLoading: connectionsLoading, refetch: refetchConnections } = useData(`users/${loggedInUser?.id}/connections?page_no=1&page_size=10`, 30000);
  const { isOpen: chatBoxIsOpen, onOpen: onOpenChatBox, onClose: onCloseChatBox, onToggle: onToggleChatBox } = useDisclosure();
  const foundersContainerRef = useRef(null)

  const navigate = useNavigate()
  
  useEffect(() => {
    if (connectionsData?.data) {
      setConnections(connectionsData?.data?.connections?.sort((a, b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at).diff(moment(a?.lastMessage?.createdAt || a?.connection?.created_at))));
    }
    if (chatWithUserState) {
      openChatBox(chatWithUserState?.id, chatWithUserState);
    }
  }, [connectionsData?.data?.connections, chatWithUserState]);

  const openMessageTray = () => {
    setShowMessageTray(prev => !prev)
  }

  const openChatBox = (recipientId, recipient) => {
    setRecipientId(recipientId);
    setRecipient(recipient);
    onOpenChatBox();
  };
  const closeChatBox = () => {
    setRecipientId(null);
    setRecipient(null);
    onToggleChatBox();
  };
  const isFromNavigate = () => {
    return chatWithUserState && chatWithUserState?.id === recipientId && chatWithUserState?.connection?.accepted;
  };
  useInterval(() => {
    refetchConnections();
  }, 15000);

  const searchConnections = useCallback(
    (inputValue) => {
      setIsLoadingMessages(true);
      if (inputValue !== "") {
        setSearchData(connections?.filter((connection) => `${connection?.first_name} ${connection?.last_name}`.toLowerCase().includes(inputValue.toLowerCase())));
      }
      setIsLoadingMessages(false);
    },
    [connections]
  );

  const searchResults = searchData?.map((user) => (
    <Select.Option key={user.id}>
      <Flex alignItems="center">
        <Avatar size="sm" name={`${user.first_name} ${user.last_name}`} src={user.profile_image} />
        <Text ml={4}>{`${user.first_name} ${user.last_name}`}</Text>
      </Flex>
    </Select.Option>
  ));

  const openSearchedUserChatBox = (userId) => {
    setRecipientId(userId);
    setRecipient(connections?.find((connection) => connection?.id?.toString() === userId.toString()));
    onOpenChatBox();
  };

  const handleScroll = (scrollpos = window.innerHeight + window.scrollY , containerHeight = document.documentElement.offsetHeight) => {
    let userScrollHeight = scrollpos;
    let windowBottomHeight = containerHeight;

    if (userScrollHeight >= windowBottomHeight) {
      if (lastResultsCount.current < 1) {
        return;
      } else {
        let _pageNo = pageNo + 1
        setPageNo(_pageNo);
        getFounders({pageNumber: _pageNo})
      }
    }
  };

  const updateFilter = (data) => {
    setFilters((prev) => ({
      ...prev,
      ...data
    }))
  }

  const updateState = (data, append = true, isFetchedFromServer = true) => {
    if(isFetchedFromServer)lastResultsCount.current = data.length;
    let _founders = append ? [...founders, ...data] : [...data];
    setIsLoading(false);
    setFounders([..._founders]);
  };

  useEffect(() => {
    //window.addEventListener("scroll", handleScroll);

    //return () => window.removeEventListener("scroll", handleScroll)
  }, [pageNo]);

  const parseFilters = (obj) => {
    const params = {
      'companyStages':'company_stage', 
      'fundCategories': 'fund_category', 
      'locations': 'incorporation_location',
      'businessModels': 'business_model', 
      'categories': 'business_category',  
    }
    return Object.entries(obj).map(([key, val]) => `${params[key]}=${encodeURIComponent(val)}`).join('&')
  }

  const getFounders = ({filters = filter, append = true, pageNumber = 1, showAll = isChecked }) => {
    const _filters = parseFilters(filters)
    return client().get(`${BASE_URL}user-types/founder?page_no=${pageNumber}&page_size=10${_filters ? `&${_filters}` : ''}&includeExtras=${showAll}`).then((res) => {
      setIsLoading(true)
      let data = res.data.data.users;
      updateState(data, append);
    });
  }

  useEffect(() => {
    getFounders({})
  }, [])

  const toggleShowAllCompanies = (val) => {
    setIsChecked(val)
    getFounders({
      showAll: val,
      append: false
    })
  }

  const onSelectUser = (id) => {
    setSelectedUserIndex(id)
  }

  useEffect(() => {
    onSelectUser(0)
}, [isLoading])

const onDislikeCompany = (companyId) => {
  try {
    let _founders = founders.filter(user => user.company.id !== companyId) 
    updateState(_founders, false, false)
  } catch (e) {
    //console.log(e)
  }
}


const handleResetFilter = () => {
  setResetFilters(true)  
}

useEffect(() => {
  let status = false
  for(let key of Object.keys(filter)){
    if(filter[key]?.length > 0){
      status = true
      break
    }
  }
  setFilterIsActive(status)
  setResetFilters(false)

}, [filter])

  return (
    <Flex className="flex-col border bg-white mb-4 rounded-lg pb-2">
      {
        !isOpen ? (
          <>
          {
            !viewDetailsIsOpen ? (
              <Flex style={{boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.05)'}} className="flex-row p-3 mb-4 items-start justify-between" >
                    <Box minW="100px">
                      <Text
                        color="brand.primary"
                        className={`pt-1 text-2xl font-semibold`}
                      >
                        Connect to Founders
                      </Text>
                      <Box className="flex flex-wrap gap-4 my-3 items-center">
                        <NewFilter reset={resetFilters} multichoice section="Company Stages" onChange={updateFilter}  onApply={(data) => {getFounders({filter:data, append:false})}} filters={data?.data?.companyStages ? {companyStages : data?.data?.companyStages} : null} />
                        <NewFilter reset={resetFilters} multichoice section="Funding Categories" onChange={updateFilter}  onApply={(data) => {getFounders({filter:data, append:false})}} filters={data?.data?.fundCategories ? {fundCategories : data?.data?.fundCategories} : null} />
                        <NewFilter reset={resetFilters} multichoice section="Locations" onChange={updateFilter}  onApply={(data) => {getFounders({filter:data, append:false})}} filters={data?.data?.locations ? {locations : data?.data?.locations} : null} />
                        {
                          filterIsActive ? (
                            <Box>
                              <BrandButton
                                text="Reset Filters" onClick={handleResetFilter} colorScheme="blue" w={"100%"} className="w-full max-w-min" 
                              />
                            </Box>
                          ) : null
                        }
                      </Box>
                    </Box>
                  {
                    showViewAll ? (
                      <Box onClick={() => {navigate('/founders')}} className={`gap-2 py-1 cursor-pointer items-center font-medium hover:font-semibold flex`}>
                        <Box className="sm:pr-2 sm:border-r">
                          <img className="h-3.5 w-3.5" src={dashboard} alt='dashboard-icon'/>
                        </Box>
                        <Text className="hidden sm:block text-green-800 text-sm">View all</Text>
                      </Box>
                    ) : (
                      <Tooltip label="Toggle to see the list of companies you marked as uninterested">
                      <Box className={`gap-2 py-1 cursor-pointer items-center font-medium hover:font-semibold flex`}>
                        <Box className="sm:pr-2">
                          <Switch onChange={(e) => toggleShowAllCompanies(e.target.checked)} isChecked={isChecked} />
                        </Box>
                          <Text className="hidden sm:block text-green-800 text-sm">Show all</Text>
                      </Box>
                      </Tooltip>

                    )
                  }
                </Flex>
            ) : null
          }
          </>
        ) : null
      }
      <Box 
        ref={foundersContainerRef}  
        onScroll={() => {handleScroll(foundersContainerRef.current.scrollTop + foundersContainerRef.current.clientHeight, foundersContainerRef.current.scrollHeight)}} 
        className={`hide-scroll ${isDesktop ? 'max-h-[90vh] overflow-y-auto pt-4 md:px-5 pb-32' : ''}`} 
      >
        {!isOpen ? (
          <>
            {viewDetailsIsOpen ? (
              <ViewFounderDetails
                founder={selectedFounder.id}
                onToggle={onToggleViewDetails}
              />
            ) : (
              <>
              {!isLoading ? (
                <Box
                  h="100%"
                  className="flex flex-wrap gap-3 items-center px-4 justify-start mx-auto"
                >
                  {founders && founders.length > 0  ? founders.map((founder, index) => (
                    <Fragment key={index}>
                      <NewSingleFounder
                        setSelectedFounder={setSelectedFounder}
                        founder={founder}
                        isOpen={viewDetailsIsOpen}
                        onToggle={onToggleViewDetails}
                        onDislikeCompany={onDislikeCompany}
                      />
                    </Fragment>
                  )) : <Flex className="text-center justify-center my-2 w-full">No founders available yet!</Flex>}
                </Box>
              ) : (
                <Spinner />
              )}
            </>
            )}
          </>
        ) : null}
      </Box>
    
    </Flex>
  );
};

export default FoundersList;
