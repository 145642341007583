// import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CustomToast } from '../components/toast/CustomToast';
import UserService from '../utils/UserService';
import { useDispatch } from 'react-redux';
import { setIsAuthenticated } from '../redux-toolkit/reducers/authSlice';
import { setUserInfo } from '../redux-toolkit/reducers/userSlice';
import Logout from '../utils/Logout';

export const useErrorHandler = () => {
	const { addToast } = CustomToast();
	const navigate = useNavigate(); 
	const dispatch = useDispatch();
	const { doLogout } = Logout();

	const getErrorMessage = (error) => {
		if (error.response && error.response.data) {
			const { message } = error.response.data;
			if (message) {
				return Array.isArray(message)?message.join(',') : (message?.message || message);
			}
			return error.response.data;
		}
		if (error.response) {
			return error.response.statusText || 'Error occurred';
		}
		return error.message;
	};

	// handles toast for error. called by default on error
	const handleError = (error) => {
		if (!error.response) {
			addToast({ message: error.message, type: "error" });
		}
		else if (error.response?.status === 401) {
			addToast({ message: getErrorMessage(error), type: "error" });
			doLogout();
			// UserService.removeToken();
			// UserService.removeUserInfo();
			// dispatch(setIsAuthenticated(false));
			// dispatch(setUserInfo(null));
			// navigate("../login");

		}else{
			addToast({ message: getErrorMessage(error), type: "error" });
		}
	};

	return { handleError };
};