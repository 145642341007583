import { Box, Flex, Text } from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/header/Header";
import ConvertVideoLinkToEmbedLink from "../../../utils/ConvertVideoLinkToEmbedLink";

const VideoViewer = () => {
  const video = useSelector((state) => state.company.companyInfo.video_url);
  const navigate = useNavigate();
  const embedLink = ConvertVideoLinkToEmbedLink(video);
  return (
    <Box h="90vh" display="flex" flexDirection="column">
      <Header title="My Pitch Deck" />
      <Flex cursor="pointer" onClick={() => navigate(-1)} justifyContent="flex-start" alignItems="center">
        <Box mr={2}>
          <HiOutlineArrowNarrowLeft size="35px" />
        </Box>
        <Box>
          <Text fontWeight={600}>Back</Text>
        </Box>
      </Flex>
      {video ? (
        <Box flex="1">
          <object data={embedLink} width="100%" height="90%">
          <iframe width="100%" height="100%" src={embedLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </object>
        </Box>
      ) : (
        <Text>You haven't uploaded a video</Text>
      )}
    </Box>
  );
};

export default VideoViewer;
