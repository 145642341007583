import React, { useState } from "react";
import {
  Center,
  Text,
  Stack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Input,
} from "@chakra-ui/react";
import BrandButton from "../brandButton/BrandButton";
import { AiFillWarning } from "react-icons/ai";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useNavigate } from "react-router-dom";
import { CustomToast } from "../toast/CustomToast";

export default function DeleteAccountModal({ isOpen, onClose }) {
  const { mutate, isLoading } = useMutation(mutateFunction);
  const navigate = useNavigate();
  const { addToast } = CustomToast();
  const [password, setpassword] = useState("");

  const formSubmit = async () => {
    mutate(
      { key: "user-verify-removal", method: "post", data : {} },
      {
        onSuccess(res) {
          addToast(res);
          navigate("/login");
        },
      }
    );
  };

  

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={4}>
            {/* <form onSubmit={(e)=>addSlot(e)}> */}
            <Center d="flex" flexDirection="column">
              <Stack spacing={6}>
                <Flex className="items-center gap-3" justify={"center"}>
                  <Text
                    fontSize={20}
                    fontWeight={700}
                    color="brand.primary"
                    align="center"
                  >
                    Delete Account
                  </Text>
                  <AiFillWarning style={{ color: "red", fontSize: "40px" }} />
                </Flex>

                <Text fontWeight={800} fontSize={14} color="#000000">
                  Are you sure you want to delete your Account?
                </Text>
              </Stack>
              <Flex
                borderWidth="1px"
                borderColor="brand.primary"
                borderRadius="5px"
                alignItems="center"
                mt={"20px"}
                style={{display : "none"}}
              >
                <form >
                  <Input
                  
                    focusBorderColor="#"
                    borderWidth="0px"
                    variant="outline"
                    isRequired={false}
                    name="password"
                    onChange={(e) => setpassword(e.target.value)}
                    placeholder="Please enter your password to consent"
                    type="password"
                    w={"350px"}
                    h="50px"
                  />
                </form>
              </Flex>
              <Flex gap={10}>
                <BrandButton
                  // isLoading={isLoading}
                  mt={6}
                  text={`${isLoading ? "Loading..." : "Yes"}`}
                  colorScheme="blue"
                  bg={"red"}
                  onClick={() => formSubmit()}
                />
                <BrandButton
                  // isLoading={isLoading}
                  mt={6}
                  text="No"
                  //   colorScheme="red"
                  onClick={onClose}
                />
              </Flex>

              <Text
                m={4}
                fontSize={14}
                fontWeight={600}
                cursor="pointer"
                color="#134A70"
                as="u"
                onClick={onClose}
              >
                Close
              </Text>
            </Center>
            {/* </form> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
