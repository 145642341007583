import {
    Drawer,
	DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react';
import Calendar from '../../calendar/Calendar';

const CalendarDrawer = ({ isOpen, onClose, btnRef }) => {
    
    return (
        <>
            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay d={['block', 'none']} />
                <DrawerContent w='100%' d={['block', 'none']}>
                    <DrawerCloseButton />
                    <DrawerHeader></DrawerHeader>
                    <DrawerBody w="100%" mt={12} p={6} borderRadius={6}>
                        <Calendar />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}
 
export default CalendarDrawer;