import React, { isValidElement } from "react";
import { Box, Image, Text, Button, Flex } from "@chakra-ui/react";

// const BrandButton = ({colorScheme, onClick, width, text, icon, type}) => {
const BrandButton = (props) => {
  // colorSchemes are "blue" or "white"... anything not "blue", returns a white button
  // types are "button", "submit", "reset"

  return (
    <>
      {props.colorScheme === "blue" ? (
        <Button
          w={props.width ? props.width : "120px"}
          bg={`${props.bg ?? "brand.primary"}`}
          borderRadius={props.borderRadius || "5px"}
          onClick={props.onClick}
          type={props.type || "button"}
          _active={{ backgroundColor: "brand.secondary" }}
          _hover={props._hover || { backgroundColor: "brand.dark" }}
          isLoading={props.isLoading || false}
          {...props}
          p={0}
        >
          <Flex w="100%" alignItems={"center"} justifyContent="center" px={2}>
            {props.icon && <Image ml={2} src={props.icon} size={30} />}
            <Box ml={props.icon ? 2 : 0}>
              <Text
                fontWeight={500}
                fontSize={props.fontSize || "15px"}
                color="white"
              >
                {props.text}
              </Text>
            </Box>
          </Flex>
        </Button>
      ) : (
        <Button
          as="button"
          w={props.width ? props.width : "120px"}
          display="flex"
          alignItems="center"
          bg="white"
          borderRadius="5px"
          borderWidth="2px"
          borderColor={props.borderColor || "brand.primary"}
          onClick={props.onClick}
          type={props.type || "button"}
          _active={{ backgroundColor: "lightgray" }}
          _hover={props._hover}
          isLoading={props.isLoading || false}
          {...props}
          p={0}
        >
          <Flex w="100%" alignItems={"center"} justifyContent="center" px={2}>
            {props.icon && typeof props.icon === "string" && (
              <Image src={props.icon} size={10} />
            )}
            {props.icon && isValidElement(props.icon) && props.icon}
            <Box ml={props.icon ? 2 : 0}>
              <Text
                fontWeight={500}
                fontSize={props.fontSize || "16px"}
                color={props.color || "brand.primary"}
              >
                {props.text}
              </Text>
            </Box>
          </Flex>
        </Button>
      )}
    </>
  );
};
export default BrandButton;
