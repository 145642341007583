import { Box, Center, Text, Stack, Flex, Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, FormControl, FormLabel, Textarea, Button, Input, Heading, useDisclosure, Divider, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, VStack, Checkbox, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BrandButton from "../../../../components/brandButton/BrandButton";
import { CustomToast } from "../../../../components/toast/CustomToast";
import { useMutation, mutateFunction } from "../../../../libs/apis";

const ViewSlot = ({slot, isOpen, onClose, onDelete = () => {} }) => {
  const [slotData, setSlotdata] = useState();
  const [slot_setting, setSlot_setting] = useState();
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const [customDays, setCustomDays] = useState([]);
  const [endDate, setEndDate] = useState("")
  const [repeatDuration, setRepeatDuration] = useState(0);

  useEffect(() => {
    if (slot) {
        setSlotdata(
            {
                slot_start_date: slot?.slot_start_date,
                slot_end_date: slot?.slot_end_date,
                slot_title: slot?.slot_title,
                slot_description: slot?.slot_description,
                slot_link: slot?.slot_link
              }
        )
    }
    }, [slot])

  const handleChange = (e) => {
    if (e.target.name === "slot_start_date" && !slot_setting?.endDate) {
      setSlotdata((prev) => ({
        ...prev,
        slot_start_date: e.target.value,
      }));
      setEndDate(e.target.value)
    } else{
      setSlotdata((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const deleteSlot = () => {
    mutate(
      { key: `experts/${slot.id}/delete-slot`, method: "delete", },
      {
        onSuccess(res) {
          addToast(res);
          onClose();
          onDelete();
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={4}>
          {/* <form onSubmit={(e)=>addSlot(e)}> */}
          <Center d="flex" flexDirection="column">
            <Stack spacing={6}>
              <Text fontSize={20} fontWeight={700} color="brand.primary" align="center">
                Slot Details
              </Text>
              <Text fontSize={14} color="#9FA7AD">
                Ensure you enter the details of your slot correctly.
              </Text>
              <Box>
                <Text fontSize={14} fontWeight={400} color="#000">
                  Slot Title
                </Text>
                <Input defaultValue={slot?.slot_title} name="slot_title" placeholder="Title" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
              </Box>
              <Box>
                <Text fontSize={14} fontWeight={400} color="#000">
                  Description
                </Text>
                <Input defaultValue={slot?.slot_description} name="slot_description" placeholder="Description" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
              </Box>
              <Box>
                <Text fontSize={14} fontWeight={400} color="#000">
                  Slot Link
                </Text>
                <Input defaultValue={slot?.slot_link} name="slot_link" placeholder="Paste link here" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
              </Box>
              <Box>
                <Text fontSize={14} fontWeight={400} color="#000">
                  Date
                </Text>
                <Input defaultValue={slot?.slot_start_date?.substr(0, 10)} name="slot_start_date" type="date" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
              </Box>
              <Flex w="100%" justifyContent="space-between">
                <Box w="45%">
                  <Text fontSize={14} fontWeight={400} color="#000">
                    Start time
                  </Text>
                  <Input defaultValue={slot?.slot_start_time} name="slot_start_time" type="time" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
                </Box>
                <Box w="45%">
                  <Text fontSize={14} fontWeight={400} color="#000">
                    End time
                  </Text>
                  <Input defaultValue={slot?.slot_end_time} name="slot_end_time" type="time" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
                </Box>
              </Flex>
            </Stack>
            <Box className="w-full flex items-center justify-center gap-3 flex-wrap">
              <BrandButton mt={6} text="Close" colorScheme="blue" onClick={onClose} />
              {slot?.status.toLowerCase() == "available" ?  <BrandButton style={{background: "#ca2d2d", color: "white", border: "none"}} color="white" isLoading={isLoading} mt={6} text="Delete Slot" onClick={() => deleteSlot()} /> : null}
            </Box>
          </Center>
          {/* </form> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewSlot;
