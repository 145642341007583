import Header from "../../../components/header/Header";
import { Document } from 'react-pdf'
import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Box,
  Flex,
  Heading,
  Wrap,
  WrapItem,
  Spinner,
  Text,
  VStack,
  HStack,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";
import { MdOutlineClose } from "react-icons/md";
import SingleFounder from "../../../components/Founders/SingleFounder";
import IsDesktop from "../../../utils/IsDesktop";
import client from "../../../libs/client";
import { BASE_URL } from "../../../helpers/constant";
import ViewFounderDetails from "../../../components/Founders/ViewFounderDetails";
import Filter from "../../../components/filter/Filter";
import { ChevronDownIcon, ChevronUpIcon, StarIcon } from "@chakra-ui/icons";

import ConnectCard from "../../founder/Dashboard/ConnectWithUserCards/ConnectCard";
import { useData } from "../../../data";
import { SearchIcon } from "@chakra-ui/icons";
import { HiChat } from "react-icons/hi";
import StyledSpinner from "../../../components/spinner/Spinner";
import ChatBox from "../../experts/Connections/ChatBox";
import ConnectionMessage from "../../experts/Connections/ConnectionMessage";
import AdvertMessage from "../../experts/Connections/AdvertMessage";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useInterval } from "../../../utils/useInterval";
import SearchBar from "../../../components/searchbar/SearchBar";
import { Select } from "antd";
import { BsArrowLeft, BsFillHandThumbsUpFill, BsXCircle } from "react-icons/bs";
import ConnectionButton from "../../../components/connectionButton/ConnectionButton";
import PdfViewer from "../../../components/pdfViewer/pdfViewer";
import YouTubePreview from "../../../components/youtubePreview/youtubePreview";
import { useNavigate } from "react-router-dom";
import StartupOffer from "../../../components/startup-offers/StartupOffer";
import StartupOffersCard from "../../founder/Dashboard/StartupOffersCard";
import AdvertFeature from "../../founder/Dashboard/AdvertFeature";
import { setUserInfo } from "../../../redux-toolkit/reducers/userSlice";
import FoundersList from "../../../components/Founders/FoundersList";
import RequestList from "../../../components/notification/RequestLists";
import { useWindowSize } from "../../../hooks/useWindowSize";


const FoundersPage = () => {
  const navigate = useNavigate();
  const viewDetails = () => {
    navigate(`/view-user?userid=${selectedUser?.id}&usertype=${selectedUser?.userTypeId}`)
}
  const userDetails = useSelector((state) => state?.user?.userInfo);
  const loggedInUserType = userDetails?.userTypeTitle || "";
  const { data, refetch } = useData(`users/${userDetails.id}`);
  const companyDetails = useSelector((state) => state?.company?.companyInfo);
  const { isOpen, onToggle } = useDisclosure();
  const [selectedFounder, setSelectedFounder] = useState({});
  const isDesktop = IsDesktop();
  const [pageNo, setPageNo] = useState(1);
  const lastResultsCount = useRef(0);
  const [founders, setFounders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilters] = useState({});
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const videoUrl = selectedUser?.company?.video_url;
  const [topConnectionIds, setTopConnectionIds] = useState([])
  const { isOpen: viewDetailsIsOpen, onToggle: onToggleViewDetails } =
    useDisclosure();
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  const chatWithUserState = useSelector((state) => state?.chat?.chatInfo);
  const [recipient, setRecipient] = useState(chatWithUserState);
  const [recipientId, setRecipientId] = useState(chatWithUserState?.id || null);
  const [isAdvert, setIsAdvert] = useState(false)
  const [advertId, setAdvertId] = useState(null)
  const [selectedAdvert, setSelectedAdvert] = useState(null)
  const { data: inboxAdverts, isLoading: inboxAdvertsLoading, refetch: refetchInboxAdverts } = useData(`inbox-adverts/`);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [showMessageTray, setShowMessageTray] = useState(false);
  const dispatch = useDispatch()

  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const { OptGroup } = Select;

  const [connections, setConnections] = useState([]);
  const {
    data: connectionsData,
    isLoading: connectionsLoading,
    refetch: refetchConnections,
  } = useData(`users/${loggedInUser?.id}/connections?page_no=1&page_size=10`, 10000);
  const {
    isOpen: chatBoxIsOpen,
    onOpen: onOpenChatBox,
    onClose: onCloseChatBox,
    onToggle: onToggleChatBox,
  } = useDisclosure();
  const foundersContainerRef = useRef(null);
  const sortFn = (a, b) => moment(b?.lastMessage?.createdAt || b?.connection?.created_at || b?.createdAt).diff(moment(a?.lastMessage?.createdAt || a?.connection?.created_at || a?.createdAt), 'second')
  const {
    isOpen: requestListIsOpen,
    onOpen: onOpenRequestList,
    onToggle: onToggleRequestList
  } = useDisclosure()

  useEffect(() => {
    if (connectionsData?.data) {
      setConnections(
        connectionsData?.data?.connections?.sort(sortFn)
      );
    }
    if (chatWithUserState) {
      openChatBox(chatWithUserState?.id, chatWithUserState);
    }
  }, [connectionsData?.data?.connections, chatWithUserState]);

  useEffect(() => {
    setTopConnectionIds(connections.slice(0, 2).map(({id}) => id))
  }, [connections.length])

  const openMessageTray = () => {
    if(userDetails?.status == "disabled") return 
    setShowMessageTray((prev) => !prev);
  };

  const openChatBox = (recipientId, recipient) => {
    setIsAdvert(false)
    setAdvertId(null)
    setSelectedAdvert(null)
    setRecipientId(recipientId);
    setRecipient(recipient);
    onOpenChatBox();
  };

  const windowSize = useWindowSize()

  const openAdvert = (advertId, advert) => {
    // console.log("opening advert=====", advertId, advert, recipient, recipientId)
    setIsAdvert(true)
    setAdvertId(advertId)
    setSelectedAdvert(advert)
    setRecipient(null)
    setRecipientId(null)
    onOpenChatBox()
  }

  const closeChatBox = () => {
    setRecipientId(null);
    setRecipient(null);
    setAdvertId(null)
    setSelectedAdvert(null)
    onToggleChatBox();
  };

  const isFromNavigate = () => {
    return (
      chatWithUserState &&
      chatWithUserState?.id === recipientId &&
      chatWithUserState?.connection?.accepted
    );
  };

  const searchConnections = useCallback(
    (inputValue) => {
      setIsLoadingMessages(true);
      if (inputValue !== "") {
        setSearchData(
          connections?.filter((connection) =>
            `${connection?.first_name} ${connection?.last_name}`
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          )
        );
      }
      setIsLoadingMessages(false);
    },
    [connections]
  );

  const searchResults = searchData?.map((user) => (
    <Select.Option key={user.id}>
      <Flex alignItems="center">
        <Avatar
          size="sm"
          name={`${user.first_name} ${user.last_name}`}
          src={user.profile_image}
        />
        <Text ml={4}>{`${user.first_name} ${user.last_name}`}</Text>
      </Flex>
    </Select.Option>
  ));

  const openSearchedUserChatBox = (userId) => {
    setRecipientId(userId);
    setRecipient(
      connections?.find(
        (connection) => connection?.id?.toString() === userId.toString()
      )
    );
    onOpenChatBox();
  };

  const handleScroll = (
    scrollpos = window.innerHeight + window.scrollY,
    containerHeight = document.documentElement.offsetHeight
  ) => {
    let userScrollHeight = scrollpos;
    let windowBottomHeight = containerHeight;

    if (userScrollHeight >= windowBottomHeight) {
      if (lastResultsCount.current < 1) {
        return;
      } else {
        let _pageNo = pageNo + 1;
        setPageNo(_pageNo);
        getFounders({ pageNumber: _pageNo });
      }
    }
  };

  const updateFilter = (data) => {
    setFilters(data);
  };

  const updateState = (data, append = true, isFetchedFromServer = true) => {
    if(isFetchedFromServer)lastResultsCount.current = data.length;
    let _founders = append ? [...(founders || []), ...data] : [...data];
    setFounders(_founders);
    setSelectedUser((_founders || [])[0]);
    setIsLoading(false);
  };


  const parseFilters = (obj) => {
    const params = {
      companyStages: "company_stage",
      fundCategories: "fund_category",
      locations: "incorporation_location",
      businessModels: "business_model",
      categories: "business_category",
    };
    return Object.entries(obj)
      .map(([key, val]) => `${params[key]}=${encodeURIComponent(val)}`)
      .join("&");
  };

  const getFounders = ({ filters = filter, append = true, pageNumber = 1 }) => {
    const _filters = parseFilters(filters);
    return client()
      .get(
        `${BASE_URL}user-types/founder?page_no=${pageNumber}&page_size=10${
          _filters ? `&${_filters}` : ""
        }`
      )
      .then((res) => {
        setIsLoading(true);
        const data = res.data.data.users;
        updateState(data, append);
      });
  };

  const likeCompany = async(e, companyId) => {
    try {
      e.stopPropagation()
      //let _founders = founders.filter(user => user.company.id !== companyId) 
      //updateState(_founders, false, false)

      if(userDetails?.likedCompanies?.includes(companyId)){
        return client()
        .delete(
          `${BASE_URL}remove-preference/${companyId}`
        )
        .then((res) => {
          
          dispatch(setUserInfo({
            ...userDetails,
            likedCompanies: userDetails?.likedCompanies?.filter((id) => id !== companyId)
          }))
        });
      }else{
        return client()
        .put(
          `${BASE_URL}like-company/${companyId}`
        )
        .then((res) => {
          
          dispatch(setUserInfo({
            ...userDetails,
            likedCompanies: [...userDetails.likedCompanies, companyId],
            disLikedCompanies: userDetails?.disLikedCompanies?.filter((id) => id !== companyId)
          }))
        });
      }
      
    } catch (e) {
      //console.log("the error====", e)
    }
  }

  const onDislikeCompany = (companyId) => {
    try {
      let _founders = founders.filter(user => user.company.id !== companyId) 
      updateState(_founders, false, false)
    } catch (e) {
      //console.log(e)
    }
  }

  const disLikeCompany = async(e, companyId) => {
    try {
      e.stopPropagation()

      if(userDetails?.disLikedCompanies?.includes(companyId)){
        return client()
        .delete(
          `${BASE_URL}remove-preference/${companyId}`
        )
        .then((res) => {
          dispatch(setUserInfo({
            ...userDetails,
            disLikedCompanies: userDetails?.disLikedCompanies?.filter((id) => id !== companyId)
          }))
        });
      }else{
        return client()
        .put(
          `${BASE_URL}dislike-company/${companyId}`
        )
        .then((res) => {
          dispatch(setUserInfo({
            ...userDetails,
            likedCompanies: userDetails?.likedCompanies?.filter((id) => id !== companyId),
            disLikedCompanies: [...userDetails.disLikedCompanies, companyId],
          }))

          onDislikeCompany(companyId)
        });
      }
      
    } catch (e) {
      //console.log("the error====", e)
    }
  }


  const onClickUserConnectionButton = (id, status) => {
    const _founders = [...founders].map((item) => {
      if(item.id === id) {
        item.connectionStatus = status;
      }
      if(item.id === id && status === "Connected" ){
        item.connection.accepted ? item.connection.accepted = true : item.accepted = true;
      }
      return item
    })

    setFounders(_founders)
  }

  const containerRef = useRef(null)

  useEffect(() => {
    getFounders({});
    onCloseChatBox()
  }, []);

  const onSelectUser = (id) => {
    setSelectedUserIndex(id);
  };

  useEffect(() => {
    onSelectUser(0);
  }, [isLoading]);

  useEffect(() => {
    let user = founders[selectedUserIndex];

    user && setSelectedUser(user);
  }, [selectedUserIndex, isLoading]);

  useEffect(() => {
    if(connections?.filter((connection) => !connection.date_accepted && connection.source_user_id !== userDetails.id)?.length > 0 && windowSize > 1279){
      onOpenRequestList()
    }else{
    }
  }, [connections?.length])


  return (
    <>
      <Box className="w-[100%] flex flex-col overflow-hidden">
        <Flex
          className="w-[100%] lg:gap-2 xl:gap-5"
          flexDir={!isDesktop ? "column" : "row"}
          h={isDesktop ? "100%" : "auto"}
          w="100%"
          p={4}
          overflow="hidden"
        >
          
          <Box
            className={`hide-scroll ${
              isDesktop ? `max-h-[90vh] overflow-y-auto w-full` : ""
            }`}
          >
            <Box className="rounded-lg w-full max-w-[695px]">
              <AdvertFeature />
            </Box>
              <FoundersList showViewAll={true} />
          </Box>
          { 
            requestListIsOpen && connections?.filter((connection) => !connection.date_accepted).length > 0 ? (
              <Box className="hidden bg-white xl:flex flex-col border border-[#DBE2ED] rounded-lg min-w-[270px]  min-h-max">
                <Box style={{boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.05)'}} className="flex justify-between p-3 w-full ">
                  <Box className="flex items-center w-full gap-1.5">
                    <Text className="font-semibold text-2xl text-[#2F4B6E]">
                      Request List
                    </Text>
        
                        <Box className="bg-[#CF1137] flex items-center justify-center px-3 py-[2px] rounded-md">
                          <Text className="text-white">
                            {connections?.filter((connection) => !connection.date_accepted && connection.source_user_id !== userDetails.id).length}
                          </Text>
                        </Box>
                  </Box>
                  <Box onClick={onToggleRequestList} className="flex items-center px-1 rounded-full gap-[2px] border border-transparent cursor-pointer hover:border-gray-300 h-min hover:bg-gray-100">
                      <MdOutlineClose className="text-sm" />
                      <Text className="text-[#487F1E] text-xs">close</Text>
                    </Box>
                </Box> 
            
                <Box
                  className={`h-full hide-scroll p-3 ${
                    isDesktop ? "max-h-[100vh] overflow-y-auto" : ""
                  }`}
                >
                  <RequestList onReactToRequest={refetchConnections} />
                </Box>
              </Box>
              ) : null
           }
        </Flex>
        {
          !requestListIsOpen ? (
            <Flex
          hidden={isDesktop ? false : true}
          className="fixed hidden items-end min-w-max right-10 gap-8 z-10 bottom-0"
        >
          <Flex
            className="bg-white"
            boxShadow="0px 10px 20px rgba(0, 0, 0, 0.05)"
          >
            {chatBoxIsOpen && (
              <Box w={"480px"} h="80vh max-h-[300px]">
                <ChatBox
                  advert={selectedAdvert} 
                  advertId={advertId} 
                  isAdvert={isAdvert} 
                  isFromNavigate={isFromNavigate}
                  recipientId={recipientId}
                  setRecipient={setRecipient}
                  recipient={recipient}
                  closeChatBox={closeChatBox}
                  refetchConnections={refetchConnections}
                  setConnections={setConnections}
                />
              </Box>
            )}
          </Flex>
          <Flex
            boxShadow="0px 10px 20px rgba(0, 0, 0, 0.05)"
            className="flex-col bg-white min-w-[300px] rounded-t-md z-10"
          >
            <Flex
              bgColor="#134A70"
              className="items-center justify-between py-2 px-3 rounded-t-md cursor-pointer"
              onClick={openMessageTray}
            >
              <Text
                fontSize={{ base: 14 }}
                fontWeight={800}
                ml={{ base: "3rem", lg: 0 }}
                color="#fff"
              >
                New Conversation
              </Text>
              <Box>
                {showMessageTray ? (
                  <ChevronDownIcon w={7} h={7} color="white" />
                ) : (
                  <ChevronUpIcon w={7} h={7} color="white" />
                )}
              </Box>
            </Flex>
            <Box className="max-h-[60vh] overflow-y-auto">
              {showMessageTray ? (
                connectionsLoading ? (
                  <StyledSpinner />
                ) : (
                  <>
                    {[...(inboxAdverts?.data?.adverts || []), ...connections]?.length > 0 ? (
                      [...(inboxAdverts?.data?.adverts || []), ...connections.filter((b) => b.accepted === true)]?.sort(sortFn)?.map((connection, index) => (
                        connection?.advert_type ? <AdvertMessage advert={connection} key={index} openChatBox={openAdvert} /> : <ConnectionMessage
                            recipient={connection}
                            key={index}
                            openChatBox={openChatBox}
                          />
                        ))
                    ) : (
                      <Box className="text-center">No connections...</Box>
                    )}
                  </>
                )
              ) : (
                <Box py={1}></Box>
              )}
            </Box>
          </Flex>
        </Flex>
          ) : null
        }
      </Box>
    </>
  );
};

const Dashboard = () => {
  const isDesktop = IsDesktop();
  return (
    <Box className="bg-[#f0f0f0]">
      <Header title="Dashboard" />
      <Flex
        className={`flex-col ${
          isDesktop ? "max-h-[95vh] pb-10 overflow-y-scroll" : ""
        }`}
      >
        <FoundersPage />
      </Flex>
    </Box>
  );
};

export default Dashboard;
