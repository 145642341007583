import ComposeInternalLayout from "../components/HOC/ComposeInternalLayout";
import { expertsUrl } from "../url/urls";
import Dashboard from "../pages/experts/Dashboard/Dashboard";
import Feed from "../pages/experts/Feed";
import Founders from "../pages/investor/Dashboard/Dashboard";
import AccountSettings from "../pages/founder/Settings/AccountSettings.jsx";
import Connections from '../pages/experts/Connections/Connections';
import PostPage from "../pages/experts/Post";

export const expertsRoutes = [
    {
      title: 'Dashboard',
      path: expertsUrl.dashboard(),
      element: ComposeInternalLayout(Dashboard),
    },
    {
      title: 'Feed',
      path: expertsUrl.feed(),
      element: ComposeInternalLayout(Feed),
    },
    {
      title: 'Post',
      path: expertsUrl.post(),
      element: ComposeInternalLayout(PostPage),
    },
    {
      title: 'Founders',
      path: expertsUrl.founders(),
      element: ComposeInternalLayout(Founders),
    },
    {
      title: 'Connections',
      path: expertsUrl.connections(),
      element: ComposeInternalLayout(Connections),
    },
    {
      title: 'Account Settings',
      path: expertsUrl.accountSettings(),
      element: ComposeInternalLayout(AccountSettings),
    }
]
