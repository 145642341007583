import { Box, Flex, HStack, Input, InputGroup, Select, InputLeftAddon, Text, Textarea, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import BrandButton from "../../../components/brandButton/BrandButton";
import { CustomToast } from "../../../components/toast/CustomToast";
import { useData } from "../../../data";
import { mutateFunction, useMutation } from "../../../libs/apis";
import IsDesktop from "../../../utils/IsDesktop";
import { useClickOutside } from "../../../utils/useClickOutside";


const AddOffer = ({ onToggle, refetch }) => {
  const isDesktop = IsDesktop();
  const { data } = useData(`brands`);
  const [offerData, setOfferData] = useState({});
  const [brands, setBrands] = useState([]);
  const { mutate, isLoading: mutateIsLoading} = useMutation(mutateFunction);
  const {addToast} = CustomToast();
  const {data : _offersCategoriesData} = useData("offers/categories")
  const list = _offersCategoriesData?.data?.categories || []
  const [ref] = useClickOutside(() => {
    if (isOpen) setIsOpen(false);
  });
  const [selected, setSelected] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const toggleDropDown = () => setIsOpen(prev => !prev)
  const buttonRef = useRef()


  const addStartupOffer = () => {
    mutate(
        { key: `offers`, method: "post", data: {
          ...offerData,
          category_id : selected?.id || null
        }},
        {
          onSuccess(res) {
            addToast(res)
            setOfferData();
            refetch();
            setSelected(null)
            setSearchValue('')
            buttonRef?.current.click()
            setTimeout(() => {
            buttonRef?.current.click()
            onToggle();
            }, 2000)
          },
        }
      );
  }

  const handleChange = (e) => {
    if (e.target.name === "brand_id"){
        setOfferData((prev) => ({
            ...prev,
            [e.target.name]: Number(e.target.value)
          }));
    } else{
        setOfferData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        }));
    }
  };

  useEffect(() => {
    if (data?.data) {
      setBrands(data?.data?.brands);
    }
  }, [data]);

  const searchCategoriesFilter = (value) => {
    return searchValue ? value?.title?.toLowerCase().includes(searchValue.toLowerCase()) || value?.title == "Other" : true
  }
  
  const onChange = (e) => {
    setIsOpen(true)
    setSearchValue(e.target.value)
    setSelected(null)
  }

  const selectItem = (item) => {
    setSearchValue(item?.title)
    setSelected(item)
    toggleDropDown()
  }

  return (
    <VStack alignItems="flex-start">
      <Flex justifyContent="flex-start" alignItems="center" my={4} mx={4}>
        <Flex ref={buttonRef} cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
          <Box mr={2}>
            <HiOutlineArrowNarrowLeft size="35px" />
          </Box>
          <Box>
            <Text fontWeight={600}>Back</Text>
          </Box>
        </Flex>
      </Flex>
      <HStack className="overflow-y-scroll h-[70vh] pb-10 hide-scroll" w="100%" alignItems="flex-start" m={4}>
        <VStack w="60%" alignItems="flex-start">
          <Flex flexDir="column" w="100%" key="title">
            <Text fontSize="14px" mb={2}>
              Offer Title
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer Title" name="title" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex>
          <Flex flexDir="column" w="100%" key="brand">
            <Text fontSize="14px" mb={2}>
              Select Brand
            </Text>
            <Select borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" placeholder="Brand" name="brand_id" onChange={ handleChange}>
              {brands?.map((eachBrand) => (
                <option key={eachBrand.id} value={eachBrand.id}>
                  {eachBrand.name}
                </option>
              ))}
            </Select>
            
          </Flex>
          <Flex flexDir="column" w="100%" key="value">
            <Text fontSize="14px" mb={2}>
              Offer Price
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer price" name="price" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex>
          {/* <Flex flexDir="column" w="100%" key="price">
            <Text fontSize="14px" mb={2}>
              Offer Value
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer value" name="offer_value" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex> */}
          <Flex flexDir="column" w="100%" key="offer_url">
            <Text fontSize="14px" mb={2}>
              Offer URL
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer url" name="offer_url" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex>
          <Flex flexDir="column" w="100%" key="caption">
            <Text fontSize="14px" mb={2}>
              Offer Caption
            </Text>
            <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer Caption" name="caption" type="text" w="100%" h="50px" onChange={(e) => handleChange(e)} />
          </Flex>
          <div className={`relative w-full`} ref={ref}>
                                
                                <Text fontSize="14px" mb={2}>
                                  Categories

                                </Text>
                                <div ref={ref} className={`bg-white cursor-pointer flex justify-between items-center mb-2 max-w-2xl `}>
                                  
                                  <Input
                                    key="category"
                                    id="category"
                                    value={searchValue}
                                    onChange={onChange}
                                    placeholder={"Category"}
                                    className="border border-[#134A70] w-full py-3 pr-2 pl-4 rounded-lg outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none"
                                    borderRadius="10px"
                                    borderWidth={1}
                                    borderColor="brand.primary"
                                    h="50px"
                                  />
                                </div>
                                {
                                    isOpen && list.length ? (
                                        <div className="absolute top-[80px] bg-white w-full max-w-2xl rounded-2xl py-2 flex flex-col gap-4 left-0 ad-card-shadow z-10">
                                            <div className={`flex flex-col gap-4 max-h-[30vh] overflow-y-auto pb-5`}>
                                            {
                                                list.filter(searchCategoriesFilter).map((item, index) => {
                                                return (
                                                    <div className="cursor-pointer px-5 py-2 hover:bg-gray-100" onClick={() => {selectItem(item)}} key={item?.id}>{item?.title}</div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    ) : null
                                }
                                
                            </div>
          <Flex flexDir="column" w="100%">
            <HStack spacing={isDesktop ? 2 : 0} flexDir={isDesktop ? "row" : "column"} justifyContent="space-between" alignItems="center" w="100%">
              <Flex flexDir="column" w="100%" key="start_date">
                <Text fontSize="14px" mb={2}>
                  Start Date
                </Text>
                <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="start_date" type="date" size="md" onChange={(e) => handleChange(e)} />
              </Flex>
              <Flex flexDir="column" w="100%" key="end_date">
                <Text fontSize="14px" mb={2}>
                  End Date
                </Text>
                <Input borderWidth="1px" borderColor="brand.primary" borderRadius="5px" name="end_date" type="date" size="md" onChange={(e) => handleChange(e)} />
              </Flex>
            </HStack>
          </Flex>
          <BrandButton isLoading={mutateIsLoading} text="Add Offer" w="120px" colorScheme="blue" onClick={()=>addStartupOffer()} />
        </VStack>
        <Flex flexDir="column" w="38%" h="100%" className="min-h-[500px]" key="details" mx={4}>
          <Text fontSize="14px" mb={2}>
            Offer Details
          </Text>
          <Textarea borderWidth="1px" borderColor="brand.primary" borderRadius="5px" focusBorderColor="#" variant="outline" placeholder="Offer Details" name="description" type="text" h="100%" w="100%" onChange={(e) => handleChange(e)} />
        </Flex>
      </HStack>
    </VStack>
  );
};
export default AddOffer;
