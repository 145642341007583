import { Box } from "@chakra-ui/react";
import { AiOutlineUp } from "react-icons/ai";

const BackToTop = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }
    return (
        <Box className="fixed z-20 right-[25px] bottom-[25px] cursor-pointer p-1 border border-2 rounded-full" borderColor={"brand.primary"} onClick={scrollToTop}>
            <AiOutlineUp fill="#134a70"  size={"30px"} className="" />
        </Box>
    )
}

export default BackToTop