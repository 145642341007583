import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useMutation, mutateFunction } from '../../../libs/apis';
import React, { useContext, useEffect, useState } from "react";
import { Link as Route, useNavigate } from "react-router-dom";
import IsDesktop from "../../../utils/IsDesktop";
import { CustomToast } from "../../../components/toast/CustomToast";
import UserService from "../../../utils/UserService";
import { setIsAuthenticated } from "../../../redux-toolkit/reducers/authSlice";
import { useDispatch } from "react-redux";
import { setUserInfo, setUserSubscriptionInfo } from "../../../redux-toolkit/reducers/userSlice";
import { setCompanyInfo } from "../../../redux-toolkit/reducers/companySlice";
import { setAllCompaniesInfo } from "../../../redux-toolkit/reducers/allCompaniesSlice";
import { BASE_URL } from "../../../helpers/constant";
import client from "../../../libs/client";
import { setAllUserMessages, addNewMessages } from "../../../redux-toolkit/reducers/messagesSlice";
import { UserContext } from "../../../context/UserContext";
import socketIOClient from 'socket.io-client';
import evaluateCompanyProfile from "../../../utils/evaluateCompanyProfile";
import { setExpertInfo } from "../../../redux-toolkit/reducers/expertSlice";
import google from '../../../public/icons/google-logo.svg';
import BrandButton from "../../../components/brandButton/BrandButton";

function LoginWithGoogle({context = 'Sign in'}) {
    const {mutate, isLoading} = useMutation(mutateFunction);
    const isDesktop = IsDesktop();
    const navigate = useNavigate();
    const { addToast } = CustomToast();
    const { 
        emitEvent,
        socket,
        setSocket,
        userInformation
     } = useContext(UserContext)

    const dispatch = useDispatch();

    const connectSocket = () => {
        if(!socket) {
            const Socket = socketIOClient(BASE_URL, {
                extraHeaders: {
                  authorization: `Bearer: ${UserService.getToken()}`,
                },
            });
            setSocket(Socket);
        }
    };

    useEffect(() => {
        UserService.removeCompanyInfo();
        UserService.removeToken();
        UserService.removeUserInfo();
        UserService.removeUserSubscriptionInfo()
        UserService.removeAllCompaniesInfo()
        googleLogout()
    }, [])

    const onSuccess = (response) => {
        try{
                mutate(
                    { key: "google-login", method: "post", data : {
                        token: response.code
                    }},
                    {
                        onSuccess(res) {
                            if(res.data){
                                const user = res?.data?.user;
                                const company = res?.data?.company;
                                const expert = res?.data?.expert
                                UserService.setUserInfo(user);
                                UserService.setToken(res?.data?.token);
                                UserService.setAllCompaniesInfo(company)
                                UserService.setCompanyInfo(company[0] || null)
                                UserService.setExpertInfo(expert)
                                dispatch(setIsAuthenticated(true));
                                dispatch(setUserInfo(user));
                                dispatch(setCompanyInfo(company[0] || null));
                                dispatch(setAllCompaniesInfo(company))
                                dispatch(setExpertInfo(expert))
                                connectSocket()
            
                                client().get(`${BASE_URL}messages`)
                                    .then((res) => {
                                        dispatch(setAllUserMessages(res?.data?.data?.messages))
                                    })
            
                                client().get(`${BASE_URL}users/${user.id}/subscriptions/current`)
                                    .then((res) => {
                                        dispatch(setUserSubscriptionInfo(res?.data?.currentSubscription?.currentSubscription));
                                        UserService.setUserSubscriptionInfo(res?.data?.currentSubscription?.currentSubscription);
                                        navigate("/dashboard");
                                })
                                
                                addToast(res);
                                if(company[0]?.status !== "live" && user?.userTypeId === 1){
                                    addToast("Your account is in draft mode. Please update all company details in account settings", "error")
                                    setTimeout(() => {
                                        evaluateCompanyProfile(company, navigate)
                                    }, 1000)
                                }
                                if(company[0]?.status !== "live" && user?.userTypeId === 2){
                                    addToast("Your account is in draft mode. Please update all company details in account settings", "error")
                                }
                                if(user?.userTypeId === 3 && expert?.status !== "live" ){
                                    addToast("Your account is in draft mode. Please update all expert details", "error")
                                }
                            }
                           
                        },
            
                        onError(e){
                            addToast(e?.response?.data?.message, "error");
            
                        }
                      }
                );
        }catch(e){
            console.trace(e)
        }
    };
    
    const onFailure = (response) => {
        addToast("Login Failed", "error");
    };

    const login = useGoogleLogin({
        onSuccess: onSuccess,
        onFailure: onFailure,
        flow: 'auth-code'
    });

    return (
        <BrandButton isLoading={isLoading} onClick={() => login()} icon={google}  width={isDesktop ? "400px" : "100%"} text={`${context} with Google`} mt={5}/>
    );
}

export default LoginWithGoogle;
