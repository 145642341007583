import React, { useEffect, useRef, useState } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import { Box, Flex, Heading, Wrap, WrapItem, Text } from "@chakra-ui/layout";
import StartupOffer from "./StartupOffer";
import ViewOfferDetails from "./ViewOfferDetails";
import IsDesktop from "../../utils/IsDesktop";
import SearchBar from "../searchbar/SearchBar";
import { BASE_URL } from "../../helpers/constant";
import client from "../../libs/client";
import { Spinner } from "@chakra-ui/react";
import { CustomToast } from "../toast/CustomToast";
import { useMutation, mutateFunction } from "../../libs/apis";
import OpenExternalOfferLinkModal from "../modal/OpenExternalOfferLinkModal";


const Offers = () => {
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const [ isOpen, setIsOpen ] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState({});
  const isDesktop = IsDesktop();
  const {
    isOpen: modalIsOpen,
    onClose: onCloseModal,
    onOpen: onOpenModal
  } = useDisclosure()

  const [link, setLink] = useState('')
  const [offerId, setOfferId] = useState('')

  const [pageNo, setPageNo] = useState(1);
  const lastResultsCount = useRef(0);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null)

  const handleScroll = (scrollpos = window.innerHeight + window.scrollY , containerHeight = document.documentElement.offsetHeight) => {
    let userScrollHeight = scrollpos;
    let windowBottomHeight = containerHeight;
    if (userScrollHeight >= windowBottomHeight) {
        if(lastResultsCount.current < 1){
            return;
        }else{
            setPageNo(pageNo + 1);
        }
    }
  };

  const onToggle = () => {
    setIsOpen(prev => !prev)
  }

  useEffect(() => {
  }, [isOpen])

  const updateState = (data) => {
    lastResultsCount.current = data.length;
    setOffers([...offers, ...data]);
  };

  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [pageNo]);

  useEffect(() => {
    setLoading(true);

    client()
      .get(`${BASE_URL}offers?status=ongoing&page_no=${pageNo}&page_size=10`)
      .then((res) => {
        setLoading(false);

        const data = res?.data?.data.offers;
        updateState(data);
      });
  }, [pageNo]);

  const getDeal = (offer) => {
    setOfferId(offer.id)
          if (offer.offer_url !== null) {
            if (
              offer.offer_url.startsWith("http://") ||
              offer.offer_url.startsWith("https://")
            ) {
              //window.open(offer.offer_url);
              setLink(offer.offer_url)       
              onOpenModal()
            } else {
              //window.open("https://" + offer.offer_url);
              setLink(`https://${offer.offer_url}`)
              onOpenModal()
            }
          } else {
            addToast({ message: "No link", type: "warning" });
          }
  };


  return (
    <Flex 
      ref={containerRef}
      onScroll={() => {handleScroll(containerRef.current.scrollTop + containerRef.current.clientHeight, containerRef.current.scrollHeight)}}
      className={`flex-col px-3 py-5 w-full ${isDesktop ? 'max-h-[95vh] pb-10 overflow-y-scroll' : ''}`}
    >
      <OpenExternalOfferLinkModal isOpen={modalIsOpen} id={offerId} onClose={() => {
        onCloseModal()
        setLink('')
        setOfferId('')
      }} link={link} />
      <Box mt={2} mx={5} py={2}>
        {isOpen && (
          <>
            <ViewOfferDetails
              getDeal={getDeal}
              onToggle={onToggle}
              offer={selectedOffer}
              setSelectedOffer={setSelectedOffer}
              isOpen={isOpen}
            />
            <Flex className="py-20 flex-col">
                <Text>Here are more offers that may interest you</Text>
                <Wrap
                    h="100%"
                    spacing={10}
                    mt={5}
                    py={2}
                    px={2}
                    justifyItems={"flex-start"}
                  >
                    {offers.filter(item => selectedOffer.id !== item.id).map((offer) => (
                      <WrapItem>
                        <StartupOffer
                          key={offer.id}
                          getDeal={getDeal}
                          setSelectedOffer={setSelectedOffer}
                          offer={offer}
                          isOpen={isOpen}
                          onToggle={onToggle}
                        />
                      </WrapItem>
                    ))}
                </Wrap>
            </Flex>
          </>
        )}
        {!isOpen && (
          <>
            <Flex className="flex-col md:flex-row gap-2 items-start md" justifyContent="space-between">
              <Box minW="100px" maxW="70%">
                <Heading
                  whiteSpace="nowrap"
                  color="brand.primary"
                  fontSize={"20px"}
                  mb={4}
                >
                  Get Startup Offers
                </Heading>
                <Text color="gray" fontSize={16} >
                  {
                    isDesktop ? `We have partnered with some of the best services and platforms
                    to bring you Startup Deals! Our exclusive deals are designed
                    to help you grow your business and save money on essential
                    tools and services. As a member of our community, you'll have
                    access to a range of discounted products and services, from
                    software tools and marketing platforms to legal services and
                    more. Don't miss out on these great opportunities to take your
                    startup to the next level!` : `Partnered with leading services for exclusive Startup Deals! Access discounted tools and services to boost your business. Don't miss out on growth opportunities!`
                  }
                </Text>
              </Box>
              <SearchBar 
                searchType="offer"
                searchRoute="search-offers"
                onSelectOption={(offer) => {
                    setSelectedOffer(offer);
                    if(!isOpen) setIsOpen(true)
                }}
              />
            </Flex>

            {loading ? (
              <Spinner />
            ) : (
              <>
                {" "}
                {offers.length > 0 ? (
                  <Wrap
                    h="100%"
                    spacing={10}
                    mt={5}
                    py={2}
                    px={2}
                    justify={isDesktop ? "flex-start" : "center"}
                  >
                    {offers.map((offer) => (
                      <WrapItem>
                        <StartupOffer
                          key={offer.id}
                          getDeal={getDeal}
                          setSelectedOffer={setSelectedOffer}
                          offer={offer}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          onToggle={onToggle}
                        />
                      </WrapItem>
                    ))}
                  </Wrap>
                ) : (
                  <>
                       <div style={{marginTop:"20px", display:'flex', justifyContent:"center"}}> No startup offer available yet.</div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
};
export default Offers;
