import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);


const ChartDisplay = ({
    prevDataPoints = [],
    currentDataPoints = [],
    prevTitle = "Past timeline",
    currentTitle = "Current timeline",
    from,
    to,
    prevFrom,
    prevTo,
    type='week',
}) => {

    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])
    const [options] = useState({
        plugins: {
          legend: {
            display: true,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        maintainAspectRatio: true,
        responsive: true,
    });

    const generateMissingDataPoints = (arrLength) => {
        let arr = []
        for (let i = 0; i < arrLength; i++){
            arr.push(0)
        }
        return arr
    }

    const generateDataPoints = (prev = [], from = from, to = to) => {
        let _prevData = [...prev]
        for(let i = 0; i < 0; i++){
            if(prev.length == 1){
                let _diff = moment(prev[i]?.time_start).diff(moment(from).startOf(type === "quarter" ? "month" : type), type === "quarter" ? "month" : type)
                _diff = type === "quarter" ? Math.round(_diff/4) : _diff
                let datapoints = _diff > 1 ? generateMissingDataPoints(_diff) : []
                _prevData.push(datapoints, Number(prev[i].count))
                let __diff = moment(to).startOf(type === "quarter" ? "month" : type).diff(prev[i]?.time_start, type === "quarter" ? "month" : type)
                __diff = type === "quarter" ? Math.round(_diff/4) : __diff
                let __datapoints = __diff > 1 ? generateMissingDataPoints(__diff) : []
                _prevData.push(__datapoints)
            }
            else if(i == 0){
                let _diff = moment(prev[i]?.time_start).diff(moment(from).startOf(type === "quarter" ? "month" : type), type === "quarter" ? "month" : type)
                _diff = type === "quarter" ? Math.round(_diff/4) : _diff
                let datapoints = _diff > 1 ? generateMissingDataPoints(_diff) : []
                _prevData.push(datapoints, Number(prev[i].count))
            }else if(i + 1 == prev.length){
                let __diff = moment(prev[i]?.time_start).diff(prev[i-1]?.time_start, type === "quarter" ? "month" : type)
                //console.log("this is the diff=====", __diff, prev[i]?.time_start, prev[i-1]?.time_start, type)
                __diff = type === "quarter" ? Math.round(_diff/4) : __diff
                let __datapoints = __diff > 1 ? generateMissingDataPoints(__diff) : []
                _prevData.push(__datapoints)
                let _diff = moment(moment(to).startOf(type === "quarter" ? "month" : type)).diff(prev[i]?.time_start, type === "quarter" ? "month" : type)
                //console.log("this is the diff 2nd wls=====", _diff, prev[i]?.time_start, to, moment(to).startOf(type === "quarter" ? "month" : type), type)
                _diff = type === "quarter" ? Math.round(_diff/4) : _diff
                let datapoints = _diff > 1 ? generateMissingDataPoints(_diff) : []
                _prevData.push(Number(prev[i].count), datapoints)
            }else {
                let _diff = moment(prev[i]?.time_start).diff(prev[i-1]?.time_start, type === "quarter" ? "month" : type)
                //console.log("this is the diff 3rd elde=====", _diff, prev[i]?.time_start, prev[i-1]?.time_start, type)
                _diff = type === "quarter" ? Math.round(_diff/4) : _diff
                let datapoints = _diff > 1 ? generateMissingDataPoints(_diff) : []
                _prevData.push(datapoints, Number(prev[i].count))
            }
        }
        _prevData = _prevData.flat()
        return _prevData
    }




    useEffect(() => {

        let diff = moment(prevTo).diff(prevFrom, 'day')
        //let timeframe = moment().subtract(diff, 'day').subtract(1, 'day').format('YYYY-MM-DD')
        //let prevTimeFrame = moment().subtract(2*diff, 'day').format('YYYY-MM-DD')

        //const _prevDataPoints = generateDataPoints(prevDataPoints, from, to)
        //const _currentDataPoints = generateDataPoints(currentDataPoints, prevTimeFrame, timeframe)

        let truncTime = 'week'

            if(diff < 8){
                truncTime = "day"
            } else if(diff < 80){
                truncTime = "week"
            } else if (diff < 367){
                truncTime = "month"
            } else if (diff < 730){
                truncTime = "quarter"
            } else {
                truncTime = "year"
            }

        let labelsLength = prevDataPoints?.length > currentDataPoints?.length ? prevDataPoints?.length : currentDataPoints?.length
        let _labels = [] 
        
        for (let i = 1; i <= labelsLength; i++){
            if(truncTime == "day") {
                _labels.push(`${moment().startOf('week').add(i-1, 'day').format('ddd')}`)
            } else if(truncTime == "month") {
                _labels.push(`${moment().startOf('year').add(i-1, 'month').format('MMM')}`)
            } else{
                _labels.push(`${truncTime.toUpperCase()} ${i}`)
            }
        }

        setLabels(_labels)

        setData(
            [
                {
                    id : 1,
                    label : prevTitle,
                    data: prevDataPoints.map((data) => Number(data?.count || 0)),
                    tension: 0.2,
                    borderColor: 'red'
                },
                {
                    id: 2,
                    label : currentTitle,
                    data: currentDataPoints.map((data) => Number(data?.count || 0)),
                    tension: 0.2,
                    borderColor: 'blue'
                },
            ]
        )
    }, [prevDataPoints.length, currentDataPoints.length])

    useEffect(() => {
    }, [prevDataPoints.length, labels.length])
    return (
        <Chart
            type='line'
            data={{
                labels: [...labels],
                datasets: [...data],
            }}
            options={options}
            
        />
    )
}

export default ChartDisplay