import React, { useState } from "react";
import IsDesktop from "../../../utils/IsDesktop";
import Header from "../../../components/header/Header";
import {
  Box,
  Text,
  Center,
  Flex,
  Link,
  Button,
  Image,
  InputGroup,
  Input,
  Textarea,
  Spacer,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { mutateFunction, useMutation } from "../../../libs/apis";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { addExpertSchema } from "../../../validators";
import ValidationError from "../../../validators/ValidationError";
import BrandButton from "../../../components/brandButton/BrandButton";
import { CustomToast } from "../../../components/toast/CustomToast";
import { RiUserLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { MdLockOutline } from "react-icons/md";
import { GiUsaFlag } from "react-icons/gi";
import { useData } from "../../../data";
import { useClickOutside } from "../../../utils/useClickOutside";

export default function Addexpert() {
  const navigate = useNavigate();
  const isDesktop = IsDesktop();
  const { addToast } = CustomToast();
  const { mutate, isLoading } = useMutation(mutateFunction);
  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState("");
  const {data : expertCategoriesData} = useData("experts/categories")
  const {data : expertLocations} = useData("experts/locations")
  const list = expertCategoriesData?.data?.categories || []
  const countriesList  = expertLocations?.data?.locations || []
  const [selected, setSelected] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [searchLocationValue, setSearchLocationValue] = useState('')
  const [isLocationOpen, setIsLocationOpen] = useState(false)
  const [ref] = useClickOutside(() => {
      if (isOpen) setIsOpen(false);
  });
  const [countrySelectionError, setCountrySelectionError] = useState(false)

  const [locationRef] = useClickOutside(() => {
    if (isLocationOpen) setIsLocationOpen(false);
  });

  const searchCategoriesFilter = (value) => {
      return searchValue ? value?.title?.toLowerCase().includes(searchValue.toLowerCase()) || value?.title == "Other" : true
  }
  const searchLocationFilter = (value) => {
    return searchLocationValue ? value?.title?.toLowerCase().includes(searchLocationValue.toLowerCase()) || value?.title == "Other" : true
  }
  
  const onChange = (e) => {
    setIsOpen(true)
    setSearchValue(e.target.value)
    setSelected(null)
  }

  const onChangeLocation = (e) => {
    setIsLocationOpen(true)
    setSearchLocationValue(e.target.value)
    setSelectedLocation(null)
    setCountrySelectionError(false)
  }
  const selectItem = (item) => {
      setSearchValue(item?.title)
      setSelected(item)
      toggleDropDown()
  }
  const selectLocation = (item) => {
    setSearchLocationValue(item?.title)
    setSelectedLocation(item)
    toggleLocationDropDown()
}
  const toggleDropDown = () => setIsOpen(prev => !prev)
  const toggleLocationDropDown = () => setIsLocationOpen(prev => !prev)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addExpertSchema),
  });
  const [profilePicture, setProfilePicture] = useState();

  const handleChangeForFile = (e, type) => {
    const fileData = e.target.files[0];
    setProfilePicture(fileData);
    if (fileData) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(fileData);
    }
  };

  const formSubmit = (output) => {
    output["user_type"] = "expert";

    if(!selectedLocation){
      setCountrySelectionError(true)
      return
    }
    const data = new FormData();

    if (profilePicture !== null && profilePicture != undefined) {
      data.append("avatar", profilePicture);
    }

    let categoryId = selected?.id || list.filter(({title}) => title == "Other")[0]?.id || ""

    if(categoryId) data.append("category_id", categoryId)
    data.append("country_id", selectedLocation?.id || '')

    for (let key in output) {
      data.append(key, output[key]);
    }

    mutate(
      { key: "experts", method: "post", data },
      {
        onSuccess(res) {
          addToast({ message: res.message, type: "info" });
          navigate(-1)
        },
        onError(err) {
          console.error(err.message, 'dkdkdkd', err.response?.data?.message);
          addToast({ message: err.response?.data?.message ?? "Error", type: "error" });
        },
      }
    );
  };

  return (
    <Box height={"100vh"} overflow={"auto"} overflowY={"auto"}>
      <Header title={"Experts/Add New"} />
      <Box px={10}>
        <Box mt="1.25rem">
          <Flex>
            <Center mt={2}>
              <BsArrowLeft />
              <Text
                ml="0.625rem"
                fontSize="1xl"
                fontWeight="700"
                color="#134A70"
                cursor="pointer"
                onClick={() => navigate(-1)}
              >
                Go Back
              </Text>
            </Center>
          </Flex>
        </Box>

        <Box mt="1.25rem">
          <Text
            color="brand.primary"
            fontFamily="Poppins"
            fontStyle="normal"
            fontWeight="43.75rem"
            fontSize="1.25rem"
            line-height="150%"
          >
            Add an Expert
          </Text>
          <Text color="#9FA7AD">
            Ensure you enter the details of expert correctly{" "}
            <Link color="brand.primary" href="#">
              import from Linkedin
            </Link>
          </Text>
        </Box>

        <Box mt="1.25rem">
          <Flex>
            {preview ? (
              <img src={preview} alt="Preview" style={{ maxWidth: "100px" }} />
            ) : (
              <Image src="/assets/images/Ellipse 3 (1).png" alt="image" />
            )}

            <Button
              mt="1.25rem"
              ml="0.625rem"
              // border="1px solid #000000"
              // borderRadius="20px"
            >
              <Input
                accept="image/*"
                hidden
                id="image-input"
                type="file"
                onChange={(e) => handleChangeForFile(e, "profile")}
                name="avatar"
                key="avatar"
              />
              <label htmlFor="image-input">
                <VStack>
                  <Box
                    cursor="pointer"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgColor="transparent"
                    h="24px"
                    w="96px"
                    border="1px solid #000"
                    borderRadius="15px"
                    fontSize={10}
                    mt={5}
                  >
                    Choose image
                  </Box>
                  {uploading ? (
                    <Flex>
                      <Text fontSize="12px">Uploading...</Text>
                      <Spinner />
                    </Flex>
                  ) : (
                    <></>
                  )}
                </VStack>
              </label>
            </Button>
          </Flex>
        </Box>

        <form onSubmit={handleSubmit(formSubmit)}>
          <Flex flexDir="row" className="flex-wrap gap-3" justifyContent="space-between" mt="1.875rem">
                <Flex flexDir="column">
                  <Text fontSize="0.875rem" mb={2}>
                    First Name
                  </Text>
                  <InputGroup
                    borderRadius="10px"
                    borderWidth={1}
                    borderColor="brand.primary"
                    h="50px"
                    w="350px"
                  >
                    <Flex
                      borderWidth="0.063rem"
                      borderColor="white-smoke"
                      borderRadius="0.313rem"
                      alignItems="center"
                    >
                      <Box ml="1rem">
                        {" "}
                        <RiUserLine />
                      </Box>
                      <Input
                        {...register("first_name")}
                        variant=""
                        focusBorderColor="#"
                        borderWidth="0rem"
                        name="first_name"
                        placeholder="First name"
                        type="text"
                        w="100%"
                        h="100%"
                      />
                    </Flex>
                  </InputGroup>
                  <ValidationError message={errors.first_name?.message} />
                </Flex>
                <Flex flexDir="column">
                  <Text fontSize="0.875rem" mb={2}>
                    Last Name
                  </Text>
                  <InputGroup
                    borderRadius="10px"
                    borderWidth={1}
                    borderColor="brand.primary"
                    h="50px"
                    w="350px"
                  >
                    <Flex
                      borderWidth="0.063rem"
                      borderColor="white-smoke"
                      borderRadius="0.313rem"
                      alignItems="center"
                    >
                      <Box ml="0.875rem">
                        <RiUserLine />
                      </Box>

                      <Input
                        {...register("last_name")}
                        focusBorderColor="#"
                        borderWidth="0px"
                        name="last_name"
                        placeholder="Last name"
                        type="text"
                        w="100%"
                        h="100%"
                      />
                    </Flex>
                  </InputGroup>
                  <ValidationError message={errors.last_name?.message} />
                </Flex>
                <Flex flexDir="column">
                  <Text fontSize="0.875rem" mb={2}>
                    Email
                  </Text>
                  <InputGroup
                    borderRadius="10px"
                    borderWidth={1}
                    borderColor="brand.primary"
                    h="50px"
                    w="350px"
                  >
                    <Flex
                      borderWidth="0.063rem"
                      borderColor="white-smoke"
                      borderRadius="0.313rem"
                      alignItems="center"
                    >
                      <Box ml="0.875rem">
                        <HiOutlineMail />
                      </Box>
                      <Input
                        {...register("email")}
                        focusBorderColor="#"
                        borderWidth="0rem"
                        name="email"
                        placeholder="email"
                        type="text"
                        w="100%"
                        h="100%"
                      />
                    </Flex>
                  </InputGroup>
                  <ValidationError message={errors.email?.message} />
                </Flex>

                <Flex flexDir="column">
                  <Text fontSize="0.875rem" mb={2}>
                    Phone Number
                  </Text>
                  <InputGroup
                    borderRadius="10px"
                    borderWidth={1}
                    borderColor="brand.primary"
                    h="50px"
                    w="350px"
                  >
                    <Flex
                      borderWidth="0.063rem"
                      borderColor="white-smoke"
                      borderRadius="0.313rem"
                      alignItems="center"
                    >
                      <Box ml="0.875rem">
                        <GiUsaFlag />
                      </Box>
                      <Input
                        {...register("phone_number")}
                        focusBorderColor="#"
                        borderWidth="0rem"
                        name="phone_number"
                        placeholder="Phone number"
                        type="number"
                        w="100%"
                        h="100%"
                        minLength={8}
                      />
                    </Flex>
                  </InputGroup>
                  <ValidationError message={errors.phone_number?.message} />
              </Flex>

              <Flex flexDir="column">
                  <Text fontSize="0.875rem" mb={2}>
                    Company Name
                  </Text>
                  <InputGroup
                    borderRadius="10px"
                    borderWidth={1}
                    borderColor="brand.primary"
                    h="50px"
                    w="350px"
                  >
                    <Flex
                      borderWidth="0.063rem"
                      borderColor="white-smoke"
                      borderRadius="0.313rem"
                      alignItems="center"
                    >
                      <Input
                        {...register("company_name")}
                        focusBorderColor="#"
                        borderWidth="0rem"
                        name="company_name"
                        placeholder="Company Name"
                        type="text"
                        w="100%"
                        h="100%"
                      />
                    </Flex>
                  </InputGroup>
                  <ValidationError message={errors.company_name?.message} />
                </Flex>
                <Flex flexDir="column">
                  <Text fontSize="0.875rem" mb={2}>
                    Position
                  </Text>
                  <InputGroup
                    borderRadius="10px"
                    borderWidth={1}
                    borderColor="brand.primary"
                    h="50px"
                    w="350px"
                  >
                    <Flex
                      borderWidth="0.063rem"
                      borderColor="white-smoke"
                      borderRadius="0.313rem"
                      alignItems="center"
                    >
                      <Input
                        {...register("headline")}
                        focusBorderColor="#"
                        borderWidth="0rem"
                        name="headline"
                        placeholder="Headline"
                        type="text"
                        w="100%"
                        h="100%"
                      />
                    </Flex>
                  </InputGroup>
                  <ValidationError message={errors.headline?.message} />
                </Flex>

                <Flex flexDir="column">
                  <Text fontSize="0.875rem" mb={2}>
                    Password
                  </Text>
                  <InputGroup
                    borderRadius="10px"
                    borderWidth={1}
                    borderColor="brand.primary"
                    h="50px"
                    w="350px"
                  >
                    <Flex
                      borderWidth="0.063rem"
                      borderColor="white-smoke"
                      borderRadius="0.313rem"
                      alignItems="center"
                    >
                      <Box ml="0.875rem">
                        <MdLockOutline />
                      </Box>
                      <Input
                        {...register("password")}
                        focusBorderColor="#"
                        borderWidth="0px"
                        name="password"
                        placeholder="Password"
                        type="text"
                        w="100%"
                        h="100%"
                      />
                    </Flex>
                  </InputGroup>
                  <ValidationError message={errors.password?.message} />
                </Flex>
              <div className={`relative w-full max-w-[350px]`} ref={ref}>
                  <Text fontSize="0.875rem" mb={2}>
                    Categories
                  </Text>
                  <div ref={ref} className={`bg-white cursor-pointer flex justify-between items-center mb-2 max-w-2xl `}>
                    <input 
                        id="search"
                        value={searchValue}
                        onChange={onChange}
                        placeholder={"Category"}
                        className="border border-[#134A70] w-full py-3 pr-2 pl-4 rounded-lg outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none"
                    />
                  </div>
                  {
                      isOpen && list.length ? (
                          <div className="absolute top-[80px] bg-white w-full max-w-2xl rounded-2xl py-2 flex flex-col gap-4 left-0 ad-card-shadow z-10">
                              <div className={`flex flex-col gap-4 max-h-[30vh] overflow-y-auto pb-5`}>
                              {
                                  list.filter(searchCategoriesFilter).map((item, index) => {
                                  return (
                                      <div className="cursor-pointer px-5 py-2 hover:bg-gray-100" onClick={() => {selectItem(item)}} key={item?.id}>{item?.title}</div>
                                  )
                              })}
                              </div>
                          </div>
                      ) : null
                  }
                  
              </div>
              <div className={`relative w-full max-w-[350px]`} ref={ref}>
                  <Text fontSize="0.875rem" mb={2}>
                    Location
                  </Text>
                  <div ref={locationRef} className={`bg-white cursor-pointer flex justify-between items-center mb-2 max-w-2xl `}>
                    <Flex className="flex-col gap-1 w-full">
                      <input 
                          id="search-location"
                          value={searchLocationValue}
                          onChange={onChangeLocation}
                          placeholder={"Location"}
                          className="border border-[#134A70] w-full py-3 pr-2 pl-4 rounded-lg outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none"
                      />
                      <ValidationError message={countrySelectionError ? 'Please select a country' : ''} />
                    </Flex>

                  </div>
                  {
                      isLocationOpen && countriesList.length ? (
                          <div className="absolute top-[80px] bg-white w-full max-w-2xl rounded-2xl py-2 flex flex-col gap-4 left-0 ad-card-shadow z-10">
                              <div className={`flex flex-col gap-4 max-h-[30vh] overflow-y-auto pb-5`}>
                              {
                                  countriesList.filter(searchLocationFilter).map((item, index) => {
                                  return (
                                      <div className="cursor-pointer px-5 py-2 hover:bg-gray-100" onClick={() => {selectLocation(item)}} key={item?.id}>{item?.title}</div>
                                  )
                              })}
                              </div>
                          </div>
                      ) : null
                  }
                  
              </div>
          </Flex>

          <Flex flexDir="column">
            <Textarea
              borderRadius="10px"
              borderWidth={1}
              borderColor="brand.primary"
              placeholder="About"
              {...register("bio")}
              mt="1.875rem"
              width="44.375rem"
              name="bio"
              height="5rem"
              box-sizing="border-box"
            />
            <ValidationError message={errors.bio?.message} />
          </Flex>
          <BrandButton
            isLoading={isLoading}
            width={"10rem"}
            text={"Add Expert"}
            type="submit"
            colorScheme="blue"
            mt="1.875rem"
          />
        </form>
      </Box>
    </Box>
  );
}
