import { Box, Flex } from "@chakra-ui/react";
import Header from "../../../components/header/Header";
import Offers from "../../../components/startup-offers/Offers";
import NewOffers from "../../../components/startup-offers/NewOffers";
import IsDesktop from "../../../utils/IsDesktop";

const StartupOffers = () => {
    return (
        <NewOffers />
    );
}
 
export default StartupOffers;