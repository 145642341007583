import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Image, Spinner, Text, Input, VStack, InputGroup, InputLeftAddon, Link, Textarea, RadioGroup, Radio  } from "@chakra-ui/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useData } from "../../../data";
import { CustomToast } from "../../../components/toast/CustomToast";
import logo_2 from '../../../public/icons/logo_.svg';
import { useMutation } from "react-query";
import { mutateFunction } from "../../../libs/apis";
import { loginSchema, accountDeletionSchema, VALIDATION_CONSTANTS } from "../../../validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ValidationError from "../../../validators/ValidationError";
import IsDesktop from "../../../utils/IsDesktop";
import envelope from '../../../public/icons/envelope.svg';
import lock from '../../../public/icons/lock.svg';
import BrandButton from "../../../components/brandButton/BrandButton";
import { ArrowBackIcon } from "@chakra-ui/icons";

const VerifyAccountDeletion = () => {
    const [searchParams] = useSearchParams({});
    const ref = searchParams.get("reference");
    const navigate = useNavigate();
    const { addToast } = CustomToast();

    const { mutate, isLoading } = useMutation(mutateFunction)
    const {register, handleSubmit, watch, formState: { errors },} = useForm({
        resolver : yupResolver (loginSchema)
    });
    const {register: register2, handleSubmit: handleSubmit2, watch : watch2, setValue, formState: { errors : errors2 },} = useForm({
        resolver : yupResolver (accountDeletionSchema)
    });

    const password = watch("password", null)
    const email = watch("email", null)
    const isDesktop = IsDesktop()
    const [step, setStep] = useState(1)

    const formSubmit = async(data, e) => {
        e.preventDefault()
        if(isLoading) return
        mutate(
          { key: "user-verify-removal-credentials", method: "post", data },
          {
            onSuccess(res) {
                setStep(2)
            },

            onError(e){
                //console.log(e, 'dkddkkd')
                addToast(e?.response?.data?.message, "error");
            }
          }
        );
    };

    const handleDeletion = async(data, e) => {
        e.preventDefault()
        mutate(
          { key: "user-delete-account", method: "post", data : {
            ...data,
            email,
            password, reference: ref
          } },
          {
            onSuccess(res) {
                addToast(res)
                window.setTimeout(() => {
                    navigate('/signUp', { replace: true })}, 3000);
            },

            onError(e){
                //console.log(e, 'dkddkkd')
                addToast(e?.response?.data?.message, "error");

            }
          }
        );
    };

    /*
    useEffect(() => {
        if(!isLoading){
            data?.data
            ? window.setTimeout(() => {
                addToast({message: "Email Verified", type: "success"})
                navigate('/login', { replace: true })}, 3000) 
                : window.setTimeout(() => {
                    navigate('/signUp', { replace: true })}, 3000);
        }
    });
    */
    

    return (
        <>
            <Box width="full" h="100vh" display="flex" flexDir="column" alignItems="center" >
                <Image src={logo_2} boxSize='130px' className="mt-10" objectFit='cover' mb={5}/>

                {step === 1 ? ( 
                    <Flex className="flex-col">
                        <Flex className="w-full justify-center" alignItems={'center'} my={'1em'}>
                            <Box className="p-2 text-sm font-semibold h-8 grid items-center justify-items-center w-8" borderRadius={'50%'} color='#fff' bg='brand.primary'>
                                1
                            </Box>
                            <Box w={!isDesktop ? 50 : 100} borderBottom='2px solid grey' />
                            <Box className="p-2 text-sm font-semibold h-8 grid items-center justify-items-center w-8"  borderRadius={'50%'} color='#fff' bg='grey'>
                                2
                            </Box>
                        </Flex>
                        <Text className="my-3 text-lg text-center font-semibold">
                            Enter details to confirm deletion
                        </Text>
                        <form onSubmit={handleSubmit(formSubmit)}>
                            <VStack className="max-w-500px" spacing={!isDesktop ? "10px" : "25px"} alignItems="center" w={!isDesktop ? "90vw" : "500px"}>
                                <Flex flexDir="column" className="max-w-[400px]">
                                    <Text fontSize="14px" mb={2}>Your Email</Text>
                                    <InputGroup>
                                    <Flex borderWidth='1px' borderColor="brand.primary" borderRadius="5px" alignItems="center">
                                        <InputLeftAddon borderWidth='0px' bg="" children={<Image src={envelope} size={30}/>} />
                                        <Input focusBorderColor="#" type="email" isRequired={false} borderWidth='0px' variant='outline' {...register('email')} name="email" placeholder='yourname@example.com' w={!isDesktop ? "250px" : "350px"} h="50px" />
                                    </Flex>
                                    </InputGroup>
                                    <ValidationError message={errors.email?.message} />
                                </Flex>
                                <Flex flexDir="column" className="max-w-[400px]">
                                    <Text mb={2}>Your Password</Text>
                                    <InputGroup>
                                    <Flex borderWidth='1px' borderColor="brand.primary" borderRadius="5px" alignItems="center">
                                        <InputLeftAddon borderWidth='0px' bg="" children={<Image src={lock} size={30}/>} />
                                        <Input focusBorderColor="#" borderWidth='0px' variant='outline' {...register('password')} isRequired={false} name="password" placeholder='At least 6 characters' type="password" w={!isDesktop ? "250px" : "350px"} h="50px" />
                                    </Flex>
                                    </InputGroup>
                                    <ValidationError message={errors.password?.message} />
                                </Flex>
                                <VStack w="100%" alignItems="flex-start">
                                    <BrandButton 
                                    isLoading={isLoading}
                                    className="mx-auto"
                                    width={isDesktop ? "400px" : "290px"} text={"Next"} type="submit" colorScheme="blue" />
                                </VStack>
                            </VStack>
                        </form>
                    </Flex>
                    ) : null
                }
                {step === 2 ? ( 
                    <Flex className="flex-col" >
                        <Flex className="gap-2 max-w-[400px] mx-auto w-[90vw] items-center">
                           <Text className="cursor-pointer" onClick={() => {setStep(1)}}>
                                <ArrowBackIcon fontSize={"18px"} /> Back
                           </Text>

                        </Flex>

                        <Flex className="w-full justify-center" alignItems={'center'} my={'1em'}>
                            <Box className="p-2 text-sm font-semibold h-8 grid items-center justify-items-center w-8" borderRadius={'50%'} color='#fff' bg='brand.primary'>
                                1
                            </Box>
                            <Box w={!isDesktop ? 50 : 100} borderBottom='2px solid' borderColor={"brand.primary"} />
                            <Box className="p-2 text-sm font-semibold h-8 grid items-center justify-items-center w-8"  borderRadius={'50%'} color='#fff' bg='brand.primary'>
                                2
                            </Box>
                        </Flex>
                        <Text className="my-3 text-lg text-center font-semibold">
                            Survey
                        </Text>
                        <form onSubmit={handleSubmit2(handleDeletion)}>
                            <VStack className="max-w-500px" spacing={!isDesktop ? "10px" : "25px"} alignItems="center" w={!isDesktop ? "90vw" : "500px"}>
                                <Flex flexDir="column" className="max-w-[400px]">
                                    <Text fontSize="14px" mb={2}>What was the primary reason for deleting your account?</Text>  
                                    <Textarea maxLength={300} variant='outline' {...register2('reason')} name="reason" placeholder='Type here...' />
                                </Flex>
                                <Flex flexDir="column" className="max-w-[400px]">
                                    <Text fontSize="14px" mb={2}>Is there anything specific we could have done to prevent you from deleting your account?</Text>  
                                    <Textarea maxLength={300} variant='outline' {...register2('changes')} name="changes" placeholder='Type here...' />
                                </Flex>
                                <Flex flexDir="column" className="max-w-[400px]">
                                    <Text fontSize="14px" mb={2}>Do you have any suggestions for improving the account deletion process?</Text>  
                                    <Textarea maxLength={300} variant='outline' {...register2('deletionSuggestions')} name="deletionSuggestions" placeholder='Type here...' />
                                </Flex>
                                <VStack w="100%" alignItems="flex-start">
                                    <BrandButton 
                                        className="mx-auto"
                                        isLoading={isLoading}
                                        width={isDesktop ? "400px" : "290px"} text={"Finish"} type="submit" colorScheme="blue" />
                                </VStack>
                            </VStack>
                        </form>
                    </Flex>
                ) : null
                }
                
            </Box>
        </>
    )
}
export default VerifyAccountDeletion;
