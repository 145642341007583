import axios from 'axios';
// import { BASE_URL } from '../helpers/constant';
import { BASE_URL } from '../helpers/constant';
import UserService from '../utils/UserService';
// import { getAuthUser } from './auth';

const client = () => {
	// const authUserData = getAuthUser();
	// const token = authUserData ? authUserData.token : '';
	const token = UserService.getToken() || '';
	return axios.create({
		baseURL: BASE_URL,
		headers: {
			authorization: `Bearer ${token}`,
			Accept: 'application/json',
			// "Content-Type": "application/json"
		},
	});
};
export default client;