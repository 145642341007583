import { Avatar, Box, CloseButton, Flex, HStack, Icon, Image, Input, InputGroup, InputLeftElement, InputRightElement, Menu, MenuList, MenuItem, MenuButton, Text, VStack, Alert, Button, InputRightAddon, useIds, useDisclosure, Textarea } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../../data";
import IsDesktop from "../../../utils/IsDesktop";
import { GrAttachment } from "react-icons/gr";
import { BsThreeDotsVertical } from "react-icons/bs";
import { TbMessageCircleOff, TbFile, TbPhoto, TbFileText, TbLink  } from "react-icons/tb";
import upload from "../../../public/icons_v2/upload.svg";
import send from "../../../public/icons/Send.svg";
import { useContext, useEffect, useRef, useState } from "react";
import StyledSpinner from "../../../components/spinner/Spinner";
import moment from "moment";
import { useQueryClient } from "react-query";
import { mutateFunction, useMutation } from "../../../libs/apis";
import { CustomToast } from "../../../components/toast/CustomToast";
import BrandButton from "../../../components/brandButton/BrandButton";
import { useNavigate } from "react-router-dom";
import { useInterval } from "../../../utils/useInterval";
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons'
import Linkify from "linkify-react";
import client from "../../../libs/client";
import { BASE_URL } from "../../../helpers/constant";
import { addNewMessage, addNewMessages, setAllUserMessages } from "../../../redux-toolkit/reducers/messagesSlice";
import { UserContext } from "../../../context/UserContext";
import OpenExternalAdvertLinkModal from "../../../components/modal/OpenExternalAdvertLinkModal";


const ChatBox = ({ advert=null, advertId=null, isAdvert = false, recipientId = null, setConnections = null, recipient, setRecipient, closeChatBox, refetchConnections, isFromNavigate }) => {
  const { data: userData, isLoading: userDataIsLoading} = useData(isAdvert ? `adverts/${advertId}` : `users/${recipientId}`);
  const navigate = useNavigate();
  const { emitEvent, connectSocket } = useContext(UserContext)
  const isConnected = userData?.data?.user?.connection?.accepted || recipient?.accepted || recipient?.connectionMade || isFromNavigate();
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure()
  const loggedInUser = useSelector((state) => state?.user?.userInfo);
  // const { data, isLoading } = useData(`connections/${recipientId}/messages`);
  const dispatch = useDispatch()
  const messages = useSelector((state) => state?.usermessages?.messages[`${recipientId}`])
  const isDesktop = IsDesktop();
  const [inputMessage, setInputMessage] = useState("");
  const { mutate, isLoading: mutateIsLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const queryClient = useQueryClient();
  const messagesEndRef = useRef(null);
  const { data: messagesData = null, isLoading: messagesIsLoading = false, refetch: refetchMessages  = () => {}} = useData( !isAdvert ? `connections/${recipientId}/messages` : `adverts/${advertId}`);
  const [files, setFiles] = useState([])
  const fileInputRef = useRef(null)
  const [images, setImages] = useState([])
  const imagesInputRef = useRef(null)
  const [documents, setDocuments] = useState([])
  const documentsInputRef = useRef(null)
  const [attachmentType, setAttachmentType] = useState('')
  const maxBytes = 10485760
  const viewAdRef = useRef(null)

  const [lastSeen, setLastSeen] = useState('')


  // const [socket, setSocket] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   if (recipientId !== null) {
  //     const newSocket = io.connect(`ws://hub8020-crowdfunding-backend.herokuapp.com`);
  //     setSocket(newSocket);
  //     newSocket.emit("establish_connect", { id: recipientId });
  //     newSocket.on("connect", () => {
  //       setIsLoading(false);
  //     });
  //     newSocket.on("connect_error", () => {
  //       console.log("Socket connection error. Retrying...");
  //       setIsLoading(true); // set isLoading to true on connection error
  //       newSocket.close();
  //       setTimeout(() => {
  //         newSocket.open();
  //       }, 1000);
  //     });
  //     newSocket.on("reconnect_attempt", () => {
  //       setIsLoading(true); // set isLoading to true on reconnect attempt
  //     });
  //     newSocket.on("reconnect", () => {
  //       setIsLoading(false); // set isLoading to false on successful reconnect
  //     });
  //     return () => {
  //       newSocket.close();
  //     };
  //   }
  // }, [recipientId]);

  const openFileUpload = (e) => {
    fileInputRef.current.click()
  }

  
  
  const handleChangeForFile = e => {
    setDocuments([])
    setImages([])
    let isTooLarge = [...e.target.files].some(file => file.size > maxBytes)
    if(isTooLarge) {
      setTimeout(() => {
        addToast({ message: "One or more files are greater than 10MB", type: "warning"})
      }, 500)
      return
    }
    if(e.target.files.length < 8){
      setFiles(e.target.files)
      setAttachmentType('file')
    } else {
      setTimeout(() => {
        addToast({ message: "More than 7 files selected", type: "warning"})
      }, 500)
    }
  }

  const openImageUpload = (e) => {
    imagesInputRef.current.click()
  }
  
  const handleChangeForImage = e => {
    setFiles([])
    setDocuments([])
    let isTooLarge = [...e.target.files].some(file => file.size > maxBytes)
    if(isTooLarge) {
      setTimeout(() => {
        addToast({ message: "One or more files are greater than 10MB", type: "warning"})
      }, 500)
      return
    }
    if(e.target.files.length < 8){
      setImages(e.target.files)
      setAttachmentType('image')
    } else {
      setTimeout(() => {
        addToast({ message: "More than 7 files selected", type: "warning"})
      }, 500)
    }
  }

  const openDocumentUpload = (e) => {
    documentsInputRef.current.click()
  }
  
  const handleChangeForDocument = (e)=> {
    setFiles([])
    setImages([])
    let isTooLarge = [...e.target.files].some(file => file.size > maxBytes)
    if(isTooLarge) {
      setTimeout(() => {
        addToast({ message: "One or more files are greater than 10MB", type: "warning"})
      }, 500)
      return
    }
    if(e.target.files.length < 8){
      setDocuments(e.target.files)
      setAttachmentType('document')
    } else {
      setTimeout(() => {
        addToast({ message: "More than 7 files selected", type: "warning"})
      }, 500)
    }
  }

  const clearData = () => {
    setFiles([])
    setDocuments([])
    setImages([])
    setAttachmentType('')
    setInputMessage('')
  }

  

  useEffect(() => {

    // if (recipientId) {
    //   client()
    //     .get(`/connections/${recipientId}/messages`)
    //     .then((res) => {
    //       setMessages(res?.data?.data?.messages?.sort((a, b) => moment(a?.createdAt).diff(moment(b?.createdAt))));
    //       intervalId = setInterval(() => {
    //         client()
    //           .get(`/connections/${recipientId}/messages`)
    //           .then((res) => {
    //             setMessages(res?.data?.data?.messages?.sort((a, b) => moment(a?.createdAt).diff(moment(b?.createdAt))));
    //           });
    //       }, 10000);
    //     });
    // }

    // return () => clearInterval(intervalId);
  }, [recipientId, messagesData, messagesEndRef?.current]);

  // useEffect(() => {
  //   if (socket) {
  //     socket.on("private_message_received", (data) => {
  //       setMessages((data));
  //     });
  //   }
  //   // socket.on("private_message_received", (data) => {
  //   //   setMessages((data));
  //   // });
  // }, [socket]);

  useEffect(() => {
    connectSocket()
    //refetchConnections()
    if(!messages){
      client().get(`${BASE_URL}messages`)
        .then((res) => {
          dispatch(setAllUserMessages(res?.data?.data?.messages))
        })
    }

    if(advertId && isAdvert && !viewAdRef?.current ){
      client().get(`${BASE_URL}/adverts/${advertId}`)
      viewAdRef.current = true
    }
  }, [])

  useEffect(() => {
    if(!isConnected && !isAdvert){
      addToast('Messages can only be sent to connections', "info")
    }
  }, [recipientId])

  const getLastTimeStamp = () => {
    let time = moment(recipient?.last_seen || recipient?.lastMessage?.createdAt || recipient?.date_accepted || recipient?.created_at).fromNow();
    setLastSeen(time)
  };

  useInterval(() => {
    getLastTimeStamp()
  }, 60000)


  useEffect(() => {
    if(messagesEndRef?.current){
      messagesEndRef.current?.scrollIntoView({ block: 'nearest', inline: 'start' })
      getLastTimeStamp()
    }
  }, [messages?.length])

  const updateParties = (data = []) => {
    let messageId = data[0].recipient_id || ''
    if(messageId){
      dispatch(addNewMessages({
        messageId,
        messages : data
      }))
      emitEvent("incomingmessages", {
        messages : data
      })

      if(setConnections){
        setConnections(prev => {
          let _connection = prev.filter(_data => _data?.id === messageId)
          let _prev = []
          if(_connection?.length === 1){
            _prev = prev.filter(_data => _data?.id !== messageId)
            _prev = [
              {
                ..._connection[0],
                lastMessage: data[data?.length - 1 ]
              },
              ..._prev
            ]
          }
          return _connection?.length === 1 ? _prev : prev
        })
      }
    }
  }

  const sendMessage = () => {
    if(mutateIsLoading) return
    // if (socket && socket.connected) {
    //   socket.emit("private_message", {
    //     content: inputMessage,
    //     sender_id: loggedInUser?.id,
    //     recipient_id: recipientId
    //   });
    // } else {
    //   console.log("Socket is not connected. Message not sent.");
    // }

    const attachments = [...files, ...images, ...documents]

    if (inputMessage.length < 1 && attachments.length < 1) {
      addToast({ message: "Message cannot be empty", type: "warning" });
      return;
    }

    let formData = new FormData()
    formData.append("content", inputMessage.trim())
    formData.append("sender_id", loggedInUser?.id)
    formData.append('recipient_id', recipientId)
    formData.append('attachmentType', attachmentType)
    for (let i = 0; i < attachments.length; i++) {
      formData.append("attachments", attachments[i]);
    }
    mutate(
      {
        key: `messages`,
        method: "post",
        data: formData,
      },
      {
        onSuccess(res) {
          addToast(res);
          //refetchMessages();
          //refetchConnections();
          clearData()
          updateParties(res.data?.messages);
          queryClient.invalidateQueries("connections");
          document.getElementById("message-input").value = "";
          
        },
      }
    );
  };

  const handleChange = (e) => {
    setInputMessage(e.target.value);
  };

  const acceptRequest = () => {
    mutate(
      { key: `users/${loggedInUser?.id}/connections/${recipient?.connection?.connection_id || recipient?.connection?.id}`, method: "put" },
      {
        onSuccess(res) {
          setRecipient({ ...recipient, accepted: true });
          addToast(res);
          refetchConnections();
          // queryClient.invalidateQueries(`connections`);
          // queryClient.invalidateQueries(`users/${loggedInUser?.id}/connections`);
        },
      }
    );
  };

  const goToUser = () => {
    navigate(`../view-user?userid=${loggedInUser?.id === recipient?.target_user_id ? recipient?.source_user_id : recipient?.target_user_id}&usertype=${recipient?.user_type_id}`);
  };

  useInterval(() => {
    refetchMessages();
  }, 15000);

  const FileDisplay = ({message}) => {
    let fileStringArr = message.content.split('/')
    let name = fileStringArr[fileStringArr.length - 1].split('_', 2)[1]

    return (
      <a className="cursor_pointer flex justify-between items-center w-[180px] no-decoration" href={message.content} download={name}>
        <Box className="flex flex-col gap-2">
          <TbFile />
          <Text className="max-w-[130px] hover:no-underline whitespace-nowrap overflow-hidden text-ellipsis">{name}</Text>
        </Box>
        <Box className="border h-6 w-6 grid place-items-center rounded-full">
            <DownloadIcon w={3} />
        </Box>
      </a>
    )
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        // Shift + Enter pressed
      } else {
        event.preventDefault();
        sendMessage();
      }
    }
  };
  return (
    <Box h="100%" overflow="auto">
      <OpenExternalAdvertLinkModal isOpen={isOpen} onClose={onClose} link={advert?.external_link} />
      <Box position="relative" w="full" boxShadow="0px 2px 4px rgba(0, 0, 0, 0.05)" borderRadius="10px" px={isDesktop && 4} h={"100%"}>
        {recipient !== null && !isAdvert ? (
          <VStack className="overflow-hidden" h={`100%`} spacing={0}>
            <Box position="sticky" top={0} zIndex={0} w="full" h="80px">
              <HStack p={3} h="100%">
                <Avatar name={`${recipient?.first_name} ${recipient?.last_name}`} src={recipient?.profile_image} alt="Avatar" w="48px" h="48px" borderRadius="100%" />
                <Box p={2} pl={4} w="68%" flex={1}>
                  <Text fontSize={16} fontWeight={700}>
                    {`${recipient?.first_name} ${recipient?.last_name}`}
                  </Text>
                  <Text fontSize={11} fontWeight={500} color="#062F36" pr={2}>
                   {moment().isSame(recipient?.lastMessage?.createdAt || recipient?.date_accepted || recipient?.created_at, 'minute') ? 'online' : `Last seen ${lastSeen}`}
                  </Text>
                </Box>
                <Box borderRadius="50%" bg="#fff" p={2} cursor="pointer" ml={2}>
                  
                  <Icon as={BsThreeDotsVertical} alt="Secondary action Icon" w={5} h="auto" />
                    
                </Box>
                <Box borderRadius="50%" bg="#fff" p={2} cursor="pointer" ml={2} onClick={() => closeChatBox()}>
                  <Icon as={CloseButton} alt="Secondary action Icon" w={5} h="auto" />
                </Box>
              </HStack>
            </Box>
            <Box w="100%" px={2} display="flex" flex={1} flexDir="column" justifyContent="space-between" overflowY="scroll" className="py-5 max-h-[65vh]">
              <style>
                {`
                    ::-webkit-scrollbar {
                      display: none;
                    }
                  `}
              </style>
              {!isConnected && (
                <Flex align="center" justify="center" flexDirection="column" mt={52}>
                  <Icon as={TbMessageCircleOff} w={12} h={12} color="#9FA7AD" mb={4} />
                  <Text fontSize={16} fontWeight={800} color="#9FA7AD" mb={4}>
                    You can only send messages to your connections
                  </Text>
                  <HStack>
                    {!(recipient?.source_user_id === loggedInUser?.id) ? <BrandButton isLoading={mutateIsLoading} text="Accept Request" width="170px" colorScheme="blue" bg="orange.500" onClick={() => acceptRequest()} /> : <BrandButton text="Pending" width="100px" colorScheme="blue" />}
                    <BrandButton text="View User Profile" w="170px" onClick={() => goToUser()} />
                  </HStack>
                </Flex>
              )}
              <VStack className="w-full">
              {isConnected && messages?.length > 0 ? (
                (messages || []).map((message, index) => (
                  <Box h="auto" w="100%" key={message?.id} display="flex" className="flex-col" justifyContent={'flex-start'}>
                    <Box className="self-center text-sm mb-4">{index === 0 || !moment(new Date(messages[index]?.updatedAt)).isSame(messages[index - 1]?.updatedAt, 'day') ? moment(new Date(message?.updatedAt)).format("ddd, MMMM D, YYYY") : ''}</Box>
                    {message?.sender_id !== loggedInUser?.id ? (
                      <HStack minH={0} pos="left" mb={4} w="100%" h="auto" alignItems="flex-start" key={message?.id}>
                        <Box bg="rgba(54, 171, 190, 0.05)" h="auto">
                          <Avatar size="xs" name={`${recipient?.first_name} ${recipient?.last_name}`} src={recipient?.profile_image} alt="Avatar" w="24px" h="24px" borderRadius="100%" />
                        </Box>
                        <Box className="flex flex-col items-end" maxW="80%">
                        <Box pos="left" bg="#F2F4F6" borderRadius="18px 18px 18px 0px" px={2} py={1} className="text-sm max-w-[250px] text-[#252631] font-light mb-1.5">
                          {
                            message?.type === 'text' ? (
                              message?.content || '').split('\n').map((txt, index) => (
                                <Linkify
                                  key={index}
                                  options={{
                                    target: '_blank',
                                    attributes: {
                                      onClick: (e) => {
                                        e.stopPropagation();
                                      },
                                    },
                                  }}
                                >
                                  {txt}
                                  <br />
                                </Linkify>
                              )
                            ) : (
                              <FileDisplay message={message} />
                            )
                          }
                          </Box>
                          <Text className="text-[13px] font-light text-[#778CA2]">
                            {index === 0 || !moment(messages[index]?.updatedAt).isSame(messages[index - 1]?.updatedAt, 'minute') || messages[index]?.sender_id != messages[index - 1]?.sender_id ? moment(new Date(message?.updatedAt)).format("h:mm A") : ''}
                          </Text>
                        </Box>
                        {/* <Icon as={BsThreeDotsVertical} alt="Secondary action Icon" w={5} h="auto" /> */}
                      </HStack>
                    ) : (
                      <HStack mb={4} w="100%" h="auto" justifyContent="flex-end" alignItems="flex-start" key={message?.id}>
                        <Box className="flex flex-col items-end" maxW="80%" >
                          <Box pos="right" bg="#4D7CFE" borderRadius="18px 18px 0 18px" px={2} py={1} className="text-sm max-w-[250px] text-white font-light mb-1.5" >
                          {
                            message.type === 'text' ? (
                              message?.content || '').split('\n').map((txt, index) => (
                                <Linkify
                                  key={index}
                                  options={{
                                    className: '',
                                    target: '_blank',
                                    attributes: {
                                      onClick: (e) => {
                                        e.stopPropagation();
                                      },
                                    },
                                  }}
                                >
                                  {txt}
                                  <br />
                                </Linkify>
                              )
                            ) : (
                              <FileDisplay message={message} />
                            )
                          }
                          </Box>
                          <Text className="text-[13px] font-light text-[#778CA2]"> 
                            {index === 0 || !moment(messages[index]?.updatedAt).isSame(messages[index - 1]?.updatedAt, 'minute') || messages[index]?.sender_id != messages[index - 1]?.sender_id ? moment(new Date(message?.updatedAt)).format("h:mm A") : ''}
                          </Text>
                        </Box>
                        <Box bg="rgba(54, 171, 190, 0.05)" h="auto">
                          <Avatar size="xs" name={`${loggedInUser?.firstName} ${loggedInUser?.lastName}`} src={loggedInUser?.profile_image} alt="Avatar" w="24px" h="24px" borderRadius="100%" />
                        </Box>
                        {/* <Icon as={BsThreeDotsVertical} alt="Secondary action Icon" w={5} h="auto" /> */}
                      </HStack>
                    )}
                  </Box>
                ))
              ) : (
                <Flex w="100%" justifyContent="center">
                  {isConnected && !messagesIsLoading && messages?.length === 0 && <Text>No messages...</Text>}
                  {isConnected && messagesIsLoading && <StyledSpinner />}
                </Flex>
              )}
              <div ref={messagesEndRef} />
              </VStack>
            </Box>
              <Box 
                className={`w-full min-h-[25px] ${(files || images || documents) && (files.length + images.length + documents.length) > 0 ? "" : "min-h-[2px]"}`}
              >
                  {
                    (files || images || documents) && (files.length + images.length + documents.length) > 0 ? (
                      <Flex className="items-center w-full justify-between bg-gray-100 py-1 px-2 ">
                          <Flex className="w-full gap-3 items-center">
                          <Text className={`max-w-[60%] text-sm whitespace-nowrap overflow-hidden text-ellipsis`}>{[...files, ...documents, ...images][0]?.name}</Text>
                          {
                            (files.length + images.length + documents.length) > 1 ? (
                              <Box className="rounded-lg bg-[#134A70] text-white font-bold text-[11px] grid place-items-center w-6">
                                {files.length + images.length + documents.length < 11 ? `+${files.length + images.length + documents.length - 1}` : '10+'}
                              </Box>
                            ) : null
                          }
                          </Flex>
                          <Box onClick={clearData} className="border cursor-pointer border-red-400 rounded-full px-1 grid place-items-center">
                            <CloseIcon color='red.400' w={2} />
                          </Box>
                      </Flex>
                    ) : null
                  }
              </Box>
            <Box w="full" >
              <InputGroup rounded={"3xl"} pb="2px">
                <textarea
                  id="message-input"
                  placeholder="Send message"
                  onChange={(e) => handleChange(e)}
                  _active={{border: '1px solid red'}}
                  isDisabled={!isConnected}
                  onKeyDown={handleKeyDown}
                  pl={"15px"} 
                  rounded="3xl"
                  // w={"90%"}
                  // className="h-10"
                  className={
                    `
                       w-full px-3 py-2 pl-4 ml-1 border border-gray-200 rounded-3xl rounded-r-none focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400
                    `
                  } 
                  style={{ 
                    maxHeight: '1rem',
                    minHeight: '2.5rem',
                    borderRight: "none"
                  }}
                  borderRight={"none"}
                />
                <InputRightAddon roundedRight={"3xl"} borderLeft={"none"} bg={"transparent"} className="border border-red-300" cursor="pointer" pointerEvents="Send" // onClick={() => sendMessage()} 
                  children={
                    <Menu >
                        <MenuButton>
                        <Image src={upload} />     
                        </MenuButton>
                        <MenuList py={0} w={"fit-content"} gap={1} minW={"fit-content"}>
                          <MenuItem onClick={openFileUpload} className="flex flex-col gap-1 text-xs items-center hover:font-semibold">
                            <Input
                              accept=".pdf,.csv,.ppt,.pptx,xls,.xlsx"
                              id="file-attachments"
                              type="file"
                              onChange={(e) => handleChangeForFile(e)}
                              name="attachments"
                              key="file-attachments"
                              hidden
                              multiple
                              ref={fileInputRef}
                            />
                            <TbFile />
                              File
                          </MenuItem>
                          <MenuItem onClick={openImageUpload} className="flex flex-col gap-1 text-xs items-center hover:font-semibold">
                            <Input
                              accept="image/*"
                              id="image-attachments"
                              type="file"
                              onChange={(e) => handleChangeForImage(e)}
                              name="attachments"
                              key="image-attachments"
                              hidden
                              multiple
                              ref={imagesInputRef}
                            />
                            <TbPhoto />
                            Image
                          </MenuItem>
                          <MenuItem onClick={openDocumentUpload} className="flex flex-col gap-1 text-xs items-center hover:font-semibold">
                            <Input
                              accept=".doc,.docx,.xml,.ppt,.pptx,xls,.xlsx"
                              id="doc-attachments"
                              type="file"
                              onChange={(e) => handleChangeForDocument(e)}
                              name="attachments"
                              key="doc-attachments"
                              hidden
                              multiple
                              ref={documentsInputRef}
                            />
                            <TbFileText />
                            Document
                          </MenuItem>
                        </MenuList>
                    </Menu>
                    }
                />
              </InputGroup>
            </Box>
          </VStack>
        ) : (
          <></>
        )}
        {isAdvert && recipientId == null ? (
          <VStack className="overflow-hidden" h={`100%`} spacing={0}>
            <Box position="sticky" top={0} zIndex={0} w="full" h="80px">
              <HStack p={3} h="100%">
                <Avatar name={`${advert?.brand?.name}`} src={advert?.brand?.logo} alt="Avatar" w="48px" h="48px" borderRadius="100%" />
                <Box p={2} pl={4} w="68%" flex={1}>
                  <Text fontSize={16} fontWeight={700}>
                    {`${advert?.brand?.name}`}
                  </Text>
                  <Text fontSize={11} fontWeight={500} color="#062F36" pr={2}>
                   {moment(advert?.createdAt).format('MMM DD')}
                  </Text>
                </Box>
                <Box className="hidden" borderRadius="50%" bg="#fff" p={2} cursor="pointer">
                  <Menu >
                    <MenuButton>
                  <Icon  as={GrAttachment} alt="Attachment Icon" w={4} h="auto" />
                  </MenuButton>
                    <MenuList py={0} w={"fit-content"} gap={1} minW={"fit-content"}>
                      <MenuItem onClick={openFileUpload} className="flex flex-col gap-1 text-xs items-center hover:font-semibold">
                        <Input
                          accept=".pdf,.csv,.ppt,.pptx,xls,.xlsx"
                          id="file-attachments"
                          type="file"
                          onChange={(e) => handleChangeForFile(e)}
                          name="attachments"
                          key="file-attachments"
                          hidden
                          multiple
                          ref={fileInputRef}
                        />
                        <TbFile />
                          File
                      </MenuItem>
                      <MenuItem onClick={openImageUpload} className="flex flex-col gap-1 text-xs items-center hover:font-semibold">
                        <Input
                          accept="image/*"
                          id="image-attachments"
                          type="file"
                          onChange={(e) => handleChangeForImage(e)}
                          name="attachments"
                          key="image-attachments"
                          hidden
                          multiple
                          ref={imagesInputRef}
                        />
                        <TbPhoto />
                        Image
                      </MenuItem>
                      <MenuItem onClick={openDocumentUpload} className="flex flex-col gap-1 text-xs items-center hover:font-semibold">
                        <Input
                          accept=".doc,.docx,.xml,.ppt,.pptx,xls,.xlsx"
                          id="doc-attachments"
                          type="file"
                          onChange={(e) => handleChangeForDocument(e)}
                          name="attachments"
                          key="doc-attachments"
                          hidden
                          multiple
                          ref={documentsInputRef}
                        />
                        <TbFileText />
                        Document
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
                <Box className="hidden" borderRadius="50%" bg="#fff" p={2} cursor="pointer" ml={2}>
                  
                  <Icon as={BsThreeDotsVertical} alt="Secondary action Icon" w={5} h="auto" />
                    
                </Box>
                <Box borderRadius="50%" bg="#fff" p={2} cursor="pointer" ml={2} onClick={() => closeChatBox()}>
                  <Icon as={CloseButton} alt="Secondary action Icon" w={5} h="auto" />
                </Box>
              </HStack>
            </Box>
            <Box w="100%" px={2} display="flex" flex={1} flexDir="column" justifyContent="space-between" overflowY="scroll" className="py-5 max-h-[65vh]">
              <style>
                {`
                    ::-webkit-scrollbar {
                      display: none;
                    }
                  `}
              </style>
              <VStack className="w-full">
                <Box h="auto" w="100%" display="flex" className="flex-col" justifyContent={'flex-start'}>
                    <Box className="self-center text-sm mb-4">{moment(advert?.updatedAt).format("ddd, MMMM D, YYYY") }</Box>
                      <HStack minH={0} pos="left" mb={4} w="100%" h="auto" alignItems="flex-start">
                        <Box bg="rgba(54, 171, 190, 0.05)" h="auto">
                          <Avatar size="xs" name={`${advert?.brand?.name}`} src={advert?.brand?.logo} alt="Avatar" w="24px" h="24px" borderRadius="100%" />
                        </Box>
                        <Box pos="left" color="#fff" bg="#134A70" borderRadius="0px 5px 5px 5px" boxShadow="10px 10px 20px rgba(54, 171, 190, 0.1)" p={2} maxW="90%">
                              <Linkify
                                  options={{
                                    className: '',
                                    target: '_blank',
                                    attributes: {
                                      onClick: (e) => {
                                        e.stopPropagation();
                                      },
                                    },
                                  }}
                                >
                                  <pre className={`w-full whitespace-pre-wrap ${isDesktop ? 'max-w-sm' : ''}`}>{advert?.description}</pre>
                                  <br />
                                  {advert?.external_link ? <Text className="text-white cursor-pointer hover:underline" onClick={onOpen} >Click to see more</Text> : null}
                              </Linkify>
                          <Text className="text-[10px] text-right">
                            {moment(advert?.updatedAt).format("hh : mm")}
                          </Text>
                        </Box>
                        {/* <Icon as={BsThreeDotsVertical} alt="Secondary action Icon" w={5} h="auto" /> */}
                      </HStack>
                    
                  </Box>
              </VStack>
            </Box>
            <Box borderTop="1px solid rgba(135, 158, 162, 0.2)" bottom={10} w="100%">
              <Text className="py-2 text-gray-600 text-center text-sm">
                Replies are not enabled for this message
              </Text>
            </Box>
          </VStack>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
export default ChatBox;
