import { Avatar } from "@chakra-ui/avatar";
import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/layout";
import React from "react";
import BrandButton from "../brandButton/BrandButton";

const StartupOffer = ({ offer, isOpen, getDeal, setIsOpen, onToggle, setSelectedOffer }) => {
    const viewDetails = () => {
        setSelectedOffer(offer);
        if(!isOpen) setIsOpen(true)
    }


    return (
        <Box style={{flex: '1 1 250px'}} className="w-full min-w-[250px] max-w-[350px] flex flex-col gap-4 border rounded-lg p-4">
            <Box className="w-full flex items-center bg-[#F5F5F5] rounded">
                <Avatar size={"lg"} className="mx-auto" name={offer?.title} src={offer?.brand?.logo}/>
            </Box>
            <Box className="flex w-full flex-col gap-2">
                <Text color={"brand.primary"}  textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" fontSize="16px" fontWeight={600}>
                    {offer?.title}
                </Text>
                <Text className="preview_message text-xs" textOverflow="ellipsis">
                    {offer?.description}
                </Text>
            </Box>
            <Box className="flex flex-col gap-2">
                <Box className="flex flex-wrap gap-2 justify-between" >
                    <Text className="text-[#20B962] font-medium text-sm">
                        {`Offer price`}
                    </Text>
                    <Text className="text-[#20B962] font-medium text-sm">
                        {`${offer?.price}`}
                    </Text>
                </Box>
                <Box className="flex flex-wrap gap-2 justify-between" >
                    <Text className="text-sm">
                        {`Category`}
                    </Text>
                    <Text className="text-sm">
                        {`${offer?.category?.title || "Not Specified"}`}
                    </Text>
                </Box>
            </Box>
            <Box className="flex w-full">
                <BrandButton text="Get Deal" w="full" className="w-full" colorScheme="blue" onClick={()=> getDeal(offer)} />
                {/* <BrandButton text="View Details" width="130px" colorScheme="white" onClick={viewDetails}/> */}
            </Box>
        </Box>
    )
}
export default StartupOffer;