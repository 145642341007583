import { Box, Text } from "@chakra-ui/react"

const Trending = () => {
    return (
        <Box className="flex flex-col w-full px-3">
            <Box>
                <Box className="flex flex-col border p-3 rounded-xl">
                    <Text className="text-lg font-bold">
                        What's Happening
                    </Text>
                    <Box className="py-20">
                        <Text className="text-gray-500 text-center">
                            No trends to show
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Trending