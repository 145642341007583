const evaluateExpertProfile = ( expertDetails = {}, navigate = () => {}) => {

    if(!expertDetails?.company_name){
        navigate('/account-settings?edit=expertCompany')
        return
    }
    if(!expertDetails?.category_id){
        navigate('/account-settings?edit=expertCategory')
        return
    }
    if(!expertDetails?.country_id){
        navigate('/account-settings?edit=expertCountry')
        return
    }
    if(!expertDetails?.headline){
        navigate('/account-settings?edit=expertHeadline')
        return
    }
    if(!expertDetails?.phone_number){
        navigate('/account-settings?edit=expertPhone')
        return
    }
    if(!expertDetails?.bio){
        navigate('/account-settings?edit=expertBio')
        return
    }
}

export default evaluateExpertProfile