import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  Avatar,
  HStack,
  Divider,
  Heading,
  Image,
  useDisclosure,
  Spinner,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import IsDesktop from "../../utils/IsDesktop";
import BrandButton from "../brandButton/BrandButton";
import { useData } from "../../data";
import ppt from "../../public/icons/ppt.svg";
import mp4 from "../../public/icons/mp4.svg";
import PlayVideo from "./PlayVideo";
import ViewPitchDeck from "./ViewPitchDeck";
import ConnectionButton from "../connectionButton/ConnectionButton";
import founder_cover_photo from "../../public/images/Founders.png";
import ConvertVideoLinkToEmbedLink from "../../utils/ConvertVideoLinkToEmbedLink";
import { useLocation, useSearchParams } from "react-router-dom";
import { CustomToast } from "../toast/CustomToast";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useSelector } from "react-redux";
import { StarIcon } from "@chakra-ui/icons";
import moment from "moment";
import { isValidURL } from "../../utils/IsValidUrl";
import ExpertBookingCalendar from "../experts/ExpertBookingCalendar";
import FoundersBookingCalendar from "./FoundersBookingCalendar";


const ViewFounderDetails = ({ founder, onToggle, companyId = null }) => {
  const isDesktop = IsDesktop();
  const { mutate, isLoading: mutateIsLoading} = useMutation(mutateFunction);
  const { data, refetch } = useData(`users/${founder}`, 1000 * 60 * 5, {'X-C-ID' : companyId});
  const [userData, setUserData] = useState(null);
  const [companyData, setCompanyData] = useState({});
  const { addToast } = CustomToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const { isOpen: videoIsOpen, onToggle: onToggleVideoIsOpen } =
    useDisclosure();
  const { isOpen: viewPitchDeckIsOpen, onToggle: onTogglePitchDeck } =
    useDisclosure();
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const [count, setCount] = useState(0)
  const intervalId = useRef(null)
  const [relatedBookings, setRelatedBookings] = useState([])
  const { data: bookingsData, isLoading: isLoadingBookingsData, refetch: refetchBookingsData, } = useData(`users/bookings/all`, 5000);


  const showPitchDeck = () => {
    onTogglePitchDeck();
  };
  const showVideo = () => {
    onToggleVideoIsOpen();
  };

  const acceptRequest =  () => {
    mutate(
      { key: `users/${loggedInUserId}/connections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
      {
        onSuccess(res) {
          addToast(res);
          setSearchParams({
            userid: founder,
            usertype: 1
          })
          refetch()
        },
      }
    );
  }

  const declineRequest =  () => {
    mutate(
      { key: `users/${loggedInUserId}/declineConnections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
      {
        onSuccess(res) {
          addToast(res);
          setSearchParams({
            userid: founder,
            usertype: 1
          })
          refetch()
        },
      }
    );
  }


  function resetTimeout() {
    if (intervalId.current) {
      clearTimeout(intervalId.current);
    }
  }

  useEffect(() => {
      resetTimeout()
      intervalId.current = setTimeout(() => {
          setCount(prev => prev === companyData?.additional_images?.length - 1 ? 0 : prev + 1)
      }, 3000)

      return () => { resetTimeout() }
  }, [count, companyData?.additional_images?.length])

  useEffect(() => {
    if (data?.data) {
      setUserData(data.data.user);
      setCompanyData(data.data.company);
      setRelatedBookings(data?.data?.user?.bookings || [])
    }
  }, [data]);

  useEffect(() => {
    if (userData) {
      if(location?.search?.includes("status=accept")){
        acceptRequest()
      }
      if(location?.search?.includes("status=decline")){
        declineRequest()
      }
    }
  }, [userData, location?.search]);

  return (
    <>
      {userData !== null ? (
        <>
          <Box
            display="flex"
            cursor="pointer"
            onClick={onToggle}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box mr={2}>
              <HiOutlineArrowNarrowLeft size="20px" />
            </Box>
            <Box>
              <Text className="font-medium"> Go Back</Text>
            </Box>
          </Box>
          <VStack
            pb={2}
            overflow="hidden"
            overflowY="auto"
            w="100%"
            h="auto"
            bg="white"
            borderRadius="10px"
            boxShadow="0.5px 2px 10px lightgray"
          >
            <style>{` ::-webkit-scrollbar {display: none; }`}</style>
            <div className="bg-gray-300 w-full ">
              <div
                style={{
                  backgroundImage: `url(${data?.data?.user?.cover_image || founder_cover_photo})`,
                }}
                className="w-[100%] bg-no-repeat rounded-lg bg-cover bg-center h-60"
              >
              </div>
            </div>
            <Box className={`w-full`}>
              <div className={`flex w-full lg:w-[80%] max-w-[1000px]  mx-auto flex-wrap justify-between items-center px-5 py-5 lg:px-4`}>
                <Box className="flex flex-col items-center gap-3">
                  <Box
                    borderRadius="50%"
                  >
                    <Avatar
                      name={`${userData?.first_name} ${userData?.last_name}`}
                      src={userData?.profile_image}
                      size={isDesktop ? "2xl" : "xl"}
                    />
                  </Box>

                  <Flex className="flex-row gap-1 ml-1 items-center">
                          <StarIcon h={3} w={3} color={userData?.rating > 0 ? "yellow.400" : "initial"}  />
                          <StarIcon h={3} w={3} color={userData?.rating > 1 ? "yellow.400" : "initial"} />
                          <StarIcon h={3} w={3} color={userData?.rating > 2 ? "yellow.400" : "initial"} />
                        </Flex>
                  <Box
                      className="items-center justify-center"
                      display="flex"
                      flexDir="column"
                      alignItems={!isDesktop ? "flex-start" : "center"}
                    >
                      <Text className="text-2xl font-semibold">
                        {`${userData?.first_name} ${userData?.last_name}`}
                      </Text>
                    </Box>
                </Box>
                <div className={`mb-5 xs:mb-2 w-full max-w-[296px]`}>
                  <ConnectionButton
                    style={{ backgroundColor: "white" }}
                    user={userData}
                  />
                </div>
              </div>
            </Box>
            <Box className={`w-full p-5 xl:px-10 pb-20 bg-[#FAFAFA]`}>
                <Text className="text-lg font-medium text-[#2F4B6E] pb-10">
                  Overview
                </Text>
              <Box className={`w-full lg:w-[80%] max-w-[1000px] mx-auto px-5 py-5 border rounded-lg lg:px-4`}> 
                   <Box className="flex flex-col xl:flex-row gap-8">
                      <Box className="flex flex-col gap-4 xl:w-[50%]">
                          <Text className="text-[#2F4B6E] text-lg font-medium">
                            Business Overview
                          </Text>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Company Name:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.title}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Location:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                              {companyData?.incorporation_location?.map((item) => item?.name).join(', ')}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Social media:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Website
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Business model:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.business_model?.map((item) => item?.title).join(', ')}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Category:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.business_category?.map((item) => item?.title).join(', ')}
                                
                              </Text>
                            </Box>
                          </Flex>
                      </Box>
                      <Box className="flex flex-col gap-4 xl:w-[50%]">
                          <Text className="text-[#2F4B6E] text-lg font-medium">
                            Investment Overview
                          </Text>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Kind of investment:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.fund_category?.title}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Valuation:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.company_stage?.map((item) => item?.title).join(', ')}
                             
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Target Locations:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.investment_location?.map((item) => item?.name).join(', ')}

                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Equity offered:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Share price:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Investors:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                -
                              </Text>
                            </Box>
                          </Flex>
                      </Box>
                    </Box>
              </Box>
              <Box className={`w-full lg:w-[80%] max-w-[1000px] mx-auto my-10`}>
                <Box>
                        {companyData.video_url && isValidURL(companyData.video_url) ? (
                          <object
                            data={ConvertVideoLinkToEmbedLink(companyData.video_url)}
                            width="100%"
                            className={`${isDesktop ? 'min-h-[400px]' : 'min-h-[200px]'} border rounded-lg`}
                          >
                            <iframe
                              width="100%"
                              height="50%"
                              src={ConvertVideoLinkToEmbedLink(companyData.video_url)}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </object>
                        ) : (
                          <Text color="text-gray-600 font-semibold p-3 text-center text-sm"></Text>
                        )}
                </Box>
              </Box>
            </Box>
            <Box className={`w-full p-5 xl:px-10 pb-20 bg-[#FAFAFA]`}>
              <Box h="100%" mt={5} className="w-full max-w-[700px] xl:max-w-[800px]">
                  <Text className="text-lg font-medium" color="brand.primary" my={4}>
                    Booking Hours with {`${data?.data?.user?.first_name}`}
                  </Text>
              <Box>
              <Box>
              <Box>
                                              <Text fontWeight={500} color='#9FA7AD'>Select a Day</Text>
                                              <Text color="gray">Select a suitable day to have your call from the available days below.</Text>
                                              <Text className="text-gray-600 text-sm italic">Dates highlighted in blue have available slots.</Text>
                                          </Box>
                                          <Box display="flex" justifyContent="center" alignItems="center" minW="280px" maxW="530px" p={2} m={2}>
                                              <FoundersBookingCalendar refetch={refetchBookingsData} founder={founder} />
                                          </Box>
                                      </Box>
                                  </Box>
                              </Box>
            </Box>

            <Box className={`w-full p-5 xl:px-10 pb-20`}>
                <Text className="text-lg font-medium text-[#2F4B6E] pb-10">
                  About Company
                </Text>
              <Box className={``}> 
                   <Box className="flex flex-col xl:flex-row gap-8">
                      <Box className="flex flex-col gap-4 xl:w-[50%]">
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 lg:min-h-[70px] xl:max-w-[500px] 2xl: max-w-[700px]">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Description:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.description}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 lg:min-h-[70px] xl:max-w-[500px] 2xl: max-w-[700px]">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Problem:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.problem}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 lg:min-h-[70px] xl:max-w-[500px] 2xl: max-w-[700px]">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Solution:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.solution}
                              </Text>
                            </Box>
                          </Flex>
                          <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 lg:min-h-[70px] xl:max-w-[500px] 2xl: max-w-[700px]">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Customer Type:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.customer_type}
                              </Text>
                            </Box>
                          </Flex>
                      </Box>
                      <Box className="flex flex-col gap-4 xl:w-[50%]">
                      <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 lg:min-h-[70px] xl:max-w-[500px] 2xl: max-w-[700px]">
                            <Text
                              className="w-[320px]"
                              fontWeight={500}
                              color="#C0BCBC"
                            >
                              Competition:
                            </Text>
                            <Box className="w-full">
                              <Text
                                color="#000"
                                className="font-medium"
                              >
                                {companyData?.competition}
                              </Text>
                            </Box>
                      </Flex>
                      <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 lg:min-h-[70px] xl:max-w-[500px] 2xl: max-w-[700px]">
                              <Text
                                className="w-[320px]"
                                fontWeight={500}
                                color="#C0BCBC"
                              >
                                Use of Funds:
                              </Text>
                              <Box className="w-full">
                                <Text
                                  color="#000"
                                  className="font-medium"
                                >
                                  {companyData?.fund_use}
                                </Text>
                              </Box>
                      </Flex>
                      <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 lg:min-h-[70px] xl:max-w-[500px] 2xl: max-w-[700px]">
                              <Text
                                className="w-[320px]"
                                fontWeight={500}
                                color="#C0BCBC"
                              >
                                Stakeholders:
                              </Text>
                              <Box className="w-full">
                                <Text
                                  color="#000"
                                  className="font-medium"
                                >
                                  {companyData?.stakeholders}
                                </Text>
                              </Box>
                      </Flex>
                      <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 lg:min-h-[70px] xl:max-w-[500px] 2xl: max-w-[700px]">
                              <Text
                                className="w-[320px]"
                                fontWeight={500}
                                color="#C0BCBC"
                              >
                                Market Growth:
                              </Text>
                              <Box className="w-full">
                                <Text
                                  color="#000"
                                  className="font-medium"
                                >
                                  {companyData?.market_growth}
                                </Text>
                              </Box>
                      </Flex>
                      <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1 lg:min-h-[70px] xl:max-w-[500px] 2xl: max-w-[700px]">
                              <Text
                                className="w-[320px]"
                                fontWeight={500}
                                color="#C0BCBC"
                              >
                                Revenue Model:
                              </Text>
                              <Box className="w-full">
                                <Text
                                  color="#000"
                                  className="font-medium"
                                >
                                  {companyData?.revenue_model}
                                </Text>
                              </Box>
                      </Flex>
                      </Box>
                    </Box>
              </Box>
            </Box>
            <Box w="100%" display="flex" alignItems="center" flexDir="column">
              {isDesktop ? (
                <Box w="80%" px={isDesktop ? 8 : 4} py={4}>
                  <Text
                    fontSize={isDesktop ? 20 : 16}
                    fontWeight={500}
                    color="brand.primary"
                  >
                    Pitch Deck
                  </Text>
                  {companyData?.pitch_deck ? (
                    <iframe
                      title="document viewer"
                      src={`https://docs.google.com/viewer?url=${companyData?.pitch_deck}&embedded=true&#zoom=FitH`}
                      width="100%"
                      height="600px"
                    ></iframe>
                  ) : (
                    <Text color="text-gray-600 font-semibold p-3 text-center text-sm">
                      No pitch deck uploaded
                    </Text>
                  )}
                </Box>
              ) : (
                <Box py={4} w="100%">
                  {companyData?.pitch_deck ? (
                    <object
                      width="100%"
                      height="600px"
                      data={`https://drive.google.com/viewerng/viewer?embedded=true&url=${companyData?.pitch_deck}&#zoom=FitH`}
                      type="application/pdf"
                    >
                      <iframe
                        title="document viewer"
                        src={`https://docs.google.com/viewer?url=${companyData?.pitch_deck}&embedded=true&#zoom=FitH`}
                        width="100%"
                        height="600px"
                      ></iframe>
                    </object>
                  ) : (
                    <Text color="text-gray-600 font-semibold p-3 text-center text-sm">
                       No pitch deck uploaded
                    </Text>
                  )}
                </Box>
              )}
            </Box>
          </VStack>
        </>
      ) : (
        <Box>
          <Spinner />
        </Box>
      )}
    </>
  );
};
export default ViewFounderDetails;
