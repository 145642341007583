import { Box, Button, Flex, HStack, TableContainer, useDisclosure, Text, Table, Tbody, Tr, Td, VStack, Avatar, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BrandButton from "../../../components/brandButton/BrandButton";
import Header from "../../../components/header/Header";
import AdvertsCountSection from "./AdvertsCountSection"
import SearchBar from "../../../components/searchbar/SearchBar";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiPause, FiPlay } from "react-icons/fi";
import moment from "moment";
import { mutateFunction, useMutation } from "../../../libs/apis";
import { CustomToast } from "../../../components/toast/CustomToast";
import ViewAdvertDetails from "./ViewAdvertDetails";
import AddAdvert from "./AddAdvert";
import { useData } from "../../../data";
import IsDesktop from "../../../utils/IsDesktop";

const Adverts = () => {
    const { data, isLoading, refetch } = useData("adverts");
    const [displayedListStatus, setDisplayedListStatus] = useState("all");
    const [displayedList, setDisplayedList] = useState([])
    const {isOpen: addAdvertIsOpen, onToggle: onToggleAddAdvert} = useDisclosure()
    const {isOpen: advertDetailsIsOpen, onToggle: onToggleAdvertDetails} = useDisclosure()
    const [selectedAdvert, setSelectedAdvert] = useState()
    const {mutate} = useMutation(mutateFunction);
    const {addToast} = CustomToast();
    const isDesktop = IsDesktop()

  const addNewAdvert = () => {
    onToggleAddAdvert();
  }
  const changeUserView = (advertStatus) => {
    setDisplayedListStatus(advertStatus);
  };
  const viewAdvert = (advertId) => {
    setSelectedAdvert(advertId);
    onToggleAdvertDetails();
  }
  const sortList = (advertActiveStatus) => {
    if (advertActiveStatus === "all") {
      return data?.data?.adverts;
    }
    return data?.data?.adverts?.filter((advert) => advert.is_active === advertActiveStatus);
  };
  useEffect(() => {
    if (data?.data) {
      setDisplayedList(sortList(displayedListStatus));
    }
  }, [data, displayedListStatus]);

  const changeStatus = (advertId) => {
    // mutate(
    //     { key: `adverts/${advertId}`, method: "patch" },
    //     {
    //       onSuccess(res) {
    //         addToast(res);
    //         refetch()
  
    //       },
    //     }
    //   );
  }
  const deleteAdvert = (advertId) => {
    mutate(
        { key: `adverts/${advertId}`, method: "delete" },
        {
          onSuccess(res) {
            addToast(res);
            refetch()
  
          },
        }
      );
  }

  const editAdvert = (advertId, data, callback = () => {}) => {
    mutate(
        { key: `edit-advert/${advertId}`, method: "put", data},
        {
          onSuccess(res) {
            addToast(res);
            refetch()
            callback()
          },
        }
      );
  }



  return (
    <>
      <Box>
        <Header title="Adverts" />
        {advertDetailsIsOpen && (<ViewAdvertDetails editAdvert={editAdvert} advertId={selectedAdvert} onToggle={onToggleAdvertDetails} changeStatus={changeStatus} deleteAdvert={deleteAdvert} />)}
        {addAdvertIsOpen && (<AddAdvert onToggle={onToggleAddAdvert} refetch={refetch} />)}
        {(!advertDetailsIsOpen && !addAdvertIsOpen) && (
        <>
          <Flex w="100%">
            <Box mt="20px" w="100%">
              <HStack w="80%" justifyContent="space-between">
                <AdvertsCountSection totalAdverts={data?.data?.totalAdverts} totalImpressions={data?.data?.totalImpressions} totalViews={data?.data?.totalViews} totalClicks={data?.data?.totalClicks} />
                <BrandButton text="Add New" minW="120px" onClick={addNewAdvert} />
              </HStack>
              <Flex w="100%" justify="space-between" alignItems="center">
                <SearchBar />
                <Flex alignItems="flex-start" pr={2} h="29px" w="67">
                  <Button bg={displayedListStatus === "all" ? "brand.primary" : "#FFFFFF"} color={displayedListStatus === "all" ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} borderRadius="5px 0px 0px 5px" boxShadow="base" onClick={() => changeUserView("all")}>
                    All
                  </Button>
                  <Button bg={displayedListStatus === true ? "brand.primary" : "#FFFFFF"} color={displayedListStatus === true ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px" onClick={() => changeUserView(true)}>
                    Ongoing
                  </Button>
                  <Button bg={displayedListStatus === false ? "brand.primary" : "#FFFFFF"} color={displayedListStatus === false ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px 5px 5px 0px" onClick={() => changeUserView(false)}>
                    Paused
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <Box
          className={`flex-col w-full ${
            isDesktop ? "max-h-[75vh] fixed mt-5 pb-32 overflow-y-scroll" : ""
          }`}
        >
          <TableContainer mt="30px" borderRadius="10px">
            <Table variant="simple" size="sm" fontSize="12px">
              {!isLoading ? (<Tbody>
                {displayedList?.map((items) => {
                  return (
                    <Tr
                      key={items.id}
                      style={{ padding: "2rem 2rem", height: "80px" }}
                      boxShadow="md"
                      borderRadius="10px"
                      _hover={{
                        borderWidth: "2px",
                        borderColor: "brand.primary",
                        borderRadius: "10px",
                      }}
                    >
                      <Td cursor="pointer"
                      onClick={() => viewAdvert(items?.id)}>
                        <VStack justifyContent="center" alignItems="flex-start">
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {items?.title || "N/A"}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            Added on {moment(items?.createdAt).format(`MMM D, YYYY`)}
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <Flex alignItems="center">
                          <Avatar name={`${items?.brand?.name || "Seed A Founder"}`} src={items?.brand?.logo} alt="" size="sm" mr={2} />
                          <Text>{`${items?.brand?.name || "Seed A Founder"}`}</Text>
                        </Flex>
                      </Td>
                      <Td>
                        <VStack>
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {moment(items?.start_date).format(`DD/MM/YYYY`)}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            Start date
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <VStack>
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {moment(items?.end_date).format(`DD/MM/YYYY`)}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            End date
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <VStack>
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {items?.impression_count || 0}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            Views
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <VStack>
                          <Text fontSize="16px" fontWeight={600} color="brand.primary">
                            {items?.click_count || 0}
                          </Text>
                          <Text fontSize="12px" color="gray">
                            Clicks
                          </Text>
                        </VStack>
                      </Td>
                      <Td>
                        <HStack>
                            {items?.is_active === true && (
                            <Box borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="120px" h="40px" bg="#E6F3E6" >
                                <Text color="green" fontWeight={500}>Ongoing</Text>
                            </Box>
                            )}
                            {items?.is_active === false && (
                            <Box borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="120px" h="40px" bg="#FFF6E6" >
                                <Text color="orange" fontWeight={500}>Paused</Text>
                            </Box>
                            )}
                            {items?.is_active === true && (<Box cursor="pointer" onClick={()=> changeStatus(items.id)} border="1px" borderColor="orange" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px"><FiPause color="orange"/></Box>)}
                            {items?.is_active === false && (<Box cursor="pointer" onClick={()=> changeStatus(items.id)} border="1px" borderColor="green" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px"><FiPlay color="green"/></Box>)}
                            <Box cursor="pointer" onClick={()=> deleteAdvert(items.id)} border="1px" borderColor="red" borderRadius="5px" display="flex" justifyContent="center" alignItems="center" w="40px" h="40px"><RiDeleteBin6Line color="red"/></Box>
                        </HStack>
                        </Td>
                      {/* <Td>
                        <BiDotsVerticalRounded />
                      </Td> */}
                    </Tr>
                  );
                })}
              </Tbody>):(<Spinner/>)}
            </Table>
          </TableContainer>
          </Box>
        </>)}
      </Box>
    </>
  );
};
export default Adverts;
