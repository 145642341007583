import { Box, Center, Text, Stack, Flex, Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, FormControl, FormLabel, Textarea, Button, Input, Heading, useDisclosure, Divider, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, VStack, Checkbox, HStack } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { mutateFunction, useMutation, } from "../../libs/apis";
import BrandButton from "../brandButton/BrandButton";
import { CustomToast } from "../toast/CustomToast";
import { FiSettings } from "react-icons/fi";
import { Select, DatePicker } from "antd";
import { IoMdCheckmark } from "react-icons/io";
import moment, { months } from "moment";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useData } from "../../data";
import recurringSlotPredictor from "../../utils/recurringSlotPredictor";

const BookingModal = ({ isOpen, onClose = () => {}, refetch = () => {} }) => {
  const [slotData, setSlotdata] = useState({
    slot_link : '',
    slot_start_date: '',
    slot_start_time: '',
    slot_end_time: '',
    slot_title : '',
    slot_link : '',
    slot_description: '',
  });
  const [slot_setting, setSlot_setting] = useState({
    canRepeat: false,
    repeatType: 'Select recurrence type',
  });
  const accountType = useSelector(
    (state) => state?.user?.userInfo?.userTypeTitle
  );
  const { data : filledSlots, refetch: refetchFilledSlots } = useData(`${accountType}s/filled-slots`);
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { mutate, isLoading } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const { isOpen: customSettingsIsOpen, onToggle: onToggleCustomSettings } = useDisclosure();
  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [customDays, setCustomDays] = useState([]);
  const [endDate, setEndDate] = useState("")
  const [checkedBox, setCheckedBox] = useState()
  const [repeatDuration, setRepeatDuration] = useState(1);
  const [endMonthCount, setEndMonthCount] = useState(6)
  const [maxMonthCount, setMaxMonthCount] = useState(6)
  const [possibleClash, setPossibleClash] = useState(false)
  const [hasBeenAlerted, setHasBeenAlerted] = useState(false)
  const { isOpen: alertIsOpen, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
  const { isOpen: proceedAlertIsOpen, onOpen: onOpenProceedAlert, onClose: onCloseProceedAlert } = useDisclosure()

  const handleCustomDateSelection = (date, dateString) => {
    setCustomDays(dateString)
  };

  const handleSelectChange = (e) => {
    if (e.value === "custom") {
      onToggleCustomSettings();
      setSlot_setting((prev) => ({
        ...prev,
        canRepeat: true,
        repeatType: e.value,
      }));
    } else if (e.value === "none") {
      if(customSettingsIsOpen)onToggleCustomSettings();
      setSlot_setting((prev) => ({
        ...prev,
        canRepeat: false,
        repeatType: e.value,
      }));
      setEndDate(slotData?.slot_start_date ? slotData?.slot_start_date : "")
    } else {
      if(customSettingsIsOpen)onToggleCustomSettings();
      setSlot_setting((prev) => ({
        ...prev,
        canRepeat: true,
        repeatType: e.value,
        endDate: slotData?.slot_start_date,
      }));
    }
  };

  const EndDateCheckboxGroup = () => {
    const changeEndDateSelection = (type) => {
      if (checkedBox === type) {
        setCheckedBox("")
      } else {
        setCheckedBox(type)
      }
    }
    return (
      <VStack spacing={5} alignItems="flex-start">
        <Flex justifyContent="flex-start" alignItems="center">
        <Box mr={4} display="flex" justifyContent="center" alignItems="center" w="20px" h="20px" cursor="pointer" onClick={()=> changeEndDateSelection("Never")}>
          <Box position="absolute" w="20px" h="20px" bg="white" border="1px" borderColor="brand.primary" borderRadius="2px" />
          <Box position="absolute">
            {checkedBox === "Never" && <IoMdCheckmark size="20px" color="brand.primary" />}
          </Box>
        </Box>
        <Text>Never</Text>
        </Flex>
        <Flex justifyContent="flex-start" alignItems="center">
        <Box mr={4} display="flex" justifyContent="center" alignItems="center" w="20px" h="20px" cursor="pointer" onClick={()=> changeEndDateSelection("On")}>
          <Box position="absolute" w="20px" h="20px" bg="white" border="1px" borderColor="brand.primary" borderRadius="2px" />
          <Box position="absolute">
            {checkedBox === "On" && <IoMdCheckmark size="20px" color="brand.primary" />}
          </Box>
        </Box>
        <HStack>
        <Text>On</Text>
        <Input name="endDate" type="date" size="md" isDisabled={checkedBox !== "On"} mt={2} onChange={(e) => setEndDate(e.target.value)} />
        </HStack>
        </Flex>
        <Flex justifyContent="flex-start" alignItems="center">
        <Box mr={4} display="flex" justifyContent="center" alignItems="center" w="20px" h="20px" cursor="pointer" onClick={()=> changeEndDateSelection("After")}>
          <Box position="absolute" w="20px" h="20px" bg="white" border="1px" borderColor="brand.primary" borderRadius="2px" />
          <Box position="absolute">
            {checkedBox === "After" && <IoMdCheckmark size="20px" color="brand.primary" />}
          </Box>
        </Box>
        <HStack spacing={2} alignItems="center">
        <Text>After</Text>
        <NumberInput key="number-input-end-date" isDisabled={checkedBox !== "After"} w={20} size="sm" defaultValue={1} min={0} mr={2}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text>Bookings</Text>
        </HStack>
        </Flex>
      </VStack>
    );
  };

  const CustomSettings = () => {
    const isSelected = (day) => {
      return customDays.includes(day);
    };
    const selectDay = (day) => {
      if (customDays.includes(day)) {
        setCustomDays(customDays.filter((each) => each !== day));
      } else {
        setCustomDays([...customDays, day]);
      }
    };
    return (
      <>
        <Box p={2} boxShadow="0px 0px 4px lightgray" h="400px" w="340px" bg="white" display="flex" flexDir="column" justifyContent="space-between" alignItems="center">
          <Box w="100%">
            <Heading color="brand.primary" fontSize="16px">
              Custom Settings
            </Heading>
          </Box>
          <Divider mt={2} />
          <Flex mb={4} w="100%" justifyContent="flex-start" alignItems="center">
            <Text mr={4}>Repeat every</Text>
            <NumberInput key="number-input-repeat-duration" w={20} size="sm" defaultValue={1} min={0} mr={2} onChange={(e) => setRepeatDuration(e)}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select name="repeatType" defaultActiveFirstOption style={{ width: "80px" }} onChange={(e)=> handleSelectChange(e)} labelInValue>
              <option value="daily">Day</option>
              <option value="weekly">Week</option>
              <option value="weekdays">Weekday</option>
              <option value="monthly">Month</option>
              <option value="yearly">Year</option>
            </Select>
          </Flex>
          <Flex w="100%" flexDir="column" justifyContent="flex-start" alignItems="flex-start">
            <Text mr={4}>Repeat on</Text>
            <Flex w="100%" justifyContent="flex-start" alignItems="center">
              {weekdays.map((eachDay) => (
                <Box mr={2} key={eachDay} display="flex" justifyContent="center" alignItems="center" borderRadius="50%" fontSize="12px" cursor="pointer" onClick={() => selectDay(eachDay)} w="30px" h="30px" bg={isSelected(eachDay) ? "brand.primary" : "white"} color={!isSelected(eachDay) ? "brand.primary" : "white"}>
                  {eachDay.charAt(0)}
                </Box>
              ))}
            </Flex>
          </Flex>
          <VStack mt={4} w="100%" justifyContent="flex-start" alignItems="flex-start">
            <Text mr={4}>Ends</Text>
            <EndDateCheckboxGroup/>
          </VStack>
          <Flex w="100%" justifyContent="flex-end" alignItems="center">
            <BrandButton w="80px" text="Done" colorScheme="blue" onClick={onToggleCustomSettings} mr={4} />
            <Text fontWeight={600} cursor="pointer" color="brand.primary" _hover={{ textDecoration: "underline" }} fontSize="14px" mr={4}>
              Close
            </Text>
          </Flex>
        </Box>
      </>
    );
  };

  const handleChange = (e) => {
    if (e.target.name === "slot_start_date" && !slot_setting?.endDate) {
      setSlotdata((prev) => ({
        ...prev,
        slot_start_date: e.target.value,
      }));
      setEndDate(e.target.value)
    } else{
      setSlotdata((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const addSlot = () => {
    if(Number(slotData?.slot_end_time.replace(':', '') || 0) - Number(slotData?.slot_start_time.replace(':', '') || 0) < 15){
      addToast({message : 'Meeting length cannot be shorter than 15 minutes or across days'}, 'error')
      return
    }
    if(!moment(slotData?.slot_start_date, "YYYY-MM-DD").isValid() || moment(slotData?.slot_start_date, "YYYY-MM-DD").isBefore(moment(), 'day')){
      addToast({message : "Start date cannot be a past date"}, 'error')
      return
    }

    if(possibleClash && !hasBeenAlerted){
      onOpenAlert()
      return 
    }
    const completeSettingsData = {...slot_setting, endDate: moment().add(endMonthCount, 'months').format("YYYY-MM-DD"), customDays, repeatDuration}
    const completedSlotsData = {...slotData, slot_setting: completeSettingsData}
    if(completeSettingsData.repeatType === 'custom') completedSlotsData['slot_start_date'] = customDays?.length > 0 ? customDays[0] : null
    mutate(
      { key: `${accountType}s/${loggedInUserId}/booking-slots`, method: "post", data: completedSlotsData },
      {
        onSuccess(res) {
          addToast(res);
          refetch();
          onOpenProceedAlert()
          refetchFilledSlots();
          setSlot_setting({
            canRepeat: false,
            repeatType: 'Select recurrence type',
          })
          setSlotdata({
            slot_link : '',
            slot_start_date: '',
            slot_start_time: '',
            slot_end_time: '',
            slot_title : '',
            slot_link : '',
            slot_description: '',
          })
          setCustomDays([])

        },
        onError(res) {
          let message = 'Please fill in all details'
          let errorMessage = res?.response?.data?.message || ''
          if(errorMessage.includes('slot_title')){
            message = 'Please add a title'
          }else if(errorMessage.includes('slot_start_date') || errorMessage.includes('slot_start_time') || errorMessage.includes('slot_end_time') || errorMessage.includes('time') || errorMessage.includes('date')){
            message = "Please enter a valid date and time for the slot."
          } else if(errorMessage?.includes('slot_description')){
            message = 'Please add a brief description'
          }
          addToast({message}, 'error')
        }
      }
    );
  };

  useEffect(() => {
    if(slot_setting?.repeatType === 'yearly'){
      setMaxMonthCount(24)
      setEndMonthCount(24)
    }else {
      setMaxMonthCount(6)
      setEndMonthCount(3)
    }
  }, [slot_setting?.repeatType])

  const closeAlert = () => {
    setHasBeenAlerted(true)
    onCloseAlert()
  }

  const closeProceedAlert = () => {
    onClose()
    onCloseProceedAlert()
  }

  useEffect(() => {

    const alertPossibleClash = () => {
      if(!Object.keys(filledSlots || {}).length || !slotData?.slot_start_date || !slotData?.slot_start_time || !slotData?.slot_end_time || !slot_setting?.repeatType) {
        setPossibleClash(false)
        setHasBeenAlerted(false)
        return 
      }
      let _days = [];
      //console.log("The shit======", slot_setting?.repeatType, slotData?.slot_start_date, slotData?.slot_start_time, slotData?.slot_end_time, endMonthCount )

      if (slot_setting?.repeatType == "none" || slot_setting?.repeatType == "custom"){
        _days = slot_setting?.repeatType == "none" ? [slotData?.slot_start_date] : customDays
      }else{
        _days = recurringSlotPredictor({
          end_date: moment().add(endMonthCount, 'months').format("YYYY-MM-DD"),
          slot_start_date: slotData['slot_start_date'],
          recurring: slot_setting?.repeatType,
          onlyWeekday: slot_setting?.repeatType === 'weekdays'
        })
       // console.log("days====", _days)

      }
      
      _days.filter((_day) => filledSlots?.data?.slots[_day]).some((_day) => {
        let status = false
        //console.log("dayum====", _day)
        let _slot_start_time = Number(slotData?.slot_start_time.replace(':', ''))
        let _slot_end_time = Number(slotData?.slot_end_time.replace(':', ''))
        filledSlots?.data?.slots[_day].some((_time) => {
          let start = Number(_time[0].slice(0, 5).replace(':', ''))
          let stop = Number(_time[1].slice(0, 5).replace(':', ''))
          //console.log("clash=========", (start >= _slot_start_time && _slot_end_time >= start) || (stop >= _slot_start_time && _slot_end_time >= stop), start, stop, _slot_start_time, _slot_end_time)
          status = (start >= _slot_start_time && _slot_end_time >= start) || (stop >= _slot_start_time && _slot_end_time >= stop) || (stop >= _slot_end_time && _slot_start_time >= start)
          return status
        })
        if(status){
          addToast({
            message: `Possible meeting clash on ${_day}`
          }, 'info') 
        }
        setPossibleClash(status)
        return status
      })
      
    }

    alertPossibleClash()
  }, [slot_setting?.repeatType, slotData?.slot_start_date, slotData?.slot_start_time, slotData?.slot_end_time, endMonthCount ])

  return (
    <>
      <Modal isOpen={alertIsOpen} onClose={closeAlert}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Text className="text-center font-medium mt-4 mb-10">
                The selected slot overlaps with an existing appointment. Would you like to proceed?
              </Text>
              <Box className="w-fit mx-auto mt-3 mb-1 flex items-center justify-center gap-3">
                <Button variant='ghost' width={"80px"} className="px-2" onClick={onCloseAlert}>No</Button>
                <Button background='brand.primary'  width={"80px"} color={"#fff"}  className="px-2" onClick={closeAlert}>
                  Yes
                </Button>
              </Box>
            </ModalBody>

            <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={proceedAlertIsOpen} onClose={closeProceedAlert}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Text className="text-center font-medium mt-4 mb-10">
                Slot successfully added, would you like to add another?
              </Text>
              <Box className="w-fit mx-auto mt-3 mb-1 flex items-center justify-center gap-3">
                <Button variant='ghost' width={"80px"} className="px-2" onClick={closeProceedAlert}>No</Button>
                <Button background='brand.primary' width={"80px"} color={"#fff"}  className="px-2" onClick={onCloseProceedAlert}>
                  Yes
                </Button>
              </Box>
            </ModalBody>

            <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={4}>
          {/* <form onSubmit={(e)=>addSlot(e)}> */}
          <Center d="flex" flexDirection="column">
            <Stack spacing={6}>
              <Text fontSize={20} fontWeight={700} color="brand.primary" align="center">
                Add New Slot
              </Text>
              <Text fontSize={14} color="#9FA7AD">
                Ensure you enter the details of your booking correctly.
              </Text>
              <Box>
                <Text fontSize={14} fontWeight={400} color="#000">
                  Slot Title
                </Text>
                <Input value={slotData?.slot_title} name="slot_title" placeholder="Title" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
              </Box>
              <Box>
                <Text fontSize={14} fontWeight={400} color="#000">
                  Description
                </Text>
                <Input value={slotData?.slot_description} name="slot_description" placeholder="Description" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
              </Box>
              <Box>
                <Text fontSize={14} fontWeight={400} color="#000">
                  Meeting/Webinar Link
                </Text>
                <Input value={slotData?.slot_link} name="slot_link" placeholder="Paste link here" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
              </Box>
              <Box>
                <Text fontSize={14} fontWeight={400} color="#000">
                  Start Date
                </Text>
                <Input value={slotData?.slot_start_date} name="slot_start_date" type="date" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
              </Box>
              <Flex w="100%" justifyContent="space-between">
                <Box w="45%">
                  <Text fontSize={14} fontWeight={400} color="#000">
                    Start time
                  </Text>
                  <Input value={slotData?.slot_start_time} name="slot_start_time" type="time" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
                </Box>
                <Box w="45%">
                  <Text fontSize={14} fontWeight={400} color="#000">
                    End time
                  </Text>
                  <Input value={slotData?.slot_end_time} name="slot_end_time" type="time" size="md" borderColor="#134A70" mt={2} onChange={(e) => handleChange(e)} />
                </Box>
              </Flex>
              <Box >
                <Text fontSize={14} fontWeight={400} color="#000">
                  Recurrence Type
                </Text>
                  <Select value={slot_setting?.repeatType} name="repeatType" placeholder="Select recurrence type" size="large" style={{ width: "100%", borderRadius: "5px" }} borderColor="#134A70" mt={2} onChange={(e) => handleSelectChange(e)} labelInValue>
                    <option value="none">Does Not Repeat</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly on same day</option>
                    <option value="monthly">Monthly on same day</option>
                    <option value="weekdays">Weekdays (Monday - Friday)</option>
                    <option value="yearly">Yearly on same day</option>
                    <option value="custom">
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text>Custom</Text>
                        <FiSettings />
                      </Flex>
                    </option>
                  </Select>

                {
                  customSettingsIsOpen ? (
                    <Box className="my-4">
                      <DatePicker
                        multiple
                        onChange={handleCustomDateSelection}
                        defaultValue={customDays.map(day => dayjs(day))}
                        maxTagCount="responsive"
                        size="large"
                        minDate={dayjs()}
                        maxDate={dayjs().add(endMonthCount, 'month')}
                        maxLength={20}
                      />
                    </Box>
                  ) : null
                }
              </Box>
              <Box className="flex flex-col gap-2">
                <Text fontSize={14} fontWeight={400} color="#000">
                  End Date
                </Text>
                <Box className="flex items-center gap-8 justify-between">
                  <FaMinusSquare size={"25px"} className="cursor-pointer" onClick={() => { setEndMonthCount(prev => prev <= 1 ? 1 : prev - 1)}} />
                  <Text className="">in <b>{endMonthCount}</b> {endMonthCount > 1 ? 'months' : 'month'}</Text>
                  <FaPlusSquare size={"25px"} className="cursor-pointer" onClick={() => { setEndMonthCount(prev => prev >= maxMonthCount ? maxMonthCount : prev + 1)}} />
                </Box>
              </Box>
            </Stack>
            <BrandButton isLoading={isLoading} mt={6} text="Save Slot" colorScheme="blue" onClick={() => addSlot()} />
            <Text m={4} fontSize={14} fontWeight={600} cursor="pointer" color="#134A70" as="u" onClick={onClose}>
              Close
            </Text>
          </Center>
          {/* </form> */}
        </ModalBody>
      </ModalContent>
    </Modal>
    </>
  );
};

export default BookingModal;
