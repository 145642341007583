import { Flex, Box, Text, Avatar, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import useData from "../../../data/useData";
import moment from "moment";
import Founders from "./Founders";
import SearchBar from "../../../components/searchbar/SearchBar";
import UserExperts from "../../../pages/founder/Allusers/Experts";
import UserInvestors from "../../../pages/founder/Allusers/Investors";
import Users from "./Users";
import RestrictedUsers from "./RestrictedUsers";
import IsDesktop from "../../../utils/IsDesktop";

const AllUsers = () => {
  const navigate = useNavigate();
  const { data, refetch } = useData("user-statistics");
  const [userCount, setUserCount] = useState(data?.data?.totalUsers);
  const [blockedUserCount, setBlockedUserCount] = useState(data?.data?.totalBlockedUsers);
  const [disabledUserCount, setDisabledUserCount] = useState(data?.data?.totalDisabledUsers);
  const [foundersCount, setFoundersCount] = useState(data?.data?.totalFounders);
  const [investorsCount, setInvestorsCount] = useState(data?.data?.totalInvestors);
  const [expertsCount, setExpertsCount] = useState(data?.data?.totalExperts);
  const [lastTwoUsers, setLastTwoUsers] = useState(data?.data?.lastTwoUsers);
  const [lastTwoFounders, setLastTwoFounders] = useState(data?.data?.lastTwoFounders);
  const [lastTwoExperts, setLastTwoExperts] = useState(data?.data?.lastTwoExperts);
  const [lastTwoInvestors, setLastTwoInvestors] = useState(data?.data?.lastTwoInvestors);
  const [lastTwoBlockedUsers, setLastTwoBlockedUsers] = useState(data?.data?.lastTwoBlockedUsers);
  const [lastTwoDisabledUsers, setLastTwoDisabledUsers] = useState(data?.data?.lastTwoDisabledUsers);
  const [displayedUserList, setDisplayedUserList] = useState("users");
  const [userSearchType, setUserSearchType] = useState("all")
  const isDesktop = IsDesktop()

  useEffect(() => {
    if (data?.data) {
      setUserCount(data.data.totalUsers - 2);
      setFoundersCount(data.data.totalFounders);
      setInvestorsCount(data.data.totalInvestors);
      setExpertsCount(data.data.totalExperts);
      setBlockedUserCount(data?.data?.totalBlockedUsers)
      setDisabledUserCount(data?.data?.totalDisabledUsers)
      setLastTwoUsers(data.data.lastTwoUsers);
      setLastTwoFounders(data.data.lastTwoFounders);
      setLastTwoExperts(data.data.lastTwoExperts);
      setLastTwoInvestors(data.data.lastTwoInvestors);
      setLastTwoBlockedUsers(data?.data?.lastTwoBlockedUser)
      setLastTwoDisabledUsers(data?.data?.lastTwoDisabledUsers)
    } else {
      refetch();
    }
  }, [data, refetch]);

  const searchContext = {
    "founders": 1,
    "investors": 2,
    "experts": 3,
    "users": "all",
    "blocked": "all",
    "disabled" : "all"
  }

  const changeUserListView = (userType) => {
    setDisplayedUserList(userType);
    setUserSearchType(searchContext[userType])
  };

  const viewUser = (userId) => {
    navigate(`../admin/view-user?userid=${userId}`);
  }

  return (
    <Box >
      <Header title={"Users"} />
      <Box className={`hide-scroll ${isDesktop ? 'max-h-[90vh] overflow-y-auto p-3' : ''} overflow-x-hidden px-3`} >
        
        <Flex  mt="3rem" mr="auto">
          <Box className="flex flex-wrap gap-4 w-full justify-center lg:justify-start items-center">
            <Box onClick={() => changeUserListView("users")} cursor="pointer" height="12.5rem" p={4} borderWidth="0.0625rem" borderRadius="1.25rem" boxShadow="lg" width="275px">
              <Flex justify="start">
                <Box bg="#fff" w="1.563rem" borderRadius="0.188rem" pl="0.063rem" boxShadow="lg" borderWidth="0.188rem">
                  <HiOutlineUserGroup />
                </Box>
                <Text color="#134A70" style={{marginLeft:'16px'}}>All users</Text>
                {/* <BiDotsVerticalRounded /> */}
              </Flex>
              <Flex mt="1.875rem">
                {lastTwoUsers?.map((each, index) => (
                  <Box>
                    <Avatar name={`${each?.first_name} ${each?.last_name}`} src={each?.profile_image} zIndex={index} ml={-2} />
                  </Box>
                ))}
                <Box display="flex" justifyContent="center" alignItems="center" bg="brand.primary" w="50px" h="50px" fontWeight="25rem" borderRadius="50%" p="0.188rem" boxShadow="lg" zindex="3" ml="-0.563rem" color="white">
                  {userCount ? `+${userCount}` : 0}
                </Box>
              </Flex>
              <Text mt="2.5rem" color="#134A70" fontWeight="25rem" fontFamily="Poppins" fontSize="0.75rem">
                {lastTwoUsers && `updated last  ${moment(lastTwoUsers[0]?.created_at).format("DD/MM/YYYY")}`}
              </Text>
            </Box>
            <Box cursor="pointer" onClick={()=> changeUserListView("founders")} width="275px" height="12.5rem" p={4} borderWidth="0.0625rem" borderRadius="1.25rem" boxShadow="lg" overflow="hidden" bg="#F10D35" box-shadow=".25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)">
              <Flex justify="start">
                <Box bg="#fff" w="1.563rem" borderRadius="0.188rem" pl="0.063rem" borderWidth="0.188rem">
                  <HiOutlineUserGroup />
                </Box>
                <Text color="white" style={{marginLeft:'16px'}}>Founders</Text>
                {/* <BiDotsVerticalRounded color="white" /> */}
              </Flex>
              <Flex mt="1.875rem">
                {lastTwoFounders?.map((each, index) => (
                  <Box>
                    <Avatar name={`${each?.first_name} ${each?.last_name}`} src={each?.profile_image} zIndex={index} ml={-2} />
                  </Box>
                ))}
                <Box display="flex" justifyContent="center" alignItems="center" bg="brand.primary" w="50px" h="50px" fontWeight="25rem" borderRadius="50%" p="0.188rem" boxShadow="lg" zindex="3" ml="-0.563rem" color="white">
                  {foundersCount ? `+${foundersCount}` : 0}
                </Box>
              </Flex>

              <Text mt="2.5rem" color="white" fontWeight="25rem" fontFamily="Poppins" fontSize="0.75rem">
                {lastTwoFounders && `updated last  ${moment(lastTwoFounders[0]?.created_at).format("DD/MM/YYYY")}`}
              </Text>
            </Box>

            <Box cursor="pointer" onClick={()=> changeUserListView("experts")} width="275px" height="12.5rem" p={4} borderWidth="0.063rem" borderRadius="1.25rem" overflow="hidden" boxShadow="lg" bg="#134A70" box-shadow="1.25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)" >
              <Flex justify="start">
                <Box bg="#fff" w="1.563rem" borderRadius="0.188rem" pl="0.063rem" borderWidth="0.188rem">
                  <HiOutlineUserGroup />
                </Box>
                <Text color="white" style={{marginLeft:'16px'}}>Experts</Text>
                {/* <BiDotsVerticalRounded color="white" /> */}
              </Flex>
              <Flex mt="1.875rem">
                {lastTwoExperts?.map((each, index) => (
                  <Box>
                    <Avatar name={`${each?.first_name} ${each?.last_name}`} src={each?.profile_image} zIndex={index} ml={-2} />
                  </Box>
                ))}
                <Box display="flex" justifyContent="center" alignItems="center" bg="brand.primary" w="50px" h="50px" fontWeight="25rem" borderRadius="50%" p="0.188rem" boxShadow="lg" zindex="3" ml="-0.563rem" color="white">
                  {expertsCount ? `+${expertsCount}` : 0}
                </Box>
              </Flex>

              <Text mt="2.5rem" color="white" fontWeight="25rem" fontFamily="Poppins" fontSize="0.75rem">
                {lastTwoExperts &&  `updated last ${ moment(lastTwoExperts[0]?.created_at).format("DD/MM/YYYY")}`}
              </Text>
            </Box>

            <Box cursor="pointer"  onClick={()=> changeUserListView("investors")} width="275px" height="12.5rem" p={4} borderWidth="0.063rem" borderRadius="1.25rem" overflow="hidden" boxShadow="lg" bg="#F8B748" box-shadow="1.25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)">
              <Flex justify="start">
                <Box bg="#fff" w="1.563rem" borderRadius="0.188rem" pl="0.063rem" borderWidth="0.188rem">
                  <HiOutlineUserGroup />
                </Box>
                <Text color="white" style={{marginLeft:'16px'}}>Investors</Text>
                {/* <BiDotsVerticalRounded color="white" /> */}
              </Flex>
              <Flex mt="1.875rem">
                {lastTwoInvestors?.map((each, index) => (
                  <Box>
                    <Avatar name={`${each?.first_name} ${each?.last_name}`} src={each?.profile_image} zIndex={index} ml={-2} />
                  </Box>
                ))}
                <Box display="flex" justifyContent="center" alignItems="center" bg="brand.primary" w="50px" h="50px" fontWeight="25rem" borderRadius="50%" p="0.188rem" boxShadow="lg" zindex="3" ml="-0.563rem" color="white">
                  {investorsCount ? `+${investorsCount}` : 0}
                </Box>
              </Flex>

              <Text mt="2.5rem" color="white" fontWeight="25rem" fontFamily="Poppins" fontSize="0.75rem">
              {lastTwoInvestors && `updated last ${moment(lastTwoInvestors[0]?.created_at).format("DD/MM/YYYY")}`}
              </Text>
            </Box>

            <Box className="bg-blue-400" cursor="pointer" onClick={()=> changeUserListView("disabled")} width="275px" height="12.5rem" p={4} borderWidth="0.063rem" borderRadius="1.25rem" overflow="hidden" boxShadow="lg" box-shadow="1.25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)">
              <Flex justify="start">
                <Box bg="#fff" w="1.563rem" borderRadius="0.188rem" pl="0.063rem" borderWidth="0.188rem">
                  <HiOutlineUserGroup />
                </Box>
                <Text color="white" style={{marginLeft:'16px'}}>Disabled</Text>
                {/* <BiDotsVerticalRounded color="white" /> */}
              </Flex>
              <Flex mt="1.875rem">
                {lastTwoDisabledUsers?.map((each, index) => (
                  <Box>
                    <Avatar name={`${each?.first_name} ${each?.last_name}`} src={each?.profile_image} zIndex={index} ml={-2} />
                  </Box>
                ))}
                <Box display="flex" justifyContent="center" alignItems="center" bg="brand.primary" w="50px" h="50px" fontWeight="25rem" borderRadius="50%" p="0.188rem" boxShadow="lg" zindex="3" ml="-0.563rem" color="white">
                  {disabledUserCount ? `+${disabledUserCount}` : 0}
                </Box>
              </Flex>

              <Text mt="2.5rem" color="white" fontWeight="25rem" fontFamily="Poppins" fontSize="0.75rem">
                {lastTwoDisabledUsers && `updated last ${moment(lastTwoDisabledUsers[0]?.updated_at).format("DD/MM/YYYY")}`}
              </Text>
            </Box>

            <Box className="bg-purple-400" cursor="pointer" onClick={()=> changeUserListView("blocked")} width="275px" height="12.5rem" p={4} borderWidth="0.063rem" borderRadius="1.25rem" overflow="hidden" boxShadow="lg"  box-shadow="1.25rem 1.25rem 2.5rem rgba(232, 83, 62, 0.2)">
              <Flex justify="start">
                <Box bg="#fff" w="1.563rem" borderRadius="0.188rem" pl="0.063rem" borderWidth="0.188rem">
                  <HiOutlineUserGroup />
                </Box>
                <Text color="white" style={{marginLeft:'16px'}}>Blocked</Text>
                {/* <BiDotsVerticalRounded color="white" /> */}
              </Flex>
              <Flex mt="1.875rem">
                {lastTwoBlockedUsers?.map((each, index) => (
                  <Box>
                    <Avatar name={`${each?.first_name} ${each?.last_name}`} src={each?.profile_image} zIndex={index} ml={-2} />
                  </Box>
                ))}
                <Box display="flex" justifyContent="center" alignItems="center" bg="brand.primary" w="50px" h="50px" fontWeight="25rem" borderRadius="50%" p="0.188rem" boxShadow="lg" zindex="3" ml="-0.563rem" color="white">
                  {blockedUserCount ? `+${blockedUserCount}` : 0}
                </Box>
              </Flex>

              <Text mt="2.5rem" color="white" fontWeight="25rem" fontFamily="Poppins" fontSize="0.75rem">
              {lastTwoBlockedUsers && `updated last ${moment(lastTwoBlockedUsers[0]?.updated_at).format("DD/MM/YYYY")}`}
              </Text>
            </Box>
          </Box>
        </Flex>
        <Flex w="100%">
          <Box mt="20px" w="100%">
            <Flex w="100%" justify="space-between" className="flex-row flex-wrap gap-5" alignItems="center">
              <SearchBar 
                status={displayedUserList === "blocked" || displayedUserList === "disabled" ? displayedUserList : null } 
                userType={userSearchType} 
              />
              <Flex className="flex-wrap gap-3 md:gap-0 justify-center" padding="7px 9px">
                <Button bg={displayedUserList === "users" ? "brand.primary" : "#FFFFFF"} color={displayedUserList === "users" ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px" onClick={() => changeUserListView("users")}>
                  All
                </Button>
                <Button bg={displayedUserList === "founders" ? "brand.primary" : "#FFFFFF"} color={displayedUserList === "founders" ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px" onClick={() => changeUserListView("founders")}>
                  Founders
                </Button>
                <Button bg={displayedUserList === "experts" ? "brand.primary" : "#FFFFFF"} color={displayedUserList === "experts" ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} borderRadius="5px 0px 0px 5px" boxShadow="base" onClick={() => changeUserListView("experts")}>
                  Experts
                </Button>              
                <Button bg={displayedUserList === "investors" ? "brand.primary" : "#FFFFFF"} color={displayedUserList === "investors" ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px 5px 5px 0px" onClick={() => changeUserListView("investors")}>
                  Investors
                </Button>
                <Button bg={displayedUserList === "disabled" ? "brand.primary" : "#FFFFFF"} color={displayedUserList === "disabled" ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px 5px 5px 0px" onClick={() => changeUserListView("disabled")}>
                  Disabled
                </Button>
                <Button bg={displayedUserList === "blocked" ? "brand.primary" : "#FFFFFF"} color={displayedUserList === "blocked" ? "#FFFFFF" : "brand.primary"} _hover={{ bg: "#134A70", color: "#FFFFFF" }} boxShadow="base" borderRadius="0px 5px 5px 0px" onClick={() => changeUserListView("blocked")}>
                  Blocked
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <Box className={`${isDesktop ? 'max-w-[75vw] xl:max-w-[83vw]' : 'max-w-98vw'} overflow-x-auto hide-scroll`}>
          {displayedUserList === "users" && <Users viewUser={viewUser} />}
          {displayedUserList === "founders" && <Founders viewUser={viewUser} />}
          {displayedUserList === "experts" && <UserExperts viewUser={viewUser} />}
          {displayedUserList === "investors" && <UserInvestors viewUser={viewUser} />}
          {displayedUserList === "disabled" && <RestrictedUsers type="disabled" viewUser={viewUser} />}
          {displayedUserList === "blocked" && <RestrictedUsers viewUser={viewUser} />}
          {/* <Recent /> */}
        </Box>
      </Box>
    </Box>
  );
};
export default AllUsers;
