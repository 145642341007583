import ComposeInternalLayout from "../components/HOC/ComposeInternalLayout";
import Survey from "../pages/survey/Survey";
import ViewUser from "../pages/viewUser/ViewUser";
import { privateUrls } from "../url/urls";

export const privateRoutes = [
    {
      title: 'Survey',
      path: privateUrls.survey(),
      element: Survey,
    },
    {
      title: 'ViewUser',
      path: privateUrls.viewUser(),
      element: ComposeInternalLayout(ViewUser),
    }
]