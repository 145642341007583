import React, { useState, useEffect } from "react";
import { 
  Box, 
  Button, 
  ButtonGroup, 
  Flex, Icon, 
  Image, Text, 
  useDisclosure, 
  FormControl,
  FormLabel,
  Switch,
  Tooltip,
} from "@chakra-ui/react";
import Header from "../../../components/header/Header";
import calendar from "../../../public/icons/calendar.svg";
import plus from "../../../public/icons/plus.svg";
import eventarrow from "../../../public/icons/eventarrow.svg";
import List from "../../../components/table/FoundersBookingList";
import TableAction from "../../../components/table/action/TableAction";
import useData from "../../../data/useData";
import Calendar from "../../../components/calendar/Calendar";
import CalendarDrawer from "../../../components/drawer/calendar/CalendarDrawer";
import IsDesktop from "../../../utils/IsDesktop";
import BookingModal from "../../../components/modal/BookingModal";
import moment from "moment";
import BrandButton from "../../../components/brandButton/BrandButton";
import { AiOutlineCalendar } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FounderBookingsCalendarWide from "../../experts/Dashboard/CalendarWide/FounderBookingsCalendarWide";
import { setUserInfo } from "../../../redux-toolkit/reducers/userSlice";
import UserService from "../../../utils/UserService";
import InvestorsBookingList from "../../../components/table/InvestorsBookingList";

const tableColumns = [
  {
    key: "founder",
    title: "Founder / Booking",
  },
  {
    key: "date",
    title: "Date",
  },
  {
    key: "start_time",
    title: "Time",
  },
  {
    key: "status",
    title: "Status",
  },
];

const buttonGroup = [
  {
    value: "upcoming",
    title: "Upcoming",
  },
  {
    value: "past",
    title: "Past",
  },
];

const BookingsDashboard = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const { data, isLoading, refetch, } = useData(`users/bookings/all`, 5000);
  const [bookings, setBookings] = useState([]);
  const isDesktop = IsDesktop();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams()
  const location = useLocation()
  const user = useSelector(
    (state) => state?.user
  );
  const [isChecked, setIsChecked] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    const _user = {
      ...user.userInfo,
      myBookings : [
        ...(data?.data?.bookings || [])
      ]
    }
    UserService.setUserInfo(_user);
    dispatch(setUserInfo(_user));
  }, [isLoading])


  return (
    <>
      <Box className="bg-[#f0f0f0]">
      <Header title="Bookings" />
      <Flex
        className={`flex-col p-4 ${
          isDesktop ? "max-h-[95vh] min-h-[90vh] pb-10 overflow-y-scroll" : ""
        }`}
      >
          <Box className="flex w-full items-end py-4 justify-end">
            <FormControl onChange={(e) => setIsChecked(e.target.checked)} className="flex w-full py-2 items-center justify-end">
              <FormLabel htmlFor='isChecked' mb='0'>
                View Calendar
              </FormLabel>
              <Switch size='lg' iid='isChecked' isChecked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
            </FormControl>
          </Box>
        
          {
            isChecked ? (
              <Box className="w-full mb-5">
                <FounderBookingsCalendarWide />
              </Box>
            ) : null
          }
          <Flex className="pb-20">
              <Box className="w-full">
                <InvestorsBookingList listType={activeIndex === 0 ? 'upcoming': 'past'} header={tableColumns} data={data?.data?.bookings} isLoading={isLoading} borderRadius="10px" refetch={refetch}/>
              </Box>
          </Flex>
      </Flex>
    </Box>
    </>
  );
};

export default BookingsDashboard;
