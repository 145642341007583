import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Avatar,
  Spinner,
  Button,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useData } from "../../../data";
import { CustomToast } from "../../../components/toast/CustomToast";
import { mutateFunction, useMutation } from "../../../libs/apis";


const Users = ({ viewUser }) => {
  const { data, isLoading } = useData("admins/investors");
  const [index, setIndex] = useState(-1);
  const { mutate, isLoading: Load } = useMutation(mutateFunction);
  const { addToast } = CustomToast();
  const [isSorted, setIsSorted] = useState(false)
  const [sortType, setSortType] = useState('date') 

  const triggerResetPassword = (e, user) => {
    e.stopPropagation();
    e.preventDefault();

    let data = {
      email: user?.email,
    };


    setIndex(user.user_id);
    mutate(
      { key: "forgot-password", method: "post", data },
      {
        onSuccess(res) {
          setIndex(-1);

          // showCheckEmail();
          // onToggleCheckEmail();
          addToast({
            message: "Sucesss, email reset process sent to user.",
            type: "info",
          });
        },
      }
    );
  };

  const compareStr = (a, b) => {
    if (a < b) {
      return -1;
    }else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }

  const compareDates = (a, b) => {
    if (moment(b).isBefore(a, "day")) {
      return 1;
    } else if (moment(a).isBefore(b, "day")) {
      return -1;
    } else {
      return 0;
    }
  }

  const accountTypes = {
    'active': 0,
    'disabled': 1,
    'blocked': 2
  }

  const sortFunctions = {
    'status': (a, b) => { return isSorted ? accountTypes[b?.status] - accountTypes[a?.status] : accountTypes[a?.status] - accountTypes[b?.status]},
    'profile': (a, b) => { return isSorted ? compareStr(a?.first_name, b?.first_name) : compareStr(b?.first_name, a?.first_name)},
    'email': (a, b) => { return isSorted ? compareStr(a?.email, b?.email) : compareStr(b?.email, a?.email)},
    'date' : (a, b) => { return isSorted ? compareDates(a?.date_added, b?.date_added) : compareDates(b?.date_added, a?.date_added)},
    'mode': (a, b) => { return isSorted ? compareStr(a?.companies[0]?.status || 'Draft', b?.companies[0]?.status || 'Draft') : compareStr(b?.companies[0]?.status || 'Draft', a?.companies[0]?.status || 'Draft' )}
  }

  const sortFunction = (a, b) => {
    return sortType && sortFunctions[sortType] && sortFunctions[sortType](a, b) 
  }

  const toggleSort = (type) => {
    type === sortType ? setIsSorted(prev => !prev) : setIsSorted(true)
    setSortType(type)
  }

  return (
    <Box maxHeight={"100vh"} className={`hide-scroll`} overflow={"auto"}>
      {/* --------------------table */}
      <TableContainer mb="50px" mt="50px" borderRadius="10px 10px 0px 0px">
        <Table variant="simple" borderWidth="2px" size="sm" fontSize="12px">
          <Thead bgColor="rgba(19, 74, 112, 0.05)">
            <Tr fontSize="2px">
              <Th className="cursor-pointer" onClick={() => toggleSort('profile')}>Profile</Th>
              <Th className="cursor-pointer" onClick={() => toggleSort('email')}>Email</Th>
              <Th>Phone no</Th>
              <Th>Country</Th>
              <Th>Position</Th>
              <Th className="cursor-pointer" onClick={() => toggleSort('date')}>Date Added</Th>
              <Th className="cursor-pointer" onClick={() => toggleSort('status')}>Status</Th>
              <Th className="cursor-pointer" onClick={() => toggleSort('mode')}>Mode</Th>
              <Th>Trigger Password Reset</Th>
              <Th>Last Contact</Th>
              <Th></Th>
            </Tr>
          </Thead>
          {!isLoading ? (
            <Tbody>
              {data?.data?.founders?.sort(sortFunction).map((items) => {
                return (
                  <Tr
                    style={{ padding: "5rem" }}
                    boxShadow="md"
                    borderRadius="10px"
                    _hover={{
                      borderWidth: "2px",
                      borderColor: "brand.primary",
                      borderRadius: "10px",
                    }}
                    bg={()=>{
                      let mode = items?.companies?.length > 0 ? items?.companies[0]?.status : 'Draft'
                      return mode.toLowerCase() === 'draft' ? '#faebeb' : '#eefaeb'
                    }}
                    onClick={() => viewUser(items.user_id)}
                  >
                    <Td
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "100%",
                      }}
                      px={2}
                    >
                      <Avatar
                        name={`${items?.first_name} ${items?.last_name}`}
                        src={items.profile_image}
                        alt=""
                        size="sm"
                        mr={2}
                      />
                      <Text>{`${items?.first_name} ${items?.last_name}`}</Text>
                    </Td>
                    <Td className="td">{items.email}</Td>
                    <Td className="td">{items.phone_number || "N/A"}</Td>
                    <Td className="td">{items.country || "N/A"}</Td>
                    <Td className="td">{items.position || "N/A"}</Td>
                    <Td className="td">
                      {moment(items?.date_added).format(`MMM D, YYYY`)}
                    </Td>
                    <Td className="td capitalize">
                      {items?.status}
                    </Td>
                    <Td className="td capitalize">{items?.companies[0]?.status || 'Draft'}</Td>
                    <Th
                      onClick={(e) => triggerResetPassword(e, items)}
                      className="td"
                    >
                      {items.user_id == index ? (
                        <Spinner />
                      ) : (
                        <>
                          <Button
                            _hover={{
                              borderWidth: "2px",
                              borderColor: "brand.primary",
                              borderRadius: "10px",
                            }}
                          >
                            Trigger
                          </Button>
                        </>
                      )}
                    </Th>
                    <Flex justify="space-evenly" alignItems="center" gap="1">
                      <Text>
                        <img src={items.lastContact} alt="" />
                        N/A
                      </Text>
                      <Text>{items.name}</Text>
                    </Flex>
                    {/* <Td className="td">
                    <BiDotsVerticalRounded />
                  </Td> */}
                  </Tr>
                );
              })}
            </Tbody>
          ) : (
            <Flex w="100%">
              <Spinner />
            </Flex>
          )}
        </Table>
      </TableContainer>
      {/* --------------------table */}
    </Box>
  );
};

export default Users;
