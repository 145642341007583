export default class UserService {
  static getToken() {
    return localStorage.getItem("Hub8020_token");
  }
  static getUserInfo() {
    return localStorage.getItem("Hub8020_user") !== "undefined"
      ? JSON.parse(localStorage.getItem("Hub8020_user"))
      : null;
  }
  static getUserSubscriptionInfo() {
    return localStorage.getItem("Hub8020_user_subscription_info") !==
      "undefined"
      ? JSON.parse(localStorage.getItem("Hub8020_user_subscription_info"))
      : null;
  }
  static getCompanyInfo() {
    return localStorage.getItem("Hub8020_company") !== "undefined"
      ? JSON.parse(localStorage.getItem("Hub8020_company"))
      : null;
  }
  static getAllCompaniesInfo() {
    return localStorage.getItem("Hub8020_all_companies") !== "undefined"
      ? JSON.parse(localStorage.getItem("Hub8020_all_companies"))
      : null;
  }

  static getExpertInfo() {
    const expertInfo = localStorage.getItem("Hub8020_expert");
    if (expertInfo && expertInfo !== "undefined") {
      return JSON.parse(expertInfo);
    } else {
      return null;
    }
  }

  static setToken(token) {
    localStorage.setItem("Hub8020_token", token);
  }
  static setUserInfo(user) {
    localStorage.setItem("Hub8020_user", JSON.stringify(user));
  }
  static setUserSubscriptionInfo(info) {
    localStorage.setItem(
      "Hub8020_user_subscription_info",
      JSON.stringify(info)
    );
  }

  static setCompanyInfo(company) {
    localStorage.setItem("Hub8020_company", JSON.stringify(company));
  }

  static setAllCompaniesInfo(company) {
    localStorage.setItem("Hub8020_all_companies", JSON.stringify(company));
  }

  static setExpertInfo(expert) {
    localStorage.setItem("Hub8020_expert", JSON.stringify(expert));
  }

  static removeToken() {
    localStorage.removeItem("Hub8020_token");
  }
  static removeUserInfo() {
    localStorage.removeItem("Hub8020_user");
  }
  static removeCompanyInfo() {
    localStorage.removeItem("Hub8020_company");
  }
  static removeAllCompaniesInfo() {
    localStorage.removeItem("Hub8020_all_companies");
  }

  static removeExpertInfo() {
    localStorage.removeItem("Hub8020_expert");
  }

  static removeUserSubscriptionInfo() {
    localStorage.removeItem("Hub8020_user_subscription_info");
  }

  static clearCredentials() {
    localStorage.clear();
  }
  static getCompleteSurveyAction() {
    return localStorage.getItem("Hub8020_complete_survey_action")
      ? localStorage.getItem("Hub8020_complete_survey_action")
      : false;
  }
  static setCompleteSurveyAction(action) {
    localStorage.setItem("Hub8020_complete_survey_action", action);
  }
}
