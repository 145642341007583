const evaluateCompanyProfile = (companyDetails = {}, navigate = () => {}) => {
    if(!companyDetails?.pitch_deck){
        navigate('/account-settings?edit=pitchDeck')
        return
    }
    if(!companyDetails?.video_url){
        navigate('/account-settings?edit=videoUrl')
        return 
    }
    if(!companyDetails?.company_stage_ids || companyDetails?.company_stage_ids?.length < 1){
        navigate('/account-settings?edit=companyStage')
        return 
    }
    if(!companyDetails?.incorporation_location_ids || companyDetails?.incorporation_location_ids?.length < 1){
        navigate('/account-settings?edit=incorporationLocation')
        return 
    }
    if(!companyDetails?.business_model_ids || companyDetails?.business_model_ids?.length < 1){
        navigate('/account-settings?edit=businessModel')
        return 
    }
    if(!companyDetails?.business_category_ids || companyDetails?.business_category_ids?.length < 1){
        navigate('/account-settings?edit=businessCategory')
        return 
    }
    if(!companyDetails?.fundCategoryId){
        navigate('/account-settings?edit=fundCategory')
        return 
    }
    if(!companyDetails?.problem || companyDetails?.problem == "null"){
        navigate('/account-settings?edit=problem')
        return 
    }
    if(!companyDetails?.solution || companyDetails?.solution == "null"){
        navigate('/account-settings?edit=solution')
        return 
    }
    if(!companyDetails?.market_growth || companyDetails?.market_growth == "null" ){
        navigate('/account-settings?edit=marketGrowth')
        return 
    }
    if(!companyDetails?.revenue_model || companyDetails?.revenue_model == "null"){
        navigate('/account-settings?edit=revenueModel')
        return 
    }
    if(!companyDetails?.competition || companyDetails?.competition == "null"){
        navigate('/account-settings?edit=competition')
        return 
    }
    if(!companyDetails?.fund_use || companyDetails?.fund_use== "null"){
        navigate('/account-settings?edit=fundsUse')
        return 
    }
    if(!companyDetails?.stakeholders || companyDetails?.stakeholders == "null"){
        navigate('/account-settings?edit=stakeholders')
        return 
    }
    if(!companyDetails?.customer_type || companyDetails?.customer_type == "null"){
        navigate('/account-settings?edit=customerType')
        return 
    }
}

export default evaluateCompanyProfile