import {Flex, Box } from "@chakra-ui/react";
import Header from "../../../components/header/Header";
import InvestorList from "../../../components/investors/InvestorsList";
import InvestorsPage from "../../../components/investors/InvestorsPage";
import IsDesktop from "../../../utils/IsDesktop";


const Investors = () => {
    const isDesktop = IsDesktop()
    return (
        <Box >
            <Header title='Investors'  />
            <Box className="p-4 bg-[#f0f0f0]">
                <InvestorList />
            </Box>
        </Box>
    );
}
 
export default Investors;