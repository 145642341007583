import {
  Box,
  Text,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import IsDesktop from "../../utils/IsDesktop";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import ConnectionRequestTabs from "../connectionRequests/ConnectionRequestTabs";
import { useDispatch, useSelector } from "react-redux";
import { useData } from "../../data";
import { useNavigate } from "react-router-dom";
import BrandButton from "../brandButton/BrandButton";
import { mutateFunction, useMutation } from "../../libs/apis";
import { setChatInfo } from "../../redux-toolkit/reducers/chatSlice";
import moment from "moment";

const Notifications = ({ isOpen, onClose }) => {
  const isDesktop = IsDesktop();
  const navigate = useNavigate();
  const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
  const { mutate, isLoading } = useMutation(mutateFunction);
  const dispatch = useDispatch();

  const [otherAllConnections, setOtherAllConnections] = useState();
  const [connectionNotifications, setConnectionNotification] = useState([])
  const [messageTracker, setMessageTracker] = useState({})


  const goToLink = (notificationId, link) => {
    readNotification(notificationId);
    navigate(link)
  }

  const chatWithConnection = (user, notificationId, type = '') => {

    if(type == 'message'){
      for(let _id of messageTracker[user?.id]?.ids || []){
        readNotification(_id)
      }
    }else{
      readNotification(notificationId);
    }
    

    //This can be problematic for unconnected users
    user.connectionMade = true

    dispatch(setChatInfo(user));
    navigate("../connections");
    onClose()
  };

  const goToUser = (e, connection, notificationId) => {
    e.stopPropagation();
    e.preventDefault();
    // onClose();
    readNotification(notificationId);
    navigate(
      `../view-user?userid=${connection.id}&usertype=${connection.user_type_id}`
    );
  };

  const readNotification = (notificationId) => {
    mutate(
      {
        key: `users/${notificationId}/notification`,
        method: "post",
      },
      {
        onSuccess(res) {
        },
      }
    );
  };

  const fetchOtherNotificationsForUser = () => {
    mutate(
      {
        key: `users/${loggedInUserId}/notification?page_no=1&page_size=10`,
        method: "get",
      },
      {
        onSuccess(res) {
          let _messageTracker = {} 
          setOtherAllConnections(res?.data?.notifications.filter(({type, senderId, id}) => {
            if(type == 'message'){
              let {count = 0, ids = []} =  _messageTracker[senderId] || {}
              count += 1
              ids = [...ids, id]
              _messageTracker[senderId] = {
                count, ids
              }
            }
            return type == 'message' ? !_messageTracker[senderId] || _messageTracker[senderId]?.count == 1 : type !== "connection_request"
          
          }));
          setConnectionNotification(res?.data?.notifications.filter(({type}) => type === "connection_request"));

          setMessageTracker(_messageTracker)
          // if (res?.data?.id) {
          //   addToast(res.data.message, { appearance: "info", onDismiss: handleDismiss, autoDismiss: false, id: res.data.id, className: "full-screen-toast" });
          // }
          // refetch();
        },
      }
    );
  };

  useEffect(() => {
    const fetchNotifications = () => {
      fetchOtherNotificationsForUser();
    };

    fetchNotifications();
    return () => {
      //clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Box>
        <Drawer onClose={onClose} isOpen={isOpen} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader p={0}>
              <Box>
                <Flex h="60px" w="100%" bg="brand.primary" px={5}>
                  <Flex h="100%" w="100%" alignItems="center">
                    <Box display={{ base: "none", md: "flex" }}>
                       <HiOutlineArrowNarrowLeft
                      cursor="pointer"
                      size="30px"
                      color="white"
                      onClick={onClose}
                    />
                    </Box>
                   
                    <Flex justifyContent="center" w="full">
                      <Text color="white">Notifications</Text>
                    </Flex>
                    <Box display={{ base: "flex", md: "none" }}>
                      <HiOutlineArrowNarrowLeft
                      cursor="pointer"
                      size="30px"
                      color="white"
                      onClick={onClose}
                    />
                    </Box>
                    
                  </Flex>
                </Flex>
              </Box>
            </DrawerHeader>
            <DrawerBody p={0}>
              <Flex h="40px" w="100%" bg="brand.primary" px={5}>
                <Tabs
                  minW="100%"
                  rounded="10px"
                  mr="20px"
                  mt="0px"
                  p="0"
                  variant="unstyled"
                >
                  <TabList h="100%">
                    <Tab
                      h="100%"
                      _focus={{ outline: "0 !important", color: "white" }}
                      _selected={{
                        outline: "0 !important",
                        color: "white",
                        borderBottom: "2px",
                        borderColor: "white",
                        paddingBottom: "0px",
                      }}
                      color="white"
                      fontSize={!isDesktop ? "12px" : "16px"}
                    >
                      <Text>
                        Connection Requests
                      </Text>
                      {
                        connectionNotifications?.length > 0 ? (
                          <Box className="w-2 h-2 ml-1 rounded-full bg-blue-300"/>
                        ) : null
                      }
                    </Tab>
                    <Tab
                      h="100%"
                      _focus={{ outline: "0 !important", color: "white" }}
                      _selected={{
                        outline: "0 !important",
                        color: "white",
                        borderBottom: "2px",
                        borderColor: "white",
                        paddingBottom: "0px",
                      }}
                      color="white"
                      fontSize={!isDesktop ? "12px" : "16px"}
                    >
                      <Text>
                        Others
                      </Text>
                      {
                        otherAllConnections?.length > 0 ? (
                          <Box className="w-2 h-2 ml-1 rounded-full bg-blue-300"/>
                        ) : null
                      }
                    </Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <ConnectionRequestTabs notifications={connectionNotifications.map(({type, sender, id}) => ({id, type, senderId: sender.id }))} onReadNotification={readNotification} onClose={onClose} />
                    </TabPanel>
                    <TabPanel>
                      <Box>
                        {otherAllConnections?.length > 0 ? (
                          otherAllConnections.map((eachConnection) => (
                            <Box className="relative">
                              <Flex
                                // key={eachConnection.key}
                                border={
                                  eachConnection?.read == false
                                    ? "2px solid #134A70"
                                    : "none"
                                }
                                key={eachConnection.id}
                                mb={4}
                                px={4}
                                py={2}
                                justifyContent="space-between"
                                alignItems={"center"}
                                w="100%"
                                cursor={"pointer"}
                                boxShadow="0px 0px 4px lightgray"
                                onClick={() =>
                                  eachConnection?.type == "message" ? chatWithConnection(
                                    eachConnection?.sender,
                                    eachConnection.id,
                                    eachConnection?.type
                                  ) : goToLink(eachConnection?.id, eachConnection?.link)
                                }
                              >
                                <Flex
                                  minW="60%"
                                  onClick={(e) =>
                                    goToUser(
                                      e,
                                      eachConnection?.sender,
                                      eachConnection.id
                                    )
                                  }
                                  alignItems="center"
                                >
                                  <Box mr={4}>
                                    <Avatar
                                      borderRadius="10px"
                                      name={`${eachConnection?.sender?.first_name} ${eachConnection?.sender?.last_name}`}
                                      src={
                                        eachConnection?.sender?.profile_image
                                      }
                                      size={ "sm"}
                                    />
                                  </Box>
                                  <Box d="flex" flexDir="column">
                                    <Text
                                      fontWeight={500}
                                      fontSize={isDesktop ? "16px" : "12px"}
                                    >
                                      {`${eachConnection?.sender?.first_name} ${eachConnection?.sender?.last_name}`}
                                    </Text>
                                    {
                                      eachConnection?.type !== "message" ? (
                                        <Text
                                          fontWeight={500}
                                          fontSize={"14px"}
                                        >
                                          {eachConnection?.title}
                                        </Text>
                                      ) : null
                                    }
                                    {
                                      eachConnection?.type == "message" ? (
                                        <Text
                                          fontWeight={300}
                                          fontSize={isDesktop ? "14px" : "12px"}
                                        >
                                          {eachConnection?.message.substring(0, 22)}
                                          ...
                                        </Text>
                                      ) : null
                                    }
                                    
                                    {
                                      eachConnection?.type === "booking_rejected" ? (
                                        <Text
                                          fontWeight={300}
                                          fontSize={isDesktop ? "14px" : "12px"}
                                        >
                                          <span className="font-medium">Reason:</span> {eachConnection?.message.split('_')[1] || 'Not specified'}
                                        </Text>
                                      ) : null
                                    }
                                    
                                  </Box>
                                </Flex>
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                  gap={2}
                                >
                                  <Text
                                    fontWeight={500}
                                    fontSize={isDesktop ? "16px" : "12px"}
                                    whiteSpace="nowrap"
                                  >
                                    {moment(eachConnection.createdAt).fromNow()}
                                  </Text>
                                  {
                                    eachConnection?.type == "message" ? (
                                      <Box
                                        display={{ base: "none", md: "flex" }}
                                      >
                                        <BrandButton
                                          h="35px"
                                          text="Chat"
                                          width="100px"
                                          colorScheme="blue"
                                        />
                                      </Box>
                                    ) : null
                                  }

                                  {/* )} */}
                                </Flex>
                              </Flex>
                              {
                                messageTracker[eachConnection.senderId] && messageTracker[eachConnection.senderId]?.count > 1 ? (
                                  <Box className="w-fit absolute right-[-7px] top-[-10px] p-1.5 bg-gray-300 text-xs font-semibold rounded-full flex items-center justify-center">
                                    {`+${messageTracker[eachConnection.senderId]?.count}`} 
                                  </Box>
                                ) : null
                              }
                            </Box>
                          ))
                        ) : (
                          <>
                            {isLoading ? (
                              <Spinner />
                            ) : (
                              <Box>You have no new notifications.</Box>
                            )}
                          </>
                        )}
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
};
export default Notifications;
