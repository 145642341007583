import React, { useEffect, useState } from "react";
import { Box, Flex, Text, VStack, Avatar, HStack, Divider, Spinner } from "@chakra-ui/react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import IsDesktop from "../../utils/IsDesktop";
import { useData } from "../../data";
import ExpertBookingCalendar from "./ExpertBookingCalendar";
import { useLocation, useSearchParams } from "react-router-dom";
import { CustomToast } from "../toast/CustomToast";
import { mutateFunction, useMutation } from "../../libs/apis";
import { useSelector } from "react-redux";
import moment from "moment";


const ViewExpertDetails = ({expert, onToggle}) =>{
    const isDesktop = IsDesktop();
    const {data, refetch} = useData(`users/${expert}`)
    const [userData, setUserData] = useState(null)
    const { mutate, isLoading: mutateIsLoading} = useMutation(mutateFunction);
    const { addToast } = CustomToast()
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const loggedInUserId = useSelector((state) => state?.user?.userInfo?.id);
    const loggedInUser = useSelector((state) => state?.user?.userInfo);
    const [slotId, setSlotId] = useState(null)
    const [slotDetails, setSlotDetails] = useState(null)
    const { data: bookingsData, isLoading: isLoadingBookingsData, refetch: refetchBookingsData, } = useData(`users/bookings/all`, 5000);
    const [relatedBookings, setRelatedBookings] = useState([])

    useEffect(()=>{
        if(data?.data){
            setUserData(data.data.user)
            setRelatedBookings((bookingsData?.data?.bookings || []).filter(booking => {
              return booking?.expert_id === data?.data?.user?.id && moment().isSameOrBefore(booking?.expert_slot?.slot_end_date, 'day')
        }))
        }
    },[data, bookingsData, bookingsData?.data?.bookings?.length, isLoadingBookingsData])

    useEffect(() => {
      setSlotId(searchParams.get('slot_id'))
    }, [])

  

    useEffect(() => {
      
      const _ = () => {
        let _slotDetails;
        (bookingsData?.data?.bookings || []).some((booking) => {
          if(slotId && booking?.slot_id == slotId){ 
            _slotDetails = booking.expert_slot
        }
          return booking?.slot_id == slotId
        })
        if(_slotDetails)setSlotDetails(_slotDetails)
      }
      _()
    }, [slotId])


    const acceptRequest =  () => {
        mutate(
          { key: `users/${loggedInUserId}/connections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
          {
            onSuccess(res) {
              addToast(res);
              setSearchParams({
                userid: expert,
                usertype: 3
              })
              refetch()
            },
          }
        );
      }
    
      const declineRequest =  () => {
        mutate(
          { key: `users/${loggedInUserId}/declineConnections/${userData?.connection?.connection_id  || userData?.connection?.id}`, method: "put" }, 
          {
            onSuccess(res) {
              addToast(res);
              setSearchParams({
                userid: expert,
                usertype: 3
              })
              refetch()
            },
          }
        );
      }


      useEffect(() => {
        if (userData) {
          if(location?.search?.includes("status=accept")){
            acceptRequest()
          }
          if(location?.search?.includes("status=decline")){
            declineRequest()
          }
        }
    }, [userData, location?.search]);

    return (
        <>
        {userData !== null ? (<>
            <Flex cursor="pointer" onClick={onToggle} justifyContent="flex-start" alignItems="center">
                <Box mr={2}><HiOutlineArrowNarrowLeft size="35px"/></Box>
                <Box><Text fontWeight={600}>Back</Text></Box>
            </Flex>
            <VStack overflowY="auto" h="85vh" bg="white" borderRadius="10px"  justifyContent="space-between" alignItems="flex-start">
                <Flex h="100%" alignItems="center" className="p-8" justifyContent="center" w="100%">
                        <HStack flexDir={isDesktop ? "row" : "column"} h="100%" spacing={isDesktop ? 10 : 0} w="100%" alignItems="flex-start" justifyContent="flex-start">
                            <VStack w={isDesktop ? "50%" : "90%"} ml={isDesktop ? 5 : 0} mb={10} alignItems="flex-start" justifyContent="center">
                                <Box className="flex flex-col gap-2 mb-8">
                                  <Box borderRadius="50%" >
                                      <Avatar name={`${userData?.first_name} ${userData?.last_name}`} src={userData?.profile_image} size={isDesktop ? "2xl" : "lg"}/>
                                  </Box>
                                  <Box>
                                      <Text fontWeight={700}>
                                          {`${userData?.first_name} ${userData?.last_name}`}
                                      </Text>
                                      
                                  </Box>
                                </Box>

                                <Box className={`w-full mx-auto px-5 py-5 border mb-10 rounded-lg lg:px-4`}> 
                                    <Box className="flex flex-col xl:flex-row gap-8">
                                    <Box className="flex flex-col gap-4">
                                        <Text className="text-[#2F4B6E] text-lg font-medium">
                                         Expert Overview
                                        </Text>
                                        <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                                          <Text
                                            className="w-[120px]"
                                            fontWeight={500}
                                            color="#C0BCBC"
                                          >
                                            Industry:
                                          </Text>
                                          <Box className="w-full">
                                            <Text
                                              color="#000"
                                              className="font-medium"
                                            >
                                              {userData?.expert_price?.expert_category?.title}
                                            </Text>
                                          </Box>
                                        </Flex>
                                        <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                                          <Text
                                            className="w-[120px]"
                                            fontWeight={500}
                                            color="#C0BCBC"
                                          >
                                            Location:
                                          </Text>
                                          <Box className="w-full">
                                            <Text
                                              color="#000"
                                              className="font-medium"
                                            >
                                              {userData?.expert_price?.location?.title}

                                            </Text>
                                          </Box>
                                        </Flex>
                                        <Flex className="flex flex-wrap lg:flex-nowrap w-full gap-1">
                                          <Text
                                            className="w-[120px]"
                                            fontWeight={500}
                                            color="#C0BCBC"
                                          >
                                            About:
                                          </Text>
                                          <Box className="w-full">
                                            <Text
                                              color="#000"
                                              className="font-medium"
                                            >
                                              {userData?.bio}

                                            </Text>
                                          </Box>
                                        </Flex>
                                    </Box>
                                    </Box>
                                  </Box>
                                <Box my={"24px"} className="flex flex-col gap-4 py-10 w-full">
                                  <Text className="text-lg font-medium" color={"brand.primary"}>Bookings</Text>
                                    {
                                      slotDetails?.id ? (
                                        <Box className="flex flex-col gap-2 my-4">
                                          <Text className="font-medium mb-1">Here are your booking details</Text>
                                          <Text>
                                            <strong>Title:</strong> {slotDetails?.slot_title}
                                          </Text>
                                          <Text>
                                            <strong>Date:</strong>  {moment(slotDetails?.slot_start_date).format('DD-MM-YYYY')}
                                          </Text>
                                          <Text>
                                            <strong>Time:</strong>  {moment(slotDetails?.slot_start_time, "kk:mm:ss").format('hh:mm a')} - {moment(slotDetails?.slot_end_time, 'kk:mm:ss').format('hh:mm a')}
                                          </Text>
                                        </Box>
                                      ) : relatedBookings?.length > 0 ? relatedBookings?.map((booking) => {
                                        return (
                                          <Box className="border-b border-blue-400 my-6 py-2 w-full">
                                             <Text>
                                              <strong>Title:</strong> {booking?.expert_slot?.slot_title}
                                            </Text>
                                            <Text>
                                              <strong>Date:</strong>  {moment(booking?.expert_slot?.slot_start_date).format('DD-MM-YYYY')}
                                            </Text>
                                            <Text>
                                              <strong>Time:</strong>  {moment(booking?.expert_slot?.slot_start_time, "kk:mm:ss").format('hh:mm a')} - {moment(booking?.expert_slot?.slot_end_time, 'kk:mm:ss').format('hh:mm a')}
                                            </Text>
                                            <Text className="capitalize" > <strong>Status:</strong> {booking.status}</Text>
                                          </Box>
                                        )
                                      }) : <Box className="my-3">
                                        <Text className="text-semibold text-sm text-gray-600">You currently have no bookings with this expert.</Text>
                                      </Box>
                                    }
                                </Box>
                            </VStack>
                            <Box className="hidden lg:block h-full">
                              <Divider orientation="vertical"/>
                            </Box>
                            <Box h="100%" mt={5} className="w-full max-w-[700px] xl:max-w-[800px]">
                                    <Text className="text-lg font-medium" color="brand.primary" my={4}>
                                        Booking Hours with {`${userData?.first_name}`} (Seed A Founder)
                                    </Text>
                                <Box>
                                    <Box>
                                        <Box>
                                            <Text fontWeight={500} color='#9FA7AD'>Select a Day</Text>
                                            <Text color="gray">Select a suitable day to have your call from the available days below.</Text>
                                            <Text className="text-gray-600 text-sm italic">Dates highlighted in blue have available slots.</Text>
                                        </Box>
                                        <Box display="flex" justifyContent="center" alignItems="center" minW="280px" maxW="530px" p={2} m={2}>
                                            <ExpertBookingCalendar refetch={refetchBookingsData} expert={expert} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </HStack>
                    {/* </Box> */}
                </Flex>
            </VStack>
        </>
        ):(
        <Box><Spinner/></Box>
        )}
        </>
    )
}
export default ViewExpertDetails;