import { Box } from "@chakra-ui/react";
import IsDesktop from "../utils/IsDesktop";
import { useLocation } from "react-router-dom";
import { trackPage } from "../helpers/analytics";
import { useEffect } from "react";

const InternalLayout = ({ Component, breadCrumbs, ...otherProps }) => {
    const isDesktop = IsDesktop()
    const location = useLocation()
    useEffect(() => {
        trackPage(`${location.pathname}${location?.search || ''}`)
    }, [location])
    return (
        <>
            <Box className={`${!isDesktop ? 'mt-[80px]' : ''}`}>
                <main>
                    <Component {...otherProps}/>
                </main>
            </Box>
        </>
    );
}
 
export default InternalLayout;