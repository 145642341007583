import { createSlice } from "@reduxjs/toolkit";
import UserService from "../../utils/UserService";

const initialState = {
  isAuthenticated: !!UserService.getToken()
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    }
  }
});

export const { setIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;