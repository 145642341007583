import { Image, Box } from "@chakra-ui/react";
import dashboard from "../../../public/icons/dashboard.svg"
import dashboardActive from "../../../public/icons/dashboardActive.svg"
import connections from "../../../public/icons_v2/message.svg"
import connectionsActive from "../../../public/icons_v2/messageActive.svg"
import investors from "../../../public/icons_v2/profile-add.svg"
import investorsActive from "../../../public/icons_v2/profile-add-active.svg"
import startup from "../../../public/icons_v2/cup.svg"
import startupActive from "../../../public/icons_v2/cupActive.svg"
import experts from "../../../public/icons_v2/user-octagon.svg"
import expertsActive from "../../../public/icons_v2/user-octagon-active.svg"
import settings from "../../../public/icons_v2/setting.svg"
import settingsActive from "../../../public/icons_v2/settingActive.svg"
import { HUB_8020_AUTH_USER } from "../../../helpers/constant"
import users from "../../../public/icons/users.svg"
import creditCard from "../../../public/icons/creditCard.svg"
import { useEffect, useMemo, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";



const Navs = () => {
    const user = JSON.parse(localStorage.getItem(HUB_8020_AUTH_USER));
    const accountType = user?.userTypeTitle
    const founderNav = useMemo(()=>{
        return [
        {
            to: '/dashboard',
            name: 'Dashboard',
            icon: <Image h='22px' w='22px' src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/feed',
            name: 'Feed',
            icon: <Image h='22px' w='22px' src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/connections',
            name: 'Connections',
            icon: <Image h='22px' w='22px' src={connections} alt='connections-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={connectionsActive} alt='connections-icon'/>
        },
        {
            to: '/investors',
            name: 'Investors',
            icon: <Image h='22px' w='22px' src={investors} alt='investors-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={investorsActive} alt='investors-icon'/>
        },
        {
            to: '/experts',
            name: 'Experts',
            icon: <Image h='22px' w='22px' src={experts} alt='experts-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={expertsActive} alt='experts-icon'/>
        },
        {
            to: '/startup-offers',
            name: 'Startup Offers',
            icon: <Image h='22px' w='22px' src={startup} alt='startup-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={startupActive} alt='startup-icon'/>
        },
        {
            to: '/bookings',
            name: 'Bookings',
            icon: <Image h='22px' w='22px' src={investors} alt='investors-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={investorsActive} alt='investors-icon'/>
        },
        {
            to: '/account-settings',
            name: 'Settings',
            icon: <Image h='22px' w='22px' src={settings} alt='settings-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={settingsActive} alt='settings-icon'/>
        }
    ]
    }, [])

    const expertNav = useMemo(()=>{ return [
        {
            to: '/dashboard',
            name: 'Dashboard',
            icon: <Image h='22px' w='22px' src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/feed',
            name: 'Feed',
            icon: <Image h='22px' w='22px' src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/founders',
            name: 'Founders',
            icon: <Image h='22px' w='22px' src={investors} alt='founder-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={investorsActive} alt='founder-icon'/>
        },
        {
            to: '/connections',
            name: 'Connections',
            icon: <Image h='22px' w='22px' src={connections} alt='connections-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={connectionsActive} alt='connections-icon'/>
        },
        {
            to: '/account-settings',
            name: 'Settings',
            icon: <Image h='22px' w='22px' src={settings} alt='settings-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={settingsActive} alt='settings-icon'/>
        }
    ]}, [])

    const adminNav = useMemo(()=>{ return [
        {
            to: '/dashboard',
            name: 'Dashboard',
            icon: <Image h='22px' w='22px' src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/users',
            name: 'Users',
            icon: <Image h='22px' w='22px' src={users} alt='all-users-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={dashboardActive} alt='all-users-icon'/> 
        },
        {
            to: '/startup-offers',
            name: 'Startup Offers',
            icon: <Image h='22px' w='22px' src={startup} alt='startup-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={startupActive} alt='startup-icon'/> 
        },
        {
            to: '/brands',
            name: 'Brands',
            icon: <Image h='22px' w='22px' src={startup} alt='startup-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={startupActive} alt='startup-icon'/>
        },
        {
            to: '/admin/subscriptions',
            name: 'Subscriptions',
            icon: <Image h='22px' w='22px' src={creditCard} alt='connections-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={creditCard} alt='connections-icon'/>
        },
        {
            to: '/admin/adverts',
            name: 'Adverts',
            icon: <Image h='22px' w='22px' src={experts} alt='experts-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={expertsActive} alt='experts-icon'/>
        },
        {
            to: '/admin/deleted-users',
            name: 'Deleted Users',
            icon: <Image h='22px' w='22px' src={experts} alt='experts-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={expertsActive} alt='experts-icon'/>
        },
        {
            to: '/account-settings',
            name: 'Settings',
            icon: <Image h='22px' w='22px' src={settings} alt='settings-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={settingsActive} alt='settings-icon'/>
        }
    ]}, [])

    const investorNav = useMemo(()=>{ return [
        {
            to: '/dashboard',
            name: 'Dashboard',
            icon: <Image h='22px' w='22px' src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/feed',
            name: 'Feed',
            icon: <Image h='22px' w='22px' src={dashboard} alt='dashboard-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={dashboardActive} alt='dashboard-icon'/>
        },
        {
            to: '/connections',
            name: 'Connections',
            icon: <Image h='22px' w='22px' src={connections} alt='connections-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={connectionsActive} alt='connections-icon'/>
        },
        {
            to: '/founders',
            name: 'Founders',
            icon: <Image h='22px' w='22px' src={investors} alt='founder-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={investorsActive} alt='founder-icon'/>
        },
        {
            to: '/bookings',
            name: 'Bookings',
            icon: <Image h='22px' w='22px' src={investors} alt='investors-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={investorsActive} alt='investors-icon'/>
        },
        {
            to: '/account-settings',
            name: 'Settings',
            icon: <Image h='22px' w='22px' src={settings} alt='settings-icon'/>,
            activeIcon: <Image h='22px' w='22px' src={settingsActive} alt='settings-icon'/>
        }
    ]}, [])
    const [navs, setNavs] = useState(founderNav)


    useEffect(()=>{
        switch (accountType) {
            case "admin":
                setNavs(adminNav);
                break;
            case "expert":
                setNavs(expertNav);
                break
            case "investor":
                setNavs(investorNav);
                break;
            default:
                setNavs(founderNav);
        }

    },[accountType, adminNav, expertNav, investorNav, founderNav])
    
    return navs;
}
export default Navs;