import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "Poppins, Inter, sans-serif"
      },
      a: {
        color: "teal.500",
        _hover: {
          textDecoration: "underline",
        },
      },
    }
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 700,
        fontFamily: "Poppins, Inter, sans-serif"
      }
    },
  },
  zIndices: {
    hide: -1,
    auto: "auto",
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1000,
    modal: 1000,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800
  },
  colors: {
    brand: {
      primary: "#134A70",
      secondary: "rgba(19,74,112,0.50)",
      dark: "#062F36",
      borderColor: "rgba(0, 0, 0, 0.08)",
      white: "#ffffff",
      black: "#000000",
    },
  },
  fontWeights: {
    heading: 700,
    bolder: 800,
    boldest: 900
  }
});

export const mainColors = {
  primary: "#134A70",
  gray: "#F2F2F2",
  borderColor: "rgba(0, 0, 0, 0.08)",
  white: "#ffffff"
};

export default customTheme;