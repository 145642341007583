import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    messages : {}
}

const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {
        setAllUserMessages(state, action) {
            state.messages = action.payload
        },
        addNewMessages(state, action){
            let _messages = {...state.messages}
            action.payload.messages.forEach(item => {
                _messages[`${action.payload.messageId}`] = [
                    ...(_messages[`${action.payload.messageId}`] || []),
                   item
                ]
            });
            state.messages = _messages
        },
        addNewMessage(state, action){
            state.messages = {
                ...state.messages,
                [`${action.payload.messageId}`] : [
                    ...(state.messages[`${action.payload.messageId}`] || []),
                    action.payload.message
                ]
            }
        }
    }
});

export const { setAllUserMessages, addNewMessage, addNewMessages }  = messagesSlice.actions

export default messagesSlice.reducer